export const CounterType = Object.freeze({
  TIMERANGEAGGREGATE: "TIMERANGEAGGREGATE",
  ONSCREENCOUNT: "ONSCREENCOUNT"
});

export const ViewType = Object.freeze({
  GRIDVIEW: "GRIDVIEW",
  LISTVIEW: "LISTVIEW"
});

export const defaultHeight = 10,
defaultWidth = 6,
defaultX = 0,
minWidth= 6,
maxWidth=30,
minHeight=10,
maxHeight=10,
PROPERTY_BASE_NAME = "counterWidget";

export const CustomOperators = Object.freeze([
  { label: "DISTINCT", value: "DISTINCT" }
]);