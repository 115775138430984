import React from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import * as widgets from '../widgets/widgetCatalogue';

const WidgetList = ({fade, modal, dashboard, closeModal, clickHandler}) => {

    let thumbnailList = [];
    Object.values(widgets).forEach(function (widget, index) {
        let widgetNumber = dashboard.widgets && dashboard.widgets.length > 0 ?
            dashboard.widgets.filter((obj) => { return obj.type === widget.type;}).length : 0;
        let isDisabled = widget.maxAllowed && widgetNumber >= widget.maxAllowed;
        thumbnailList.push(<widget.thumbnail key={index} isDisabled={isDisabled} clickHandler = {clickHandler.bind(this, widget.type)} />);
    });

    return (
        <Modal className="modal-dialog-centered" size="lg" isOpen={modal} backdrop="static" fade={fade}>
            <ModalHeader toggle={closeModal}>Select Widget</ModalHeader>
            <ModalBody>
                <div className="select-widget-wrapper d-flex align-items-center">
                    <div>
                        <div className="card-deck">
                            {thumbnailList}
                        </div>
                    </div>
                </div>
            </ModalBody>
            <ModalFooter>
                <button type="button" className="btn btn-outline-secondary" onClick={closeModal}>Close</button>
            </ModalFooter>
        </Modal>
    );
};

WidgetList.propTypes = {
    fade: PropTypes.bool,
    modal: PropTypes.bool,
    dashboard: PropTypes.object,
    clickHandler: PropTypes.func,
    closeModal: PropTypes.func
}

export default WidgetList;
