import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Modal, ModalHeader, ModalFooter, Button} from "reactstrap";
import {v1 as uuidv1} from 'uuid';
import ConfigurationForm from "./configurationBody";
import {defaultCameraHeight,defaultCameraWidth,defaultX,
    defaultCameraSelectorHeight,defaultCameraSelectorWidth,validate,
    addCameraView,addCameraSelector} from './constants';

export default class CameraConfigurationModal extends Component{
    constructor(props){
        super(props);
        this.state = {
            label : "",
            description : "",
            selectedCameraFeeds: [],
            message:""
        }
    }

    closeModal = () => {
        this.setState({
            label : "",
            description: "",
            selectedCameraFeeds: [],
            message:""
        },() => {
            this.props.closeModal();
        });
    }

    fieldUpdateHandler = (event) => {
        this.setState({[event.target.name] : event.target.value});
    }

    setSelectedCameraFeeds = (e,obj) => {
        let selectedOptions = Object.assign([],this.state.selectedCameraFeeds);
        let index = selectedOptions.findIndex(x => x.serviceUrl === obj.serviceUrl)
        if( index === -1){
            selectedOptions.push(obj);
        }
        else{
            selectedOptions.splice(index, 1);
        }
        this.setState({
            selectedCameraFeeds : selectedOptions
        });
    }

    submitHandler = () => {
        const {getVerticalAlignmentY} = this.props;
        let {label, description, selectedCameraFeeds} = this.state;
        let validationMessage = validate(label, selectedCameraFeeds)
        if(validationMessage.length === 0){
            let verticalAlignmentY = getVerticalAlignmentY(defaultCameraWidth)
            let cameraViewConfig = addCameraView(label, description, selectedCameraFeeds, defaultCameraHeight,
                defaultCameraWidth, defaultX, verticalAlignmentY);
            cameraViewConfig.id = uuidv1();
            if(selectedCameraFeeds.length > 1) {
                let cameraSelectorConfig = addCameraSelector(label, description, selectedCameraFeeds,
                    cameraViewConfig.name,cameraViewConfig.id, defaultCameraSelectorHeight,
                    defaultCameraSelectorWidth, defaultX, (verticalAlignmentY + defaultCameraHeight));
                cameraSelectorConfig.id = uuidv1();
                cameraViewConfig.config.dependentWidgetId = [cameraSelectorConfig.id];
                this.props.addWidget(cameraSelectorConfig).then(() => {
                    this.props.addWidget(cameraViewConfig);
                    this.closeModal();
                });
            } else {
                this.props.addWidget(cameraViewConfig).then(() => {
                    this.closeModal();
                });
            }
        } else {
            this.setState({
                message : validationMessage.join(', ')
            },()=>{
                setTimeout(() => {
                    this.setState({message:''})
                }, 5000)
            })
        }
    }

    showWidgetSelectionPage = () =>{
        this.setState( {
            label : "",
            description: "",
            selectedCameraFeeds: [],
            message:""
        },() => {
            this.props.showWidgetSelectionPage();
        })
    }

    render(){
        let {message, label, description, selectedCameraFeeds} = this.state;
        let {modal, widgetCreationMetadata, getVerticalAlignmentY} = this.props;
        let verticalAlignmentY = getVerticalAlignmentY(defaultCameraWidth)
        let cameraViewMetadata = addCameraView(label, description, selectedCameraFeeds,
            defaultCameraHeight, defaultCameraWidth, defaultX, verticalAlignmentY);
        cameraViewMetadata.id = 'cameraWidget';
        let cameraSelectorMetadata = addCameraSelector(label, description, selectedCameraFeeds,
            cameraViewMetadata.name, cameraViewMetadata.id, defaultCameraSelectorHeight,
            defaultCameraSelectorWidth, defaultX, (verticalAlignmentY + defaultCameraHeight));
        return(
            <Modal className="modal-dialog-centered" size="lg" isOpen={modal} backdrop="static" fade={false}>
                <ModalHeader toggle={this.closeModal} charCode= "×">
                    <span>Select Widget</span>
                    <span>Configure widget</span>
                </ModalHeader>
                <ConfigurationForm
                    cameraViewMetadata={cameraViewMetadata}
                    message={message}
                    cameraSelectorMetadata={cameraSelectorMetadata}
                    widgetCreationMetadata={widgetCreationMetadata}
                    fieldUpdateHandler={this.fieldUpdateHandler}
                    setSelectedCameraFeeds={this.setSelectedCameraFeeds}
                />
                <ModalFooter className="align-content-center">
                    <Button color="primary" onClick={this.submitHandler}>Add</Button>
                    <Button outline color="secondary" onClick={this.showWidgetSelectionPage}>Previous</Button>
                </ModalFooter>
            </Modal>
        );
    }
}

CameraConfigurationModal.propTypes = {
    modal: PropTypes.bool,
    widgetCreationMetadata: PropTypes.object,
    closeModal: PropTypes.func,
    getVerticalAlignmentY: PropTypes.func,
    showWidgetSelectionPage: PropTypes.func,
    addWidget: PropTypes.func
};