import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {widgetLibrary} from '../../utils/widgetUtils';

export default class EditWidgetIndex extends Component {
    render(){
        const {modal, getWidgetById, timeZone, getVerticalAlignmentY, addWidget,
            deleteWidget, closeModal, updateWidget, widgetConfigDataForEdit,
            widgetCreationMetadata, applicationId, addCustomSelectionForTimeRangeWidget} = this.props;
        let widget = widgetConfigDataForEdit.type? widgetLibrary[widgetConfigDataForEdit.type]:null;
        if(widget && modal){
            return (
                <widget.editConfiguration
                    modal={modal}
                    widgetConfigDataForEdit={widgetConfigDataForEdit}
                    widgetCreationMetadata={widgetCreationMetadata}
                    closeModal={closeModal}
                    updateWidget={updateWidget}
                    timeZone={timeZone}
                    getVerticalAlignmentY={getVerticalAlignmentY}
                    addCustomSelectionForTimeRangeWidget={addCustomSelectionForTimeRangeWidget}
                    applicationId={applicationId}
                    getWidgetById={getWidgetById}
                    deleteWidget={deleteWidget}
                    addWidget={addWidget}
                />
            )
        }
        else{
            return null
        }
    }
}

EditWidgetIndex.propTypes = {
    modal: PropTypes.bool,
    applicationId: PropTypes.string,
    timeZone: PropTypes.string,
    widgetConfigDataForEdit: PropTypes.object,
    widgetCreationMetadata: PropTypes.object,
    addCustomSelectionForTimeRangeWidget:PropTypes.func,
    closeModal: PropTypes.func,
    updateWidget: PropTypes.func,
    getVerticalAlignmentY: PropTypes.func,
    getWidgetById: PropTypes.func,
    deleteWidget: PropTypes.func,
    addWidget: PropTypes.func
}