import React, { Component } from 'react';
import PropTypes from 'prop-types';
import SideNav, { NavItem, NavIcon, NavText } from '@trendmicro/react-sidenav';
import '@trendmicro/react-sidenav/dist/react-sidenav.css';
import DashboardTab from './dashboardTab';
import Avatar from 'react-avatar';
import LoadingImage from '../../../img/loader.gif';
import { Dropdown, Modal, ModalBody, ModalHeader, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import AddLayouts from './addLayouts';
import Alert from '../common/custom-alert';
import DeleteModalPanel from '../common/deleteModal';
import {getLayoutConfiguration} from './utils';
import RoboVisionLogo from "../../../img/robovision-logo.svg";
import RoboVisionLogoDark from '../../../img/robovision-logo-dark.svg';
import { baseURL } from '../../store/constants/urls';

const theme = localStorage.getItem('theme')
const robovisionLogo = theme === 'dark' ? RoboVisionLogo : RoboVisionLogoDark;
export default class ApplicationPreviewIndex extends Component {
    constructor() {
        super();
        this.state = {
            activeDashboardID: "",
            dropdownOpen: false,
            showAddLayoutsPopUp: false,
            showDeleteLayoutConfirmationPopUp: false,
            message: "",
            layoutName: "",
            changeLayoutFlag : false
        }
    }

    UNSAFE_componentWillMount() {
        this.props.getApplicationDetail(this.props.applicationId);
    }

    UNSAFE_componentWillReceiveProps (newProps) {
        const {currentApp} = newProps.application;
        let arrLength = Array.isArray(currentApp.dashboards) && currentApp.dashboards.length;
        if(arrLength > 0) {
            this.setState({
                activeDashboardID : JSON.parse(JSON.stringify(currentApp)).dashboards[0].id
            }, () =>{
                const {activeDashboardID} = this.state;
                let layoutName = "Default";
                let changeLayoutFlag = false;
                let layout = JSON.parse(localStorage.getItem('layouts'));
                let layoutObj = layout ? layout.find(x => x.id === activeDashboardID) : null;
                if(layoutObj && layoutObj.layouts.length > 0){
                    let layoutIndividual = layoutObj.layouts.find(x => x.isActive === true)
                    if(layoutIndividual){
                        layoutName = layoutIndividual.name;
                    }
                }
                this.setState({
                    layoutName: layoutName,
                    changeLayoutFlag: changeLayoutFlag
                })
            });
        }
    }

    resetChangeLayoutFlag = () => {
        setTimeout(() => {
            this.setState({changeLayoutFlag: false});
        }, 1000);
    }

    tabClickHandler  = (layoutName) => {
        let layout = JSON.parse(localStorage.getItem('layouts'))
        const {activeDashboardID} = this.state;
        if(layoutName === "Default"){
            layout.find(x => x.id === activeDashboardID).layouts.find(y=> y.isActive === true).isActive = false;
        }
        else{
            if(layout.find(x => x.id === activeDashboardID).layouts.find(y=> y.isActive === true)){
                layout.find(x => x.id === activeDashboardID).layouts.find(y=> y.isActive === true).isActive = false;
            }
            layout.find(x => x.id === activeDashboardID).layouts.find(y=> y.name === layoutName).isActive = true;
        }
        localStorage.setItem('layouts', JSON.stringify(layout));
        this.setState({
            layoutName: layoutName,
            changeLayoutFlag: true
        }, this.resetChangeLayoutFlag);
    }

    toggle = () => {
        this.setState({
            dropdownOpen: !this.state.dropdownOpen
        });
    }
    setDeleteLayoutPopUpFlagFalse = (args) =>{
        this.setState({
            showDeleteLayoutConfirmationPopUp:false
        }, () =>{
            if(args){
                args.callback();
            }
        })
    }

    layoutChangedFunc = () =>{
        this.setState({
            changeLayoutFlag:true
        }, this.resetChangeLayoutFlag)
    }

    deleteLayout = () =>{
        const {activeDashboardID,layoutName} = this.state;
        let layout = JSON.parse(localStorage.getItem('layouts'));
        let layoutObj = layout.find(x => x.id === activeDashboardID).layouts;
        layoutObj = layoutObj.filter(x => { if(x.name !== layoutName){return x}})
        layout.find(x => x.id === activeDashboardID).layouts = layoutObj;
        localStorage.setItem('layouts', JSON.stringify(layout));
        this.setState({
            layoutName: "Default"
        },() =>{
            this.setDeleteLayoutPopUpFlagFalse({callback:this.layoutChangedFunc})
        })
    }

    changeDashBoard = (id) =>{
        let layout = JSON.parse(localStorage.getItem('layouts'))
        if(layout){
            let layoutObj = layout.find(x => x.id === id)
            if(layoutObj){
                if(layoutObj.layouts.length > 0){
                    let layoutIndividual = layoutObj.layouts.find(x => x.isActive === true)
                    if(layoutIndividual){
                        this.setState({
                            layoutName: layoutIndividual.name,
                            activeDashboardID: id,
                            changeLayoutFlag: false
                        })
                    }
                    else{
                        this.setState({
                            layoutName: "Default",
                            activeDashboardID: id,
                            changeLayoutFlag: false
                        })
                    }
                }
                else{
                    this.setState({
                        layoutName: "Default",
                        activeDashboardID: id,
                        changeLayoutFlag: false
                    })
                }
            }
            else{
                this.setState({
                    layoutName: "Default",
                    activeDashboardID: id,
                    changeLayoutFlag: false
                })
            }
        }
        else{
            this.setState({
                layoutName: "Default",
                activeDashboardID: id,
                changeLayoutFlag: false
            })
        }
    }

    getDropdownMenuItem = () => {
        const {currentApp} = this.props.application;
        let dropdownObj = []
        currentApp.dashboards.map((obj, index) => {  
            const imgUrl = `${baseURL}/applications/${obj.id}/dashboardImages`;   
            dropdownObj.push(
                <NavItem key={index} eventKey={obj.name}>
                    <NavIcon title={obj.name}>
                        <div className="avatar-container">
                            <Avatar src={imgUrl} name={obj.name} size="30" round={true} />
                        </div>      
                    </NavIcon>
                    <NavText>
                        {obj.name}
                    </NavText>
                    <div className="seperator"></div>
                </NavItem>
            )
        })
        return dropdownObj;
    }

    closeModal = (args) => {
        this.setState({
            showAddLayoutsPopUp: false
        },() =>{
            if(args && args.callback){
                args.callback();
            }
        })
    }

    addLayoutHandler = (name) =>{
        const {activeDashboardID} = this.state;
        let layouts = JSON.parse(localStorage.getItem('layouts'));
        if(layouts){
            if(layouts.find(x => x.id === activeDashboardID)){
                if(layouts.find(x => x.id === activeDashboardID).layouts.find(x => x.isActive === true))
                {
                    layouts.find(x => x.id === activeDashboardID).layouts.find(x => x.isActive === true)
                        .isActive = false;
                }
                layouts.find(x => x.id === activeDashboardID).layouts.push({
                    name: name,
                    isActive: true,
                    widgetsConfiguration: getLayoutConfiguration()
                })
            }
            else{
                layouts.push({
                    id: activeDashboardID,
                    layouts: [{
                        name: name,
                        isActive:true,
                        widgetsConfiguration: getLayoutConfiguration()
                    }]
                })
            }
        }
        else{
            layouts = [{
                id: activeDashboardID,
                layouts: [{
                    name: name,
                    isActive:true,
                    widgetsConfiguration: getLayoutConfiguration()
                }]
            }]
        }
        localStorage.setItem('layouts', JSON.stringify(layouts));
        this.setState({
            layoutName: name
        },() =>{
            this.closeModal({callback:this.layoutChangedFunc});
        })
    }

    updateLayoutHandler = () =>{
        const {activeDashboardID,layoutName} = this.state;
        let layouts = JSON.parse(localStorage.getItem('layouts'));
        layouts.find(x => x.id === activeDashboardID).layouts
            .find(x => x.name === layoutName).widgetsConfiguration = getLayoutConfiguration();
        layouts.find(x => x.id === activeDashboardID).layouts
            .find(x => x.name === layoutName).isActive = true;
        localStorage.setItem('layouts', JSON.stringify(layouts));
        this.setState({
            message: "The Layout " + layoutName + " is updated successfully."
        }, () => {
            setTimeout(() => {
                this.setState({ message : '' });
            }, 5000);
        })
    }

    getLayoutsItems = () =>{
        const {activeDashboardID,layoutName} = this.state;
        let listItem=[
            <DropdownItem key={0} className="cursor-pointer" tag="a"
                style={{color:("Default" === layoutName)?"rgb(246, 143, 61)":"#7F7F7F", backgroundColor: "white"}}
                onClick={this.tabClickHandler.bind(this,"Default")}>
                Default
            </DropdownItem>
        ]
        let layouts = JSON.parse(localStorage.getItem('layouts'));
        if(layouts && layouts.find(x => x.id === activeDashboardID)){
            let layoutList = layouts.find(x => x.id === activeDashboardID).layouts;
            layoutList.forEach((obj,index) =>{
                listItem.push(
                    <DropdownItem key={index + 1} className="cursor-pointer" tag="a"
                        style={{
                            color:(obj.name === layoutName)?"rgb(246, 143, 61)":"#7F7F7F",
                            backgroundColor: "white"
                        }}
                        onClick={this.tabClickHandler.bind(this,obj.name)}>
                        {obj.name}
                    </DropdownItem>
                );
            })
        }
        return listItem;
    }

    showAddModal = () =>{
        this.setState({
            showAddLayoutsPopUp: true
        })
    }

    showDeleteModal = () =>{
        this.setState({
            showDeleteLayoutConfirmationPopUp:true
        })
    }

    render() {
        const {currentApp} = this.props.application;
        const {showAddLayoutsPopUp,layoutName,message,changeLayoutFlag,activeDashboardID,
            showDeleteLayoutConfirmationPopUp} = this.state;
        if(currentApp){
            let activeDashboardName = "";
            if (currentApp.dashboards.length > 0) {
                let activeDashboard = currentApp.dashboards.find(x => x.id === activeDashboardID);
                activeDashboardName = activeDashboard ? activeDashboard.name : ""; 
            }
            let applicationIcon = currentApp.icon?currentApp.icon : robovisionLogo;
            let applicationIconUrl = currentApp.iconUrl?currentApp.iconUrl:"#";
            return (
                <React.Fragment>
                    <header>
                        <nav className="navbar navbar-expand-md navbar-light bg-light h-100">
                        <a href={applicationIconUrl} target="_blank" rel="noopener noreferrer"><Avatar  src={applicationIcon} alt="Robovision" size={70} /></a>
                            <div id="navbarText" className="collapse navbar-collapse">
                                <ol className="breadcrumb p-0 mb-0 ml-3 mr-auto">
                                    <li className="breadcrumb-item">
                                        <a style={{cursor:"default"}}>{currentApp.name}</a>
                                    </li>
                                    <li className="breadcrumb-item active">
                                        <a style={{cursor:"default"}}>{activeDashboardName}</a>
                                    </li>
                                    <div className="position-absolute d-flex" style={{right: "85px"}}>
                                        <div className="pr-5 d-flex">
                                            <i className="icon-app iconsItem cursor-pointer mr-3"
                                                title="Add Layout" onClick={this.showAddModal}/>
                                            <i title="Save Layout" onClick={this.updateLayoutHandler}
                                                className={(layoutName === "Default")
                                                    ?"icon-save-button buttonTextDisabled mr-3"
                                                    :"icon-save-button iconsItem cursor-pointer mr-3"
                                                }
                                            />
                                            <i title="Delete Layout" onClick={this.showDeleteModal}
                                                className={(layoutName === "Default")
                                                    ?"icon-delete buttonTextDisabled mr-3"
                                                    :"icon-delete iconsItem cursor-pointer mr-3"
                                                }
                                            />
                                        </div>
                                        <li className="f-13">
                                            <Dropdown isOpen={this.state.dropdownOpen} toggle={this.toggle}>
                                                <DropdownToggle className="cursor-pointer" tag="a" style={{color: "#7F7F7F"}} caret>
                                                    {layoutName}
                                                </DropdownToggle>
                                                <DropdownMenu style={{zIndex:"1050"}}>
                                                    {this.getLayoutsItems()}
                                                </DropdownMenu>
                                            </Dropdown>
                                        </li>
                                    </div>
                                </ol>
                            </div>
                           
                        </nav>
                    </header>
                    <main className="preview-dashboard d-flex dashboard-container px-0">
                        <div className="h-100 pr-1 pt-1 pb-1 parent-nav">
                            <SideNav onSelect={(selected) => {
                                this.changeDashBoard(currentApp.dashboards.find(x => x.name === selected).id);
                            }}>
                                <SideNav.Toggle />
                                <SideNav.Nav defaultSelected={activeDashboardName}>
                                    {this.getDropdownMenuItem()}
                                </SideNav.Nav>
                            </SideNav>
                        </div>
                        <div>
                            <Alert className="dashboard-alert position-absolute" color="success">{message}</Alert>
                        </div>
                        <div className="container-fluid h-100 overflow-auto preview-dashboard-container">
                            <div className="row">
                                <div className="tab-content px-3 w-100">
                                    {!changeLayoutFlag?
                                        <DashboardTab
                                            currentApp={currentApp}
                                            activeDashboardID={activeDashboardID}
                                            layoutName={layoutName}
                                        />
                                        : null
                                    }
                                </div>
                            </div>
                        </div>
                    </main>
                    {showAddLayoutsPopUp?
                        <Modal className="modal-dialog modal-dialog-centered modal-sm"
                            isOpen={showAddLayoutsPopUp} backdrop="static" fade={false}>
                            <ModalHeader toggle={this.closeModal} charCode= "×">
                                <span>Add New Layout</span>
                            </ModalHeader>
                            <AddLayouts
                                addLayoutHandler={this.addLayoutHandler}
                                activeDashboardID={activeDashboardID}
                                closeModal={this.closeModal}
                            >
                            </AddLayouts>
                        </Modal>
                        :null
                    }
                    <DeleteModalPanel
                        name = {layoutName}
                        type = 'application'
                        modal = {showDeleteLayoutConfirmationPopUp}
                        deleteContent = {this.deleteLayout}
                        closeDeleteModal = {this.setDeleteLayoutPopUpFlagFalse}
                    />
                    <Modal className="modal-dialog modal-dialog-centered modal-sm"
                        isOpen={changeLayoutFlag} backdrop="static" fade={false}>
                        <ModalHeader>
                            Switching to Layout
                            <span style={{fontWeight:"600"}}> {layoutName} </span>
                        </ModalHeader>
                        <ModalBody>
                            <div className="d-flex h-100 justify-content-center">
                                <img className="d-flex align-self-center" src={LoadingImage} />
                            </div>
                        </ModalBody>
                    </Modal>
                </React.Fragment>
            );
        } else {
            return(
                <main className="preview-dashboard dashboard-container px-0">
                    <div className="d-flex h-100 justify-content-center">
                        <img className="d-flex align-self-center" src={LoadingImage} />
                    </div>
                </main>
            );
        }
    }
}

ApplicationPreviewIndex.propTypes = {
    application: PropTypes.object,
    applicationId: PropTypes.string,
    getApplicationDetail: PropTypes.func
};
