import React, { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import { FormGroup, Label, Input, Button } from 'reactstrap'
import { baseURL } from '../../store/constants/urls';
import { v4 } from 'uuid';

let timezones = moment.tz.names().filter((s) =>{ return /\//.test(s) && !s.includes('US/') });

const CreateEditAppModal = ({name,
    description,
    dataSource,
    icon,
    imagePreview,
    iconUrl,
    timeZone,
    applicationId,
    removeIcon,
    fieldUpdateHandler}) => {
    const defaultState  = applicationId ? `${baseURL}applications/${applicationId}/applicationImage` : '';
    const [imgSrc, setImgSrc] = useState(defaultState);
    const removeImg = () => {
        setImgSrc(''); 
        removeIcon();
        document.getElementById('application-icon').value = null;
    };
    return(
        <div className="px-2 d-flex align-items-center">
            <div className="w-100">
                <div className="form-group">
                    <label htmlFor="application-name">Application name</label>
                    <input id="application-name" required type="text" name="name" value={name} className="form-control" onChange={fieldUpdateHandler}/>
                </div>
                <div className="form-group">
                    <label htmlFor="app-description">Description<span className="text-muted font-weight-light"> (Optional)</span></label>
                    <textarea id="app-description" rows="3" className="form-control" name="description" value={description} onChange={fieldUpdateHandler}/>
                </div>
                <div className="form-group">
                    <label htmlFor="datasource-url">RVAI access URL</label>
                    <input id="datasource-url" type="url" required placeholder="172.0.0.0/sample/url"
                        name="dataSource" value={dataSource} className="form-control" onChange={fieldUpdateHandler}/>
                </div>
                <FormGroup>
                    <Label for="application-icon">
                        {(imagePreview || imgSrc) ? 'Change Application Icon' : 'Upload Application Icon (Optional)'}
                    </Label>
                    <div style={{display: 'flex'}}>
                        <Input type="file" name="icon" id="application-icon"
                            accept="image/png, image/jpeg, image/gif, image/jpg" 
                            placeholder="Upload Application Icon" onChange={fieldUpdateHandler}/>
                        {(imagePreview || imgSrc) && <Button outline color="secondary" onClick={removeImg}>Remove</Button>}
                    </div>
                </FormGroup>
                {imagePreview ? <img id="blah" src={imagePreview} height="100" width="100" title="Image to be uploaded" /> : null}
                <div>{!imagePreview ? <img src={`${imgSrc}?uid=${v4()}`} title="Current image" onError={(e)=>{e.target.style.display='none'; removeImg()}} height="100" width="100" /> : null}</div>
                <div className="form-group">
                    <label htmlFor="application-icon-url">Application Icon URL<span className="text-muted font-weight-light"> (Optional)</span></label>
                    <input id="application-icon-url" type="url" placeholder="172.0.0.0/sample/url"
                        name="iconUrl" value={iconUrl} className="form-control" onChange={fieldUpdateHandler}/>
                </div>
                <div className="form-group">
                    <label htmlFor="timeZone">Select Timezone</label>
                    <select id="timeZone" required className="form-control custom-select" name="timeZone" value={timeZone} onChange={fieldUpdateHandler}>
                        <option value="">Select Timezone</option>
                        {timezones.map((e, key) => {
                            return <option key={key} value={e}>{e}</option>;
                        })}
                    </select>
                </div>
            </div>
        </div>
    );
}

CreateEditAppModal.propTypes = {
    name: PropTypes.string,
    description: PropTypes.string,
    applicationId: PropTypes.string,
    dataSource: PropTypes.string,
    icon: PropTypes.object,
    imagePreview: PropTypes.string,
    iconUrl: PropTypes.string,
    timeZone: PropTypes.string,
    fieldUpdateHandler: PropTypes.func,
    removeIcon: PropTypes.func,
};

CreateEditAppModal.defaultProps = {
    iconUrl: '',
}

export default CreateEditAppModal;