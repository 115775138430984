import React, { Component } from 'react';
import { Router, Route } from 'react-router-dom';
import IndexContainer from '../containers';
import AppContainer from "../containers/appContainer";
import DashboardContainer from "../containers/dashboardContainer";
import AppPreviewContainer from "../containers/appPreviewContainer";

import history from './history';
import { applyTheme } from '../utils/commonUtils';

class Routes extends Component {
    componentDidMount() {
        const theme = localStorage.getItem('theme');
        theme && applyTheme(theme)
    }

    render () {
        return (
            <Router history={history}>
                <React.Fragment>
                    <Route exact path="/" component={IndexContainer} />
                    <Route exact path="/applications/:applicationId" component={AppContainer} />
                    <Route exact path="/applications/:applicationId/preview" component={AppPreviewContainer} />
                    <Route exact path="/applications/:applicationId/dashboards/:dashboardId"
                        component={DashboardContainer} />
                </React.Fragment>
            </Router>
        );
    }
}

export default Routes;
