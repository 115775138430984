import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FormGroup, Label, Input, Button } from 'reactstrap';
import { baseURL } from '../../store/constants/urls';
import { v4 } from 'uuid';

const CreateEditDashboardModal = ({ name, imagePreview, removeIcon, dashboardId, description, icon, fieldUpdateHandler, layouts, handleLayoutChange, disabled, layoutid, natsSubject }) => {
    const defaultState  = dashboardId ? `${baseURL}applications/${dashboardId}/dashboardImages` : '';
    const [imgSrc, setImgSrc] = useState(defaultState);
    const removeImg = () => {
        setImgSrc(''); 
        removeIcon();
        document.getElementById('dashboard-icon').value = null;
    };
    return (
        <React.Fragment>
            <FormGroup>
                <Label for="dashboard-name">Dashboard Name</Label>
                <Input required={true} type="text" name="name" id="dashboard-name" value={name}
                    placeholder="Enter the dashboard name here" onChange={fieldUpdateHandler} />
            </FormGroup>
            <FormGroup>
                <Label for="dashboard-description">Description</Label>
                <Input type="text" name="description" id="dashboard-description" value={description}
                    placeholder="Enter the dashboard description here" onChange={fieldUpdateHandler} />
            </FormGroup>
            <FormGroup>
                <Label for="dashboard-icon">
                    {(imagePreview || imgSrc) ? 'Change Dashboard Icon' : 'Upload Dashboard Icon (Optional)'}
                </Label>
                <div style={{display: 'flex', marginBottom: '6px'}}>
                <Input type="file" name="icon" id="dashboard-icon"
                    accept="image/png, image/jpeg, image/gif, image/jpg"
                    placeholder="Upload Dashboard Icon" onChange={fieldUpdateHandler}/>
                {(imagePreview || imgSrc) && <Button outline color="secondary" onClick={removeImg}>Remove</Button>}
                </div>
                {imagePreview ? <img id="blah" src={imagePreview} height="100" width="100" title="Image to be uploaded" /> : null}
                <div>{!imagePreview ? <img src={`${imgSrc}?uid=${v4()}`} title="Current image" onError={(e)=>{e.target.style.display='none'; removeImg()}} height="100" width="100" /> : null}</div>
            </FormGroup>
            <FormGroup>
                <Label for="selectLayout">Layout</Label>
                <select required className="custom-select" id="clientName" onChange={handleLayoutChange} disabled={disabled} value={layoutid}>
                    <option value="">Select Layout</option>
                    {layouts.map((layout) => <option key={layout.layoutId} data-nats-subject={layout.natsSubject} value={layout.layoutId}>{layout.layoutName}</option>
                    )}
                </select>
            </FormGroup>
            <input type="hidden" name="natsSubject" id="natsSubject" value={natsSubject}></input>
        </React.Fragment>
)};



CreateEditDashboardModal.propTypes = {
    name: PropTypes.string,
    description: PropTypes.string,
    fieldUpdateHandler: PropTypes.func,
    layouts: PropTypes.array,
    handleLayoutChange: PropTypes.func,
    disabled: PropTypes.bool,
    icon:PropTypes.object,
    layoutid: PropTypes.string,
    natsSubject: PropTypes.string,
    dashboardId: PropTypes.string,
    imagePreview: PropTypes.string,
    removeIcon: PropTypes.func,
}

export default CreateEditDashboardModal;
