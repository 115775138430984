import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {orderByOptions} from './constants'
import { FormGroup, Label } from 'reactstrap';
export default class OrderByQuery extends Component {

    updateField = (event) => {
        let{config} = this.props;
        // adding the below check for the older widgets that do not have the orderBy section
        let orderBy = config.orderBy ? config.orderBy : {};
        this.props.updateOrderBy({
            field : event.target.value,
            order: orderBy.order
        });
    }

    updateOrder = (event) => {
        let{config, updateOrderBy} = this.props;
        // adding the below check for the older widgets that do not have the orderBy section
        let orderBy = config.orderBy ? config.orderBy : {};
        updateOrderBy({
            field : orderBy.field,
            order: event.target.value
        });
    }

    render(){
        let{config} = this.props;
        // adding the below check for the older widgets that do not have the orderBy section
        let orderBy = config.orderBy ? config.orderBy : {};

        let orderByFields = [<option key={0} value="">Select a field</option>];
        orderByFields.push(<option key={1} value="time">time</option>);
        let orderSequenceOptions = [<option key={0} value="">Select order sequence</option>];
        Object.keys(orderByOptions).forEach((key, i) => {
            orderSequenceOptions.push(<option key={i+1} value={key}>{orderByOptions[key]}</option>)
        });
        return(
            <React.Fragment>
                <FormGroup className="mb-0">
                    <Label> Order By </Label>
                </FormGroup>
                <div>
                    <div className="input-group">
                        <div className="input-group-prepend w-50">
                            <select className="form-control group-input custom-select" name="field"
                                defaultValue={orderBy.field} onChange={this.updateField} >
                                {orderByFields}
                            </select>
                        </div>
                        <div className="input-group-append w-50">
                            <select className="form-control group-input custom-select" name="order"
                                defaultValue={orderBy.order} onChange={this.updateOrder} >
                                {orderSequenceOptions}
                            </select>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

OrderByQuery.propTypes= {
    config: PropTypes.object,
    updateOrderBy: PropTypes.func
};