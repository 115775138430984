import { connect } from 'react-redux'
import DashboardWidget from './dashboardWidget';
import {clearFilters, addFilterForData, deleteWidget} from '../../../store/actions/widgetActions'
const mapStateToProps = (state, ownProps) => ({
    metadata: ownProps.metadata,
    editDeleteFlag: ownProps.editDeleteFlag,
    widgetConfigDataForEdit: ownProps.widgetConfigDataForEdit
})

const mapDispatchToProps = {
    deleteWidget: deleteWidget,
    clearFilters: clearFilters,
    addFilterForData: addFilterForData
}

const DashboardWidgetContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(DashboardWidget)

export default DashboardWidgetContainer;