import { SET_CAMERA_FEED_URL, REMOVE_CAMERA_FEED_URL } from '../constants/actionTypes';
import { setData } from '../../utils/storeUtils';

const setCameraFeedUrl = (state, data) => {
  let dataParsed = JSON.parse(JSON.stringify(data));
  let cameraFeedUrl = dataParsed['pipeline']['accessPoints']['http'];
  return setData(state, "cameraFeedUrl", cameraFeedUrl)
  // return setData(state, "cameraFeedUrls", {
  //   ...state.cameraFeedUrl,
  //   streamId: cameraFeedUrl
  // });
}

const cameraReducer = (state = {}, action) => {
    switch (action.type) {
        case SET_CAMERA_FEED_URL:
            return setCameraFeedUrl(state, action.data);
        case REMOVE_CAMERA_FEED_URL:
            return {
                ...state,
                cameraFeedUrl: '',
            }
        default:
            return state;
    }
};

export default cameraReducer;
