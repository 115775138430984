import React, {Component} from "react";
import PropTypes from 'prop-types';
import { Responsive, WidthProvider } from "react-grid-layout";
import {widgetLibrary, generateWidgetHeight} from '../../utils/widgetUtils';
import {gridDefaultProps} from '../common/constants';

const ResponsiveReactGridLayout = WidthProvider(Responsive);

export default class DashboardCanvas extends Component{
    constructor(props){
        super(props);
        this.state = {
        };
    }

    static defaultProps = gridDefaultProps;

    addWidgetsToCanvas = () => {
        let self = this;
        let {dashboard}  = this.props;
        let widgetComponents = [];
        dashboard.widgets.forEach((widget) => {
            widgetComponents.push(self.createElement({
                id: widget.id,
                type: widget.type,
                name: widget.name,
                description: widget.description,
                x: widget.xCoordinate,
                y: widget.yCoordinate,
                w: widget.width,
                minW:widget.minWidth,
                maxW:widget.maxWidth,
                ...generateWidgetHeight(widget.type, widget.config, widget.height),
                config: widget.config
            }));
        });
        return widgetComponents;
    }

    createElement = (widgetConfig) => {
        let {getWidgetConfigData, timeZone, applicationId, dashboard, deleteWidget} = this.props;
        let WidgetComponent = widgetLibrary[widgetConfig.type].widget;
        const isTimeRange = widgetConfig.type === 'TIME_RANGE_SELECTOR';
        return (
            <div key={widgetConfig.id} data-grid={widgetConfig} className={isTimeRange && 'height-auto-grid-item'}>
                <WidgetComponent
                    getWidgetConfigData={getWidgetConfigData}
                    metadata={widgetConfig}
                    deleteWidget={deleteWidget}
                    applicationId={applicationId}
                    dashboardId={dashboard.id}
                    editDeleteFlag={true}
                    timeZone={timeZone}
                />
            </div>
        );
    }

    onLayoutChange = (layout) => {
       
        let {dashboard} = this.props;
        if(dashboard.widgets && dashboard.widgets.length > 0){
            let updatedWidgetItems = [];
            layout.forEach(function (component) {
                let selectedWidget = dashboard.widgets.find(function (item) {
                    return item.id === component.i;
                });
                if(selectedWidget) {
                    selectedWidget.xCoordinate = component.x;
                    selectedWidget.yCoordinate = component.y;
                    selectedWidget.height = component.h;
                    selectedWidget.width = component.w;
                    updatedWidgetItems.push(selectedWidget);
                }
            });
            dashboard.widgets = updatedWidgetItems;
            this.props.setDashboard(dashboard);
        }
    }

    render(){
        return(
            <div className="col w-100 h-100">
                <ResponsiveReactGridLayout onLayoutChange={this.onLayoutChange} {...this.props} >
                    {this.addWidgetsToCanvas()}
                </ResponsiveReactGridLayout>
            </div>
        );
    }
}

DashboardCanvas.propTypes = {
    applicationId: PropTypes.string,
    timeZone: PropTypes.string,
    dashboard: PropTypes.object,
    setDashboard: PropTypes.func,
    deleteWidget: PropTypes.func,
    getWidgetConfigData: PropTypes.func
}