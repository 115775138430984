"use strict";
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Form } from 'reactstrap';
import history from '../../router/history';
import CreateEditAppModal from '../common/createEditAppModal';
import Alert from '../common/custom-alert';
import { RVAI_DEFAULT_METADATA_URL } from '../common/constants'

const errorMessages = {
    MESSAGE_VALID_EXTENSION : "Upload a valid file (Allowed extensions .jpeg/.jpg/.png/.gif only)",
    MESSAGE_VALID_SIZE: "Image size exceeds! (max size limit 500kb)"
};
export default class CreateApp extends Component {
    constructor() {
        super();
        this.state = {
            name: "",
            description: "",
            dataSource: RVAI_DEFAULT_METADATA_URL,
            imagePreview: "",
            icon:null,
            iconUrl:"",
            timeZone: "",
            applicationId: "",
            message: ""
        }
    }

    resetMessage = () => {
        setTimeout(() => {
            this.setState({ message: '' })
        }, 5000)
    }

    removeIcon = () => {
        this.setState({
            icon:null,
            imagePreview: "",
        })
    }

    fieldUpdateHandler = (event) => {

        if(event.target.name === "icon")
        {
            const file = event.target.files[0];
                
            this.setState({
                icon : event.target.files[0],
                imagePreview:  URL.createObjectURL(event.target.files[0])
            });
        }
        else
             this.setState({[event.target.name] : event.target.value});

    }

    submitHandler = (event) => {
        event.preventDefault();
        let self = this;
        let {createApplication} = this.props;
        let {name, description, dataSource,icon,iconUrl, timeZone, applicationId} = this.state;
        let data = {
            name,
            description,
            dataSource,
            icon,
            iconUrl,
            applicationId,
            timeZone
        };
        var imageValid = this.fileValidation();
        if (imageValid) {
            createApplication(data).then((app) => {
                history.push(`/applications/${app.id}`);
            }).catch((errorMsg) => {
                self.setState({ message: errorMsg }, this.resetMessage)
            });
        }
    }

    fileValidation = () => {
        if(this.state.icon === null) {
            return true;
        }
        var fileInput = document.getElementById('application-icon');
        if(fileInput.files.length) {
            var fileSize = fileInput.files[0].size;
        }
        var filePath = fileInput.value;
        if(!filePath) {
            return true;
        }
        var allowedExtensions = /(\.jpg|\.jpeg|\.png|\.gif)$/i;
        if(!allowedExtensions.exec(filePath)){
            this.setState({ message: errorMessages.MESSAGE_VALID_EXTENSION }, this.resetMessage)
            fileInput.value = '';
            this.setState({
                icon : null
            });
            return false;
        }
        if(fileSize > 512000) {
            this.setState({ message: errorMessages.MESSAGE_VALID_SIZE }, this.resetMessage)
            fileInput.value = '';
            this.setState({
                icon : null
            });
            return false;
        }
       return true;
    }

    closeModal = () => {
        this.setState({
            name: "",
            description: "",
            dataSource: RVAI_DEFAULT_METADATA_URL,
            icon: null,
            iconUrl:"",
            timeZone: "",
            applicationId: "",
            message: "",
            imagePreview: ''
        }, () => {
            this.props.closeCreateAppModal();
        });
    }

    render() {
        let {name, description, dataSource,icon,imagePreview, iconUrl,timeZone, applicationId, message} = this.state;
        let {modal} = this.props;
        return(
            <Modal isOpen={modal} backdrop="static">
                <Form onSubmit={this.submitHandler} style={{ padding: "10px" }}>
                    <ModalHeader toggle={this.closeModal}>Create New App</ModalHeader>
                    <ModalBody>
                        <Alert color="danger">{message}</Alert>
                        <CreateEditAppModal
                            name={name}
                            description={description}
                            dataSource={dataSource}
                            imagePreview={imagePreview}
                            icon={icon}
                            iconUrl={iconUrl}
                            timeZone={timeZone}
                            applicationId={applicationId}
                            fieldUpdateHandler={this.fieldUpdateHandler}
                            removeIcon={this.removeIcon}
                        />
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" type="submit">Create</Button>
                        <Button outline color="secondary" onClick={this.closeModal}>Cancel</Button>
                    </ModalFooter>
                </Form>
            </Modal>
        );
    }
}

CreateApp.propTypes = {
    modal: PropTypes.bool,
    closeCreateAppModal: PropTypes.func,
    createApplication: PropTypes.func
}
