import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup, Label, Input, Button } from 'reactstrap';
import moment from 'moment';
import CustomAlert from '../../common/custom-alert';
import {getDefaultTimeRangeOptions} from './constants'
let startDate = '';
let stopDate = '';

const CURRENT_DATE_TIME = '$current_date_time';
const CURRENT_DATE = '$current_date';
const DATE_TIME_FORMAT = 'YYYY-MM-DD HH:mm:ss';
const DATE_FORMAT = 'YYYY-MM-DD';

export default class TimeRangeSelectorQuickSelectionConfigurationModal extends Component{
    constructor(props){
        super(props);
        this.state = {
            stop: '',
            start: '',
            name: '',
            message: ''
        }
    }

     UNSAFE_componentWillMount()
    {

        let defaultOptions =getDefaultTimeRangeOptions

        let timeRangeOptions = this.props.timeRangeOptions && Array.isArray(this.props.timeRangeOptions)
                 ? JSON.parse(JSON.stringify(this.props.timeRangeOptions)) : [];
                      
        let defaultOptionAdded=false;
        for(let i = 0;i<defaultOptions.length;i++)
        {
            if (!timeRangeOptions.some(e => e.name === defaultOptions[i].name)) 
            {
                defaultOptionAdded =true;
                timeRangeOptions.push(defaultOptions[i]);   
            }
                           
        }
        if(defaultOptionAdded)
        {
            this.props.addCustomSelectionForTimeRangeWidget(timeRangeOptions,this.props.applicationId)
            .then(this.closeModal())
            .catch( (error) => {
                this.setState({message: error});
            },()=>{
                setTimeout(() => {
                    this.setState({message:''})
                }, 5000)
            })
        }
       
    }

    closeModal = () =>{
        this.setState({
            name: '',
            start: '',
            stop: '',
            message: ''
        },() =>{
            this.props.closeQuickSelectionModal();
        })
    }

    validateForm = (name,start,stop) => {
        let messages = [];
        if(!name) messages.push('Custom Quick Selection Name is mandatory');
        if(!start) messages.push('Custom Quick Selection Start Date Time String is mandatory');
        if(!stop) messages.push('Custom Quick Selection Stop Date Time String is mandatory');
        if(start && !this.validateStartStop(start,true)) messages.push('Start Date Time string is not in valid format');
        if(stop && !this.validateStartStop(stop,false)) messages.push('Stop Date Time string is not in valid format');
        if(start && stop && !this.checkIfStopIsGreaterThanStart())
            messages.push('Start DateTime cannot be greater than Stop DateTime');
        if(name && this.props.timeRangeOptions && this.props.timeRangeOptions.find(x=> x.name === name))
            messages.push('Custom Quick Selection Name must be unique');
        return messages;
    }

    submit = () => {
        const {name,start,stop} = this.state;
        let messages = this.validateForm(name,start,stop);
        if(messages.length === 0){
            let obj = {
                name: name,
                start: start,
                stop: stop
            }
            let timeRangeOptions = this.props.timeRangeOptions && Array.isArray(this.props.timeRangeOptions)
                ? JSON.parse(JSON.stringify(this.props.timeRangeOptions)) : [];
            timeRangeOptions.push(obj);
            this.props.addCustomSelectionForTimeRangeWidget(timeRangeOptions,this.props.applicationId)
                .then(this.closeModal())
                .catch( (error) => {
                    this.setState({message: error});
                },()=>{
                    setTimeout(() => {
                        this.setState({message:''})
                    }, 5000)
                })
        }
        else{
            this.setState({ message : messages.join(', ') }, () => {
                setTimeout(() => {
                    this.setState({ message : '' });
                }, 5000);
            })
        }
                    
    }

    checkAndModifyIfMissingSpace=(value)=>
    {
        if(value.includes("-"))
        {
            let termsInExpression = value.split("-").map(function(term) {
                return term.trim();
              });
    
              return termsInExpression[0]+" - "+termsInExpression[1];  
        }
       return value;
    }

    validateStartStop = (value, dateObj) =>{
        if(value === CURRENT_DATE_TIME){
            if(dateObj) {
                startDate = moment().format(DATE_TIME_FORMAT)
            } else {
                stopDate= moment().format(DATE_TIME_FORMAT)
            }
            return true;
        }
        else if(value === CURRENT_DATE){
            if(dateObj) {
                startDate=  moment().format(DATE_FORMAT) + ' 00:00:00';
            } else {
                stopDate= moment().format(DATE_FORMAT) + ' 00:00:00';
            }
            return true;
        }
        else if(value.startsWith(`${CURRENT_DATE} - `)){
            if(value.endsWith('h') || value.endsWith('d') || value.endsWith('m') || value.endsWith('s')){
                if(!isNaN(value.substring((`${CURRENT_DATE} - `).length , value.length - 1))){
                    if(value.endsWith('h')){
                        if(dateObj) {
                            startDate = moment(moment().format(DATE_FORMAT) + ' ' + "00:00:00")
                                .subtract(parseInt(value.substring((`${CURRENT_DATE} - `).length , value.length - 1)), 'hours');
                        } else {
                            stopDate = moment(moment().format(DATE_FORMAT) + ' ' + "00:00:00")
                                .subtract(parseInt(value.substring((`${CURRENT_DATE} - `).length , value.length - 1)), 'hours');
                        }                            
                    }
                    else if(value.endsWith('d')){
                        if(dateObj) {
                            startDate =  moment(moment().format(DATE_FORMAT) + ' ' + "00:00:00")
                                .subtract(parseInt(value.substring((`${CURRENT_DATE} - `).length , value.length - 1)), 'days');
                        } else {
                            stopDate =  moment(moment().format(DATE_FORMAT) + ' ' + "00:00:00")
                                .subtract(parseInt(value.substring((`${CURRENT_DATE} - `).length , value.length - 1)), 'days');
                        }                                        
                    }
                    else if(value.endsWith('m')){
                        if(dateObj) {
                            startDate =  moment(moment().format(DATE_FORMAT) + ' ' + "00:00:00")
                                .subtract(parseInt(value.substring((`${CURRENT_DATE} - `).length , value.length - 1)), 'minutes');
                        } else {
                            stopDate =  moment(moment().format(DATE_FORMAT) + ' ' + "00:00:00")
                                .subtract(parseInt(value.substring((`${CURRENT_DATE} - `).length , value.length - 1)), 'minutes');
                        }
                    }
                    else{
                        if(dateObj) {
                            startDate = moment(moment().format(DATE_FORMAT) + ' ' + "00:00:00")
                                .subtract(parseInt(value.substring((`${CURRENT_DATE} - `).length , value.length - 1)), 'seconds');
                        } else {
                            stopDate =moment(moment().format(DATE_FORMAT) + ' ' + "00:00:00")
                                .subtract(parseInt(value.substring((`${CURRENT_DATE} - `).length , value.length - 1)), 'seconds');
                        }
                    }
                    return true;
                }
            }
        }
        else if(value.startsWith(`${CURRENT_DATE_TIME} - `)){
            if(value.endsWith('h') || value.endsWith('d') || value.endsWith('m') || value.endsWith('s')){
                if(!isNaN(value.substring((`${CURRENT_DATE_TIME} - `).length , value.length - 1))){
                    if(value.endsWith('h')){
                        if(dateObj) {
                            startDate =  moment(moment().format(DATE_TIME_FORMAT))
                                .subtract(parseInt(value.substring((`${CURRENT_DATE_TIME} - `).length , value.length - 1)), 'hours');
                        } else {
                            stopDate =  moment(moment().format(DATE_TIME_FORMAT))
                                .subtract(parseInt(value.substring((`${CURRENT_DATE_TIME} - `).length , value.length - 1)), 'hours');
                        }
                    }
                    else if(value.endsWith('d')){
                        if(dateObj) {
                            startDate =  moment(moment().format(DATE_TIME_FORMAT))
                                .subtract(parseInt(value.substring((`${CURRENT_DATE_TIME} - `).length , value.length - 1)), 'days')
                        } else {
                            stopDate =  moment(moment().format(DATE_TIME_FORMAT))
                                .subtract(parseInt(value.substring((`${CURRENT_DATE_TIME} - `).length , value.length - 1)), 'days');
                        }
                    }
                    else if(value.endsWith('m')){
                        if(dateObj) {
                            startDate =  moment(moment().format(DATE_TIME_FORMAT))
                                .subtract(parseInt(value.substring((`${CURRENT_DATE_TIME} - `).length , value.length - 1)), 'minutes');
                        } else {
                            stopDate =  moment(moment().format(DATE_TIME_FORMAT))
                                .subtract(parseInt(value.substring((`${CURRENT_DATE_TIME} - `).length , value.length - 1)), 'minutes');
                        }
                    }
                    else{
                        if(dateObj) {
                            startDate =  moment(moment().format(DATE_TIME_FORMAT))
                                .subtract(parseInt(value.substring((`${CURRENT_DATE_TIME} - `).length , value.length - 1)), 'seconds');
                        } else {
                            stopDate =  moment(moment().format(DATE_TIME_FORMAT))
                                .subtract(parseInt(value.substring((`${CURRENT_DATE_TIME} - `).length , value.length - 1)), 'seconds');
                        }                            
                    }
                    return true;
                }
            }
        }
        else if(value.startsWith(`${CURRENT_DATE} `)){
            if(value.indexOf(':')!== -1){
                let subStr = value.substring(value.length - 8 , value.length)
                if(value.length - (`${CURRENT_DATE} `).length === 8 && 
                    ((subStr.split(':').length === 3 && subStr.split(':')[0].length === 2 && subStr.split(':')[1].length === 2 && subStr.split(':')[2].length === 2)
                        || (subStr.split(':').length === 2 && subStr.split(':')[0].length === 2 && subStr.split(':')[1].length === 2))){
                    if(dateObj) {
                        startDate =  moment().format(DATE_FORMAT) + ' ' + subStr;
                    } else {
                        stopDate = moment().format(DATE_FORMAT) + ' ' + subStr;
                    }
                    return true;
                }
            }
        }
        if(dateObj) {
            startDate = '';
        } else {
            stopDate = '';
        }
        return false;
    } 

    checkIfStopIsGreaterThanStart = () => {
        return moment(stopDate).diff(startDate) > 0;
    }

    fieldUpdateHandler = (event) => {
       
        if(event.target.name==='start' || event.target.name==='stop')
        {
           let value = this.checkAndModifyIfMissingSpace(event.target.value)
           this.setState({ [event.target.name] : value });
        }
        else
            this.setState({ [event.target.name] : event.target.value });    
    }

    render(){
        const {modal} = this.props;
        const {name,message} = this.state;
        return(
            <Modal className="modal-dialog modal-dialog-centered" isOpen={modal} backdrop="static" fade={false}>
                <ModalHeader toggle={this.closeModal} charCode= "×">Add Custom Quick Selection</ModalHeader>
                <ModalBody>
                    <CustomAlert color="danger">{message}</CustomAlert>
                    <Form className="flex-shrink-1 overflow-auto">
                        <FormGroup>
                            <Label for="time-range-quick-selection-widget-name">Name</Label>
                            <Input required type="text" name="name" id="time-range-quick-selection-widget-name"
                                placeholder="Enter the text here" value={name} onChange={this.fieldUpdateHandler}
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label for="time-range-quick-selection-widget-Start">Start</Label>
                            <Input required type="text" name="start" id="time-range-quick-selection-widget-Start"
                                placeholder={`${CURRENT_DATE} - 30d`} onChange={this.fieldUpdateHandler}
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label for="time-range-quick-selection-widget-Stop">Stop</Label>
                            <Input required type="text" name="stop" id="time-range-quick-selection-widget-Stop"
                                placeholder={`${CURRENT_DATE_TIME} - 5d`} onChange={this.fieldUpdateHandler}
                            />
                        </FormGroup>
                    </Form>
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={this.submit}>Submit</Button>
                </ModalFooter>
            </Modal>
        )
    }
}

TimeRangeSelectorQuickSelectionConfigurationModal.propTypes = {
    modal: PropTypes.bool,
    applicationId: PropTypes.string,
    timeRangeOptions: PropTypes.array,
    closeQuickSelectionModal: PropTypes.func,
    addCustomSelectionForTimeRangeWidget:PropTypes.func
};