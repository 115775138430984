"use strict";
import { connect } from 'react-redux'
import Home from '../components/home';
import { getApplications, createApplication, testConnection } from '../store/actions/applicationActions';

const mapStateToProps = (state, ownProps) => ({
    application: state.application
});

const mapDispatchToProps = {
    getApplications: getApplications,
    createApplication: createApplication,
    testConnection: testConnection
}

const HomeContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(Home);

export default HomeContainer;