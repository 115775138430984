import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Header from "../common/header";
import ActionPanel from "./actionPanel";
import DashboardComponent from "./dashboard";
import AddWidget from '../addWidget'
import Alert from '../common/custom-alert';
import EditWidgetIndex from './editWidget'
import { setVerticalAlignmentY } from './utils';
import LoadingImage from '../../../img/loader.gif';
import { connect } from 'react-redux';
class Dashboard extends Component {
    constructor() {
        super();
        this.state = {
            addWidgetModalOpen: false,
            message: "",
            widgetConfigDataForEdit: {},
            editWidgetModalOpen: false,
            messageColor: "danger",
        }
    }

    UNSAFE_componentWillMount() {
        const { applicationId, dashboardId, application, dashboard, getApplicationDetail, setDashboard } = this.props;
        this.setDashboard(applicationId, dashboardId, application, dashboard, getApplicationDetail, setDashboard);
    }

    UNSAFE_componentWillReceiveProps(newProps) {
        const { applicationId, dashboardId, application, dashboard, getApplicationDetail, setDashboard } = newProps;
        this.setDashboard(applicationId, dashboardId, application, dashboard, getApplicationDetail, setDashboard);
    }

    resetMessage = () => {
        setTimeout(() => {
            this.setState({
                message: "",
                messageColor: ""
            });
        }, 3000);
    }

    setDashboard(applicationId, dashboardId, application, dashboard, getApplicationDetail, setDashboard) {
        if (!dashboard || (dashboard && dashboard.id !== dashboardId)) {
            let { currentApp } = application;
            if (!currentApp || currentApp.id !== applicationId) {
                getApplicationDetail(applicationId);
            } else if (currentApp.dashboards.length > 0) {
                let dashboardObj = currentApp.dashboards.find(function (dashboardObj) {
                    return dashboardObj.id === dashboardId;
                });
                if (dashboardObj) {
                    setDashboard(dashboardObj);
                }
            }
        }
    }

    getWidgetConfigData = (widgetId) => {
        let widget = this.props.dashboard.widgets.find(obj => obj.id === widgetId);
        this.props.getApplicationMetadata(this.props.applicationId)
            .then((responseData) => {
                if (responseData) {
                    this.setState({
                        widgetConfigDataForEdit: widget,
                        editWidgetModalOpen: true
                    });
                } else {
                    this.setState({
                        message: "No metadata available to add widgets. Please add metadata on " +
                            "the application detail page.",
                        messageColor: "danger"
                    }, this.resetMessage);
                }
            })
            .catch((error) => {
                this.setState({
                    message: error,
                    messageColor: "danger"
                }, this.resetMessage);
            });
    }

    getWidgetById = (widgetId) => {
        return this.props.dashboard.widgets.find(widget => widget.id === widgetId);
    }

    saveDashboardChanges = () => {
        const { timeRangeWidgetData } = this.props;
        this.props.updateDashboard(this.props.dashboard, this.props.applicationId, timeRangeWidgetData)
            .then((responseData) => {
                this.props.setDashboard(responseData);
                this.setState({
                    message: "The dashboard was updated successfully.",
                    messageColor: "success"
                }, this.resetMessage);
            })
            .catch((error) => {
                this.setState({
                    message: error,
                    messageColor: "danger"
                }, this.resetMessage)
            });
    }

    showAddWidgetModal = () => {
        this.props.getApplicationMetadata(this.props.applicationId)
            .then((responseData) => {
                if (responseData) {
                    this.setState({ addWidgetModalOpen: true });
                } else {
                    this.setState({
                        message: "No metadata available to add widgets. Please add metadata on the application detail" +
                            " page.",
                        messageColor: "danger"
                    }, this.resetMessage);
                }
            })
            .catch((errorMsg) => {
                this.setState({
                    message: errorMsg,
                    messageColor: "danger"
                }, this.resetMessage);
            });
    }

    closeAddWidgetModal = () => {
        this.setState({ addWidgetModalOpen: false });
    }

    closeEditWidgetModal = () => {
        this.setState({ editWidgetModalOpen: false });
    }

    getVerticalAlignmentY = (widgetWidth) => {
        const { dashboard } = this.props;
        return setVerticalAlignmentY(dashboard.widgets, widgetWidth);
    }

    render() {
        const { application, applicationId, dashboard, updateDashboard, deleteDashboard,
            addWidget, deleteWidget, updateWidget, setDashboard, addCustomSelectionForTimeRangeWidget } = this.props;
        let { addWidgetModalOpen, message, messageColor, editWidgetModalOpen, widgetConfigDataForEdit } = this.state;
        
        let {
            showAddWidgetModal,
            saveDashboardChanges,
            getWidgetById,
            closeAddWidgetModal,
            closeEditWidgetModal
        } = this;
        let loading = true;
        let currentApp = null;
        let layouts = null;
        let metadata = null;
        if(application && application.currentApp ){
            loading = false;
            currentApp = application.currentApp;
            layouts = currentApp.layouts;
            metadata = this.props.metadata;
            if (metadata 
                && metadata.widgetMetadata 
                && metadata.widgetMetadata.metadata 
                && metadata.widgetMetadata.metadata.layouts) {
                let widgetCreationMetadata = metadata.widgetMetadata.metadata 
                && metadata.widgetMetadata.metadata.layouts.filter((metadata) => {
                    return metadata.layoutId == dashboard.layoutid
                });
                metadata.widgetMetadata = widgetCreationMetadata[0];
            }
        }
        return (
            loading ? 
                <div className="d-flex h-100 justify-content-center">
                    <img className="d-flex align-self-center" src={LoadingImage} />
                </div> : (
                    <React.Fragment>
                        <Header
                            currentApp={application.currentApp}
                            dashboardName={dashboard.name}
                        />
                        <ActionPanel
                            dashboard={dashboard}
                            applicationId={applicationId}
                            showAddWidgetModal={showAddWidgetModal}
                            saveDashboardChanges={saveDashboardChanges}
                            updateDashboard={updateDashboard}
                            deleteDashboard={deleteDashboard}
                            setDashboard={setDashboard}
                            layouts={layouts}
                        />
                        <main className="dashboard-container">
                            <div className="container-fluid h-100 overflow-auto">
                                <Alert className="dashboard-alert" color={messageColor}>{message}</Alert>
                                <DashboardComponent
                                    dashboard={dashboard}
                                    deleteWidget={deleteWidget}
                                    setDashboard={setDashboard}
                                    applicationId={applicationId}
                                    timeZone={application.currentApp ? application.currentApp.timeZone : "GMT"}
                                    showAddWidgetModal={showAddWidgetModal}
                                    getWidgetConfigData={this.getWidgetConfigData}
                                />
                            </div>
                        </main>
                        <AddWidget
                            applicationId={applicationId}
                            modal={addWidgetModalOpen}
                            dashboard={dashboard}
                            widgetCreationMetadata={metadata.widgetMetadata}
                            addWidget={addWidget}
                            closeModal={closeAddWidgetModal}
                            getVerticalAlignmentY={this.getVerticalAlignmentY}
                            timeZone={application.currentApp ? application.currentApp.timeZone : "GMT"}
                            addCustomSelectionForTimeRangeWidget={addCustomSelectionForTimeRangeWidget}
                        />
                        <EditWidgetIndex
                            modal={editWidgetModalOpen}
                            widgetConfigDataForEdit={widgetConfigDataForEdit}
                            applicationId={applicationId}
                            widgetCreationMetadata={metadata.widgetMetadata}
                            closeModal={closeEditWidgetModal}
                            timeZone={application.currentApp ? application.currentApp.timeZone : "GMT"}
                            addCustomSelectionForTimeRangeWidget={addCustomSelectionForTimeRangeWidget}
                            updateWidget={updateWidget}
                            getVerticalAlignmentY={this.getVerticalAlignmentY}
                            getWidgetById={getWidgetById}
                            deleteWidget={deleteWidget}
                            addWidget={addWidget}
                        />
                    </React.Fragment>
                )
        );
    }

    componentWillUnmount() {
        this.props.clearDashboard();
    }
}

Dashboard.propTypes = {
    application: PropTypes.object,
    applicationId: PropTypes.string,
    dashboard: PropTypes.object,
    dashboardId: PropTypes.string,
    metadata: PropTypes.object,
    getApplicationDetail: PropTypes.func,
    setDashboard: PropTypes.func,
    updateDashboard: PropTypes.func,
    deleteDashboard: PropTypes.func,
    clearDashboard: PropTypes.func,
    addWidget: PropTypes.func,
    updateWidget: PropTypes.func,
    updateAllWidgets: PropTypes.func,
    deleteWidget: PropTypes.func,
    getApplicationMetadata: PropTypes.func,
    addCustomSelectionForTimeRangeWidget: PropTypes.func,
    timeRangeWidgetData: PropTypes.object,
}

Dashboard.defaultProps = {
    timeRangeWidgetData: {},
}

const mapStateToProps = state => ({
    timeRangeWidgetData: state.widgetData,
})

export default connect(mapStateToProps)(Dashboard);
