import React from 'react'
import PropTypes from 'prop-types';
import EmptyDashboardContainerImage from '../../../img/empty-screen-placeholder.svg';

const EmptyDashboardComponent = ({showAddWidgetModal}) => {
  return(
    <div className="text-center">
      <div className="d-flex flex-column">
        <img src={EmptyDashboardContainerImage} alt="Add Widget" width=""/>
        <div className="my-5">Dashboard is currently empty. Click on ‘Add Widget‘ button to get started.</div>
        <p>
          <button title="Add Widget" className="btn-lg btn btn-primary shadow px-4" onClick={showAddWidgetModal}>
            <i className="icon-app mr-2"/>
            <span className="f-16 font-weight-normal">Add Widget</span>
          </button>
        </p>
      </div>
    </div>
  );
};

EmptyDashboardComponent.propTypes = {
    showAddWidgetModal : PropTypes.func
};
export default EmptyDashboardComponent;