import { connect } from 'react-redux'
import DashboardWidget from './dashboardWidget';
import {
    deleteWidget,
    clearFilters,
    getEventData,
    emitEvent,
    addFilterForData,
    setStreamId
} from '../../../store/actions/widgetActions';
import {getCameraFeedUrl} from '../../../store/actions/cameraActions';

const mapStateToProps = (state, ownProps) => ({
    widgetData: state.widgetData,
    metadata: ownProps.metadata,
    applicationId: ownProps.applicationId,
    editDeleteFlag: ownProps.editDeleteFlag,
    widgetConfigDataForEdit: ownProps.widgetConfigDataForEdit,
    cameraFeedUrl: state.camera.cameraFeedUrl,
    streamId: state.dashboard.streamId,
})

const mapDispatchToProps = {
    deleteWidget: deleteWidget,
    clearFilters: clearFilters,
    getEventData: getEventData,
    emitEvent: emitEvent,
    addFilterForData: addFilterForData,
    getCameraFeedUrl,
    setStreamId

}

const DashboardWidgetContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(DashboardWidget)

export default DashboardWidgetContainer;