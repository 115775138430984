import React from 'react';
import { Alert } from 'reactstrap';
import PropTypes from 'prop-types';

class CustomAlert extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
          visible: false
        };

        this.onDismiss = this.onDismiss.bind(this);
    }

    onDismiss() {
        this.setState({ visible: false });
    }

    setVisibleProperty = (children) =>{
      if(!children) this.setState({ visible : false });
      else this.setState({ visible : true });
    }

    UNSAFE_componentWillMount () {
      const {children} = this.props;
      this.setVisibleProperty(children);
    }

    UNSAFE_componentWillReceiveProps (newProps) {
        const {children} = newProps;
        this.setVisibleProperty(children);
    }

    render() {
        let {children, color, className} = this.props;
        return (
          <Alert color={color} isOpen={this.state.visible} toggle={this.onDismiss} className={className}>
            {children}
          </Alert>
        );
    }
}

CustomAlert.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
  color: PropTypes.string
}

export default CustomAlert;
