import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Alert from '../../common/custom-alert';
import LoadingImage from '../../../../img/loader.gif';
import { getServiceUrl } from '../../../utils/commonUtils'

class Widget extends Component {
    getEditDeleteSection = () => {
        const {deleteWidget, getWidgetConfigData, metadata} = this.props;
        return (
            <div className='d-flex flex-column action-drawer pt-1 pb-2'>
                <button 
                    className='f-22' 
                    onClick={getWidgetConfigData.bind(this, metadata.id)}>
                    <i className='icon-edit'/>
                </button>
                <button 
                    className='f-22' 
                    onClick={deleteWidget.bind(this, metadata.id)}>
                    <i className='icon-delete'/>
                </button>
            </div>
        );
    }

    getWidget = () => {
        const {widgetData, propertyName, metadata, serviceUrl} = this.props;
        const API = getServiceUrl(serviceUrl)
        const selectedParameter = metadata.config.selectedParameter;
        const name = metadata.config.parametersName
        const iFrameUrl = `${API}/rvai/deployments/inference/${name}/${selectedParameter}`
        const message = widgetData.errors && widgetData.errors[propertyName] ? widgetData.errors[propertyName] : '';
        return (
            <div style={{background: 'white'}}  className='card-body  py-0 my-3 d-block align-items-center'>
                <Alert color='danger'>{message}</Alert>                    
                <iframe src={iFrameUrl} width='100%' height='100%' frameBorder='0'/>
            </div>
        )
    }
    render(){
        const {metadata, editDeleteFlag} = this.props;
        const selectedParameter = metadata.config.selectedParameter;
        return(
            <div className='card card-shadow overflow-hidden h-100 w-100' key={metadata.id} >
                <div className='card-header'>
                    { editDeleteFlag ? this.getEditDeleteSection() : '' }
                    <div className='d-flex'>
                        <i className='icon-counter mr-2'/>{metadata.name ? metadata.name : 'Runtime parameters'}
                    </div>
                </div>
                {   selectedParameter ?
                    this.getWidget()
                    :
                    <div className='d-flex h-100 justify-content-center'>
                        <img className='d-flex align-self-center' src={LoadingImage} />
                    </div>
                }                
                <div className='card-footer'>
                    <div>{metadata.description?metadata.description : ' '}</div>
                </div>
            </div>
        );
    }
}

Widget.propTypes = {
    editDeleteFlag: PropTypes.bool,
    propertyName: PropTypes.string,
    metadata: PropTypes.object,
    widgetData: PropTypes.object,
    deleteWidget: PropTypes.func,
    getWidgetConfigData: PropTypes.func,
    deleteWidgetData: PropTypes.func,
    clearWidgetErrorData: PropTypes.func,
    serviceUrl: PropTypes.string
}

const mapState = state => ({
    serviceUrl: state.application.currentApp.dataSource,
})

export default connect(mapState)(Widget);