import {
    SET_APP_LIST,
    SET_CURRENT_APP,
    ADD_NEW_APP,
    EDIT_APP,
    ADD_NEW_DASHBOARD,
    DELETE_APP,
    DELETE_DASHBOARD,
    UPDATE_DASHBOARD
} from '../constants/actionTypes';
import { setData } from '../../utils/storeUtils';

const editApp = (state, newApp) =>{
    return setData(state, "currentApp", newApp);
}

const addNewApp = (state, newApp) => {
    let { appList } = state;
    let newState;
    if (!appList || appList.length === 0) {
        appList = [newApp];
    } else {
        appList.push(newApp);
    }
    newState = setData(state, "appList", appList);
    return setData(newState, "currentApp", newApp);
};

const addNewDashboard = (state, newDashboard) => {
    let currentApp = JSON.parse(JSON.stringify(state.currentApp));
    if (!currentApp.dashboards || currentApp.dashboards.length === 0) {
        currentApp.dashboards = [newDashboard];
    } else {
        currentApp.dashboards = currentApp.dashboards.concat([newDashboard]);
    }
    return setData(state, "currentApp", currentApp);
};

const deleteApp = (state, id) => {
    let { appList } = state;
    let newState;
    // Used in Case if SET_APP_LIST call fails
    let list = appList && appList.length > 0 ? appList.filter(app => app.id !== id) : appList;
    newState = setData(state, "appList", list);
    return setData(newState, "currentApp", {});
};

const deleteDashboard = (state, id) => {
    let currentApp = JSON.parse(JSON.stringify(state.currentApp));
    currentApp.dashboards = currentApp.dashboards.filter(dashboard => dashboard.id !== id);
    return setData(state, "currentApp", currentApp);
};

const updateDashboard = (state, updatedDashboard) => {
    let currentApp = JSON.parse(JSON.stringify(state.currentApp));
    let index = currentApp.dashboards.findIndex(dashboardObj => dashboardObj.id === updatedDashboard.id);
    currentApp.dashboards.splice(index, 1, updatedDashboard);
    return setData(state, "currentApp", currentApp);
};

const applicationReducer = (state = {}, action) => {
    switch (action.type) {
        case SET_APP_LIST:
            return setData(state, "appList", action.data);
        case SET_CURRENT_APP:
            return setData(state, "currentApp", action.data);
        case ADD_NEW_APP:
            return addNewApp(state, action.data);
        case EDIT_APP:
            return editApp(state,action.data);
        case ADD_NEW_DASHBOARD:
            return addNewDashboard(state, action.data);
        case DELETE_APP:
            return deleteApp(state, action.data);
        case DELETE_DASHBOARD:
            return deleteDashboard(state, action.data);
        case UPDATE_DASHBOARD:
            return updateDashboard(state, action.data);
        default:
            return state;
    }
};

export default applicationReducer;
