export const StringOperators = [
    { name: 'null', label: 'Is Null' }, // All(Bool, String, Integer, Float, Time)
    { name: 'notNull', label: 'Is Not Null' }, // All(Bool, String, Integer, Float, Time)
    { name: 'in', label: 'In' },// Only String
    { name: 'notIn', label: 'Not In' },// Only String
    { name: '=', label: '=' }, // All(Bool, String, Integer, Float, Time)
    { name: '!=', label: '!=' }, // All(Bool, String, Integer, Float, Time)
    { name: 'StartsWith', label: 'StartsWith' },// Only String
    { name: 'EndsWith', label: 'EndsWith' },//Only String
    { name: 'Contains', label: 'Contains' } // Only String
];

export const IntOperators = [
    { name: '=', label: '=' }, // All(Bool, String, Integer, Float, Time)
    { name: '!=', label: '!=' }, // All(Bool, String, Integer, Float, Time)
    { name: '<', label: '<' }, //Time,Integer,Float
    { name: '>', label: '>' }, //Time,Integer,Float
    { name: '<=', label: '<=' }, //Time,Integer,Float
    { name: '>=', label: '>=' } //Time,Integer,Float
];

export const IntOperatorsWithRange = [
    { name: '=', label: '=' }, // All(Bool, String, Integer, Float, Time)
    { name: '!=', label: '!=' }, // All(Bool, String, Integer, Float, Time)
    { name: '<', label: '<' }, //Time,Integer,Float
    { name: '>', label: '>' }, //Time,Integer,Float
    { name: '<=', label: '<=' },
    { name: 'range', label: 'Range' }, //Time,Integer,Float
    { name: '>=', label: '>=' } //Time,Integer,Float
];

export const BooleanOperators = [
    { name: '=', label: '=' }, // All(Bool, String, Integer, Float, Time)
    { name: '!=', label: '!=' } // All(Bool, String, Integer, Float, Time)
];

export const operators = [
    { name: 'null', label: 'Is Null' }, // All(Bool, String, Integer, Float, Time)
    { name: 'notNull', label: 'Is Not Null' }, // All(Bool, String, Integer, Float, Time)
    { name: 'in', label: 'In' },// Only String
    { name: 'notIn', label: 'Not In' },// Only String
    { name: '=', label: '=' }, // All(Bool, String, Integer, Float, Time)
    { name: '!=', label: '!=' }, // All(Bool, String, Integer, Float, Time)
    { name: '<', label: '<' }, //Time,Integer,Float
    { name: '>', label: '>' }, //Time,Integer,Float
    { name: '<=', label: '<=' }, //Time,Integer,Float
    { name: '>=', label: '>=' }, //Time,Integer,Float
    { name: 'range', label: 'Range' },// if Min max is present
    { name: 'startsWith', label: 'StartsWith' },// Only String
    { name: 'endsWith', label: 'EndsWith' },//Only String
    { name: 'contains', label: 'Contains' } // Only String
];

export const QueryType = Object.freeze({
    CUSTOMQUERY: 'CUSTOMQUERY',
    QUERY: 'QUERY'
});

export const gridDefaultProps = Object.freeze({
    className: '',
    cols: { lg: 30, md: 30, sm: 30, xs: 30, xxs: 30 },
    minRows: 5,
    maxRows: 80,
    rowHeight: 15,
    compactType: null, // used for assigning the drop of elements, vertical or horizontal
    style: { position: 'relative' },
    preventCollision: false
});

export const timeOptions = Object.freeze({
    'HOURS': 'h',
    'MINUTES': 'm',
    'SECONDS': 's',
    'DAYS': 'd',
    'WEEKS': 'w'
})

export const selectedOptionsStyles = {
};

export const optionsListStyles = Object.freeze({
    backgroundColor: '#F0F2F2',
    color: 'darkgrey'
});

export const orderByOptions = Object.freeze({
    'ASC': 'ASC',
    'DESC': 'DESC'
});

export const RVAI_DEFAULT_METADATA_URL = 'https://demo.robovision.ai/api/v1/regulator/monitoring-metadata'
export const DASHBOARD_HELP_URL = 'https://docs.dashboards.robovision.ai/0.1.0/Content/Dashboards_Home.htm'