import * as widgetCatalogue from '../components/widgets/widgetCatalogue';

let widgets = {};
Object.values(widgetCatalogue).forEach((widgetObj) => {
  widgets[widgetObj.type] = widgetObj;
});

export const widgetLibrary = widgets;

export const generateWidgetHeight = function(widgetType, config, height) {
    if(widgetType === 'TIME_RANGE_SELECTOR' && config.viewType === 'FULLVIEW'){
        return {
            minH: 24,
            maxH: Infinity,
            h: 24,
        }
    } else if(widgetType === 'TIME_RANGE_SELECTOR' && config.viewType === 'MINIFIEDVIEW') {
        return {
            minH: 3,
            h: height,
            maxH: 3,
        }
    } else {
        return {
            minH: 1,
            h: height,
            maxH: Infinity,
        }
    }
}