import matchSorter from 'match-sorter';
import {QueryType} from '../../common/constants';

export const validateQuery = (customQuery) =>{
    let messages = [];
    if(customQuery.from === ""){
        messages.push("Please select dimension");
    }
    if(customQuery.orderBy && ((!customQuery.orderBy.field && customQuery.orderBy.order) ||
    (!customQuery.orderBy.order && customQuery.orderBy.field))) {
        messages.push("Please provide both values in order by")
    }
    if(customQuery.limit === 0){
        messages.push("Maximum rows must be greater than zero")
    }

    if(customQuery.select.length <= 0){
        messages.push("Please construct select query");
        return messages;
    }
    if(customQuery.select.findIndex(x => x.field !== "") === -1){
        messages.push("Please select field in the select query");
        return messages;
    }
    if(customQuery.select.length > 0){
        let aliasList = []
        let count = 0;
        let select = JSON.parse(JSON.stringify(customQuery.select));
        for(let i=0; i < select.length; i++){
            if(select[i].alias !== ""){
                if(aliasList.indexOf(select[i].alias) === - 1) {
                    aliasList.push(select[i].alias);
                } else {
                    count++;
                }
            }
        }
        if(count > 0){
            messages.push("Duplicate aliases are present in the query");
        }
    }
    return messages;
}

export const validateForm = (label, ownQuery, customQuery, queryType, pollingTime) => {
    let messages = [];
    if(!label) messages.push('Label is mandatory');
    if (queryType === QueryType.QUERY){
        if(!ownQuery) messages.push("Please construct your Query");
    }
    else{
        let resultMessages = validateQuery(customQuery);
        messages = messages.concat(resultMessages);
    }
    if(!pollingTime || parseInt(pollingTime) === 0) messages.push('Polling Time is mandatory and greater than zero');
    return messages;
}

export const generateMetadata = (id,name,description,height,width,xCoordinate,yCoordinate,minWidth,maxWidth,minHeight,maxHeight,type,config) =>{
    let widgetMetaData = {
        id: id,
        name : name,
        description: description,
        height: height,
        width : width,
        xCoordinate: xCoordinate,
        yCoordinate: yCoordinate,
        minWidth: minWidth,
        maxWidth: maxWidth,
        minHeight: minHeight,
        maxHeight: maxHeight,
        type: type,
        config : config
    };
    return widgetMetaData
}

export const getListOfKeys = (data, timeFlag) =>{
    let listOfKeys = []
    let groupBy = JSON.parse(JSON.stringify(data));
    delete groupBy['resultFeeds'];
    let resultFeedsObj = JSON.parse(JSON.stringify(data.resultFeeds[0]));
    if(!timeFlag) delete resultFeedsObj['time'];
    if(Object.keys(groupBy).length > 0){
        for(let k=0; k < Object.keys(groupBy).length; k++){
            listOfKeys.push(Object.keys(groupBy)[k]);
        }
    }
    for(let l=0; l < Object.keys(resultFeedsObj).length; l++){
        listOfKeys.push(Object.keys(resultFeedsObj)[l]);
    }
    return listOfKeys;
}

export const computeColumnsAndCSV =  (data,timeFlag) =>{
    let obj = {
        csvColumns:[],
        columns:[]
    }
    for(let i=0; i < data.length; i++){
        if(data[i] !== "time" || timeFlag){
            let csvObject = {
                id : data[i],
                displayName : data[i]
            }
            obj.csvColumns.push(csvObject);
            let columnObject ={
                Header:data[i],
                accessor:data[i],
                filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, { keys: [data[i]] }),
                filterAll: true
            }
            obj.columns.push(columnObject);
        }
    }
    return obj
}

export const computeTableData = (data,timeFlag) =>{
    let obj = JSON.parse(JSON.stringify(data));
    let resultData = [];
    for( let i=0; i < obj.length; i++ ){
        let groupBy = JSON.parse(JSON.stringify(obj[i]));
        delete groupBy['resultFeeds'];
        for( let j=0; j < obj[i].resultFeeds.length; j++){
            let resultObj = {};
            let resultFeedsObj = JSON.parse(JSON.stringify(obj[i].resultFeeds[j]));
            if(!timeFlag) delete resultFeedsObj['time'];
            if(Object.keys(groupBy).length > 0){
                for(let k=0; k < Object.keys(groupBy).length; k++){
                    resultObj[Object.keys(groupBy)[k]] = Object.values(groupBy)[k];
                }
            }
            for(let l=0; l < Object.keys(resultFeedsObj).length; l++){
                resultObj[Object.keys(resultFeedsObj)[l]] = (Object.values(resultFeedsObj)[l] === true || Object.values(resultFeedsObj)[l] === false)
                    ? Object.values(resultFeedsObj)[l].toString()
                    : Object.values(resultFeedsObj)[l];
            }
            resultData.push(resultObj);
        }
    }
    return resultData;
}
const getLastIndexOfFirstNestedQuery = (expression,index) =>{
    let stackOfBraces = []
    let lastIndex = 0;
    for (let i = index; i < expression.length; i++) {
        if (expression[i] === '(') {  
            stackOfBraces.push(expression[i])
        }
        else if (expression[i] === ')') {  
            stackOfBraces.splice(stackOfBraces.length - 1,1);  
        } 
        
        if(stackOfBraces.length === 0){
            lastIndex=i;
            break;
        }
    }
    return lastIndex;
}

const checkForTime = (str) => {
    if(str.indexOf('time(') !== -1){
        return true
    }
    else{
        return false
    }
}

const setTimeFlagParentGroupBySection = (parentGroupBySection) => {
    let orderByIndex = parentGroupBySection.indexOf('order by')
    if(orderByIndex !== -1){
        let groupBySectionBeforeOrderby = parentGroupBySection.substring(0,orderByIndex);
        return checkForTime(groupBySectionBeforeOrderby);
    }
    else{
        return checkForTime(parentGroupBySection);
    }
}

const getQuerySectionAfterFirstIndex = (queryString) => {
    let queryStringFromArray = queryString.split("from");
    let trimmedResult = queryStringFromArray.map( x => {
        return x.trim();
    })
    let queryStringRegenerated = trimmedResult.join(' from')
    let fromFirstIndex = queryStringRegenerated.indexOf('from');
    let querySectionAfterFirstIndex = queryStringRegenerated.substring(fromFirstIndex + 4,queryStringRegenerated.length);
    return querySectionAfterFirstIndex;
}

const setGroupByQueryTimeFlag = (queryString) =>{
    let timeFlag = false;
    let querySectionAfterFirstIndex = getQuerySectionAfterFirstIndex(queryString);
    let parentGroupBySection = ""
    if(querySectionAfterFirstIndex.indexOf('(') === 0){
        let lastIndex = getLastIndexOfFirstNestedQuery(querySectionAfterFirstIndex,0);
        parentGroupBySection = querySectionAfterFirstIndex.substring(lastIndex,querySectionAfterFirstIndex.length - 1);
        timeFlag = setTimeFlagParentGroupBySection(parentGroupBySection);
    }
    else{
        parentGroupBySection = querySectionAfterFirstIndex;
        timeFlag = setTimeFlagParentGroupBySection(parentGroupBySection);
    }
    return timeFlag;
}

const setSelectQueryTimeFlag = (queryString) => {
    let selectFirstIndex = queryString.indexOf('select ');
    let fromFirstIndex = queryString.indexOf('from');
    let selectPart = queryString.substring(selectFirstIndex + 7, fromFirstIndex);
    if(selectPart.indexOf('time') !== -1 && selectPart.indexOf('(time') === -1){
        return true;
    } 
    return false
}

const returnTimeFlagParsingOwnQuery = (query) =>{
    let timeFlag = false;
    let queryString = query.toLowerCase();
    timeFlag = setSelectQueryTimeFlag(queryString)
    if(!timeFlag){
        timeFlag = setGroupByQueryTimeFlag(queryString);
    }
    return timeFlag;
}

export const setTimeFlag = (config) =>{
    let timeFlag = false
    if(config.queryType !== QueryType.CUSTOMQUERY){
        timeFlag = returnTimeFlagParsingOwnQuery(config.query);
    }
    return timeFlag;
}

export const updateTimeAlias =(customQuery) =>
{
    customQuery.select && customQuery.select.forEach(function (arrayItem) {
        if(arrayItem.field==="time")
             arrayItem.alias="Time"
       
    });

    return customQuery;
}