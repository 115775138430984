import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalHeader, Button, ModalFooter } from 'reactstrap';
import {v1 as uuidv1} from 'uuid';
import {ViewType, defaultHeight, defaultWidth, defaultX, minWidth,maxWidth,minHeight,maxHeight} from './constants';
import {QueryType} from '../../common/constants';
import ConfigurationForm from "./configurationBody";
import {validateQuery,validateForm,generateMetadata} from './utils';

export default class CounterConfigurationModal extends Component{
    constructor(props){
        super(props);
        this.state = {
            label : "",
            description : "",
            viewType : ViewType.GRIDVIEW,
            pollingTime : 1,
            queryType : QueryType.CUSTOMQUERY,
            ownQuery:"",
            customQuery:{
                select:[],
                from:"",
                where:null,
                groupBy:[]
            },
            message:'',
            testFlag:false
        }
    }

    closeModal = () => {
        this.setState({
            label : "",
            description : "",
            viewType : ViewType.GRIDVIEW,
            pollingTime : 1,
            ownQuery:"",
            customQuery:{
                select:[],
                from:"",
                where:null,
                groupBy:[]
            },
            queryType : QueryType.CUSTOMQUERY,
            message: '',
            testFlag:false
        }, () => {
            this.props.closeModal();
        });
        
    }

    fieldUpdateHandler = (event) => {
        this.setState({[event.target.name] : event.target.value});
    }

    radioButtonChangeHandler = (name, value) => {
        this.setState({[name] : value});
    }

    queryTypeRadioButtonChangeHandler = (name, value) => {
        this.setState({
            [name] : value,
            testFlag:false
        });
    }

    submitHandler = () => {
        
        const {getVerticalAlignmentY} = this.props;
        let {label, description, viewType, pollingTime, ownQuery, customQuery, queryType} = this.state;
        let messages = validateForm(label, viewType, pollingTime,  ownQuery, customQuery, queryType);
        if(messages.length === 0) {
            let queryData = queryType === QueryType.QUERY ? ownQuery : JSON.parse(JSON.stringify(customQuery));
            let customConfig = {
                pollingTime: parseInt(pollingTime),
                viewType,
                query:queryData,
                queryType
            };

            let widgetMetaData = generateMetadata(uuidv1(),label,description,defaultHeight,
                defaultWidth,defaultX,getVerticalAlignmentY(defaultWidth),minWidth,maxWidth,minHeight,maxHeight,"COUNTER",customConfig)
            this.props.addWidget(widgetMetaData).then(() => {
                this.closeModal();
            });
        } else {
            this.setMessage(messages);
        }
    }

    showWidgetSelectionPage = () =>{
        this.setState({
            label : "",
            description : "",
            viewType : ViewType.GRIDVIEW,
            pollingTime : 1,
            queryType : QueryType.CUSTOMQUERY,
            ownQuery:"",
            customQuery:{
                select:[],
                from:"",
                where:null,
                groupBy:[]
            },
            message:'',
            testFlag:false
        },() => {
            this.props.showWidgetSelectionPage();
        })
    }

    setMessage = (messages) =>{
        this.setState({ message :  messages.join(', ') }, () => {
            setTimeout(() => {
                this.setState({ message : '' });
            }, 5000);
        })
    }

    updateQuery = (args) => {
        this.setState({
            customQuery:{
                where: args.key=== 'where'?args.value:this.state.customQuery.where,
                select: args.key=== 'select'?args.value:this.state.customQuery.select,
                from: args.key=== 'from'?args.value:this.state.customQuery.from,
                groupBy: args.key=== 'groupBy'?args.value: this.state.customQuery.groupBy
            },
            testFlag:false
        });
    }

    resetQuery = (where,select,from,groupBy) => {
        this.setState({
            customQuery:{
                where: where,
                select: select,
                from: from,
                groupBy: groupBy
            },
            testFlag:false
        });
    }

    setTestFlagTrue = () =>{
        const {queryType,customQuery} = this.state;
        let checkFlag = true;
        if(queryType === QueryType.CUSTOMQUERY){
            let messages = validateQuery(customQuery,messages);
            if(messages.length > 0){
                checkFlag= false
                this.setMessage(messages);
            }
        }
        if(checkFlag){
            this.setState({
                testFlag:true
            })
        }
    }

    ownQueryUpdateHandler = (event) =>{
        this.setState({
            [event.target.name] : event.target.value,
            testFlag:false
        });
    }

    render(){
        let {label, description, viewType, pollingTime, queryType, ownQuery,
            testFlag, customQuery, message} = this.state;
        let {modal, applicationId, dashboardId, timeZone, widgetCreationMetadata} = this.props;
        let metadata = { name:label, description, type:"COUNTER", config: {
            pollingTime, viewType, queryType,
            query: (queryType === QueryType.QUERY)? ownQuery:customQuery
        }};
        return(
            <Modal className="modal-dialog-centered" size="lg" isOpen={modal} backdrop="static" fade={false}>
                <ModalHeader toggle={this.closeModal} charCode= "×">
                    <span>Select Widget</span>
                    <span>Configure widget</span>
                </ModalHeader>
                <ConfigurationForm
                    metadata={metadata}
                    message={message}
                    applicationId={applicationId}
                    dashboardId={dashboardId}
                    widgetCreationMetadata={widgetCreationMetadata}
                    fieldUpdateHandler={this.fieldUpdateHandler}
                    timeZone={timeZone}
                    radioButtonChangeHandler={this.radioButtonChangeHandler}
                    setMessage={this.setMessage}
                    queryTypeRadioButtonChangeHandler={this.queryTypeRadioButtonChangeHandler}
                    ownQueryUpdateHandler={this.ownQueryUpdateHandler}
                    setTestFlagTrue = {this.setTestFlagTrue}
                    testFlag= {testFlag}
                    updateQuery= {this.updateQuery}
                    resetQuery= {this.resetQuery}
                />
                <ModalFooter className="align-content-center">
                    <Button color="primary" onClick={this.submitHandler}>Add</Button>
                    <Button outline color="secondary" onClick={this.showWidgetSelectionPage}>Previous</Button>
                </ModalFooter>
            </Modal>
        );
    }
}

CounterConfigurationModal.propTypes = {
    modal: PropTypes.bool,
    applicationId: PropTypes.string,
    dashboardId: PropTypes.string,
    timeZone: PropTypes.string,
    widgetCreationMetadata: PropTypes.object,
    showWidgetSelectionPage: PropTypes.func,
    getVerticalAlignmentY: PropTypes.func,
    closeModal: PropTypes.func,
    addWidget: PropTypes.func
};