import React, {Component} from 'react';
import { connect } from 'react-redux'
import PropTypes from 'prop-types';
import { setParametersAction, changeRuntimeParameterAction } from '../../../store/actions/dashboardActions'
import { Modal, ModalHeader, Button, ModalFooter } from 'reactstrap';
import {v1 as uuidv1} from 'uuid';
import {ViewType, WIDGET_TYPE, defaultHeight, defaultWidth, defaultX, minWidth,maxWidth,minHeight,maxHeight} from './constants';
import ConfigurationForm from "./configurationBody";
import {validateForm,generateMetadata} from './utils';
class RuntimeParametersConfigurationModal extends Component{
    constructor(props){
        super(props);
        this.state = {
            label : "",
            description : "",
            viewType : ViewType.GRIDVIEW,
            iFrameUrl: '',
        }
    }

    componentDidMount() {
        const data = {
            ...this.props.widgetCreationMetadata,
            serviceUrl: this.props.serviceUrl
        }
        this.props.setParameters(data) 
    }

    closeModal = () => {
        this.setState({
            label : "",
            description : "",
            viewType : ViewType.GRIDVIEW,
            iFrameUrl: ''
        }, () => {
            this.props.closeModal();
        });
        
    }

    changeRuntimeParameter = (e) => {
        this.props.changeRuntimeParameter(e.target.value)
    }

    fieldUpdateHandler = (event) => {
        this.setState({[event.target.name] : event.target.value});
    }

    radioButtonChangeHandler = (name, value) => {
        this.setState({[name] : value});
    }

    RadioButtonChangeHandler = (name, value) => {
        this.setState({
            [name] : value,
            testFlag:false
        });
    }

    submitHandler = () => {
        const {getVerticalAlignmentY, runtimeParameters, parametersName, selectedParameter} = this.props;
        let {label, description, viewType} = this.state;
        const messages = validateForm(label, selectedParameter);
        if(messages.length === 0) {
            let customConfig = {
                viewType,
                runtimeParameters,
                selectedParameter,
                parametersName
            };

            let widgetMetaData = generateMetadata(uuidv1(),label,description,defaultHeight,
                defaultWidth,defaultX,
                getVerticalAlignmentY(defaultWidth),
                minWidth,maxWidth,minHeight,maxHeight,
                WIDGET_TYPE,customConfig)
            this.props.addWidget(widgetMetaData).then(() => {
                this.closeModal();
            });
        } else {
            this.setMessage(messages);
        }
    }

    showWidgetSelectionPage = () =>{
        this.setState({
            label : "",
            description : "",
            viewType : ViewType.GRIDVIEW,
            iFrameUrl : '',
            message:'',
            testFlag:false
        },() => {
            this.props.showWidgetSelectionPage();
        })
    }

    setMessage = (messages) =>{
        this.setState({ message :  messages.join(', ') }, () => {
            setTimeout(() => {
                this.setState({ message : '' });
            }, 5000);
        })
    }

    setTestFlagTrue = () =>{
        this.setState({
            testFlag:true
        })
    }

    UpdateHandler = (event) =>{
        this.setState({
            [event.target.name] : event.target.value,
            testFlag:false
        });
    }

    render(){
        let {label, description, viewType, testFlag, message} = this.state;
        let {modal, applicationId, parametersLoading, dashboardId, timeZone, widgetCreationMetadata, selectedParameter, runtimeParameters, parametersName} = this.props;
        let metadata = { name:label, description, type: WIDGET_TYPE, config: {
            viewType, 
            selectedParameter: selectedParameter || '',
            parametersName: parametersName || '',
            runtimeParameters: runtimeParameters || []
        }};
        return(
            <Modal className="modal-dialog-centered" size="lg" isOpen={modal} backdrop="static" fade={false}>
                <ModalHeader toggle={this.closeModal} charCode= "×">
                    <span>Select Widget</span>
                    <span>Configure widget</span>
                </ModalHeader>
                <ConfigurationForm
                    metadata={metadata}
                    message={message}
                    applicationId={applicationId}
                    dashboardId={dashboardId}
                    widgetCreationMetadata={widgetCreationMetadata}
                    fieldUpdateHandler={this.fieldUpdateHandler}
                    timeZone={timeZone}
                    radioButtonChangeHandler={this.radioButtonChangeHandler}
                    setMessage={this.setMessage}
                    RadioButtonChangeHandler={this.RadioButtonChangeHandler}
                    UpdateHandler={this.UpdateHandler}
                    setTestFlagTrue = {this.setTestFlagTrue}
                    testFlag= {testFlag}
                    changeRuntimeParameter={this.changeRuntimeParameter}
                    parametersLoading={parametersLoading}
                />
                <ModalFooter className="align-content-center">
                    <Button 
                        disabled={parametersLoading} 
                        color="primary" 
                        onClick={this.submitHandler}>
                            Add
                    </Button>
                    <Button outline color="secondary" onClick={this.showWidgetSelectionPage}>Previous</Button>
                </ModalFooter>
            </Modal>
        );
    }
}

RuntimeParametersConfigurationModal.propTypes = {
    modal: PropTypes.bool,
    applicationId: PropTypes.string,
    dashboardId: PropTypes.string,
    timeZone: PropTypes.string,
    widgetCreationMetadata: PropTypes.object,
    showWidgetSelectionPage: PropTypes.func,
    getVerticalAlignmentY: PropTypes.func,
    closeModal: PropTypes.func,
    addWidget: PropTypes.func,
    setParameters: PropTypes.func,
    selectedParameter: PropTypes.string,
    runtimeParameters: PropTypes.array,
    parametersName: PropTypes.string,
    changeRuntimeParameter: PropTypes.func,
    serviceUrl: PropTypes.string,
    parametersLoading: PropTypes.bool
};

const mapDispatch = dispatch => ({
    setParameters: payload => dispatch(setParametersAction(payload)),
    changeRuntimeParameter: payload => dispatch(changeRuntimeParameterAction(payload))
})

const mapState = state => ({
    runtimeParameters: state.dashboard.parameters,
    selectedParameter: state.dashboard.selectedParameter,
    parametersName: state.dashboard.parametersName,
    serviceUrl: state.application.currentApp.dataSource,
    parametersLoading: state.dashboard.parametersLoading
})

export default connect(mapState, mapDispatch)(RuntimeParametersConfigurationModal)