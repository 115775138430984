import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalHeader, ModalBody, ModalFooter , Button } from 'reactstrap';
import {v1 as uuidv1} from 'uuid';
import {defaultHeight,defaultWidth,defaultX,minWidth,maxWidth,minHeight,maxHeight} from './constants';
import ConfigBody from "./configurationBody";
import {QueryType} from '../../common/constants';
import {validateQuery,generateMetadata,validateForm} from './utils';

export default class TimeSeriesGraphConfigurationModal extends Component{

    constructor(props){

        super(props);
        this.state = {
            label : "",
            description : "",
            yAxis:"",
            pollingTime: 1,
            queryType : QueryType.CUSTOMQUERY,
            ownQuery:"",
            customQuery:{
                select:[],
                from:"",
                where:null,
                groupBy:[],
                orderBy: { 
                    field:"time",
                    order:"DESC"
                },
                limit: 100
            },
            testFlag:false,
            message:""
        }
    }

    closeModal = () => {
        this.setState({
            label : "",
            description : "",
            yAxis:"",
            pollingTime: 1,
            queryType : QueryType.CUSTOMQUERY,
            ownQuery:"",
            customQuery: {
                select:[],
                from:"",
                where:null,
                groupBy:[],
                limit: 100,
                orderBy: { 
                    field:"time",
                    order:"DESC"
                }
            },
            testFlag:false,
            message:""
        },()=>{
            this.props.closeModal();
        });
    }

    fieldUpdateHandler = (event) => {
        this.setState({
            [event.target.name] : event.target.value
        });
    }

    submitHandler = () => {
        
        const {getVerticalAlignmentY} = this.props;
        let {label, description, ownQuery, customQuery, queryType, yAxis, pollingTime} = this.state;
        let messages = validateForm(label, ownQuery, customQuery, queryType, yAxis, pollingTime);
        if(messages.length === 0) {
            let queryData =""
            if (queryType === QueryType.QUERY){
                queryData = ownQuery
            }
            else{
                let val = JSON.parse(JSON.stringify(customQuery));
                queryData = val
            }
            let customConfig = {
                query:queryData,
                queryType,
                yAxis,
                pollingTime: parseInt(pollingTime)
            };
            let widgetMetaData = generateMetadata(uuidv1(),label,description,defaultHeight,
                defaultWidth,defaultX,getVerticalAlignmentY(defaultWidth),minWidth,maxWidth,minHeight,maxHeight,"TIME_SERIES_GRAPH",customConfig);
            this.props.addWidget(widgetMetaData).then(() => {
                this.closeModal();
            });
        }
        else {
            this.setMessage(messages);
        }
        
    }

    showWidgetSelectionPage = () =>{
        this.setState( {
            label : "",
            description : "",
            yAxis:"",
            pollingTime: 1,
            queryType : QueryType.CUSTOMQUERY,
            ownQuery:"",
            customQuery:{
                select:[],
                from:"",
                where:null,
                groupBy:[],
                orderBy: { 
                    field:"time",
                    order:"DESC"
                },
                limit: 100
            },
            testFlag:false,
            message:''
        },() => {
            this.props.showWidgetSelectionPage();
        })
    }

    setMessage = (messages) =>{
        this.setState({ message :  messages.join(', ') }, () => {
            setTimeout(() => {
                this.setState({ message : '' });
            }, 5000);
        })
    }

    updateQuery = (args) => {
        this.setState({
            customQuery:{
                where: args.key=== 'where'?args.value:this.state.customQuery.where,
                select: args.key=== 'select'?args.value:this.state.customQuery.select,
                from: args.key=== 'from'?args.value:this.state.customQuery.from,
                groupBy: args.key=== 'groupBy'?args.value:this.state.customQuery.groupBy,
                orderBy: args.key=== 'orderBy'?args.value:this.state.customQuery.orderBy,
                limit: args.key=== 'limit'?args.value:this.state.customQuery.limit
            },
            testFlag:false
        });
    }

    resetQuery = (where,select,groupBy,limit,from, orderBy) => {
        this.setState({
            customQuery:{
                where: where,
                select: select,
                from: from,
                groupBy: groupBy,
                orderBy:orderBy ,
                limit: limit
            },
            testFlag:false
        });
    }

    setTestFlagTrue = () =>{
        const {queryType,customQuery} = this.state;
        let checkFlag = true;
        if(queryType === QueryType.CUSTOMQUERY){
            let messages = validateQuery(customQuery,messages);
            if(messages.length > 0){
                checkFlag= false
                this.setMessage(messages);
            }
        }
        if(checkFlag){
            this.setState({
                testFlag:true
            })
        }
    }

    ownQueryUpdateHandler = (event) =>{
        this.setState({
            [event.target.name] : event.target.value,
            testFlag:false
        });
    }

    queryTypeRadioButtonChangeHandler = (name, value) => {
        this.setState({
            [name] : value,
            testFlag:false
        });
    }

    render(){
        let {modal, applicationId, dashboardId, timeZone, widgetCreationMetadata} = this.props;
        let {label, description, queryType, ownQuery, customQuery, testFlag, yAxis, pollingTime, message} = this.state;
        let metadata = { name:label, description, type:"TIME_SERIES_GRAPH", config:{ pollingTime, queryType, yAxis,
            query: (queryType === QueryType.QUERY)? ownQuery:customQuery } };
        return(
            <Modal className="modal-dialog-centered" size="lg" isOpen={modal} backdrop="static" fade={false}>
                <ModalHeader toggle={this.closeModal} charCode= "×">
                    <span>Select Widget</span>
                    <span>Configure widget</span>
                </ModalHeader>
                <ModalBody className="py-0">
                    <ConfigBody
                        message={message}
                        metadata={metadata}
                        applicationId={applicationId}
                        dashboardId={dashboardId}
                        widgetCreationMetadata={widgetCreationMetadata}
                        fieldUpdateHandler={this.fieldUpdateHandler}
                        testFlag={testFlag}
                        setTestFlagTrue={this.setTestFlagTrue}
                        ownQueryUpdateHandler={this.ownQueryUpdateHandler}
                        setMessage={this.setMessage}
                        updateQuery={this.updateQuery}
                        resetQuery={this.resetQuery}
                        timeZone={timeZone}
                        queryTypeRadioButtonChangeHandler={this.queryTypeRadioButtonChangeHandler}
                    />
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={this.submitHandler}>Add</Button>
                    <Button outline color="secondary" onClick={this.showWidgetSelectionPage}>Previous</Button>
                </ModalFooter>
            </Modal>
        );
    }
}

TimeSeriesGraphConfigurationModal.propTypes = {
    modal: PropTypes.bool,
    applicationId: PropTypes.string,
    dashboardId: PropTypes.string,
    timeZone: PropTypes.string,
    widgetCreationMetadata: PropTypes.object,
    getVerticalAlignmentY: PropTypes.func,
    closeModal: PropTypes.func,
    addWidget: PropTypes.func,
    showWidgetSelectionPage: PropTypes.func
}

