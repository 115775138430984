import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Widget from './widget';

class DashboardWidget extends Component {
    constructor(props){
        super(props);
        this.state = {
            propertyName:""
        }
        this.interval = null;
    }

    render(){
        const {metadata, widgetData, clearWidgetErrorData, getWidgetConfigData, deleteWidget, deleteWidgetData, editDeleteFlag} = this.props;
        const {propertyName} = this.state;
        return(
            <React.Fragment>
                <Widget 
                    editDeleteFlag={editDeleteFlag}
                    getWidgetConfigData={getWidgetConfigData}
                    deleteWidget={deleteWidget}
                    metadata={metadata} 
                    widgetData={widgetData} 
                    propertyName={propertyName}
                    deleteWidgetData={deleteWidgetData}
                    clearWidgetErrorData={clearWidgetErrorData}
                />
            </React.Fragment>
        );
    }
}

DashboardWidget.propTypes = {
    editDeleteFlag: PropTypes.bool,
    applicationId: PropTypes.string,
    timeZone: PropTypes.string,
    metadata: PropTypes.object,
    widgetData: PropTypes.object,
    streamId: PropTypes.string,
    getWidgetData: PropTypes.func,
    deleteWidget: PropTypes.func,
    getWidgetConfigData: PropTypes.func,
    deleteWidgetData: PropTypes.func,
    emitWidgetData: PropTypes.func,
    clearWidgetErrorData: PropTypes.func
}

export default DashboardWidget;