export const validateForm = (layouts,name) => {
    let messages = []
    if(!name){
        messages.push("Please provide layout Name");
    }
    if(layouts.find(x => x.name === name) || name === "Default"){
        messages.push("Layout with this name exists");
    }
    return messages;
}

export const getFromLS = (key) => {
    let ls = {};
    if (global.localStorage) {
        try {
            ls = JSON.parse(global.localStorage.getItem("rgl")) || {};
        } catch (e) {
            /*Ignore*/
        }
    }
    return Object.keys(ls).length === 0 ?{}:ls[key];
}

export const saveToLS = (key, value) => {
    if (global.localStorage) {
        global.localStorage.setItem(
            "rgl",
            JSON.stringify({[key]: value})
        );
    }
}

const getChangedData = (component) => {
    return {
        id: component.i? component.i: component.id,
        x: component.x,
        y: component.y,
        h: component.h,
        w: component.w
    }
}

export const getLayoutConfiguration = () =>{
    let layoutConfiguration = JSON.parse(JSON.stringify(getFromLS("layouts")))
    let layoutChangedData = [];
    layoutConfiguration.forEach((component) => {
        layoutChangedData.push(getChangedData(component));
    });
    return layoutChangedData;
}

export const getWidgetObject = (widget) => {
    return {
        id: widget.id,
        type: widget.type,
        name: widget.name,
        description: widget.description,
        x: widget.xCoordinate,
        y: widget.yCoordinate,
        w: widget.width,
        h: widget.height,
        config: widget.config
    };
}
