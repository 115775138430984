import React, {Component} from 'react'
import PropTypes from 'prop-types'
import { FormGroup, Label } from 'reactstrap';
import { getSelectData,convertOperationsFormatFunc } from './utils'
import { v1 as uuidv1 } from 'uuid'
import MultiSelect from './multiselect'

class SelectQuery extends Component {
  addEmptySelectSection = () => {
      const {config,updateSelectQuery} = this.props;
      let selectedSelectOptions = JSON.parse(JSON.stringify(config.select));
      selectedSelectOptions.push(getSelectData(uuidv1(),'','',[]))
      updateSelectQuery(selectedSelectOptions);
  }

  updateSelectQuery = (id, event) => {

      const {config,updateSelectQuery} = this.props;
      let selectedSelectOptions = JSON.parse(JSON.stringify(config.select));
      let index = selectedSelectOptions.findIndex((o) => {return o.id === id});
      selectedSelectOptions[index][event.target.name] = event.target.value;
      if(event.target.name === "field"){
        selectedSelectOptions[index]["alias"] = event.target.value;
          selectedSelectOptions[index]["operations"] = [] 
      }
      updateSelectQuery(selectedSelectOptions);
  }

  updateOperations = (id, value) => {
    const {config,updateSelectQuery} = this.props;
    let selectedSelectOptions = JSON.parse(JSON.stringify(config.select));
    let index = selectedSelectOptions.findIndex((o) => {return o.id === id});
    selectedSelectOptions[index].operations = value
    updateSelectQuery(selectedSelectOptions);
  }

  deleteSelectOption = (id) =>{
    const {config,updateSelectQuery} = this.props;
    let selectedSelectOptions = JSON.parse(JSON.stringify(config.select));
    let updatedSelectedSelectOptions = selectedSelectOptions.filter((o) => {return o.id !== id});
    updateSelectQuery(updatedSelectedSelectOptions);
  }

  convertOperationsFormat = (obj) =>{
    return convertOperationsFormatFunc(obj);
  }

  renderRow = (index, selectedOption) => {
    const{fieldsList,CommonOperationsList,IntegerOperationsList} = this.props;
    const{tags} = this.props;
  
    let fieldAndTagOptions = [...fieldsList,...tags];
    let selectOptions=[<option key={0} value="">Select a field</option>]
    fieldAndTagOptions.forEach(function (sourceOption, i) {
      selectOptions.push(<option key={i + 1} value={sourceOption.name}>{sourceOption.label}</option>);
    });

    let customOperatorList = []
    if(selectedOption.field !== ""){
        let field = fieldsList.find(x=> x.name === selectedOption.field)
        let type = field && field.type?field.type.toLowerCase():""
        if(type === 'integer' || type === 'float'){
            customOperatorList = IntegerOperationsList
        }
        else if(type === 'string' || type === 'boolean'){
          customOperatorList = CommonOperationsList
        }
        else{
          customOperatorList = []
        }
    }
    return (
      <FormGroup key={selectedOption.id}>
        <div className="text-center">
          <Label className="font-weight-normal align-middle"> Field {index + 1}</Label>
          <a onClick={this.deleteSelectOption.bind(null, selectedOption.id)} className="float-right link d-flex align-items-center align-middle f-12">
            <i className="icon-delete ml-1"/>
          </a>
        </div>
        <div>
            <div className="input-group">
                <div className="input-group-prepend w-50">
                <select className="form-control group-input custom-select" name="field"
                        defaultValue={selectedOption.field} onChange={(e) => this.updateSelectQuery(selectedOption.id, e)}>
                    {selectOptions}
                </select>
                </div>
                <div className="input-group-append w-50">
                <input placeholder="alias" maxLength={30} type="text" className="form-control group-input"
                        name="alias" value={selectedOption.alias} onChange={(e) => this.updateSelectQuery(selectedOption.id, e)}
                />
                </div>
            </div>
            {customOperatorList.length > 0?
                <MultiSelect
                    options={customOperatorList}
                    updateOperations={this.updateOperations}
                    id={selectedOption.id}
                    operations={this.convertOperationsFormat(selectedOption.operations)}
                ></MultiSelect>:null
            }
        </div>
      </FormGroup>
    )
  }

  render() {
    let{config} = this.props;
    let rows = [];
    let selectedSelectOptions = JSON.parse(JSON.stringify(config.select))
    selectedSelectOptions.forEach((obj,index) =>{
      rows.push(this.renderRow(index, obj));
    })
    return(
      <React.Fragment>
        <FormGroup className="mb-0">
          <Label> Selected Fields </Label>
        </FormGroup>
        {rows}
        <div className="py-1 mb-2">
          <a className="link f-14" onClick={this.addEmptySelectSection}>
            <i className="icon-add-quick-selection mr-2"/>
            Add Select Query
          </a>
        </div>
      </React.Fragment>
    )
  }

}

SelectQuery.propTypes= {
  config: PropTypes.object,
  fieldsList: PropTypes.array,
  CommonOperationsList: PropTypes.array,
  IntegerOperationsList: PropTypes.array,
  updateSelectQuery: PropTypes.func,
  tags: PropTypes.array
};

export default SelectQuery;