import React from 'react';
import { connect } from 'react-redux'
import PropTypes from 'prop-types';
import thumbnailImage from "../../../../img/widget-thumbnails/camera-feed.svg";
import thumbnailImageDark from "../../../../img/widget-thumbnails/camera-feed-dark.svg";
import { getTheme } from '../../../utils/commonUtils';

const CameraThumbnail = ({isDisabled, clickHandler}) => {
    let classes = isDisabled ? "card card-shadow card-disabled" : "card card-shadow cursor-pointer";
    return (
        <div className={classes}>
            <div className="card-body text-center" onClick={clickHandler}>
                <h5 className="card-title">Camera Feed</h5>
                <div className="img-wrapper f-42">
                    <img src={getTheme() === 'dark' ? thumbnailImage : thumbnailImageDark} alt="Camera Feed"/>
                </div>
                <p className="card-text">
                {isDisabled ? "This widget has already been added." : "View image/video from camera, select from multiple sources."}
                </p>
            </div>
        </div>
    );
}

CameraThumbnail.propTypes = {
    isDisabled: PropTypes.bool,
    clickHandler: PropTypes.func
}

const mapStateToProps = (state) => ({theme: state.ui.theme})

export default connect(mapStateToProps)(CameraThumbnail);