import React from "react";
import { FormGroup, Label, Input, ModalBody } from 'reactstrap';
import PropTypes from "prop-types";
import PreviewWidget from './previewWidget';
import CustomAlert from '../../common/custom-alert';
import {ViewType} from './constants';
import TimeRangeCustomQuickSelection from './customQuickSelection';
const ConfigForm = ({
    metadata,
    message,
    fieldUpdateHandler,
    setTimeRangeOptions,
    widgetCreationMetadata,
    openQuickCustomModal,
    applicationId,
    openQuickCustomSelectionModal,
    addCustomSelectionForTimeRangeWidget,
    closeQuickSelectionModal,
    radioButtonChangeHandler
}) => {
    let {name, description, config} = metadata;
    let label = name;
    let {viewType,selectedTimeRangeOptions} = config;
    let checkboxHtml = [];
    let quickSelections = widgetCreationMetadata.timeRangeOptions ? widgetCreationMetadata.timeRangeOptions : [];
    quickSelections.forEach((obj, index) => {
        let selectedIndex = selectedTimeRangeOptions.findIndex(x => x.name === obj.name)
        if(selectedIndex === -1){
            checkboxHtml.push(
                <div className="custom-control custom-checkbox"  key={index} >
                    <input id={"customCheck" + index} type="checkbox" className="custom-control-input" onChange={(e) => setTimeRangeOptions(e,obj)} checked={false}/>
                    <Label for={"customCheck" + index}className="custom-control-label">{obj.name}</Label>
                    <p>(start : {obj.start}, stop : {obj.stop})</p>
                </div>
            );
        }
        else{
            checkboxHtml.push(
                <div className="custom-control custom-checkbox"  key={index}>
                    <input id={"customCheck" + index} type="checkbox" className="custom-control-input" onChange={(e) => setTimeRangeOptions(e,obj)} checked={true}/>
                    <Label for={"customCheck" + index} className="custom-control-label">{obj.name}</Label>
                    <p>(start : {obj.start}, stop : {obj.stop})</p>
                </div>
            ); 
        }
    })
    return(
        <React.Fragment>
            <ModalBody className="py-0">
                <div className="row">
                    <div className="col-md-8 pl-5 py-2 d-flex flex-column h-100 justify-content-between" style={{maxHeight:"70vh", minHeight:"30vh"}}>
                        <div className="preview-header border-bottom py-3 mr-5 mb-3">
                            <i className="icon-time-range-selector mr-2"/>Time Range Selector
                        </div>
                        <CustomAlert color="danger">{message}</CustomAlert>
                        <form className="flex-shrink-1 overflow-auto pr-5">
                            <FormGroup className="form-group">
                                <Label for="time-range-widget-label">Widget Label</Label>
                                <Input maxLength={30} required type="text" name="label" id="time-range-widget-label"
                                    placeholder="Enter the widget label here" value={label}
                                    onChange={fieldUpdateHandler} className="form-group"
                                />
                            </FormGroup>
                            <FormGroup>
                                <Label for="time-range-widget-description">Widget Description</Label>
                                <Input maxLength={150} type="text" name="description" id="time-range-widget-description"
                                    placeholder="Enter the widget description here"
                                    value={description} onChange={fieldUpdateHandler}
                                />
                            </FormGroup>
                            <FormGroup>
                                <Label for="">View type</Label>
                                <div className="row">
                                    <div className="col">
                                        <div className="custom-control custom-radio custom-control-inline"
                                            onClick={radioButtonChangeHandler.bind(this, "viewType", ViewType.FULLVIEW)}>
                                            <Input type="radio" name="viewType" className="custom-control-input"
                                                checked={viewType === ViewType.FULLVIEW} onChange={fieldUpdateHandler}/>
                                            <Label className="custom-control-label" style={{paddingTop:'3px'}}>Full view</Label>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="custom-control custom-radio custom-control-inline"
                                            onClick={radioButtonChangeHandler.bind(this, "viewType", ViewType.MINIFIEDVIEW)} >
                                            <Input type="radio" name="viewType" className="custom-control-input"
                                                checked={viewType === ViewType.MINIFIEDVIEW} onChange={fieldUpdateHandler}/>
                                            <Label className="custom-control-label" style={{paddingTop:'3px'}}>Minified view</Label>
                                        </div>
                                    </div>
                                </div>
                            </FormGroup>
                            <FormGroup>
                                <Label>Quick Selections</Label>
                                {checkboxHtml}
                            </FormGroup>
                            <div className="py-4 mt-2 mr-5 border-top">
                                <div className="link f-14" onClick={openQuickCustomSelectionModal}>
                                    <i className="icon-add-quick-selection mr-2"/>
                                Add custom quick selection
                                </div>
                            </div>
                        </form>
                    </div>
                    <div className="col-md-16 preview-bg p-3">
                        <div className="row justify-content-center h-100">
                            <div className="col col-md-14 d-flex align-items-center justify-content-center">
                                <PreviewWidget metadata={metadata}/>
                            </div>
                        </div>
                    </div>
                </div>
            </ModalBody>
            <TimeRangeCustomQuickSelection 
                timeRangeOptions={widgetCreationMetadata.timeRangeOptions}
                applicationId={applicationId}
                addCustomSelectionForTimeRangeWidget={addCustomSelectionForTimeRangeWidget}
                modal={openQuickCustomModal}
                closeQuickSelectionModal={closeQuickSelectionModal}
            />
        </React.Fragment>
    );
};

ConfigForm.propTypes = {
    openQuickCustomModal: PropTypes.bool,
    message: PropTypes.string,
    applicationId: PropTypes.string,
    metadata: PropTypes.object,
    widgetCreationMetadata: PropTypes.object,
    fieldUpdateHandler: PropTypes.func,
    addCustomSelectionForTimeRangeWidget: PropTypes.func,
    setTimeRangeOptions: PropTypes.func,
    closeQuickSelectionModal: PropTypes.func,
    radioButtonChangeHandler: PropTypes.func,
    openQuickCustomSelectionModal: PropTypes.func
}

export default ConfigForm;