import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalHeader, ModalFooter, Button } from 'reactstrap';
import { PROPERTY_BASE_NAME, minWidth, maxWidth, minHeight, maxHeight } from './constants';
import { validateQuery, generateMetadata, validateForm } from './utils';
import ConfigurationForm from "./configurationBody";
import { QueryType } from '../../common/constants';
import { checkIfMetadataIsAfterWidgetUpdate } from '../../../utils/commonUtils';
export default class EditTableConfigurationModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            label: "",
            description: "",
            pollingTime: 1,
            queryType: QueryType.CUSTOMQUERY,
            ownQuery: "",
            customQuery: {
                select: [],
                from: "",
                where: null,
                groupBy: [],
                orderBy: {
                    field: "time",
                    order: "DESC"
                },
                limit: 100
            },
            testFlag: false,
            message: ""
        }
    }

    UNSAFE_componentWillMount() {
        const { widgetConfigDataForEdit, widgetCreationMetadata } = this.props;
        if (checkIfMetadataIsAfterWidgetUpdate(widgetCreationMetadata.updatedAt, widgetConfigDataForEdit.updatedAt)) {
            this.setMessage(["The metadata is changed after last update of the widget.  Widget may not behave as desired, please edit."]);
        }
        this.setStateFunc(widgetConfigDataForEdit)
    }

    setStateFunc = (widgetConfigDataForEdit) => {
        if (widgetConfigDataForEdit.config.queryType === QueryType.QUERY) {
            this.setState({
                label: widgetConfigDataForEdit.name,
                description: widgetConfigDataForEdit.description,
                pollingTime: widgetConfigDataForEdit.config.pollingTime,
                queryType: widgetConfigDataForEdit.config.queryType,
                ownQuery: widgetConfigDataForEdit.config.query
            })
        }
        else {
            let val = JSON.parse(JSON.stringify(widgetConfigDataForEdit.config.query))
            this.setState({
                label: widgetConfigDataForEdit.name,
                description: widgetConfigDataForEdit.description,
                pollingTime: widgetConfigDataForEdit.config.pollingTime,
                queryType: widgetConfigDataForEdit.config.queryType,
                customQuery: val
            })
        }
    }

    closeModal = () => {
        this.setState({
            label: "",
            description: "",
            pollingTime: 1,
            queryType: QueryType.CUSTOMQUERY,
            ownQuery: "",
            customQuery: {
                select: [],
                from: "",
                where: null,
                groupBy: [],
                orderBy: {
                    field: "time",
                    order: "DESC"
                },
                limit: 100
            },
            testFlag: false,
            message: ""
        }, () => {
            this.props.closeModal();
        })
    }

    fieldUpdateHandler = (event) => {
        this.setState({ [event.target.name]: event.target.value });
    }


    submitHandler = () => {
        
        let { label, description, pollingTime, ownQuery, customQuery, queryType } = this.state;
        let messages = validateForm(label, ownQuery, customQuery, queryType, pollingTime);
        if (messages.length === 0) {
            const { widgetConfigDataForEdit } = this.props;
            let queryData = ""
            if (queryType === QueryType.QUERY) {
                queryData = ownQuery
            }
            else {
                let val = JSON.parse(JSON.stringify(customQuery));
                queryData = val
            }
            let customConfig = {
                query: queryData,
                queryType,
                pollingTime: parseInt(pollingTime)
            };
            let widgetMetaData = generateMetadata(widgetConfigDataForEdit.id, label, description,
                widgetConfigDataForEdit.height, widgetConfigDataForEdit.width, widgetConfigDataForEdit.xCoordinate,
                widgetConfigDataForEdit.yCoordinate, minWidth, maxWidth, minHeight, maxHeight, "TABLE", customConfig)
            this.props.updateWidget(widgetMetaData)
                .then(() => {
                    this.closeModal();
                })
        } else {
            this.setMessage(messages);
        }
    }

    setMessage = (messages) => {
        this.setState({ message: messages.join(', ') }, () => {
            setTimeout(() => {
                this.setState({ message: '' });
            }, 5000);
        })
    }

    updateQuery = (args) => {
        this.setState({
            customQuery: {
                where: args.key === 'where' ? args.value : this.state.customQuery.where,
                select: args.key === 'select' ? args.value : this.state.customQuery.select,
                from: args.key === 'from' ? args.value : this.state.customQuery.from,
                groupBy: args.key === 'groupBy' ? args.value : this.state.customQuery.groupBy,
                orderBy: args.key === 'orderBy' ? args.value : this.state.customQuery.orderBy,
                limit: args.key === 'limit' ? args.value : this.state.customQuery.limit
            },
            testFlag: false
        });
    }

    resetQuery = (where, select, groupBy, limit, from, orderBy) => {
        this.setState({
            customQuery: {
                where: where,
                select: select,
                from: from,
                groupBy: groupBy,
                orderBy: orderBy,
                limit: limit
            },
            testFlag: false
        });
    }

    setTestFlagTrue = () => {
        const { queryType, customQuery } = this.state;
        let checkFlag = true;
        if (queryType === QueryType.CUSTOMQUERY) {
            let messages = validateQuery(customQuery, messages);
            if (messages.length > 0) {
                checkFlag = false
                this.setMessage(messages);
            }
        }
        if (checkFlag) {
            this.setState({
                testFlag: true
            })
        }
    }

    ownQueryUpdateHandler = (event) => {
        this.setState({
            [event.target.name]: event.target.value,
            testFlag: false
        });
    }

    queryTypeRadioButtonChangeHandler = (name, value) => {
        this.setState({
            [name]: value,
            testFlag: false
        });
    }

    render() {
        let { label, description, pollingTime, queryType, ownQuery, customQuery, testFlag, message } = this.state;
        let { modal, timeZone, applicationId, widgetCreationMetadata } = this.props;
        let metadata = {
            name: label, description, type: "TABLE", config: {
                pollingTime, queryType, query: (queryType ===
                    QueryType.QUERY) ? ownQuery : customQuery
            }
        };
        return (
            <Modal className="modal-dialog-centered" size="lg" isOpen={modal} backdrop="static">
                <ModalHeader toggle={this.closeModal} charCode="×">
                    <span>Configure Widget</span>
                </ModalHeader>
                <ConfigurationForm
                    message={message}
                    label={label}
                    description={description}
                    metadata={metadata}
                    widgetCreationMetadata={widgetCreationMetadata}
                    timeZone={timeZone}
                    fieldUpdateHandler={this.fieldUpdateHandler}
                    setMessage={this.setMessage}
                    queryTypeRadioButtonChangeHandler={this.queryTypeRadioButtonChangeHandler}
                    ownQueryUpdateHandler={this.ownQueryUpdateHandler}
                    setTestFlagTrue={this.setTestFlagTrue}
                    testFlag={testFlag}
                    applicationId={applicationId}
                    resetQuery={this.resetQuery}
                    updateQuery={this.updateQuery}
                />
                <ModalFooter className="align-content-center">
                    <Button color="primary" onClick={this.submitHandler}>Update</Button>
                </ModalFooter>
            </Modal>
        );
    }
}

EditTableConfigurationModal.propTypes = {
    modal: PropTypes.bool,
    applicationId: PropTypes.string,
    timeZone: PropTypes.string,
    widgetConfigDataForEdit: PropTypes.object,
    widgetCreationMetadata: PropTypes.object,
    closeModal: PropTypes.func,
    updateWidget: PropTypes.func
};
