import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalHeader, ModalFooter, Button } from "reactstrap";
import ConfigurationForm from "./configurationBody";
import {
    defaultCameraHeight, defaultCameraWidth, defaultX, defaultY, defaultCameraSelectorHeight,
    defaultCameraSelectorWidth, validate, addCameraView, addCameraSelector
} from './constants';
import { v1 as uuidv1 } from 'uuid'

export default class EditCameraConfigurationModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            label: "",
            description: "",
            selectedCameraFeeds: [],
            message: ""
        }
    }

    UNSAFE_componentWillMount() {
        const { widgetConfigDataForEdit } = this.props;
        this.setStateFunc(widgetConfigDataForEdit)
    }

    setStateFunc = (widgetConfigDataForEdit) => {
        this.setState({
            label: widgetConfigDataForEdit.name,
            description: widgetConfigDataForEdit.description,
            selectedCameraFeeds: widgetConfigDataForEdit.config.cameraFeeds,
        })
    }

    closeModal = () => {
        this.setState({
            label: "",
            description: "",
            selectedCameraFeeds: [],
            message: ""
        }, () => {
            this.props.closeModal();
        });
    }

    fieldUpdateHandler = (event) => {
        this.setState({ [event.target.name]: event.target.value });
    }

    setSelectedCameraFeeds = (e, obj) => {
        let selectedOptions = Object.assign([], this.state.selectedCameraFeeds);
        let index = selectedOptions.findIndex(x => x.serviceUrl === obj.serviceUrl)
        if (index === -1) {
            selectedOptions.push(obj);
        }
        else {
            selectedOptions.splice(index, 1);
        }
        this.setState({
            selectedCameraFeeds: selectedOptions
        });
    }

    updateCameraWidget = (config) => {
        // Using this since we need to unmount the widget and remount it to use the latest default feed
        const { addWidget, deleteWidget } = this.props;
        deleteWidget(config.id).then(() => {
            addWidget(config)
        }).then(() => {
            this.closeModal();
        })
    }

    updateHandler = () => {
        let { label, description, selectedCameraFeeds } = this.state;
        let validationMessage = validate(label, selectedCameraFeeds)
        if (validationMessage.length === 0) {
            const { widgetConfigDataForEdit, getVerticalAlignmentY, addWidget,
                deleteWidget, getWidgetById, updateWidget } = this.props;
            let cameraViewConfig = addCameraView(label, description, selectedCameraFeeds,
                widgetConfigDataForEdit.height, widgetConfigDataForEdit.width,
                widgetConfigDataForEdit.xCoordinate, widgetConfigDataForEdit.yCoordinate);
            cameraViewConfig.id = widgetConfigDataForEdit.id;
            let cameraSelectorConfig;
            if (widgetConfigDataForEdit.config.dependentWidgetId) {
                let dependentWidgetMetaData = getWidgetById(widgetConfigDataForEdit.config.dependentWidgetId[0]);
                // case 1: When the camera selector widget exists and will being updated since selectedCameraFeeds.length > 1
                if (selectedCameraFeeds.length > 1) {
                    cameraSelectorConfig = addCameraSelector(label, description, selectedCameraFeeds,
                        cameraViewConfig.name, cameraViewConfig.id, dependentWidgetMetaData.height,
                        dependentWidgetMetaData.width, dependentWidgetMetaData.xCoordinate,
                        dependentWidgetMetaData.yCoordinate);
                    cameraSelectorConfig.id = dependentWidgetMetaData.id;
                    cameraViewConfig.config.dependentWidgetId = [dependentWidgetMetaData.id];
                    updateWidget(cameraSelectorConfig).then(() => {
                        this.updateCameraWidget(cameraViewConfig)
                    })
                } else {
                    // case 2: When the camera selector widget exists before and will being deleted since
                    // selectedCameraFeeds.length == 1
                    deleteWidget(dependentWidgetMetaData.id).then(() => {
                        this.updateCameraWidget(cameraViewConfig);
                    });
                }
            } else {
                // case 3: When the camera selector widget does not exist and will be created since
                // selectedCameraFeeds.length > 1

                if (selectedCameraFeeds.length > 1) {
                    let verticalAlignmentY = getVerticalAlignmentY(defaultCameraSelectorWidth);
                    let cameraSelectorConfig = addCameraSelector(label, description, selectedCameraFeeds, cameraViewConfig.name,
                        cameraViewConfig.id, defaultCameraSelectorHeight, defaultCameraSelectorWidth,
                        defaultX, verticalAlignmentY);
                    cameraSelectorConfig.id = uuidv1();
                    cameraViewConfig.config.dependentWidgetId = [];
                    cameraViewConfig.config.dependentWidgetId.push(cameraSelectorConfig.id);
                    addWidget(cameraSelectorConfig).then(() => {
                        updateWidget(cameraViewConfig)
                    }).then(() => {
                        this.closeModal();
                    })
                } else {
                    // case 3: When the camera selector widget does not exist and will not be created since
                    // selectedCameraFeeds.length == 1
                    this.updateCameraWidget(cameraViewConfig);
                }
            }
        }
        else {
            this.setState({
                message: validationMessage.join(', ')
            }, () => {
                setTimeout(() => {
                    this.setState({ message: '' })
                }, 5000)
            })
        }
    }

    render() {
        let { message, label, description, selectedCameraFeeds } = this.state;
        let { modal, widgetCreationMetadata } = this.props;
        let cameraViewMetadata = addCameraView(label, description, selectedCameraFeeds,
            defaultCameraHeight, defaultCameraWidth, defaultX, defaultY);
        cameraViewMetadata.id = 'cameraWidget';
        cameraViewMetadata.config.widgetMappedID = cameraViewMetadata.id;
        let cameraSelectorMetadata = addCameraSelector(label, description, selectedCameraFeeds,
            cameraViewMetadata.name, cameraViewMetadata.id, defaultCameraSelectorHeight,
            defaultCameraSelectorWidth, defaultX, defaultY);
        return (
            <Modal className="modal-dialog-centered" size="lg" isOpen={modal} backdrop="static" fade={false}>
                <ModalHeader toggle={this.closeModal} charCode="×">
                    <span>Configure widget</span>
                </ModalHeader>
                <ConfigurationForm
                    cameraViewMetadata={cameraViewMetadata}
                    message={message}
                    cameraSelectorMetadata={cameraSelectorMetadata}
                    widgetCreationMetadata={widgetCreationMetadata}
                    fieldUpdateHandler={this.fieldUpdateHandler}
                    setSelectedCameraFeeds={this.setSelectedCameraFeeds}
                />
                <ModalFooter className="align-content-center">
                    <Button color="primary" onClick={this.updateHandler}>Update</Button>
                </ModalFooter>
            </Modal>
        );
    }
}

EditCameraConfigurationModal.propTypes = {
    modal: PropTypes.bool,
    widgetCreationMetadata: PropTypes.object,
    widgetConfigDataForEdit: PropTypes.object,
    closeModal: PropTypes.func,
    updateWidget: PropTypes.func,
    deleteWidget: PropTypes.func,
    getVerticalAlignmentY: PropTypes.func,
    addWidget: PropTypes.func,
    getWidgetById: PropTypes.func
};
