export const validateForm = (label, selectedParameter) => {
    let messages = [];
    if(!label) messages.push('Label is mandatory');
    if(!selectedParameter) messages.push('Rintime parameter is required');
    return messages;
}

export const generateMetadata = (id,name,description,height,width,xCoordinate,yCoordinate,minWidth,maxWidth,minHeight,maxHeight,type,config) =>{
    return {
        id: id,
        name : name,
        description: description,
        height: height,
        width : width,
        xCoordinate: xCoordinate,
        yCoordinate: yCoordinate,
        minWidth: minWidth,
        maxWidth: maxWidth,
        minHeight: minHeight,
        maxHeight: maxHeight,
        type: type,
        config : config
    };
}

export const getSelectData = (id, selectField,selectAlias,isDistinct) =>{
    let obj ={
        id: id,
        field: selectField,
        alias: selectAlias,
        operations: ["COUNT"]
    };
    if(isDistinct){
        obj.operations.push("DISTINCT")
    }
    return obj;
}