import {
    ADD_NEW_DASHBOARD,
    DELETE_DASHBOARD,
    SET_DASHBOARD,
    UPDATE_DASHBOARD,
    CLEAR_DASHBOARD,
    ADD_STATE_TO_DASHBOARD,
    SET_RUNTIME_PARAMETERS,
    CHANGE_RUNTIME_PARAMETER,
    CHANGE_RUNTIME_PARAMETER_IN_WIDGET,
    SET_PARAMETERS_LOADING
} from '../constants/actionTypes'
import { baseURL } from '../constants/urls';
import { stringify, getServiceUrl, fetchAccessToken } from '../../utils/commonUtils';

const CONTENT_TYPE = 'application/json';
const FORM_CONTENT_TYPE = 'multipart/form-data';

export const createDashboard = (data, applicationId) => (dispatch) => {

    const formdata = new FormData();
    formdata.append("name", data.name)
    formdata.append("description", data.description)
    if (data.icon) {
        formdata.append("icon", data.icon, data.icon.name)
    }
    formdata.append("layoutid", data.layoutid)
    formdata.append("natsSubject", data.natsSubject)

    

    return new Promise((resolve, reject) => {
        fetch(`${baseURL}applications/${applicationId}/dashboards`, {
            method: 'POST', // or 'PUT'
            body: formdata,
            headers: { 'userID': '12345' }
        }).then(res => res.json()).then(response => {
            if (response.status === "error") {
                let errorMessage = response.errors.map(err => err.userMessage).join(',');
                
                reject(errorMessage);
            } else {
                dispatch({
                    type: ADD_NEW_DASHBOARD,
                    data: response.data
                });
                resolve(response.data);
            }
        }).catch(error => {
            
            reject(error.message);
        });
    });
};

export const addTimeRangeStateToDashboard = (state) => (dispatch) => {
    dispatch({
        type: ADD_STATE_TO_DASHBOARD,
        data: state,
    })
}

export const updateDashboard = (dashboard, applicationId) => (dispatch) => {

    const formdata = new FormData();
    formdata.append("id", dashboard.id)
    formdata.append("name", dashboard.name)
    formdata.append("description", dashboard.description)
    if (dashboard.icon) {
        formdata.append("icon", dashboard.icon, dashboard.icon.name)
    }

    const timeRange = dashboard.widgets.find(widget => widget.type === 'TIME_RANGE_SELECTOR');
    if(timeRange && timeRange.config.savedState) {
        timeRange.config.savedState = stringify(timeRange.config.savedState);
    }
    formdata.append("layoutid", dashboard.layoutid)
    formdata.append("widgets", JSON.stringify(dashboard.widgets))

    return new Promise((resolve, reject) => {
        fetch(`${baseURL}applications/${applicationId}/dashboards/${dashboard.id}`, {
            method: 'PUT',
            body: formdata,
            headers: { 'userID': '12345' }
        }).then(res => res.json()).then(response => {
            if (response.status === "error") {
                let errorMessage = response.errors.map(err => err.userMessage).join(',');
                
                reject(errorMessage);
            } else {
                if(dashboard.icon === null) {
                    const removeIconURL = `${baseURL}applications/${dashboard.id}/dashboardImages`;
                    fetch(removeIconURL, {
                        method: 'DELETE',
                        headers: { 'userID': '12345' },
                    }).then(() => {
                        dispatch({
                            type: UPDATE_DASHBOARD,
                            data: response.data
                        });
                        resolve(response.data);
                    }).catch(() => reject('Could not delete image'))
                } else {
                    dispatch({
                        type: UPDATE_DASHBOARD,
                        data: response.data
                    });
                    resolve(response.data);
                }
            }
        }).catch(error => {
            
            reject(error.message);
        });
    });
};

export const deleteDashboard = (dashboardId, applicationId) => (dispatch) => {
    let deleteUrl = `${baseURL}applications/${applicationId}/dashboards/${dashboardId}`;
    return new Promise((resolve, reject) => {
        fetch(deleteUrl, {
            method: 'DELETE',
            headers: { 'Content-Type': CONTENT_TYPE, 'userID': '12345' }
        }).then(response => {
            if (response.status === "error") {
                let errorMessage = response.errors.map(err => err.userMessage).join(',');
                
                reject(errorMessage);
            } else {
                dispatch({
                    type: DELETE_DASHBOARD,
                    data: dashboardId
                });
                resolve(response);
            }
        }).catch(error => {
            
            reject(error.message);
        });
    });
};

export const setDashboard = (dashboard) => (dispatch) => {
    dispatch({
        type: SET_DASHBOARD,
        data: dashboard
    });
}

export const clearDashboard = () => (dispatch) => {
    dispatch({
        type: CLEAR_DASHBOARD
    });
}

export const setParametersAction = ({layoutName, serviceUrl}) => (dispatch) => {
    let id = '';
    let token = ''
    const API = getServiceUrl(serviceUrl)
    dispatch({
        type: SET_PARAMETERS_LOADING
    })
    fetchAccessToken()
        .then(accessToken => {
            token = `Bearer ${accessToken}`;
            return fetch(`${API}/api/v1/pipeline-layouts`, {
                headers: {
                    'Authorization': token
                }
            })
        })
        .then(d => d.json()).then(d => {
            id = String(d.pipelineLayouts.find(x => x.name === layoutName).id)
            return fetch(`${API}/api/v1/pipeline-instance/inference/data?deployed=1`, {
                headers: {'Authorization': token}
            })
        }).then(d => d.json())
        .then(d => {                    
            const layout = d.instances.find(i => i.pipelineLayoutId === id)
            if(layout) {
                const groups = Object.keys(layout.inferencePipeline.runtimeParams.groups)
                dispatch({
                    type: SET_RUNTIME_PARAMETERS,
                    data: {
                        parameters: groups,
                        parametersName: layout.name
                    }
                })
            }
        })
}

export const changeRuntimeParameterAction = (payload) => (dispatch) => {
    dispatch({
        type: CHANGE_RUNTIME_PARAMETER,
        selectedParameter: payload
    })
}
export const changeRuntimeParameterInWidgetAction = (payload) => (dispatch) => {
    dispatch({
        type: CHANGE_RUNTIME_PARAMETER_IN_WIDGET,
        payload
    })
}
