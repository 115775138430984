import thumbnail from '../timeSeriesGraphWidget/thumbnail';
import addConfiguration from '../timeSeriesGraphWidget/addConfiguration';
import editConfiguration from '../timeSeriesGraphWidget/editConfiguration';
import widget from '../timeSeriesGraphWidget/dashboardWidgetContainer';

const timeSeriesGraphWidget = {
    type : "TIME_SERIES_GRAPH",
    thumbnail : thumbnail,
    configuration: addConfiguration,
    editConfiguration: editConfiguration,
    widget : widget
};

export default timeSeriesGraphWidget;

