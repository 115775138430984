import { connect } from 'react-redux'
import DashboardWidget from './dashboardWidget';
import {getWidgetData, emitWidgetData, deleteWidget, 
    clearWidgetErrorData, deleteWidgetData} from '../../../store/actions/widgetActions'
const mapStateToProps = (state, ownProps) => ({
    widgetData: state.widgetData,
    metadata: ownProps.metadata,
    applicationId: ownProps.applicationId,
    editDeleteFlag: ownProps.editDeleteFlag,
    timeZone: ownProps.timeZone,
    widgetConfigDataForEdit: ownProps.widgetConfigDataForEdit
})

const mapDispatchToProps = {
    getWidgetData: getWidgetData,
    deleteWidgetData: deleteWidgetData,
    deleteWidget: deleteWidget,
    emitWidgetData: emitWidgetData,
    clearWidgetErrorData: clearWidgetErrorData
}

const DashboardWidgetContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(DashboardWidget)

export default DashboardWidgetContainer;