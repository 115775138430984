export const setVerticalAlignmentY = (widgets,widgetWidth) => {
    let verticalAlignmentY = 0
    if(widgets && widgets.length > 0){
      for( var i=0; i < widgets.length; i++){
          if(widgets[i].xCoordinate < widgetWidth){
              if(widgets[i].yCoordinate >= verticalAlignmentY){
                  verticalAlignmentY = widgets[i].yCoordinate + widgets[i].height
              }
          }
      }
    }
    return verticalAlignmentY;
}