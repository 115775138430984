import {QueryType} from '../../common/constants';
import _ from 'lodash';

export const validateQuery = (customQuery) =>{
    let messages = []
    if(customQuery.select.length === 0){
        messages.push("Please construct Select Query");
    }
    if(customQuery.select.length > 0 && customQuery.select.findIndex(x => x.field !== "") === -1){
        messages.push("Please select Field in the Select Query");
    }
    if(customQuery.select.length > 0){
        let aliasList = []
        let count = 0;
        let select = JSON.parse(JSON.stringify(customQuery.select));
        for(let i=0; i < select.length; i++){
            if(select[i].alias !== ""){
                if(aliasList.indexOf(select[i].alias) === - 1){
                    aliasList.push(select[i].alias);
                }
                else{
                    count++;
                }
            }
        }
        if(count > 0){
            messages.push("Duplicate aliases are present in the query");
        }
    }
    if(customQuery.from === ""){
        messages.push("Please Select dimension");
    }
    if(customQuery.orderBy && ((!customQuery.orderBy.field && customQuery.orderBy.order) ||
    (!customQuery.orderBy.order && customQuery.orderBy.field))) {
        messages.push("Please provide both values in order by")
    }
    if(customQuery.limit === 0){
        messages.push("Maximum Rows must be greater than zero")
    }
    return messages;
}

export const validateForm = (label, ownQuery, customQuery, queryType, yAxis, pollingTime) => {
    let messages = [];
    if(!label) messages.push('Label is mandatory');
    if (queryType === QueryType.QUERY){
        if(!ownQuery) messages.push("Please construct your Query");
    }
    else{
        let resultMessages = validateQuery(customQuery);
        messages = messages.concat(resultMessages);
    }
    if(!yAxis) messages.push('yAxis is mandatory');
    if(!pollingTime || parseInt(pollingTime) === 0) messages.push('Polling Time is mandatory and greater than zero');
    return messages;
}

export const generateMetadata = (id,name,description,height,width,xCoordinate,yCoordinate,minWidth,maxWidth,minHeight,maxHeight,type,config) =>{
    let widgetMetaData = {
        id: id,
        name : name,
        description: description,
        height: height,
        width : width,
        xCoordinate: xCoordinate,
        yCoordinate: yCoordinate,
        minWidth: minWidth,
        maxWidth: maxWidth,
        minHeight: minHeight,
        maxHeight: maxHeight,
        type: type,
        config : config
    };
    return widgetMetaData
}

export const getSelectData = (id, selectField,selectAlias,operations) =>{
    let obj ={
        id: id,
        field: selectField,
        alias: selectAlias,
        operations: operations
    };
    return obj;
}

export const computeColumnsAndCSV =  (data) =>{
    let obj = {
        csvColumns:[],
        columns:[]
    }
    for(let i=0; i < data.length; i++){
        if(data[i] !== "time"){
            let csvObject = {
                id : data[i],
                displayName : data[i]
            }
            obj.csvColumns.push(csvObject);
            let columnObject ={
                Header:data[i],
                accessor:data[i],
                filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, { keys: [data[i]] }),
                filterAll: true
            }
            obj.columns.push(columnObject);
        }
    }
    return obj
}

const addTimeToTagList = (tagsList) =>{
    tagsList.push({
        name:'time',
        label:'Time'
    })
    return tagsList;
}

export const getTagList = (value,widgetCreationMetadata) =>{
    let obj = widgetCreationMetadata.measurements.find(x=> x.measurement === value);
    let tagsList = JSON.parse(JSON.stringify(obj.tags));
    addTimeToTagList(tagsList);
    return tagsList;
}

export const convertToGraphData = (data, timeZone) =>{
    let csvData = [];
    let widgetData = [];
    let arrayList = JSON.parse(JSON.stringify(data));
    let listOfTime = []
    for( let i = 0; i < arrayList.length; i++){
        let listOfTimeResultFeeds = arrayList[i].resultFeeds.map(function(x){
            return x.time;
        })
        listOfTime = _.union(listOfTime, listOfTimeResultFeeds);
    }
    listOfTime.sort();
    csvData.push(['Date Time'])
    widgetData.push([{label:'x',type:'date'}])
    for(let i=0; i < arrayList.length; i++){
        let obj = arrayList[i].resultFeeds[0]
        let groupBy = JSON.parse(JSON.stringify(arrayList[i]));
        delete groupBy['resultFeeds'];
        for(let l=0; l < Object.keys(obj).length; l++){
            if(typeof(Object.values(obj)[l]) !== 'string' && typeof(Object.values(obj)[l]) !== "boolean" 
            && Object.keys(obj)[l] !== 'time'){
                let string = Object.keys(groupBy).length > 0?
                    Object.keys(obj)[l] + JSON.stringify(groupBy).replace(",", "&"):
                    Object.keys(obj)[l];
                widgetData[0].push({label:string,type:'number'});
                csvData[0].push(string);
            }
        }
    }
    for(let i=0; i < listOfTime.length; i++){
        let dataObject = [], dataStringObjForCSV = [];
        for(let j =0; j < widgetData[0].length; j++){
            if(j===0){
                dataStringObjForCSV.push(listOfTime[i]);
                let date = new Date(listOfTime[i]).toLocaleString("en-US", {timeZone: timeZone});
                date = new Date(date);
                dataObject.push(date);
            }
            else{
                dataStringObjForCSV.push("");
                dataObject.push(null);
            }
        }
        csvData.push(dataStringObjForCSV);
        widgetData.push(dataObject);
    }

    for(let i = 0; i < arrayList.length; i++){
        let resultFeeds = JSON.parse(JSON.stringify(arrayList[i].resultFeeds));
        let groupBy = JSON.parse(JSON.stringify(arrayList[i]));
        delete groupBy['resultFeeds'];
        for(let j = 0; j < resultFeeds.length ; j++){
            let obj = resultFeeds[j]
            for(let k=0; k < Object.keys(obj).length; k++){
                if(typeof(Object.values(obj)[k]) !== 'string' && typeof(Object.values(obj)[k]) !== "boolean" 
                && Object.keys(obj)[k] !== 'time'){
                    let time = obj.time;
                    let yIndex = listOfTime.indexOf(time);
                    let xIndex = Object.keys(groupBy).length > 0? 
                        widgetData[0].findIndex(x => x['label'].indexOf(JSON.stringify(groupBy).replace(',','&')) !== -1)
                        : widgetData[0].findIndex(x => x['label'] === Object.keys(obj)[k])
                    csvData[yIndex + 1][xIndex] = Object.values(obj)[k];
                    widgetData[yIndex + 1][xIndex] = Object.values(obj)[k]
                }
            }
        }
    }
    
    return {
        csvData : csvData,
        graphData: widgetData
    }
}