import React from "react";
import { FormGroup, Label, Input, ModalBody } from 'reactstrap';
import PropTypes from "prop-types";
import PreviewWidget from './previewWidgetContainer';
import CustomAlert from '../../common/custom-alert';

const ConfigForm = ({
    message,
    cameraViewMetadata,
    cameraSelectorMetadata,
    widgetCreationMetadata,
    setSelectedCameraFeeds,
    fieldUpdateHandler,
}) => {
    let {name, description} = cameraViewMetadata;
    let label = name;
    let selectedCameraFeeds = cameraSelectorMetadata.config.cameraFeeds;
    let checkboxHtml = [];
    let cameraFeeds = widgetCreationMetadata["cameraFeeds"] ? widgetCreationMetadata["cameraFeeds"] : [];
    let serviceUrl = '';

    cameraFeeds.forEach((obj,index) => {
        // TODO: FIX THIS
        serviceUrl = obj.serviceUrl;

        let selectedCameraFeedsIndex = selectedCameraFeeds.findIndex(x => x.serviceUrl === obj.serviceUrl)
        if(selectedCameraFeedsIndex === -1){
            checkboxHtml.push(
                <div className="custom-control custom-checkbox"  key={index} >
                    <input id={"customCheck" + index} type="checkbox" className="custom-control-input"
                        onChange={(e) => setSelectedCameraFeeds(e,obj)} checked={false}
                    />
                    <Label for={"customCheck" + index} className="custom-control-label w-100">{obj.description}
                    </Label>
                    <p className="text-truncate w-100 d-block" title={obj.streamId}>{obj.streamId}</p>
                </div>
            );
        }
        else{
            checkboxHtml.push(
                <div className="custom-control custom-checkbox"  key={index}>
                    <input id={"customCheck" + index} type="checkbox" className="custom-control-input"
                        onChange={(e) => setSelectedCameraFeeds(e,obj)} checked={true}
                    />
                    <Label for={"customCheck" + index} className="custom-control-label w-100">{obj.description}
                    </Label>
                    <p className="text-truncate w-100 d-block" title={obj.streamId}>{obj.streamId}</p>
                </div>
            ); 
        }
    })
    return(
        <ModalBody className="py-0">
            <div className="row">
                <div className="col-md-8 pl-5 py-2 d-flex flex-column h-100 justify-content-between"
                    style={{maxHeight:"70vh", minHeight:"30vh"}}>
                    <div className="preview-header border-bottom py-3 mr-5 mb-3">
                        <i className="icon-camera-feed mr-2"/>Camera Widget
                    </div>
                    <CustomAlert color="danger">{message}</CustomAlert>
                    {checkboxHtml.length ? (
                        <form className="flex-shrink-1 overflow-auto pr-5">
                            <FormGroup>
                                <Label for="widget-label">Widget Label</Label>
                                <Input required maxLength={30} type="text" name="label" id="widget-label"
                                    placeholder="Enter the widget label here" 
                                    value={label} onChange={fieldUpdateHandler}/>
                            </FormGroup>
                            <FormGroup>
                                <Label for="widget-description">Description</Label>
                                <Input type="text" name="description" maxLength={150} id="widget-description"
                                    placeholder="Enter the widget description here" 
                                    value={description} onChange={fieldUpdateHandler}/>
                            </FormGroup>
                            <FormGroup>
                                <Label>Select URLs</Label>
                                {checkboxHtml}
                            </FormGroup>
                        </form>
                    ) : (
                        <div>
                            <h2>Camera&apos;s URLs are missing</h2>
                            <p>Please check if configuration is correct</p>
                        </div>
                    )}
                </div>
                <div className="col-md-16 preview-bg p-3">
                    <div className="row justify-content-center h-100">
                        <div className={selectedCameraFeeds.length > 1
                            ?"col col-md-24 d-flex align-items-center justify-content-center":
                            "col col-md-14 d-flex align-items-center justify-content-center"}>
                            <PreviewWidget
                                cameraViewMetadata={cameraViewMetadata}
                                cameraSelectorMetadata={cameraSelectorMetadata}
                                serviceUrl={serviceUrl}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </ModalBody>
    );
};

ConfigForm.propTypes = {
    message: PropTypes.string,
    cameraViewMetadata: PropTypes.object,
    widgetCreationMetadata: PropTypes.object,
    cameraSelectorMetadata: PropTypes.object,
    fieldUpdateHandler: PropTypes.func,
    setSelectedCameraFeeds:PropTypes.func
}

export default ConfigForm;