import React from 'react';
import { connect } from 'react-redux'
import PropTypes from 'prop-types';
import thumbnailImage from "../../../../img/widget-thumbnails/counter.svg";
import thumbnailImageDark from "../../../../img/widget-thumbnails/counter-dark.svg";
import { getTheme } from '../../../utils/commonUtils';

const CounterThumbnail = ({clickHandler}) => {
    return (
        <div className="card cursor-pointer card-shadow">
            <div className="card-body text-center" onClick={clickHandler}>
                <h5 className="card-title">Counter</h5>
                <div className="img-wrapper f-42"><img src={getTheme() === 'dark' ? thumbnailImage : thumbnailImageDark} alt="Counter"/></div>
                <p className="card-text">Display the current count, derivatives, averages, sums etc.</p>
            </div>
        </div>
    );
}

CounterThumbnail.propTypes = {
    clickHandler: PropTypes.func
}

const mapStateToProps = (state) => ({theme: state.ui.theme})

export default connect(mapStateToProps)(CounterThumbnail);