import thumbnail from '../runtimeParametersWidget/thumbnail';
import configuration from '../runtimeParametersWidget/addConfiguration';
import editConfiguration from '../runtimeParametersWidget/editConfiguration';
import widget from '../runtimeParametersWidget/dashboardWidgetContainer';
import { WIDGET_TYPE } from '../runtimeParametersWidget/constants'

const runtimeParametersWidget = {
    type : WIDGET_TYPE,
    thumbnail : thumbnail,
    configuration: configuration,
    editConfiguration: editConfiguration,
    widget : widget
};

export default runtimeParametersWidget;