"use strict";

import React, { Component } from "react";
import PropTypes from 'prop-types';
import { Chart } from 'react-google-charts';
import CsvDownloader from 'react-csv-downloader';
import { convertToGraphData } from './utils';
import Alert from '../../common/custom-alert';
import LoadingImage from '../../../../img/loader.gif';
export default class Widget extends Component {

    downloadCSV = () => {
        this.props.downloadClickHandler(this.props.metadata.id);
    }

    getEditDeleteSection = () => {
        const { deleteWidget, getWidgetData, metadata } = this.props;
        return (
            <div className="d-flex flex-column action-drawer pt-1 pb-2">
                <button className="f-22" onClick={getWidgetData.bind(this, metadata.id)}>
                    <i className="icon-edit" />
                </button>
                <button className="f-22" onClick={deleteWidget.bind(this, metadata.id)}>
                    <i className="icon-delete" />
                </button>
            </div>
        );
    }

    render() {
        const { metadata, timeZone, widgetData, propertyName, editDeleteFlag } = this.props;
        let data = {};
        let csvData = [];
        if (widgetData[propertyName] && widgetData[propertyName].data && widgetData[propertyName].data.length > 0) {
            data = convertToGraphData(widgetData[propertyName].data, timeZone);
            csvData = JSON.parse(JSON.stringify(data.csvData));
        } else {
            data = {
                graphData: []
            }
        }
        let message = widgetData.errors && widgetData.errors[propertyName] ?
            widgetData.errors[propertyName] : "";
        if (message && Object.keys(data).length > 0 && data.graphData.length > 0
            && data.graphData[1].length === 1) {
            message = "Not enough columns given to draw the requested chart"
        }
        return (
            <div className='card card-shadow overflow-hidden h-100 w-100'>
                <div className="card-header">
                    {editDeleteFlag ? this.getEditDeleteSection() : ""}
                    <div className="d-flex">
                        <i className="icon-camera-feed-s align-self-center mr-2" />
                        {metadata.name ? metadata.name : 'Time Series Graph'}
                        <span className="ml-auto">
                            <button className="d-inline-flex ml-2 p-1 btn-sm btn btn-secondary"
                                onClick={this.downloadCSV} >
                                <i className="icon-download" />
                            </button>
                            <CsvDownloader filename="GraphData" datas={csvData}>
                                <a id={metadata.id + "-csv"} style={{ display: "none" }} />
                            </CsvDownloader>
                        </span>
                    </div>
                </div>
                {data.graphData ? data.graphData.length > 0 ?
                    <div className="card-body p-0 overflow-auto" style={{ height: '246px' }}>
                        <Alert color="danger">{message}</Alert>
                        {message === "" ?
                            <div id='graph-id'>
                                <Chart
                                    width={'100%'}
                                    height={'100%'}
                                    chartType="LineChart"
                                    loader={<div>Loading Chart</div>}
                                    data={data.graphData}
                                    options={{
                                        hAxis: {
                                            title: metadata.config.xAxis,
                                            textPosition: 'out',
                                            format: 'M/d/yy hh:mm aa',
                                            showTextEvery: 1,
                                            gridlines: {
                                                count: -1,
                                                units: {
                                                    years: { format: ['M/yy'] },
                                                    months: { format: ['MMM'] },
                                                    days: { format: ['MMM dd'] },
                                                    hours: { format: ['hh:mm aa'] },
                                                    minutes: { format: ['hh:mm aa'] },
                                                    seconds: { format: ['mm:ss'] },
                                                    milliseconds: { format: ['fff'] },
                                                }
                                            }
                                        },
                                        vAxis: { title: metadata.config.yAxis },
                                        series: { 1: { curveType: 'function' } },
                                        legend: { position: 'bottom' },
                                        interpolateNulls: true
                                    }}
                                />
                            </div>
                            : null
                        }
                    </div> :
                    <div className="card-body overflow-auto py-0 my-3 d-block align-items-center"
                        style={{ height: '246px' }}>
                        <div className="d-flex h-100 justify-content-center">No data</div>
                    </div> :
                    <div className="d-flex h-100 justify-content-center">
                        <img className="d-flex align-self-center" src={LoadingImage} />
                    </div>
                }
                <div className="card-footer">
                    <div>{metadata.description ? metadata.description : ' '}</div>
                </div>
            </div>
        );
    }
}

Widget.propTypes = {
    editDeleteFlag: PropTypes.bool,
    propertyName: PropTypes.string,
    timeZone: PropTypes.string,
    metadata: PropTypes.object,
    widgetData: PropTypes.object,
    getWidgetData: PropTypes.func,
    deleteWidget: PropTypes.func,
    getWidgetConfigData: PropTypes.func,
    downloadClickHandler: PropTypes.func,
    deleteWidgetData: PropTypes.func,
    clearWidgetErrorData: PropTypes.func
}
