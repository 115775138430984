import React, {Component} from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Modal, Button , ModalHeader, ModalFooter } from 'reactstrap';
import {v1 as uuidv1} from 'uuid';
import ConfigurationForm from "./configurationBody";
import { removeTimeRangeInitState } from '../../../store/actions/widgetActions';
import {ViewType,defaultHeightForFullView,defaultWidthForFullView, defaultHeightForMinifiedView, 
    defaultWidthForMinifiedView, defaultX,generateMetadata,minWidthForFullView,minWidthForMinifiedView,
    minHeightForFullView,minHeightForMinifiedView,maxWidthForFullView,maxWidthForMinifiedView,maxHeightForMinifiedView,
    maxHeightForFullView,getDefaultTimeRangeOptions} from './constants';

function mergeOptions(currentOptions ,newOptions) {
    if(newOptions) {
        return newOptions.length === 1 ? [...currentOptions, ...newOptions] : currentOptions;
    }
    return currentOptions;
}
class TimeRangeSelectorConfigurationModal extends Component{
    constructor(props){
        super(props);
        this.state = {
            label : "",
            description : "",
            selectedTimeRangeOptions: getDefaultTimeRangeOptions,
            openQuickCustomModal: false,
            viewType : ViewType.FULLVIEW,
            message:""
        }
    }

    closeModal = () => {
        this.setState({
            label : "",
            description : "",
            selectedTimeRangeOptions: getDefaultTimeRangeOptions,
            viewType : ViewType.FULLVIEW,
            openQuickCustomModal: false
        },() =>{
            this.props.closeModal();
        })
    }
    setTimeRangeOptions = (e,obj) => {

        let selectedOptions = this.state.selectedTimeRangeOptions;
        let index = selectedOptions.findIndex(x => x.name === obj.name)
        if( index === -1){
            selectedOptions.push(obj);
        }
        else{
            selectedOptions.splice(index, 1);
        }
        this.setState({
            selectedTimeRangeOptions : selectedOptions
        });
    }

    fieldUpdateHandler = (event) => {
        this.setState({
            [event.target.name] : event.target.value
        });
    }

    submitHandler = () => {
        
        const {getVerticalAlignmentY, widgetData} = this.props;
        let {label, description, viewType, selectedTimeRangeOptions} = this.state;
        const rangeOptions = mergeOptions(selectedTimeRangeOptions, this.props.widgetMetadata);
        if(label){
            let defaultHeight = viewType ===ViewType.FULLVIEW? defaultHeightForFullView : defaultHeightForMinifiedView;
            let defaultWidth = viewType === ViewType.FULLVIEW? defaultWidthForFullView : defaultWidthForMinifiedView;
            let minWidth = viewType===ViewType.FULLVIEW?minWidthForFullView:minWidthForMinifiedView;
            let maxWidth = viewType===ViewType.FULLVIEW?maxWidthForFullView:maxWidthForMinifiedView;
            let minHeight  = viewType===ViewType.FULLVIEW?minHeightForFullView:minHeightForMinifiedView;
            let maxHeight = viewType===ViewType.FULLVIEW? maxHeightForFullView:maxHeightForMinifiedView;
            
            let widgetMetaData = generateMetadata(uuidv1(),label,description, defaultHeight, 
                defaultWidth,defaultX,getVerticalAlignmentY(defaultWidth),minWidth,maxWidth,minHeight,maxHeight,viewType,rangeOptions)
            widgetMetaData.config.savedState = widgetData.initTimeRangeState;
            this.props.addWidget(widgetMetaData)
                .then(() => {
                    this.closeModal();
                });
        }
        else{
            this.setState({
                message : 'Widget Name cannot be empty'
            },()=>{
                setTimeout(() => {
                    this.setState({message:''})
                }, 5000)
            })
        }
    }

    radioButtonChangeHandler = (name, value) => {
        this.setState({[name] : value});
    }

    openQuickCustomSelectionModal = () =>{
        this.setState({
            openQuickCustomModal:true
        })
    }

    closeQuickSelectionModal = () =>{
        this.setState({
            openQuickCustomModal:false
        })
    }

    showWidgetSelectionPage = () =>{
        this.setState( {
            label : "",
            description : "",
            selectedTimeRangeOptions: [],
            viewType : ViewType.FULLVIEW,
            openQuickCustomModal: false,
            message:''
        },() => {
            this.props.showWidgetSelectionPage();
        })
    }

    render(){
        let {label, description, viewType, selectedTimeRangeOptions, openQuickCustomModal, message} = this.state;
        let {modal, applicationId, widgetCreationMetadata ,addCustomSelectionForTimeRangeWidget} = this.props;
        const rangeOptions = mergeOptions(selectedTimeRangeOptions, this.props.widgetMetadata);
        let metadata = {name: label,description: description,config: {
            viewType,
            selectedTimeRangeOptions: rangeOptions,
        }};
        return(
            <Modal className="modal-dialog-centered" size="lg" isOpen={modal} backdrop="static" fade={false}>
                <ModalHeader toggle={this.closeModal} charCode= "×">
                    <div>
                        <span>Select Widget</span>
                        <span>Configure widget</span>
                    </div>
                </ModalHeader>
                <ConfigurationForm
                    metadata={metadata}
                    message={message}
                    widgetCreationMetadata={widgetCreationMetadata}
                    applicationId={applicationId}
                    fieldUpdateHandler={this.fieldUpdateHandler}
                    addCustomSelectionForTimeRangeWidget={addCustomSelectionForTimeRangeWidget}
                    openQuickCustomModal={openQuickCustomModal}
                    openQuickCustomSelectionModal={this.openQuickCustomSelectionModal}
                    setTimeRangeOptions={this.setTimeRangeOptions}
                    closeQuickSelectionModal={this.closeQuickSelectionModal}
                    radioButtonChangeHandler={this.radioButtonChangeHandler}
                />
                <ModalFooter>
                    <Button color="primary" onClick={this.submitHandler}>Add</Button>
                    <Button outline color="secondary" onClick={this.showWidgetSelectionPage}>Previous</Button>
                </ModalFooter>
            </Modal>
        );
    }
}

TimeRangeSelectorConfigurationModal.propTypes = {
    modal: PropTypes.bool,
    applicationId: PropTypes.string,
    widgetCreationMetadata: PropTypes.object,
    widgetData: PropTypes.object,
    closeModal: PropTypes.func,
    addWidget: PropTypes.func,
    showWidgetSelectionPage: PropTypes.func,
    getVerticalAlignmentY: PropTypes.func,
    addCustomSelectionForTimeRangeWidget: PropTypes.func,
    widgetMetadata: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.array,
    ]),
};

const mapDispatchToProps = dispatch => ({
    removeTimeRangeInitState: () => dispatch(removeTimeRangeInitState()),
})

const mapStateToProps = state => ({
    widgetData: state.widgetData,
    widgetMetadata: state.metadata.meta.timeRangeOptions,
})

export default connect(mapStateToProps, mapDispatchToProps)(TimeRangeSelectorConfigurationModal);