import {
    SET_DASHBOARD,
    ADD_WIDGET,
    UPDATE_WIDGET,
    DELETE_WIDGET,
    CLEAR_DASHBOARD,
    UPDATE_ALL_WIDGETS,
    SET_STREAM_ID,
    ADD_STATE_TO_DASHBOARD,
    SET_RUNTIME_PARAMETERS,
    CHANGE_RUNTIME_PARAMETER,
    CHANGE_RUNTIME_PARAMETER_IN_WIDGET,
    SET_PARAMETERS_LOADING
} from '../constants/actionTypes'
import { setData } from '../../utils/storeUtils';
import { stringify } from '../../utils/commonUtils';

const setDashboard = (dashboard, streamId) => {
    dashboard = JSON.parse(JSON.stringify(dashboard));
    if(streamId) {
        dashboard.streamId = streamId;
    }
    return dashboard;
}
const addWidget = (state, newWidget) => {
    let widgets = JSON.parse(JSON.stringify(state.widgets));
    if (!widgets || widgets.length === 0) {
        widgets = [newWidget];
    } else {
        widgets = widgets.concat([newWidget]);
    }
    return setData(state, "widgets", widgets);
};

const updateWidget = (state, updatedWidget) => {
    let widgets = JSON.parse(JSON.stringify(state.widgets));
    let index = widgets.findIndex(obj => obj.id === updatedWidget.id);
    widgets.splice(index, 1, updatedWidget);
    return setData(state, "widgets", widgets);
};

const updateTimeRangeConfig = (state, widgetState) => {
    const newWidgets = [...state.widgets];
    const widget = newWidgets.find(widget => widget.type === 'TIME_RANGE_SELECTOR');
    if(widget) {
        widget.config.savedState = widgetState;
    }
    state.widgets = newWidgets;
    return {...state};
}

const deleteWidget = (state, id) => {
    let widgets = JSON.parse(JSON.stringify(state.widgets));
    widgets = widgets.filter(obj => obj.id !== id);
    return setData(state, "widgets", widgets);
};

const dashboardReducer = (state = {}, action) => {
    switch (action.type) {
        case SET_DASHBOARD:
            return setDashboard(action.data, state.streamId);
        case ADD_WIDGET:
            return addWidget(state, action.data);
        case UPDATE_WIDGET:
            return updateWidget(state, action.data);
        case UPDATE_ALL_WIDGETS:
            return setData(state, "widgets", action.data);
        case DELETE_WIDGET:
            return deleteWidget(state, action.data);
        case CLEAR_DASHBOARD:
            return {};
        case SET_STREAM_ID:
            return setData(state, "streamId", action.data);
        case ADD_STATE_TO_DASHBOARD:
            return updateTimeRangeConfig(state, action.data);
        case CHANGE_RUNTIME_PARAMETER:
            return setDashboard({
                ...state,
                selectedParameter: action.selectedParameter
            })
        case SET_RUNTIME_PARAMETERS:
            return setDashboard({
                ...state,
                parameters: action.data.parameters,
                parametersName: action.data.parametersName,
                parametersLoading: false,
                selectedParameter: state.selectedParameter || action.data.parameters[0]
            }, state.streamId)
        case CHANGE_RUNTIME_PARAMETER_IN_WIDGET: {
            const widgetId = action.payload.id
            let index = -1;
            for(let i = 0; i < state.widgets.length; i++) {
                if(state.widgets[i].id === widgetId) {
                    index = i
                    break;
                }
            }
            if(index !== -1) {
                state.widgets[index].config.selectedParameter = action.payload.value
            }
            return {...state, widgets: [...state.widgets]}
        }
        case SET_PARAMETERS_LOADING: {
            return {...state, parametersLoading: true}
        }
        default:
            return state;
    }
};

export default dashboardReducer;
