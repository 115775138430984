import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { cameraFeedBaseUrl } from '../../../store/constants/urls';
import CustomAlert from '../../common/custom-alert';
import LazyImage from '../../common/lazyImage';
import LoadingImage from '../../../../img/loader.gif';
import ImageNotAvailable from '../../../../img/image-not-available.svg';

class CameraViewWidget extends Component {
    constructor(props){
        super(props);
        this.state = {
            message : ""
        }
    }

    getUrl = () => {
        const {cameraUrl} = this.props;
        let cameraFeed = cameraUrl;
        if(cameraFeed && cameraFeed.startsWith("rtsp")) return `${cameraFeedBaseUrl}streams/convert_stream?url=${cameraFeed}`;
        return cameraFeed;
    }

    deleteClickHandler = () =>{
        const {metadata,deleteWidget} = this.props;
        if( metadata.config.dependentWidgetId ){
            deleteWidget(metadata.config.dependentWidgetId[0])
                .then(() => {
                    deleteWidget(metadata.id)
                })
        }
        else{
            deleteWidget(metadata.id);
        }
    }

    getEditDeleteSection = () => {
        const {getWidgetConfigData, metadata} = this.props;
        return (
            <div className="d-flex flex-column action-drawer pt-1 pb-2">
                <button className="f-22" onClick={getWidgetConfigData.bind(this, metadata.id)}><i className="icon-edit"/></button>
                <button className="f-22" onClick={this.deleteClickHandler}><i className="icon-delete"/></button>
            </div>
        );
    }

    render(){
        const {metadata,editDeleteFlag} = this.props;
        const {message} = this.state;
        return(
            <div className="card card-shadow overflow-hidden h-100 w-100">
                <div className="card-header">
                    {editDeleteFlag ? this.getEditDeleteSection() : ""}
                    <div className="d-flex">
                        <i className="icon-camera-feed-s mr-2"/>
                        {metadata.name ? metadata.name : 'Camera Feed'}
                    </div>
                </div>
                <div className="card-body p-0 overflow-auto">
                    <CustomAlert color="danger">{message}</CustomAlert>
                    <LazyImage
                        id={metadata.id}
                        className="img-fluid w-100 h-100"
                        src={this.getUrl()}
                        loadingImage={LoadingImage}
                        errorImage={ImageNotAvailable}
                    />
                </div>
                <div className="card-footer">
                    <div>{metadata.description ? metadata.description : ' '}</div>
                </div>
            </div>
        );
    }

}

CameraViewWidget.propTypes = {
    editDeleteFlag: PropTypes.bool,
    cameraUrl: PropTypes.string,
    metadata: PropTypes.object,
    widgetData: PropTypes.object,
    deleteWidget: PropTypes.func,
    getWidgetConfigData: PropTypes.func
}

export default CameraViewWidget;