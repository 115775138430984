import {
    SET_APP_LIST,
    SET_CURRENT_APP,
    ADD_NEW_APP,
    EDIT_APP,
    DELETE_APP,
    SET_WIDGET_METADATA
} from '../constants/actionTypes'
import { baseURL } from '../constants/urls';

const CONTENT_TYPE = 'application/json';
const FORM_CONTENT_TYPE = 'multipart/form-data';

const DUMMY_USER_ID = '12345'; // Will be replaced by actual userID whenever auth is implemented

export const getApplications = () => (dispatch) => {
    return new Promise((resolve, reject) => {
        fetch(`${baseURL}applications`, {
            method: 'GET',
            headers: { 'Content-Type': CONTENT_TYPE, 'userID': DUMMY_USER_ID }
        }).then(res => res.json()).then(response => {
            if(response.status === "error"){
                let errorMessage = response.errors.map(err => err.userMessage).join(',');
                
                reject(errorMessage);
            } else {
                dispatch({
                    type: SET_APP_LIST,
                    data: response.data
                });
            }
        }).catch(error => {
            
            reject(error.message);
        });
    });
};

export const getApplicationDetail = (applicationId) => (dispatch) => {
    let getUrl = `${baseURL}applications/${applicationId}`;
    return new Promise((resolve, reject) => {
        fetch(getUrl, {
            method: 'GET',
            headers: { 'Content-Type': CONTENT_TYPE, 'userID': DUMMY_USER_ID }
        }).then(res => res.json()).then(response => {
            if(response.status === "error"){
                let errorMessage = response.errors.map(err => err.userMessage).join(',');
                
                reject(errorMessage);
            } else {
                dispatch({
                    type: SET_CURRENT_APP,
                    data: response.data
                });
            }
        }).catch(error => {
            
            reject(error.message);
        });
    });
};

export const createApplication = (data) => (dispatch) => {

    const formdata = new FormData();
    formdata.append("name",data.name)
    formdata.append("description",data.description)
    formdata.append("dataSource",data.dataSource)
    if(data.icon) {
        formdata.append("icon",data.icon,data.icon.name)
    }
    formdata.append("iconUrl",data.iconUrl)
    formdata.append("timeZone",data.timeZone)

    

    return new Promise((resolve, reject) => {
        fetch(`${baseURL}applications`, {
            method: 'POST',
            body: formdata,
            headers: { 'userID': DUMMY_USER_ID }
        }).then(res => res.json()).then(response => {
            if(response.status === "error"){
                let errorMessage = response.errors.map(err => err.userMessage).join(',');
                
                reject(errorMessage);
            } else {
                dispatch({
                    type: ADD_NEW_APP,
                    data: response.data
                });
                resolve(response.data);
            }
        }).catch(error => {
            
            reject(error.message);
        });
    });
};



export const editApplication = (data) => (dispatch) => {

    const formdata = new FormData();
    formdata.append("name",data.name)
    formdata.append("description",data.description)
    formdata.append("dataSource",data.dataSource)
    if(data.icon) {
        formdata.append("icon",data.icon,data.icon.name)
    }

    formdata.append("iconUrl",data.iconUrl)
    formdata.append("id",data.id)
    formdata.append("timeZone",data.timeZone)

    return new Promise((resolve, reject) => {
        let editApplicationsURL = `${baseURL}applications/${data.id}`;
        fetch(editApplicationsURL, {
            method: 'PUT',
            body: formdata,
            headers: {'userID':DUMMY_USER_ID}
        }).then(res => res.json()).then(response => {
            if(response.message === 'Internal Server Error') {
                reject('Internal Server Error');
            } else if(response.status === "error"){
                let errorMessage = response.errors.map(err => err.userMessage).join(',');
                reject(errorMessage);
            } else {
                if(data.icon === null) {
                    const removeIconURL = `${baseURL}applications/${data.id}/applicationImage`;
                    fetch(removeIconURL, {
                        method: 'DELETE',
                        headers: {'userID':DUMMY_USER_ID},
                    }).then(() => {
                        dispatch({
                            type: EDIT_APP,
                            data: response.data
                        });
                        resolve(response.data);
                    }).catch(() => reject('Could not delete image'))
                } else {
                    dispatch({
                        type: EDIT_APP,
                        data: response.data
                    });
                    resolve(response.data);
                }
                
            }
        }).catch(error => {
            reject(error.message);
        });
    });
}

export const deleteApplication = (applicationId) => (dispatch) => {
    let deleteUrl = `${baseURL}applications/${applicationId}`;
    return new Promise((resolve, reject) => {
        fetch(deleteUrl, {
            method: 'DELETE',
            headers: { 'Content-Type': CONTENT_TYPE, 'userID': DUMMY_USER_ID }
        }).then(response => {
            if(response.status === "error"){
                let errorMessage = response.errors.map(err => err.userMessage).join(',');
                
                reject(errorMessage);
            } else {
                dispatch({
                    type: DELETE_APP,
                    data: applicationId
                });
            }
        }).catch(error => {
            
            reject(error.message);
        });
    });
};

export const testConnection = (url) => () => {
    let getUrl = `${baseURL}applications/connect/test?url=${url}`;
    return new Promise((resolve, reject) => {
        return fetch(getUrl, {
            method: 'GET',
            headers: { 'Content-Type': CONTENT_TYPE, 'userID': DUMMY_USER_ID }
        }).then(res => res.json()).then(response => {
            if(response.status === "error"){
                resolve(false);
            } else {
                resolve(true);
            }
        }).catch(error => {
            
            resolve(false);
        });
    });
}

export const getApplicationMetadata = (applicationId) => (dispatch) => {
    let url = `${baseURL}applications/${applicationId}/widget/metadata`;
    return new Promise((resolve, reject) => {
        return fetch(url, {
            method: 'GET',
            headers: {'Content-Type':CONTENT_TYPE, 'userID':DUMMY_USER_ID}
        }).then(res => res.json()).then( response => {
            if(response.status === "error"){
                let errorMessage = response.errors.map(err => err.userMessage).join(',');
                reject(errorMessage);
            } else {
                dispatch({
                    type: SET_WIDGET_METADATA,
                    data: response.data
                });
                resolve(response.data);
            }
        }).catch(error => {
            
            reject(error.message);
        });
    });
};

export const updateApplicationMetadata = (applicationId) => (dispatch) => {
    let url = `${baseURL}applications/${applicationId}/widget/metadata`;
    return new Promise((resolve, reject) => {
        fetch(url, {
            method: 'PUT',
            headers: {'Content-Type':CONTENT_TYPE, 'userID':DUMMY_USER_ID}
        }).then(res => res.json()).then( response => {
            if(response.status === "error"){
                let errorMessage = response.errors.map(err => err.userMessage).join(',');
                reject(errorMessage);
            } else {
                dispatch({
                    type: SET_WIDGET_METADATA,
                    data: response.data
                });
                resolve(response.data);
            }
        }).catch(error => {
            
            reject(error.message);
        });
    });
}