import React from "react";
import PropTypes from 'prop-types';
import EmptyDashboardComponent from './emptyDashboard';
import DashboardCanvas from './canvas';

const Dashboard = ({dashboard,
  applicationId,
  setDashboard,
  deleteWidget,
  showAddWidgetModal,
  getWidgetConfigData,
  timeZone
}) => {
    let html = !dashboard.widgets || dashboard.widgets.length === 0 ? <EmptyDashboardComponent showAddWidgetModal={showAddWidgetModal}/> :
        <DashboardCanvas
            dashboard={dashboard}
            deleteWidget={deleteWidget}
            setDashboard={setDashboard}
            applicationId={applicationId}
            getWidgetConfigData={getWidgetConfigData}
            timeZone={timeZone}
        />;
    return(
        <div className="row h-100 py-3 align-items-center justify-content-center">
            {html}
        </div>
    );
}

Dashboard.propTypes = {
  applicationId: PropTypes.string,
  timeZone: PropTypes.string,
  dashboard: PropTypes.object,
  setDashboard: PropTypes.func,
  deleteWidget: PropTypes.func,
  showAddWidgetModal: PropTypes.func,
  getWidgetConfigData: PropTypes.func,
}

export default Dashboard;