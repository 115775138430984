import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {areDifferent} from '../../../utils/commonUtils';
import { socketBaseUrl } from '../../../store/constants/urls';
import openSocket from 'socket.io-client';
import Widget from './widget';
import {PROPERTY_BASE_NAME} from './constants';
import {QueryType} from '../../common/constants';
class PreviewWidget extends Component {
    constructor(props){
        super(props);
        this.state = {
            query:{}
        }
        this.socket = openSocket (`${socketBaseUrl}clients/widget_data`,{'path': '/sockets'});
    }

    UNSAFE_componentWillMount = () =>{
        const {getWidgetData} = this.props;
        getWidgetData(this.socket,PROPERTY_BASE_NAME)
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        let {metadata, emitWidgetData, testFlag, streamId, timeZone, deleteWidgetData, widgetData} = nextProps;
        if(metadata.config.query && testFlag && areDifferent(metadata.config.query,this.state.query)){
            let query = JSON.parse(JSON.stringify(metadata.config.query));
            this.setState({
                query:query
            }, () => {
                if((metadata.config.queryType === QueryType.QUERY && metadata.config.query !== "") || 
                (metadata.config.queryType === QueryType.CUSTOMQUERY && Object.keys(metadata.config.query).length > 0)){
                    let obj = {
                        type:metadata.config.queryType,
                        value:metadata.config.query
                    }
                    emitWidgetData(this.socket,obj,streamId,timeZone)
                }
            })
        }
        else if(metadata.config.query && !testFlag && areDifferent(metadata.config.query,this.state.query)){
            this.setState({
                query: {}
            },() => {
                if(widgetData[PROPERTY_BASE_NAME] && widgetData[PROPERTY_BASE_NAME].data){
                    deleteWidgetData(PROPERTY_BASE_NAME)
                }
            })
        }
    }

    render(){
        const {metadata, widgetData, deleteWidgetData, clearWidgetErrorData, testFlag} = this.props;
        if(metadata.config.query && testFlag){
            return(
                <div className="w-100">
                    <Widget
                        metadata={metadata}
                        widgetData={widgetData}
                        propertyName={PROPERTY_BASE_NAME}
                        deleteWidgetData={deleteWidgetData}
                        clearWidgetErrorData={clearWidgetErrorData}
                    />
                </div>
            );
        }
        else{
            return null;
        }
    }

    componentWillUnmount = () => {
        const {clearWidgetErrorData,deleteWidgetData} = this.props;
        deleteWidgetData(PROPERTY_BASE_NAME)
        .then(() => {
            clearWidgetErrorData(PROPERTY_BASE_NAME)
            .then(() => {
                this.socket.disconnect()
            })
        })
    }
}

PreviewWidget.propTypes = {
    testFlag: PropTypes.bool,
    applicationId: PropTypes.string,
    streamId: PropTypes.string,
    timeZone: PropTypes.string,
    metadata: PropTypes.object,
    widgetData: PropTypes.object,
    getWidgetData: PropTypes.func,
    deleteWidgetData: PropTypes.func,
    emitWidgetData: PropTypes.func,
    clearWidgetErrorData: PropTypes.func
}

export default PreviewWidget;