import React, { Component } from 'react';
import { connect } from 'react-redux'
import PropTypes from 'prop-types';
import Widget from './widget';
import {PROPERTY_BASE_NAME} from './constants';
class PreviewWidget extends Component {
    constructor(props){
        super(props);
    }

    render(){
        const {metadata, widgetData, deleteWidgetData, clearWidgetErrorData, testFlag, selectedParameter, name} = this.props;
        if(!metadata.config.parametersName || !metadata.config.selectedParameter) {
            metadata.config.parametersName = name
            metadata.config.selectedParameter = selectedParameter
        }
        if(testFlag){
            return(
                <div className="w-100 h-100">
                    <Widget
                        metadata={metadata}
                        widgetData={widgetData}
                        propertyName={PROPERTY_BASE_NAME}
                        deleteWidgetData={deleteWidgetData}
                        clearWidgetErrorData={clearWidgetErrorData}
                    />
                </div>
            );
        }
        else{
            return null;
        }
    }
}

PreviewWidget.propTypes = {
    testFlag: PropTypes.bool,
    applicationId: PropTypes.string,
    streamId: PropTypes.string,
    timeZone: PropTypes.string,
    metadata: PropTypes.object,
    widgetData: PropTypes.object,
    getWidgetData: PropTypes.func,
    deleteWidgetData: PropTypes.func,
    emitWidgetData: PropTypes.func,
    clearWidgetErrorData: PropTypes.func,
    selectedParameter: PropTypes.string,
    name: PropTypes.string
}

const mapState = state => ({
    selectedParameter: state.dashboard.selectedParameter,
    name: state.dashboard.parametersName
})

export default connect(mapState)(PreviewWidget);