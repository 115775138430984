import { SET_WIDGET_METADATA } from '../constants/actionTypes';
import { setData } from '../../utils/storeUtils';

const metadataReducer = (state = {}, action) => {
    switch (action.type) {
        case SET_WIDGET_METADATA:
            return setData(state, "widgetMetadata", action.data);
        default:
            return state;
    }
};

export default metadataReducer;
