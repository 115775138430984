import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {addEmptyGroupBySelectSectionFunc,updateGroupbyQueryFunc,deleteGroupbyOptionFunc} from './utils'
import {timeOptions} from './constants'
import { FormGroup, Label } from 'reactstrap';
export default class GroupByQuery extends Component {

    addEmptyGroupBySelectSection = () =>{
        const {config,updateGroupByQuery} = this.props;
        updateGroupByQuery({
            key:"groupBy",
            value:addEmptyGroupBySelectSectionFunc(config.groupBy)
        });
    }

    updateGroupbyQuerySelect = (id,event) =>{
        const {config,updateGroupByQuery} = this.props;
        updateGroupByQuery({
            key:"groupBy",
            value:updateGroupbyQueryFunc(id,event,config.groupBy)
        });
    }

    updateGroupbyQueryInput = (id,event,val) =>{
        const {config,updateGroupByQuery} = this.props;
        updateGroupByQuery({
            key:"groupBy",
            value:updateGroupbyQueryFunc(id,event,config.groupBy,val)
        });
    }

    deleteGroupbyOption = (id) =>{
        const {config,updateGroupByQuery} = this.props;
        updateGroupByQuery({
            key:"groupBy",
            value:deleteGroupbyOptionFunc(id,config.groupBy)
        });
    }

    renderRow = (selectedOption,index) =>{
        const{tags} = this.props;
        let tagsOptions = [<option key={0} value="">Select a field</option>];
        tags.forEach(function (sourceOption, i) {
            tagsOptions.push(<option key={i + 1} value={sourceOption.name}>{sourceOption.label}</option>);
        });
        let timeOption = []
        for( let i=0; i < Object.keys(timeOptions).length; i++){
            timeOption.push(<option key={i + 1} value={Object.values(timeOptions)[i]}>{Object.keys(timeOptions)[i]}</option>)
        }
        
        return (
            <FormGroup key={selectedOption.id}>
                <div className="text-center">
                    <Label className="font-weight-normal align-middle"> Field {index + 1}</Label>
                    <a onClick={this.deleteGroupbyOption.bind(null, selectedOption.id)} 
                        className="float-right link d-flex align-items-center align-middle f-12">
                        <i className="icon-delete ml-1"/>
                    </a>
                </div>
                <div>
                    {selectedOption.field === 'time'? 
                        <React.Fragment>
                            <select className="form-control group-input border-bottom-left-radius-0 border-bottom-right-radius-0 custom-select" name="field"
                                defaultValue={selectedOption.field} onChange={(e) => this.updateGroupbyQuerySelect(selectedOption.id, e)} style={{"width":"99.8%"}}>
                                {tagsOptions}
                            </select>
                            <div className="input-group">
                                <div className="input-group-prepend w-50">
                                    <select className="form-control group-input custom-select border-top-left-radius-0" 
                                        name="type" defaultValue={selectedOption.type}
                                        onChange={(e) => this.updateGroupbyQuerySelect(selectedOption.id, e)}>
                                        {timeOption}
                                    </select>
                                </div>
                                <div className="input-group-prepend w-50">
                                    <input placeholder="value" type="number" min="1" step="1" name="value" value={selectedOption.value}
                                        className="form-control border-top-left-radius-0 border-bottom-left-radius-0 border-top-right-radius-0"
                                        onChange={(e) => this.updateGroupbyQueryInput(selectedOption.id,e,parseInt(e.target.value))}
                                    />
                                </div>
                            </div>
                        </React.Fragment> :
                        <select className="form-control group-input border-bottom-left-radius-0 border-bottom-right-radius-0 custom-select" 
                            name="field" defaultValue={selectedOption.field} style={{"width":"99.8%"}}
                            onChange={(e) => this.updateGroupbyQuerySelect(selectedOption.id, e)}>
                            {tagsOptions}
                        </select>
                    }
                </div>
            </FormGroup>
        )
    }

    render(){
        let{config} = this.props;
        let rows = [];
        let selectedSelectOptions = JSON.parse(JSON.stringify(config.groupBy))
        selectedSelectOptions.forEach((obj,index) =>{
            rows.push(this.renderRow(obj,index));
        })
        return(
            <React.Fragment>
                <FormGroup className="mb-0">
                    <Label> Select Group By Fields </Label>
                </FormGroup>
                {rows}
                <div className="py-1 mb-2">
                <a className="link f-14" onClick={this.addEmptyGroupBySelectSection}>
                    <i className="icon-add-quick-selection mr-2"/>
                    Add Group By Query
                </a> 
                </div>
            </React.Fragment>
        )
    }
}

GroupByQuery.propTypes= {
    config: PropTypes.object,
    tags: PropTypes.array,
    updateGroupByQuery: PropTypes.func
};
