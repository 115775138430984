import thumbnail from '../tableWidget/thumbnail';
import configuration from '../tableWidget/addConfiguration';
import editConfiguration from '../tableWidget/editConfiguration';
import widget from '../tableWidget/dashboardWidgetContainer';

const tableWidget = {
    type : "TABLE",
    thumbnail : thumbnail,
    configuration: configuration,
    editConfiguration: editConfiguration,
    widget : widget
};

export default tableWidget;