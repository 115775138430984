import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form, Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Alert from '../common/custom-alert';
import CreateEditDashboardModal from '../common/createEditDashboardModal';
import { areDifferent } from '../../utils/commonUtils';

const errorMessages = {
    MESSAGE_VALID_EXTENSION : "Upload a valid file (Allowed extensions .jpeg/.jpg/.png/.gif only)",
    MESSAGE_VALID_SIZE: "Image size exceeds! (max size limit 500kb)"
};
export default class EditDashboardConfig extends Component {
    constructor() {
        super();
        this.state = {
            id: "",
            name: "",
            description: "",
            layoutid: "",
            icon:null,
            message: "",
            imagePreview: '',
        }
    }

    resetMessage = () => {
        setTimeout(() => {
            this.setState({ message: '' })
        }, 5000)
    }

    removeIcon = () => {
        this.setState({
            icon:null,
            imagePreview: "",
        })
    }

    fieldUpdateHandler = (event) => {
        if(event.target.name === "icon")
        {
            const file = event.target.files[0];
                
            this.setState({
                icon : event.target.files[0],
                imagePreview:  URL.createObjectURL(event.target.files[0]),
            });
        }
        else
             this.setState({[event.target.name] : event.target.value});
    }

    componentDidMount = () => {
        const { dashboard } = this.props;
        this.setState({
            id: dashboard.id,
            layoutid: dashboard.layoutid,
            name : dashboard.name,
            description: dashboard.description,
            icon: dashboard.icon
        });
    }

    UNSAFE_componentWillReceiveProps(newProps) {
        const { dashboard } = newProps;
        if (areDifferent(dashboard, this.props.dashboard)) {
            this.setState({
                id: dashboard.id,
                name : dashboard.name,
                description: dashboard.description,
                icon: dashboard.icon
            });
        }
    }

    submitHandler = (event) => {
        event.preventDefault();
        let {updateDashboard, applicationId, setDashboard, widgets} = this.props;
        let { layoutid } = this.props.dashboard
        let {name, description, id,icon} = this.state;
        let data = {
            id,
            name,
            description,
            layoutid,
            icon,
            widgets,
        };
        var imageValid = this.fileValidation();
        if (imageValid) {
            updateDashboard(data, applicationId)
                .then((response) => {
                    setDashboard(response)
                    this.closeEditDashboardModal();
                })
                .catch((error) => {
                    this.setState({
                        message: error
                    })
                })
        }
    }

    fileValidation = () => {
        if(this.state.icon === null) {
            return true;
        }
        var fileInput = document.getElementById('dashboard-icon');
        if(fileInput.files.length) {
            var fileSize = fileInput.files[0].size;
        }
        var filePath = fileInput.value;
        if(!filePath) {
            return true;
        }
        var allowedExtensions = /(\.jpg|\.jpeg|\.png|\.gif)$/i;
        if(!allowedExtensions.exec(filePath)){
            this.setState({ message: errorMessages.MESSAGE_VALID_EXTENSION })
            fileInput.value = '';
            this.setState({
                icon : null
            });
            return false;
        }
        if(fileSize > 51200) {
            this.setState({ message: errorMessages.MESSAGE_VALID_SIZE })
            fileInput.value = '';
            this.setState({
                icon : null
            });
            return false;
        }
       return true;
    }

    closeEditDashboardModal = () => {
        this.setState({
            name: "",
            description: "",
            icon:null,
            message: ""
        },() => {
            this.props.closeModal();
        })
    }
    handleLayoutChange = (event) => {

    }
    render() {
        let {name, description,icon, message, imagePreview} = this.state;
        let { layoutid, id } = this.props.dashboard
        const { layouts } = this.props
        let disabled = true;
        return(
            <Modal isOpen={true}>
                <ModalHeader toggle={this.closeEditDashboardModal}>Update </ModalHeader>
                <Form onSubmit={this.submitHandler} style={{ padding: "10px" }}>
                    <ModalBody>
                        <Alert color="danger">{message}</Alert>
                        <CreateEditDashboardModal imagePreview={imagePreview} removeIcon={this.removeIcon} dashboardId={id} name={name} description={description} icon={icon} fieldUpdateHandler={this.fieldUpdateHandler} layouts={layouts} handleLayoutChange={this.handleLayoutChange} disabled={disabled} layoutid={layoutid} />
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" type="submit">Update</Button>
                        <Button outline color="secondary" onClick={this.closeEditDashboardModal}>Cancel</Button>
                    </ModalFooter>
                </Form>
            </Modal>
        );
    }
}

EditDashboardConfig.propTypes = {
    applicationId: PropTypes.string,
    dashboard: PropTypes.object,
    closeModal: PropTypes.func,
    updateDashboard: PropTypes.func,
    setDashboard: PropTypes.func,
    layouts: PropTypes.array,
    layoutid: PropTypes.string,
    widgets: PropTypes.array,
}
