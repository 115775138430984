import thumbnail from '../iFrameWidget/thumbnail';
import configuration from '../iFrameWidget/addConfiguration';
import editConfiguration from '../iFrameWidget/editConfiguration';
import widget from '../iFrameWidget/dashboardWidgetContainer';

const iFrameWidget = {
    type : "IFRAME",
    thumbnail : thumbnail,
    configuration: configuration,
    editConfiguration: editConfiguration,
    widget : widget
};

export default iFrameWidget;