import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Widget from './widget';
import {areDifferent, convertRelativeDateToAbsoluteDate} from '../../../utils/commonUtils';
import {PROPERTY_BASE_NAME} from './constants';
import openSocket from 'socket.io-client';
import { socketBaseUrl } from '../../../store/constants/urls';
class DashboardWidget extends Component {
    constructor(props){
        super(props);
        this.state = {
            propertyName:""
        }
        this.socket = openSocket (`${socketBaseUrl}clients/widget_data`,{'path': '/sockets'});
        this.interval = null;
    }

    componentDidMount = () => {
        const {getWidgetData,metadata} = this.props;
        let propertyName = PROPERTY_BASE_NAME + '_' + metadata.id;
        this.setState({
            propertyName: propertyName
        }, () => {
            getWidgetData(this.socket,propertyName)
            this.setupPolling();
        });
    }

    componentDidUpdate(prevProps) {
        let {metadata, widgetData, deleteWidgetData} = prevProps;
        if(areDifferent(widgetData.filters, this.props.widgetData.filters) || areDifferent(metadata.config, this.props.metadata.config)){
            if(widgetData[PROPERTY_BASE_NAME + '_' + metadata.id] && widgetData[PROPERTY_BASE_NAME + '_' + metadata.id].data){
                deleteWidgetData(PROPERTY_BASE_NAME + '_' + metadata.id )
            }
            this.clearPolling();
            this.setupPolling();
        }
    }

    setupPolling = () => {
        let {applicationId, streamId, metadata, widgetData, emitWidgetData, timeZone} = this.props;
        let query = JSON.parse(JSON.stringify(metadata.config.query));
        let obj = {
            type:metadata.config.queryType,
            value:query
        };
        let pollingTime = metadata.config.pollingTime ? metadata.config.pollingTime * 1000 : 1000;
        let filterCopy = Object.assign({}, widgetData.filters);
        if(filterCopy.startDateTime)
            filterCopy.startDateTime = convertRelativeDateToAbsoluteDate(filterCopy.startDateTime,timeZone);
        if(filterCopy.stopDateTime)
            filterCopy.stopDateTime = convertRelativeDateToAbsoluteDate(filterCopy.stopDateTime,timeZone);
        this.clearPolling();
        emitWidgetData(this.socket,obj,streamId,timeZone,filterCopy)
        .then(() => {
            this.interval = setTimeout(() => {
                this.setupPolling();
            }, pollingTime);
        })
    }

    disConnectSocket = () => {
        this.socket.disconnect()
    }

    clearPolling = () => {
        clearTimeout(this.interval);
    }

    clickHandler = (id) => {
        document.getElementById(id+'-csv').click();
    }

    render() {
        const {metadata, widgetData, deleteWidgetData, deleteWidget, getWidgetConfigData, editDeleteFlag} = this.props;
        const {propertyName} = this.state;
        return(
            <React.Fragment>
                <Widget 
                    editDeleteFlag={editDeleteFlag} 
                    downloadClickHandler={this.clickHandler} 
                    getWidgetConfigData={getWidgetConfigData}
                    deleteWidget={deleteWidget}
                    widgetData={widgetData} 
                    metadata={metadata} 
                    propertyName={propertyName}
                    deleteWidgetData={deleteWidgetData}
                />
            </React.Fragment>
        );
    }

    componentWillUnmount = () => {
        const {clearWidgetErrorData,deleteWidgetData,metadata} = this.props;
        deleteWidgetData(PROPERTY_BASE_NAME + '_' + metadata.id)
        .then(() => {
            clearWidgetErrorData(PROPERTY_BASE_NAME)
            .then(() => {
                this.disConnectSocket();
                this.clearPolling();
            })
        })
    }
}

DashboardWidget.propTypes = {
    editDeleteFlag: PropTypes.bool,
    applicationId: PropTypes.string,
    streamId: PropTypes.string,
    timeZone: PropTypes.string,
    metadata: PropTypes.object,
    widgetData: PropTypes.object,
    getWidgetData: PropTypes.func,
    deleteWidget: PropTypes.func,
    getWidgetConfigData: PropTypes.func,
    deleteWidgetData: PropTypes.func,
    emitWidgetData: PropTypes.func,
    clearWidgetErrorData: PropTypes.func
}

export default DashboardWidget;