import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { Modal, Button, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Alert from './custom-alert'

class DeleteModal extends Component {
    constructor(){
        super();
        this.state = {
            message: ""
        }
    }

    closeModal = () => {
        this.setState({
            message: ""
        },() => {
            this.props.closeDeleteModal();
        });
    }

    render() {
        let {name, deleteContent, modal, type} = this.props;
        return (
            <div>
                <Modal style={{width: '32%', minWidth: '380px'}} isOpen={modal} backdrop="static">
                    <ModalHeader toggle={this.closeModal}>Delete {name}</ModalHeader>
                    <ModalBody>
                        <Alert color="danger">{this.state.message}</Alert>
                        <p>Are you sure you want to Delete {name} {type} ?</p>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={deleteContent}>Yes</Button>{' '}
                        <Button color="secondary" outline onClick={this.closeModal}>No</Button>
                    </ModalFooter>
                </Modal>
            </div>
        );
    }
}

DeleteModal.propTypes = {
    name: PropTypes.string,
    modal: PropTypes.bool,
    type: PropTypes.string,
    deleteContent: PropTypes.func,
    closeDeleteModal: PropTypes.func
}

DeleteModal.defaultProps = {
    type: '',
}

export default DeleteModal;
