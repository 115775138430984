import { connect } from 'react-redux'
import DashboardIndex from '../components/dashboard';
import {getApplicationDetail, getApplicationMetadata} from "../store/actions/applicationActions";
import {updateDashboard, deleteDashboard, setDashboard, clearDashboard} from "../store/actions/dashboardActions";
import { addWidget, updateWidget, updateAllWidgets, deleteWidget, 
    addCustomSelectionForTimeRangeWidget } from "../store/actions/widgetActions";

const mapStateToProps = (state, ownProps) => ({
    application: state.application,
    dashboard: state.dashboard,
    applicationId: ownProps.match.params.applicationId,
    dashboardId: ownProps.match.params.dashboardId,
    metadata: state.metadata
});

const mapDispatchToProps = {
    getApplicationDetail: getApplicationDetail,
    setDashboard: setDashboard,
    updateDashboard: updateDashboard,
    deleteDashboard: deleteDashboard,
    clearDashboard: clearDashboard,
    addWidget: addWidget,
    updateWidget: updateWidget,
    updateAllWidgets: updateAllWidgets,
    deleteWidget: deleteWidget,
    getApplicationMetadata: getApplicationMetadata,
    addCustomSelectionForTimeRangeWidget: addCustomSelectionForTimeRangeWidget
}

const DashboardContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(DashboardIndex);

export default DashboardContainer;
