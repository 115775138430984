import React, { Component } from 'react';
import { connect } from 'react-redux';
import { addFilterForData } from '../../../store/actions/widgetActions';
import PropTypes from 'prop-types';
import Widget from './widget';

class PreviewWidget extends Component {

    submitHandler = (filterObj, widgetState) =>{
        this.props.addFilterForData(filterObj, widgetState, this.props.dashboard);
    }

    clearFilters = () => {
        return false;
    }

    render() {
        const {metadata} = this.props;
        return(
            <div className="w-100">
                <Widget metadata={metadata} submitHandler={this.submitHandler} clearFilters={this.clearFilters}/>
            </div>
        );
    }
}
PreviewWidget.propTypes = {
    metadata: PropTypes.object,
    addFilterForData: PropTypes.func,
    clearFilters: PropTypes.func,
    dashboard: PropTypes.object
}

const mapDsipatchtoProps = (dispatch) => {
    return {
        addFilterForData: (filterObj, widgetState, dashboard) => dispatch(addFilterForData(filterObj, widgetState, dashboard))
    }
}

const mapStateToProps = (state) => ({
    dashboard: state.dashboard
})

export default connect(mapStateToProps, mapDsipatchtoProps)(PreviewWidget);