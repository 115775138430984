import React, { Component } from "react";
import PropTypes from 'prop-types';
import DeleteModalPanel from '../common/deleteModal'
import EditDashboard from './editDashboard';
import history from '../../router/history'
import { ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';

export default class ActionPanel extends Component {
    constructor() {
        super();
        this.state = {
            optionsSubMenuOpen: false,
            deleteDashboardOpen: false,
            editDashboardConfigOpen: false
        }
    }

    toggleOptionsSubMenu = () => {
        this.setState({ optionsSubMenuOpen: !this.state.optionsSubMenuOpen });
    }

    showDeleteDashboardModal = () => {
        this.setState({ deleteDashboardOpen: true });
    }

    closeDeleteDashboardModal = () => {
        this.setState({ deleteDashboardOpen: false });
    }

    showEditDashboardConfigModal = () => {
        this.setState({ editDashboardConfigOpen: true });
    }

    closeEditDashboardConfigModal = () => {
        this.setState({ editDashboardConfigOpen: false });
    }

    deleteDashboard = (id) => {
        const { applicationId, deleteDashboard } = this.props;
        deleteDashboard(id, applicationId).then(
            history.push(`/applications/${applicationId}`)
        );
    }

    render() {
        const { saveDashboardChanges, showAddWidgetModal, setDashboard, dashboard,
            updateDashboard, applicationId, layouts } = this.props;
        const { optionsSubMenuOpen, editDashboardConfigOpen, deleteDashboardOpen } = this.state;
        const { showEditDashboardConfigModal, closeEditDashboardConfigModal, showDeleteDashboardModal,
            closeDeleteDashboardModal, deleteDashboard } = this;

        return (
            <aside>
                <nav className="left-nav-wrapper">
                    <ul className="d-flex flex-column flex-grow-1 list-unstyled">
                        <li className="left-nav active" onClick={showAddWidgetModal}>
                            <a className="left-nav-item">
                                <i className="icon-app" />
                                <span className="left-nav-text">Add</span>
                            </a>
                        </li>
                        <li className="left-nav" onClick={saveDashboardChanges}>
                            <a className="left-nav-item">
                                <i className="icon-save-button" />
                                <span className="left-nav-text">Save</span>
                            </a>
                        </li>
                        <ButtonDropdown className="left-nav" isOpen={optionsSubMenuOpen}
                            toggle={this.toggleOptionsSubMenu} size="sm" direction="right">
                            <DropdownToggle className="left-nav-item px-2 btn btn-link " nav>
                                <i className="icon-settings-work-tool" />
                                <span className="left-nav-text">Options</span>
                            </DropdownToggle>
                            <DropdownMenu>
                                <DropdownItem onClick={showEditDashboardConfigModal}>Edit dashboard configuration</DropdownItem>
                                <DropdownItem onClick={showDeleteDashboardModal}>Delete dashboard</DropdownItem>
                            </DropdownMenu>
                        </ButtonDropdown>
                    </ul>
                </nav>
                {editDashboardConfigOpen ?
                    <EditDashboard
                        applicationId={applicationId}
                        dashboard={dashboard}
                        updateDashboard={updateDashboard}
                        closeModal={closeEditDashboardConfigModal}
                        setDashboard={setDashboard}
                        layouts={layouts}
                        widgets={dashboard.widgets}
                    /> : null}
                <DeleteModalPanel
                    name={dashboard.name}
                    type='dashboard'
                    modal={deleteDashboardOpen}
                    closeDeleteModal={closeDeleteDashboardModal}
                    deleteContent={deleteDashboard.bind(this, dashboard.id)}
                />
            </aside>
        );
    }
}

ActionPanel.propTypes = {
    dashboard: PropTypes.object,
    applicationId: PropTypes.string,
    showAddWidgetModal: PropTypes.func,
    saveDashboardChanges: PropTypes.func,
    updateDashboard: PropTypes.func,
    deleteDashboard: PropTypes.func,
    setDashboard: PropTypes.func,
    layouts:PropTypes.array
};
