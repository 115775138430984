export function setData (state, fieldName, data) {
    let newState = JSON.parse(JSON.stringify(state));
    newState[fieldName] = data;
    if(data.metadata) {
        newState['meta'] = data.metadata;
    }
    return newState;
}

export function deleteData (state, fieldName) {
    let newState = JSON.parse(JSON.stringify(state));
    delete newState[fieldName];
    return newState;
}

export function serialize (obj, prefix='&') {
    var str = [],
        p;
    for (p in obj) {
        if (obj.hasOwnProperty(p)) {
        var k = prefix ? prefix + "[" + p + "]" : p,
            v = obj[p];
        str.push((v !== null && typeof v === "object") ?
            serialize(v, k) :
            encodeURIComponent(k) + "=" + encodeURIComponent(v));
        }
    }
    return str.join("&");
}
