import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import CameraFeedIcon from "../../../../img/camera-feed-light.png";
import CameraFeedIconDark from "../../../../img/camera-feed.png";
import { getTheme, fetchAccessToken } from '../../../utils/commonUtils';
import {PROPERTY_BASE_NAME} from './constants';

function post(data) {
    fetchAccessToken()
        .then(token => {
            fetch(link, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': token,
                    'Access-Control-Allow-Origin': '*'
                },
                data
            })
        })
}

class CameraSelectorWidget extends Component {
    state = {
        activeCameraPosition: 0,
    }
    constructor() {
        super();
    }

    eventHandler = (eventObj) => {
        if(eventObj.serviceUrl)
            this.setState({highlightedCameraFeed : eventObj.serviceUrl})
    }

    clickHandler = (cameraFeedObj, cameraPosition) => {
        const { streamId } = cameraFeedObj;
        this.props.clickHandler({[PROPERTY_BASE_NAME] : cameraFeedObj});
        this.changeGrafanaQuery(streamId)
        this.setState({
            activeCameraPosition: cameraPosition,
        })
    }

    changeGrafanaQuery = (streamId) => {
        const { grafanaDashboard } = this.props;
        const { panels } = grafanaDashboard.dashboard;
        const panelTags = panels[0].targets[0].tags[0];
        if (panel) {
            panelTags.value = streamId;
            panelTags.key = 'streamId';
            panelTags.operator= '=';
            const data = {
                ...grafanaDashboard.dashboard,
                panels
            };
            post(data)
        }
    }

    onError = (event) =>{
        event.target.src = getTheme() === 'dark' ? CameraFeedIcon : CameraFeedIconDark;
        event.target.parentElement.classList.add("image-wrapper-class-padding");
    }

    render() {
        const {metadata,widgetData,highlightedCameraFeed} = this.props;
        let {cameraFeeds} = metadata.config;
        let feedUrl = widgetData && widgetData.filters && widgetData.filters[PROPERTY_BASE_NAME] ?
            widgetData.filters[PROPERTY_BASE_NAME] : cameraFeeds[0].serviceUrl;
        let cameraFeedsList = [];
        cameraFeeds.map((cameraFeed, index)=> {
            let classes = index === this.state.activeCameraPosition ? "camera-list two-col playing" : "camera-list two-col";
            cameraFeedsList.push(
                <div className={classes}  key = {index}>
                    <div className="media cursor-pointer" onClick={this.clickHandler.bind(this,cameraFeed, index)} >
                        <div className="image-wrapper mr-3 image-wrapper-class">
                            <img className="img-fluid image-wrapper-class-padding" style={{height:"100%",borderRadius:"4px"}}
                                src={getTheme() === 'dark' ? CameraFeedIcon : CameraFeedIconDark} onError={this.onError} />
                        </div>
                        <div className="media-body">
                            <h5 className="title mt-0">{"Camera: " + (index + 1)}</h5>
                            <div className="description text-wrap" title={cameraFeed.description}>
                                {cameraFeed.description}
                            </div>
                            { highlightedCameraFeed === cameraFeed.serviceUrl &&  highlightedCameraFeed !== feedUrl ? 
                                <a className="float-right text-danger disabled">
                                    <i className="icon-error"></i>
                                </a>
                                : null
                            }
                        </div>
                    </div>
                </div>
            )
        })
        
        return (
            <div className="card card-shadow overflow-hidden h-100 w-100">
                <div className="card-header">
                    <div className="d-flex">
                        <i className="icon-camera-selector align-self-center mr-2"/>
                        {metadata.config && metadata.config.cameraMappedName &&
                            metadata.config.cameraMappedName.length > 0 ? 'Camera Selector :'
                            + metadata.config.cameraMappedName : 'Camera Selector'}
                    </div>
                </div>
                <div className="card-body overflow-auto py-0 my-2">
                    <div className="d-flex flex-wrap">
                        {cameraFeedsList}
                    </div>
                </div>
            </div>
        );
    }
}

CameraSelectorWidget.propTypes = {
    highlightedCameraFeed: PropTypes.string,
    metadata: PropTypes.object,
    widgetData: PropTypes.object,
    clickHandler: PropTypes.func,
    grafanaDashboard: PropTypes.object
}

const mapStateToProps = (state) => ({
    theme: state.ui.theme,
    grafanaDashboard: state.widgeetData.grafanaDashboard
})

export default connect(mapStateToProps)(CameraSelectorWidget);