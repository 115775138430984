import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Widget from './widget';
let url = "";

class PreviewWidget extends Component {
    UNSAFE_componentWillMount = () => {
        const { getCameraFeedUrl, serviceUrl } = this.props;
        getCameraFeedUrl(serviceUrl);
    }

    clickHandler = (cameraFeed) => {
        const { streamId, serviceUrl } = cameraFeed.cameraFeed;
        this.props.setStreamId(streamId)
        this.props.getCameraFeedUrl(serviceUrl);
    }

    render() {
        const {cameraViewMetadata,cameraSelectorMetadata,widgetData,cameraFeedUrl} = this.props;

        if(cameraSelectorMetadata.config.cameraFeeds.length > 1){
            return(
                <div className="container">
                    <div className="row">
                        <div className="col-16">
                            <Widget
                                widgetData={widgetData}
                                clickHandler={this.clickHandler}
                                metadata={cameraViewMetadata}
                                cameraUrl={cameraFeedUrl}
                            />
                        </div>
                        <div className="col-8">
                            <Widget
                                widgetData={widgetData}
                                clickHandler={this.clickHandler}
                                metadata={cameraSelectorMetadata}
                                cameraUrl={cameraFeedUrl}
                            />
                        </div>
                    </div>
                </div>
            );

        }
        else if(cameraSelectorMetadata.config.cameraFeeds.length === 1){
            return(
                <div className="w-100">
                    <Widget
                        widgetData={widgetData}
                        clickHandler={this.clickHandler}
                        metadata={cameraViewMetadata}
                        cameraUrl={cameraFeedUrl}
                    />
                </div>
            )
        }
        else{
            return null;
        }
    }
}

PreviewWidget.propTypes = {
    widgetData: PropTypes.object,
    cameraViewMetadata: PropTypes.object,
    cameraSelectorMetadata: PropTypes.object,
    serviceUrl: PropTypes.string,
    cameraFeedUrl: PropTypes.string,
    getCameraFeedUrl: PropTypes.func,
    setStreamId: PropTypes.func,
    streamId: PropTypes.string,
}

export default PreviewWidget;