import moment from 'moment';

const CURRENT_DATE_TIME = '$current_date_time';
const CURRENT_DATE = '$current_date';
const DATE_TIME_FORMAT = 'YYYY-MM-DD HH:mm:ss';
const DATE_FORMAT = 'YYYY-MM-DD';

const calcSeconds = (currentIntervalText,duration) =>{
    if(duration._data.seconds > 0){
        currentIntervalText = duration._data.seconds + 'seconds ';
    }
    return currentIntervalText
}

const calcMinutes = (currentIntervalText,duration) =>{
    if(duration._data.minutes > 0){
        currentIntervalText = duration._data.minutes + 'minutes ' + calcSeconds(currentIntervalText,duration)
    }
    else{
        currentIntervalText = calcSeconds(currentIntervalText,duration)
    }
    return currentIntervalText
}

const calcHours = (currentIntervalText,duration) =>{
    if(duration._data.hours > 0){
        currentIntervalText = duration._data.hours + 'hours ' + calcMinutes(currentIntervalText,duration)
    }
    else{
        currentIntervalText = calcMinutes(currentIntervalText,duration)
    }
    return currentIntervalText
}

const calcDays = (currentIntervalText,duration) =>{
    if(duration._data.days > 0){
        currentIntervalText = duration._data.days + 'days ' + calcHours(currentIntervalText,duration)
    }
    else{
        currentIntervalText = calcHours(currentIntervalText,duration)
    }
    return currentIntervalText
}


const calcMonths = (currentIntervalText,duration) =>{
    if(duration._data.months > 0){
        currentIntervalText = duration._data.months + 'months ' + calcDays(currentIntervalText,duration)
    }
    else{
        currentIntervalText = calcDays(currentIntervalText,duration)
    }
    return currentIntervalText
}

export const calculateText = (currentIntervalText,duration) =>{
    if(duration._data.years > 0){
        currentIntervalText = duration._data.years + 'years ' + calcMonths(currentIntervalText,duration)
    }
    else{
        currentIntervalText = calcMonths(currentIntervalText,duration)
    }
    return currentIntervalText
}
export const convertDate = (value) =>{
    if(value === CURRENT_DATE_TIME){
        return moment().format(DATE_TIME_FORMAT);
    }
    else if(value === CURRENT_DATE){
        return moment().format(DATE_FORMAT) + ' 00:00:00'
    }
    else if(value.startsWith(`${CURRENT_DATE} - `)){
        if(value.endsWith('h') || value.endsWith('d') || value.endsWith('m') || value.endsWith('s')){
            if(!isNaN(value.substring((`${CURRENT_DATE} - `).length , value.length - 1))){
                if(value.endsWith('h')){
                    return moment(moment().format(DATE_FORMAT) + ' ' + "00:00:00").subtract(parseInt(value.substring((`${CURRENT_DATE} - `).length , value.length - 1)), 'hours')
                }
                else if(value.endsWith('d')){
                    return moment(moment().format(DATE_FORMAT) + ' ' + "00:00:00").subtract(parseInt(value.substring((`${CURRENT_DATE} - `).length , value.length - 1)), 'days')
                }
                else if(value.endsWith('m')){
                    return moment(moment().format(DATE_FORMAT) + ' ' + "00:00:00").subtract(parseInt(value.substring((`${CURRENT_DATE} - `).length , value.length - 1)), 'minutes')
                }
                else{
                    return moment(moment().format(DATE_FORMAT) + ' ' + "00:00:00").subtract(parseInt(value.substring((`${CURRENT_DATE} - `).length , value.length - 1)), 'seconds')
                }
            }
        }
    }
    else if(value.startsWith(`${CURRENT_DATE_TIME} - `)){
        if(value.endsWith('h') || value.endsWith('d') || value.endsWith('m') || value.endsWith('s')){
            if(!isNaN(value.substring((`${CURRENT_DATE_TIME} - `).length , value.length - 1))){
                if(value.endsWith('h')){
                    return moment(moment().format(DATE_TIME_FORMAT)).subtract(parseInt(value.substring((`${CURRENT_DATE_TIME} - `).length , value.length - 1)), 'hours')
                }
                else if(value.endsWith('d')){
                    return moment(moment().format(DATE_TIME_FORMAT)).subtract(parseInt(value.substring((`${CURRENT_DATE_TIME} - `).length , value.length - 1)), 'days')
                }
                else if(value.endsWith('m')){
                    return moment(moment().format(DATE_TIME_FORMAT)).subtract(parseInt(value.substring((`${CURRENT_DATE_TIME} - `).length , value.length - 1)), 'minutes')
                }
                else{
                    return moment(moment().format(DATE_TIME_FORMAT)).subtract(parseInt(value.substring((`${CURRENT_DATE_TIME} - `).length , value.length - 1)), 'seconds')
                }
            }
        }
    }
    else if(value.startsWith(`${CURRENT_DATE} `)){
        if(value.indexOf(':')!== -1){
            let subStr = value.substring(value.length - 8 , value.length)
            if(value.length - (`${CURRENT_DATE} `).length === 8 && 
                ((subStr.split(':').length === 3 && subStr.split(':')[0].length === 2 && subStr.split(':')[1].length === 2 && subStr.split(':')[2].length === 2) 
                    || (subStr.split(':').length === 2 && subStr.split(':')[0].length === 2 && subStr.split(':')[1].length === 2))){
                return moment().format(DATE_FORMAT) + ' ' + subStr
            }
        }
    }
    return '';
} 

export const checkInterval = (value) =>{
    if(value.endsWith('h') || value.endsWith('y') || value.endsWith('mo') || value.endsWith('d') || value.endsWith('m') || value.endsWith('s')){
        if(value.endsWith('mo')){
            if(!isNaN(value.substring(0,value.length - 2))){
                return true
            }
        }
        else{
            if(!isNaN(value.substring(0,value.length - 1))){
                return true
            }
        }
    }
    return false
}

export const workerLogicForInterval = (event,StartStopFlag,startTime,startDate,stopDate,stopTime) => {
    let obj ={}
    obj.result = true;
    if(event.target.value.endsWith('h')){
        obj.value = parseFloat(event.target.value.substring(0,event.target.value.length - 1))
        obj.startStopDate = StartStopFlag?(stopDate && stopTime)
            ?moment(stopDate + ' ' +stopTime).subtract(obj.value,'hours').format(DATE_TIME_FORMAT):''
            :(startDate && startTime)?moment(startDate + ' ' + startTime).add(obj.value,'hours').format(DATE_TIME_FORMAT):'';
        obj.currentIntervalTextData = obj.value + ' hours'
        obj.currentIntervalDurationTypeData = 'hours'
    }
    else if(event.target.value.endsWith('y')){
        obj.value = parseFloat(event.target.value.substring(0,event.target.value.length - 1));
        obj.startStopDate =  StartStopFlag?(stopDate && stopTime)
            ?moment(stopDate + ' ' + stopTime).subtract(obj.value,'years').format(DATE_TIME_FORMAT):''
            :(startDate && startTime)?moment(startDate + ' ' + startTime).add(obj.value,'years').format(DATE_TIME_FORMAT):'';
        obj.currentIntervalTextData = obj.value + ' years'
        obj.currentIntervalDurationTypeData = 'years'
    }
    else if(event.target.value.endsWith('d')){
        obj.value = parseFloat(event.target.value.substring(0,event.target.value.length - 1));
        obj.startStopDate =  StartStopFlag?(stopDate && stopTime)
            ?moment(stopDate + ' ' + stopTime).subtract(obj.value,'days').format(DATE_TIME_FORMAT):''
            :(startDate && startTime)?moment(startDate + ' ' + startTime).add(obj.value,'days').format(DATE_TIME_FORMAT):'';
        obj.currentIntervalTextData = obj.value + ' days'
        obj.currentIntervalDurationTypeData = 'days'
    }
    else if(event.target.value.endsWith('s')){
        obj.value = parseFloat(event.target.value.substring(0,event.target.value.length - 1));
        obj.startStopDate =  StartStopFlag?(stopDate && stopTime)
            ?moment(stopDate + ' ' + stopTime).subtract(obj.value,'seconds').format(DATE_TIME_FORMAT):''
            :(startDate && startTime)?moment(startDate + ' ' + startTime).add(obj.value,'seconds').format(DATE_TIME_FORMAT):'';
        obj.currentIntervalTextData = obj.value + ' seconds'
        obj.currentIntervalDurationTypeData = 'seconds'
    }
    else if(event.target.value.endsWith('mo')){
        obj.value = parseFloat(event.target.value.substring(0,event.target.value.length - 2));
        obj.startStopDate =  StartStopFlag?(stopDate && stopTime)
            ?moment(stopDate + ' ' + stopTime).subtract(obj.value,'months').format(DATE_TIME_FORMAT):''
            :(startDate && startTime)?moment(startDate + ' ' + startTime).add(obj.value,'months').format(DATE_TIME_FORMAT):'';
        obj.currentIntervalTextData = obj.value + ' months'
        obj.currentIntervalDurationTypeData = 'months'
    }
    else if(event.target.value.endsWith('m')){
        obj.value = parseFloat(event.target.value.substring(0,event.target.value.length - 1));
        obj.startStopDate = StartStopFlag?(stopDate && stopTime)
            ?moment(stopDate + ' ' + stopTime).subtract(obj.value,'minutes').format(DATE_TIME_FORMAT):''
            :(startDate && startTime)?moment(startDate + ' ' + startTime).add(obj.value,'minutes').format(DATE_TIME_FORMAT):'';
        obj.currentIntervalTextData = obj.value + ' minutes'
        obj.currentIntervalDurationTypeData = 'minutes'
    }
    else{
        obj.result = false;
    }
    return obj;
}

export const validateData = (startTime,startDate,stopDate,stopTime) =>{
    let resultObj={
        message:[],
        validateFlag:true
    }
    if(!startTime){
        resultObj.message.push("Start Time cannot be empty")
        resultObj.validateFlag = false
    }
    if(!startDate){
        resultObj.message.push("Start Date cannot be empty")
        resultObj.validateFlag = false
    }
    if(!stopDate){
        resultObj.message.push("Stop Date cannot be empty")
        resultObj.validateFlag = false
    }
    if(!stopTime){
        resultObj.message.push("Stop Time cannot be empty")
        resultObj.validateFlag = false
    }

    if(stopDate && stopTime && startTime && startDate 
        && moment(moment(stopDate + ' ' + stopTime)).diff(moment(startDate + ' ' + startTime)) < 0 ){
        resultObj.message.push("Start Date Time cannot be greater than Stop Date Time")
        resultObj.validateFlag = false
    }

    return resultObj;
}

export const getDefinitionQuickSelectedOptions = (allSelectedOptions, selectedOption) =>{
    return allSelectedOptions.find(x => x.name === selectedOption)
}