import React from "react";
import { Form, FormGroup, Label, Input, ModalBody, InputGroup, InputGroupAddon, InputGroupText } from 'reactstrap';
import PropTypes from "prop-types";
import PreviewWidget from './previewWidgetContainer';
import Alert from '../../common/custom-alert';
import QueryBuilder from './queryBuilder';
import {ViewType} from './constants';
import {QueryType} from '../../common/constants';
const ConfigForm = ({
    metadata,
    message,
    fieldUpdateHandler,
    radioButtonChangeHandler,
    widgetCreationMetadata,
    setMessage,
    ownQueryUpdateHandler,
    setTestFlagTrue,
    queryTypeRadioButtonChangeHandler,
    testFlag,
    applicationId,
    dashboardId,
    updateQuery,
    resetQuery,
    timeZone
}) => {
    let {name, description, config} = metadata;
    return(
        <ModalBody className="py-0">
            <div className="row">
                <div className="col-md-10 pl-5 py-2 d-flex flex-column h-100" 
                    style={{maxHeight:"70vh", minHeight:"70vh"}} >
                    <div className="preview-header border-bottom py-3 mr-5 mb-3">
                        <i className="icon-time-range-selector mr-2"/>Counter widget
                    </div>
                    <Alert color="danger">{message}</Alert>
                    <Form className="flex-shrink-1 overflow-auto pr-5">
                        <FormGroup>
                            <Label for="counter-widget-label">Widget Label</Label>
                            <Input maxLength={30} required type="text" name="label" id="counter-widget-label"
                                placeholder="Enter the widget label here" value={name}
                                onChange={fieldUpdateHandler}/>
                        </FormGroup>
                        <FormGroup>
                            <Label for="counter-widget-description">Widget Description</Label>
                            <Input maxLength={150} type="text" name="description" id="counter-widget-description"
                                placeholder="Enter the widget description here" value={description} 
                                onChange={fieldUpdateHandler}/>
                        </FormGroup>
                        <FormGroup>
                            <Label for="">View type</Label>
                            <div className="row">
                                <div className="col">
                                    <div className="custom-control custom-radio custom-control-inline"
                                        onClick={radioButtonChangeHandler.bind(this, "viewType", ViewType.GRIDVIEW)}>
                                        <Input type="radio" name="viewType" className="custom-control-input"
                                            checked={config.viewType === ViewType.GRIDVIEW} readOnly/>
                                        <Label className="custom-control-label">Grid view</Label>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="custom-control custom-radio custom-control-inline"
                                        onClick={radioButtonChangeHandler.bind(this, "viewType", ViewType.LISTVIEW)} >
                                        <Input type="radio" name="viewType" className="custom-control-input"
                                            checked={config.viewType === ViewType.LISTVIEW} readOnly/>
                                        <Label className="custom-control-label">List view</Label>
                                    </div>
                                </div>
                            </div>
                        </FormGroup>
                        <FormGroup>
                            <Label for="">Polling Time</Label>
                            <InputGroup>
                                <Input placeholder="Polling Time" name="pollingTime" type="number"
                                    min="1" step="1" defaultValue={config.pollingTime} onChange={fieldUpdateHandler}
                                />
                                <InputGroupAddon addonType="append">
                                    <InputGroupText className="f-14">
                                        seconds
                                    </InputGroupText>
                                </InputGroupAddon>
                            </InputGroup>
                        </FormGroup>
                        <div className="mb-3 mt-4 border-top"/>
                        <FormGroup>
                            <Label for="">Query Builder</Label>
                            <div className="row">
                                <div className="col">
                                    <div className="custom-control custom-radio custom-control-inline"
                                        onClick={queryTypeRadioButtonChangeHandler.bind(this, "queryType", QueryType.CUSTOMQUERY)} >
                                        <Input type="radio" name="queryType" className="custom-control-input"
                                            checked={config.queryType === QueryType.CUSTOMQUERY} readOnly/>
                                        <Label className="custom-control-label font-weight-normal">
                                            Use query builder
                                        </Label>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="custom-control custom-radio custom-control-inline"
                                        onClick={queryTypeRadioButtonChangeHandler.bind(this, "queryType", QueryType.QUERY)}>
                                        <Input type="radio" name="queryType" className="custom-control-input"
                                            checked={config.queryType === QueryType.QUERY} readOnly/>
                                        <Label className="custom-control-label font-weight-normal">Write query</Label>
                                    </div>
                                </div>
                            </div>
                        </FormGroup>
                        { config.queryType === QueryType.QUERY?
                            <FormGroup>
                                <textarea className="form-control w-100" rows="5" name="ownQuery" value={config.query}
                                    placeholder="Enter Your Query here" onChange={ownQueryUpdateHandler}/>
                            </FormGroup> :
                            <React.Fragment>
                                <QueryBuilder
                                    widgetCreationMetadata={widgetCreationMetadata}
                                    config={config.query}
                                    setMessage={setMessage}
                                    updateQuery= {updateQuery}
                                    resetQuery= {resetQuery}
                                />
                            </React.Fragment>
                        }
                        <button type="button" className="btn btn-primary mt-1" onClick={setTestFlagTrue}>Test</button>
                    </Form>
                </div>
                <div className="col-md-14 preview-bg">
                        <div className="row justify-content-center h-100">
                            <div className="col col-lg-12 col-xl-10 d-flex align-items-center justify-content-center">
                                <PreviewWidget
                                    metadata={metadata}
                                    applicationId={applicationId}
                                    dashboardId={dashboardId}
                                    testFlag={testFlag}
                                    timeZone={timeZone}
                                />
                            </div>
                        </div>
                    </div>
            </div>
        </ModalBody>
    );
};

ConfigForm.propTypes = {
    testFlag: PropTypes.bool,
    message: PropTypes.string,
    timeZone: PropTypes.string,
    applicationId: PropTypes.string,
    dashboardId: PropTypes.string,
    metadata: PropTypes.object,
    widgetCreationMetadata: PropTypes.object,
    fieldUpdateHandler: PropTypes.func,
    radioButtonChangeHandler: PropTypes.func,
    setMessage: PropTypes.func,
    ownQueryUpdateHandler: PropTypes.func,
    setTestFlagTrue: PropTypes.func,
    queryTypeRadioButtonChangeHandler: PropTypes.func,
    updateQuery: PropTypes.func,
    resetQuery: PropTypes.func
}

export default ConfigForm;