import React from 'react'
import PropTypes from 'prop-types';
import EmptyScreenImage from '../../../img/empty-screen-placeholder.svg';

const NoAppComponent = ({showCreateAppModal}) => {
  return(
    <div className="text-center">
      <div className="d-flex flex-column">
        <img src={EmptyScreenImage} alt="Add Application" width=""/>
        <div className="my-5">There are no applications. Click on ‘Add Application’ button to get started.</div>
        <p>
          <button title="Add Application" className="btn-lg btn btn-primary shadow px-4" onClick={showCreateAppModal}>
            <i className="icon-app mr-2"/>
            <span className="f-16 font-weight-normal">Add Application</span>
          </button>
        </p>
      </div>
    </div>
  );
};

NoAppComponent.propTypes = {
  showCreateAppModal : PropTypes.func
};
export default NoAppComponent;