import { connect } from 'react-redux'
import PreviewIndex from '../components/appPreview';
import { getApplicationDetail } from '../store/actions/applicationActions';

const mapStateToProps = (state, ownProps) => ({
    application: state.application,
    applicationId: ownProps.match.params.applicationId
});

const mapDispatchToProps = {
    getApplicationDetail: getApplicationDetail
}

const AppContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(PreviewIndex);

export default AppContainer;
