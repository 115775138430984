import React from 'react';
import { connect } from 'react-redux';
import thumbnailImage from "../../../../img/widget-thumbnails/time-series-graph.svg";
import thumbnailImageDark from "../../../../img/widget-thumbnails/time-series-graph-dark.svg"
import PropTypes from "prop-types";
import { getTheme } from '../../../utils/commonUtils'

const TimeSeriesGraphThumbnail = ({clickHandler}) => {
    return (
        <div className="card cursor-pointer card-shadow">
            <div className="card-body text-center" onClick={clickHandler}>
              <h5 className="card-title">Time-series Graph</h5>
              <div className="img-wrapper f-42"><img src={getTheme() === 'dark' ? thumbnailImage : thumbnailImageDark} alt="Time-series Graph"/></div>
              <p className="card-text">Graphical view of counts, over a selected time-period.</p>
            </div>
        </div>
    )
}

TimeSeriesGraphThumbnail.propTypes = {
    clickHandler: PropTypes.func
}

const mapStateToProps = (state) => ({theme: state.ui.theme})

export default connect(mapStateToProps)(TimeSeriesGraphThumbnail);