import { connect } from 'react-redux'
import IndexComponent from '../components';

const mapStateToProps = (state, ownProps) => ({
    dashboardData: state.dashboardData
});

const mapDispatchToProps = {

}
const IndexContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(IndexComponent);

export default IndexContainer;