import React, { Component } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {ViewType} from './constants';
import Alert from '../../common/custom-alert';
import {v1 as uuidv1} from 'uuid';
import LoadingImage from '../../../../img/loader.gif';
import { setIframeIdAction, setGrafanaDashboardAction } from '../../../store/actions/widgetActions'

const IFrameBody = ({viewType,iFrameUrl}) =>{
    return(
        <div className={viewType === ViewType.GRIDVIEW?"iframe grid-view d-block text-center text-nowrap":"iframe list-view d-flex"}>
            <div className={viewType === ViewType.GRIDVIEW ?"d-inline-block":"d-flex"} key={uuidv1()}>
                <label>
                    Embedded Widget:
                </label>
                {iFrameUrl}
            </div>
        </div>
    );
}

IFrameBody.propTypes ={
    viewType:PropTypes.string,
    iFrameUrl:PropTypes.string
}

class Widget extends Component {
    constructor(props){
        super(props);
        this.state = {
        }
    }

    configureWidget = (timeRangeWidget, url, iframeId) => {
        const state = timeRangeWidget.config.savedState;
        const start = moment(`${state.startDate} ${state.startTime}`).format('x');
        const end = moment(`${state.stopDate} ${state.stopTime}`).format('x');
        url = url.replace(/from=\d+/, `from=${start}`)
        url = url.replace(/to=\d+/, `to=${end}`)
        const id = url.match(/d-solo\/\w{1,9}/) ? url.match(/d-solo\/\w{1,9}/)[0].replace(/d-solo\//, '') : ''
        if(id !== iframeId) {
            this.setIframeId(id)
            this.setGrafanaDashboard(id)
        } 
    }

    getEditDeleteSection = () => {
        const {deleteWidget, getWidgetConfigData, metadata} = this.props;
        return (
            <div className="d-flex flex-column action-drawer pt-1 pb-2">
                <button className="f-22" onClick={getWidgetConfigData.bind(this, metadata.id)}><i className="icon-edit"/></button>
                <button className="f-22" onClick={deleteWidget.bind(this, metadata.id)}><i className="icon-delete"/></button>
            </div>
        );
    }

    setIframeId = (id) => {
        this.props.setIframeId(id)
    }

    setGrafanaDashboard = (id) => {
        this.props.setGrafanaDashboard(id, this.props.serviceUrl)
    }

    getWidget = () => {
        const {widgetData, propertyName, iFrameUrl, widgets, iframeId} = this.props;
        let message = widgetData.errors && widgetData.errors[propertyName] ? widgetData.errors[propertyName] : "";
        if (iFrameUrl) {
            const timeRangeWidget = widgets.find(widget => widget.type === 'TIME_RANGE_SELECTOR');
            if(timeRangeWidget) {
                this.configureWidget(timeRangeWidget, iFrameUrl, iframeId)
            }
            return (
                <div className="card-body  py-0 my-3 d-block align-items-center">
                    <Alert color="danger">{message}</Alert>                    
                    <iframe allow="true" src={iFrameUrl} width="100%" height="100%" frameBorder="0"/>
                </div>
            )
        }
        return(
            <div className="card-body py-0 my-3 d-block align-items-center">
                <div className="d-flex h-100 justify-content-center">No data</div>
            </div>
        );
    }
    render(){
        const {metadata, editDeleteFlag,iFrameUrl} = this.props;
        return(
            <div className='card card-shadow overflow-hidden h-100 w-100' key={metadata.id} >
                <div className="card-header">
                    { editDeleteFlag ? this.getEditDeleteSection() : "" }
                    <div className="d-flex">
                        <i className="icon-counter mr-2"/>{metadata.name ? metadata.name : 'IFrame'}
                    </div>
                </div>
                {   iFrameUrl ?
                    this.getWidget()
                    :
                    <div className="d-flex h-100 justify-content-center">
                        <img className="d-flex align-self-center" src={LoadingImage} />
                    </div>
                }                
                <div className="card-footer">
                    <div>{metadata.description?metadata.description : ' '}</div>
                </div>
            </div>
        );
    }
}

Widget.propTypes = {
    editDeleteFlag: PropTypes.bool,
    propertyName: PropTypes.string,
    metadata: PropTypes.object,
    widgetData: PropTypes.object,
    iFrameUrl: PropTypes.string,
    deleteWidget: PropTypes.func,
    getWidgetConfigData: PropTypes.func,
    deleteWidgetData: PropTypes.func,
    clearWidgetErrorData: PropTypes.func,
    widgets: PropTypes.array,
    setIframeId: PropTypes.func,
    iframeId: PropTypes.string,
    setGrafanaDashboard: PropTypes.func,
    serviceUrl: PropTypes.string
}

Widget.defaultProps = {
    widgets: [],
    serviceUrl: ''
}

const mapStateToProps = state => ({
    widgets: state.dashboard.widgets,
    iframeId: state.widgetData.iframeId,
    serviceUrl: state.application.currentApp.dataSource
})

const mapDispatchToProps = dispatch => ({
    setIframeId: payload => dispatch(setIframeIdAction(payload)),
    setGrafanaDashboard: (id, serviceUrl) => dispatch(setGrafanaDashboardAction(id, serviceUrl)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Widget);