import { connect } from 'react-redux'
import Application from '../components/app';
import { getApplicationDetail, deleteApplication,
    editApplication, updateApplicationMetadata } from '../store/actions/applicationActions';
import { createDashboard, deleteDashboard } from '../store/actions/dashboardActions';

const mapStateToProps = (state, ownProps) => ({
    application: state.application,
    applicationId: ownProps.match.params.applicationId
});

const mapDispatchToProps = {
    getApplicationDetail: getApplicationDetail,
    createDashboard: createDashboard,
    deleteDashboard: deleteDashboard,
    deleteApplication: deleteApplication,
    editApplication: editApplication,
    updateApplicationMetadata:updateApplicationMetadata
}

const AppContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(Application);

export default AppContainer;
