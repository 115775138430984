import React, { Component } from 'react';
import PropTypes from 'prop-types';
import WidgetList from './widgetList';
import WidgetConfiguration from './widgetConfiguration';
import { pages } from './constant';
export default class AddWidgetIndex extends Component {
    constructor() {
        super();
        this.state = {
            fade: true,
            page: pages.WIDGET_SELECTION,
            selectedWidget: ""
        }
    }

    closeModal = () => {
        this.setState({
            fade: true,
            page: pages.WIDGET_SELECTION,
            selectedWidget: ""
        }, () => {
            this.props.closeModal();
        });
    }

    showWidgetSelectionPage = () => {
        this.setState({
            fade: false,
            page: pages.WIDGET_SELECTION,
        });
    }

    thumbnailClickHandler = (type) => {
        
        this.setState({
            selectedWidget: type,
            page: pages.WIDGET_CONFIGURATION
        });
    }

    render() {
        const { page, selectedWidget, fade } = this.state;
        const { modal, dashboard, timeZone, getVerticalAlignmentY, addWidget,
            applicationId, addCustomSelectionForTimeRangeWidget, widgetCreationMetadata } = this.props;
        let screenComponent = <WidgetList
            modal={modal}
            fade={fade}
            dashboard={dashboard}
            closeModal={this.closeModal}
            clickHandler={this.thumbnailClickHandler}
        />;
        switch (page) {
            case pages.WIDGET_SELECTION:
                break;
            case pages.WIDGET_CONFIGURATION:
                screenComponent = <WidgetConfiguration
                    modal={modal}
                    selectedWidget={selectedWidget}
                    widgetCreationMetadata={widgetCreationMetadata}
                    addCustomSelectionForTimeRangeWidget={addCustomSelectionForTimeRangeWidget}
                    closeModal={this.closeModal}
                    showWidgetSelectionPage={this.showWidgetSelectionPage}
                    addWidget={addWidget}
                    applicationId={applicationId}
                    dashboardId={dashboard.id}
                    timeZone={timeZone}
                    getVerticalAlignmentY={getVerticalAlignmentY}
                />;
                break;
            default:
                break;
        }
        return (
            <div>
                {screenComponent}
            </div>
        );
    }
}

AddWidgetIndex.propTypes = {
    modal: PropTypes.bool,
    applicationId: PropTypes.string,
    dashboardId: PropTypes.string,
    timeZone: PropTypes.string,
    dashboard: PropTypes.object,
    widgetCreationMetadata: PropTypes.object,
    getVerticalAlignmentY: PropTypes.func,
    closeModal: PropTypes.func,
    addWidget: PropTypes.func,
    addCustomSelectionForTimeRangeWidget: PropTypes.func
};
