import {
    SET_WIDGET_DATA,
    SET_WIDGET_ERROR_DATA,
    TIME_RANGE_CUSTOM_FILTER_ERROR,
    ADD_WIDGET_DATA_FILTER,
    CLEAR_WIDGET_DATA_FILTER,
    DELETE_WIDGET_DATA,
    ADD_WIDGET,
    UPDATE_WIDGET,
    UPDATE_ALL_WIDGETS,
    DELETE_WIDGET, SET_WIDGET_METADATA,
    CLEAR_WIDGET_ERROR_DATA,
    SET_EVENTS_DATA,
    SET_STREAM_ID,
    SET_TEMP_TIME_RANGE_WIDGET_STATE,
    REMOVE_TEMP_TIME_RANGE_WIDGET_STATE,
    SET_IFRAME_ID,
    SET_GRAFANA_DASHBOARD
} from '../constants/actionTypes'
import { baseURL } from '../constants/urls';
import { getServiceUrl, fetchAccessToken } from '../../utils/commonUtils';

export const emitEvent = (socket,appID) => () => {
    return new Promise((resolve,reject) =>{
        socket.emit('join',{application_id:appID})
        resolve();
    })
}

export const getEventData = (socket, property) => (dispatch) => {
    if(!property) {
        
        return false;
    }
    return new Promise((resolve,reject) =>{
        socket.on('eventData',(response) => {
            if(response.status === "error"){
                let errorMessage = response.errors.map(err => err.userMessage).join(',');
                
                reject(errorMessage);
            } else {
                dispatch({
                    type: SET_EVENTS_DATA,
                    property: property,
                    data: response.data
                });
                resolve(response.data.camera_feed);
            }
        })
    })
}

export const emitWidgetData = (socket, config, streamId, timeZone, filters) => () =>{
    return new Promise((resolve,reject) =>{
        socket.emit('widgetData',{q:config,f:filters,stream_id:streamId,tz:timeZone})
        resolve();
    })
}

export const getWidgetData = (socket, property) => (dispatch) => {
    if(!property) {
        
        return false;
    }
    return new Promise((resolve, reject) => {
        socket.on('widgetDataResponse',(response) => {
            if(response.status === "error"){
                let errorMessage = response.errors.map(err => err.userMessage).join(',');
                
                dispatch({
                    type: DELETE_WIDGET_DATA,
                    property: property
                });
                dispatch({
                    type: SET_WIDGET_ERROR_DATA,
                    property: property,
                    data: errorMessage
                });
            } else {
                dispatch({
                    type: CLEAR_WIDGET_ERROR_DATA,
                    property: property
                });
                dispatch({
                    type: SET_WIDGET_DATA,
                    property: property,
                    data: response
                });
                resolve(response);
            }
        });
    });

};

export const removeTimeRangeInitState = () => dispatch => {
    dispatch({
        type: REMOVE_TEMP_TIME_RANGE_WIDGET_STATE,
    })
}

export const addFilterForData = (filterObj, widgetState, dashboard) => (dispatch) => {
    if(dashboard) {
        const timeRangeWidget = dashboard.widgets.find(widget => widget.type === 'TIME_RANGE_SELECTOR');
        if(!timeRangeWidget){
            dispatch({
                type: SET_TEMP_TIME_RANGE_WIDGET_STATE,
                data: widgetState,
            })
        }
    }
    dispatch({
        type: ADD_WIDGET_DATA_FILTER,
        data: filterObj
    });
};

export const clearFilters = (fieldName) => (dispatch) => {
    return new Promise((resolve, reject) => {
        dispatch({
            type: CLEAR_WIDGET_DATA_FILTER,
            data: fieldName
        });
        resolve();
    });
}

export const clearWidgetErrorData = (property) => (dispatch) => {
    return new Promise((resolve, reject) => {
        dispatch({
            type: CLEAR_WIDGET_ERROR_DATA,
            property: property
        });
        resolve();
    });
}

export const deleteWidgetData = (propertyName) => (dispatch) =>{
    return new Promise((resolve, reject) => {
        dispatch({
            type: DELETE_WIDGET_DATA,
            property: propertyName
        });
        resolve();
    });
}

export const addCustomSelectionForTimeRangeWidget = (customFilterObject, applicationId) => (dispatch) => {
    return new Promise((resolve, reject) => {
        let payload = {
            timeRangeOptions:customFilterObject
        }
        fetch(`${baseURL}applications/${applicationId}/widget/metadata`, {
            method: 'PATCH',
            body: JSON.stringify(payload),
            headers: { 'Content-Type': 'application/json', 'userID': '12345' }
        })
            .then(res => res.json())
            .then(response => {
                if(response.status === "error"){
                    let errorMessage = response.errors.map(err => err.userMessage).join(',');
                
                    reject(errorMessage);
                } else {
                    dispatch({
                        type: SET_WIDGET_METADATA,
                        data: response.data
                    });
                    resolve(response.data);
                }
            })
            .catch(error => {
            
                reject(error.message);
                dispatch({
                    type: TIME_RANGE_CUSTOM_FILTER_ERROR,
                    data: error.message
                });
            });
    });
}

export const addWidget = (widget) => (dispatch) => {
    return new Promise((resolve, reject) => {
        dispatch({
            type: ADD_WIDGET,
            data: widget
        });
        resolve(widget);
    });
}

export const updateWidget = (widget) => (dispatch) => {
    return new Promise((resolve, reject) => {
        dispatch({
            type: UPDATE_WIDGET,
            data: widget
        });
        resolve(widget);
    });
}

export const updateAllWidgets = (widgets) => (dispatch) => {
    return new Promise((resolve, reject) => {
        dispatch({
            type: UPDATE_ALL_WIDGETS,
            data: widgets
        });
        resolve(widgets);
    });
}

export const deleteWidget = (widgetId) => (dispatch) => {
    return new Promise((resolve, reject) => {
        dispatch({
            type: DELETE_WIDGET,
            data: widgetId
        });
        resolve(widgetId)
    });
}

export const setStreamId = (streamId) => (dispatch) => {
    return new Promise((resolve, reject) => {
        dispatch({
            type: SET_STREAM_ID,
            data: streamId
        });
        resolve(streamId)
    })
}

export const setIframeIdAction = id => ({
    type: SET_IFRAME_ID,
    id
})

export const setGrafanaDashboardAction = (id, url) => dispatch => {
    const serviceUrl = getServiceUrl(url)
    const link = `${serviceUrl}/api/dashboards/uid/${id}`;
    fetchAccessToken()
        .then(token => {
            fetch(link, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': token,
                    'Access-Control-Allow-Origin': '*'
                },
            })
                .then(d => d.json())
                .then(d => {dispatch({
                    type: SET_GRAFANA_DASHBOARD,
                    d
                })})
        })
}
