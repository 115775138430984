import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Widget from './widget';
import {PROPERTY_BASE_NAME} from './constants';
class PreviewWidget extends Component {
    constructor(props){
        super(props);
    }

    UNSAFE_componentWillMount = () =>{
        // const {getWidgetData} = this.props;
        // getWidgetData(this.socket,PROPERTY_BASE_NAME)
    }

    UNSAFE_componentWillReceiveProps() {
        // console.log('nextP', nextProps);
        // let {metadata, emitWidgetData, testFlag, streamId, timeZone, deleteWidgetData, widgetData} = nextProps;
        // if(metadata.config.query && testFlag && areDifferent(metadata.config.query,this.state.query)){
        //     let query = JSON.parse(JSON.stringify(metadata.config.query));
        //     this.setState({
        //         query:query
        //     }, () => {
        //         if((metadata.config.queryType === QueryType.QUERY && metadata.config.query !== "") || 
        //         (metadata.config.queryType === QueryType.CUSTOMQUERY && Object.keys(metadata.config.query).length > 0)){
        //             let obj = {
        //                 type:metadata.config.queryType,
        //                 value:metadata.config.query
        //             }
        //             emitWidgetData(this.socket,obj,streamId,timeZone)
        //         }
        //     })
        // }
        // else if(metadata.config.query && !testFlag && areDifferent(metadata.config.query,this.state.query)){
        //     this.setState({
        //         query: {}
        //     },() => {
        //         if(widgetData[PROPERTY_BASE_NAME] && widgetData[PROPERTY_BASE_NAME].data){
        //             deleteWidgetData(PROPERTY_BASE_NAME)
        //         }
        //     })
        // }
    }

    render(){
        const {metadata, widgetData, deleteWidgetData, iFrameUrl, clearWidgetErrorData, testFlag} = this.props;
        if(testFlag){
            return(
                <div className="w-100 h-100">
                    <Widget
                        metadata={metadata}
                        widgetData={widgetData}
                        propertyName={PROPERTY_BASE_NAME}
                        iFrameUrl={iFrameUrl}
                        deleteWidgetData={deleteWidgetData}
                        clearWidgetErrorData={clearWidgetErrorData}
                    />
                </div>
            );
        }
        else{
            return null;
        }
    }
}

PreviewWidget.propTypes = {
    testFlag: PropTypes.bool,
    applicationId: PropTypes.string,
    streamId: PropTypes.string,
    timeZone: PropTypes.string,
    metadata: PropTypes.object,
    widgetData: PropTypes.object,
    getWidgetData: PropTypes.func,
    deleteWidgetData: PropTypes.func,
    emitWidgetData: PropTypes.func,
    clearWidgetErrorData: PropTypes.func,
    iFrameUrl: PropTypes.string
}

export default PreviewWidget;