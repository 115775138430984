import React from "react";
import { Form, FormGroup, Label, Input, ModalBody, InputGroup } from 'reactstrap';
import PropTypes from "prop-types";
import PreviewWidget from './previewWidgetContainer';
import Alert from '../../common/custom-alert';
const ConfigForm = ({
    metadata,
    message,
    fieldUpdateHandler,
    setTestFlagTrue,
    testFlag,
    applicationId,
    dashboardId,
    timeZone,
    changeRuntimeParameter,
    parametersLoading
}) => {
    let {name, description, config} = metadata;
    const { selectedParameter, runtimeParameters } = config
    const buttonDisabled = !runtimeParameters.length || parametersLoading;
    const parametersSelect = runtimeParameters.length ? (
        <Input 
            value={selectedParameter} 
            onChange={changeRuntimeParameter} 
            type="select" name="selectedParameter" 
            id="selectedParameter">
            {runtimeParameters.map(o => <option key={o}>{o}</option>)}
        </Input>) :
        <p>Parameters are missing</p>
    return(
        <ModalBody className="py-0">
            <div className="row">
                <div className="col-md-10 pl-5 py-2 d-flex flex-column h-100" 
                    style={{maxHeight:"70vh", minHeight:"70vh"}} >
                    <div className="preview-header border-bottom py-3 mr-5 mb-3">
                        <i className="icon-time-range-selector mr-2"/>Runtime parameters widget
                    </div>
                    <Alert color="danger">{message}</Alert>
                    <Form className="flex-shrink-1 overflow-auto pr-5">
                        <FormGroup>
                            <Label for="counter-widget-label">Widget Label</Label>
                            <Input maxLength={30} required type="text" name="label" id="counter-widget-label"
                                placeholder="Enter the widget label here" value={name}
                                onChange={fieldUpdateHandler}/>
                        </FormGroup>
                        <FormGroup>
                            <Label for="counter-widget-description">Widget Description</Label>
                            <Input maxLength={150} type="text" name="description" id="counter-widget-description"
                                placeholder="Enter the widget description here" value={description} 
                                onChange={fieldUpdateHandler}/>
                        </FormGroup>
                        <FormGroup>
                            <Label for="selectedParameter">Runtime parameters</Label>
                            <InputGroup>
                                <FormGroup>
                                    <Label for="parameters">Select</Label>
                                    {parametersLoading ? <p>Loading parameters...</p> : parametersSelect}
                                </FormGroup>
                            </InputGroup>
                        </FormGroup>
                        <div className="mb-3 mt-4 border-top"/>
                        <button 
                            disabled={buttonDisabled} 
                            type="button" 
                            className="btn btn-primary mt-1" 
                            onClick={setTestFlagTrue}>
                            Test
                        </button>
                    </Form>
                </div>
                <div className="col-md-14 preview-bg">
                    <div className="row justify-content-center h-100">
                        <div 
                            style={{minWidth: '100%'}} 
                            className="col col-lg-12 col-xl-10 d-flex align-items-center justify-content-center">
                            <PreviewWidget
                                metadata={metadata}
                                applicationId={applicationId}
                                dashboardId={dashboardId}
                                testFlag={testFlag}
                                timeZone={timeZone}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </ModalBody>
    );
};

ConfigForm.propTypes = {
    testFlag: PropTypes.bool,
    message: PropTypes.string,
    timeZone: PropTypes.string,
    applicationId: PropTypes.string,
    dashboardId: PropTypes.string,
    metadata: PropTypes.object,
    widgetCreationMetadata: PropTypes.object,
    fieldUpdateHandler: PropTypes.func,
    radioButtonChangeHandler: PropTypes.func,
    setMessage: PropTypes.func,
    setTestFlagTrue: PropTypes.func,
    changeRuntimeParameter: PropTypes.func,
    parametersLoading: PropTypes.bool
}

export default ConfigForm;