import { connect } from 'react-redux'
import {getWidgetData, emitWidgetData, deleteWidget, 
    clearWidgetErrorData, deleteWidgetData} from '../../../store/actions/widgetActions'
import PreviewWidget from './previewWidget';
const mapStateToProps = (state, ownProps) => ({
    widgetData: state.widgetData,
    metadata: ownProps.metadata,
    applicationId: ownProps.applicationId,
    testFlag: ownProps.testFlag,
    timeZone: ownProps.timeZone
})

const mapDispatchToProps = {
    getWidgetData: getWidgetData,
    emitWidgetData:emitWidgetData,
    deleteWidget: deleteWidget,
    deleteWidgetData: deleteWidgetData,
    clearWidgetErrorData: clearWidgetErrorData
}

const PreviewWidgetContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(PreviewWidget)

export default PreviewWidgetContainer;

