export const defaultCameraHeight = 20,
    defaultCameraWidth = 10,
    defaultX = 0,
    defaultCameraSelectorHeight = 15,
    defaultCameraSelectorWidth = 5,
    PROPERTY_BASE_NAME = "cameraFeed";

export const addCameraSelector = (label, description, selectedCameraFeeds, cameraMappedName, cameraViewWidgetId, defaultHeight, defaultWidth, defaultX, defaultY) => {
    return {
        name : label,
        description: description,
        height: defaultHeight,
        width : defaultWidth,
        xCoordinate: defaultX,
        yCoordinate: defaultY,
        type: "CAMERA",
        config : {
            subType : "CAMERA_SELECTOR",
            cameraFeeds: selectedCameraFeeds,
            cameraMappedName: cameraMappedName,
            dependentWidgetId: [cameraViewWidgetId]
        }
    };
}

export const addCameraView = (label, description, selectedCameraFeeds, defaultHeight, defaultWidth, defaultX, defaultY) => {
    return {
        name : label,
        description: description,
        height: defaultHeight,
        width : defaultWidth,
        xCoordinate: defaultX,
        yCoordinate: defaultY,
        type: "CAMERA",
        config : {
            subType : "CAMERA_VIEW",
            defaultFeed : selectedCameraFeeds.length > 0 ? selectedCameraFeeds[0] : selectedCameraFeeds,
            cameraFeeds: selectedCameraFeeds
        }
    };
}

export const validate = (label, selectedCameraFeeds) => {
    let messages = [];
    if(!label) messages.push('Widget Label is required');
    if(selectedCameraFeeds.length === 0) messages.push('Select atleast one URL');
    return messages
}