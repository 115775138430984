import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalHeader, Button, ModalFooter } from 'reactstrap';
import { ViewType, PROPERTY_BASE_NAME } from './constants';
import ConfigurationForm from "./configurationBody";
import { QueryType } from '../../common/constants';
import { validateQuery, validateForm, generateMetadata } from './utils';
import { checkIfMetadataIsAfterWidgetUpdate } from '../../../utils/commonUtils';
export default class EditCounterConfigurationModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            label: "",
            description: "",
            viewType: ViewType.GRIDVIEW,
            pollingTime: 1,
            queryType: QueryType.CUSTOMQUERY,
            ownQuery: "",
            customQuery: {
                select: [],
                from: "",
                where: null,
                groupBy: []
            },
            message: '',
            testFlag: false
        }
    }

    UNSAFE_componentWillMount() {
        const { widgetConfigDataForEdit, widgetCreationMetadata } = this.props;
        if (checkIfMetadataIsAfterWidgetUpdate(widgetCreationMetadata.updatedAt, widgetConfigDataForEdit.updatedAt)) {
            this.setMessage(["The metadata is changed after last update of the widget. Widget may not behave as desired, please edit."]);
        }
        this.setStateFunc(widgetConfigDataForEdit)
    }

    setStateFunc = (widgetConfigDataForEdit) => {
        if (widgetConfigDataForEdit.config.queryType === QueryType.QUERY) {
            this.setState({
                label: widgetConfigDataForEdit.name,
                description: widgetConfigDataForEdit.description,
                viewType: widgetConfigDataForEdit.config.viewType,
                queryType: widgetConfigDataForEdit.config.queryType,
                ownQuery: widgetConfigDataForEdit.config.query,
                pollingTime: widgetConfigDataForEdit.config.pollingTime
            })
        }
        else {
            let val = JSON.parse(JSON.stringify(widgetConfigDataForEdit.config.query))
            this.setState({
                label: widgetConfigDataForEdit.name,
                description: widgetConfigDataForEdit.description,
                viewType: widgetConfigDataForEdit.config.viewType,
                queryType: widgetConfigDataForEdit.config.queryType,
                customQuery: val,
                pollingTime: widgetConfigDataForEdit.config.pollingTime
            })
        }
    }

    closeModal = () => {
        this.setState({
            label: "",
            description: "",
            viewType: ViewType.GRIDVIEW,
            pollingTime: 1,
            ownQuery: "",
            customQuery: {
                select: [],
                from: "",
                where: null,
                groupBy: []
            },
            queryType: QueryType.CUSTOMQUERY,
            message: '',
            testFlag: false
        }, () => {
            this.props.closeModal();
        });

    }

    fieldUpdateHandler = (event) => {
        this.setState({ [event.target.name]: event.target.value });
    }

    radioButtonChangeHandler = (name, value) => {
        this.setState({ [name]: value });
    }

    queryTypeRadioButtonChangeHandler = (name, value) => {
        this.setState({
            [name]: value,
            testFlag: false
        });
    }

    submitHandler = () => {
        
        let { label, description, viewType, pollingTime, ownQuery, customQuery, queryType } = this.state;
        let messages = validateForm(label, viewType, pollingTime, ownQuery, customQuery, queryType);
        const { widgetConfigDataForEdit } = this.props;
        if (messages.length === 0) {
            let queryData = ""
            if (queryType === QueryType.QUERY) {
                queryData = ownQuery
            }
            else {
                let val = JSON.parse(JSON.stringify(customQuery));
                queryData = val
            }
            let customConfig = {
                pollingTime: parseInt(pollingTime),
                viewType,
                query: queryData,
                queryType
            };
            let widgetMetaData = generateMetadata(widgetConfigDataForEdit.id, label, description,
                widgetConfigDataForEdit.height, widgetConfigDataForEdit.width, widgetConfigDataForEdit.xCoordinate,
                widgetConfigDataForEdit.yCoordinate, widgetConfigDataForEdit.minWidth, widgetConfigDataForEdit.maxWidth, widgetConfigDataForEdit.minHeight, widgetConfigDataForEdit.maxHeight, "COUNTER", customConfig)
            this.props.updateWidget(widgetMetaData).then(() => {
                this.closeModal();
            })
        } else {
            this.setMessage(messages);
        }
    }

    showWidgetSelectionPage = () => {
        this.setState({
            label: "",
            description: "",
            viewType: ViewType.GRIDVIEW,
            pollingTime: 1,
            queryType: QueryType.QUERY,
            ownQuery: "",
            customQuery: {
                select: [],
                from: "",
                where: null
            },
            message: '',
            testFlag: false
        }, () => {
            this.props.showWidgetSelectionPage();
        })
    }

    setMessage = (messages) => {
        this.setState({ message: messages.join(', ') }, () => {
            setTimeout(() => {
                this.setState({ message: '' });
            }, 5000);
        })
    }

    updateQuery = (args) => {
        this.setState({
            customQuery: {
                where: args.key === 'where' ? args.value : this.state.customQuery.where,
                select: args.key === 'select' ? args.value : this.state.customQuery.select,
                from: args.key === 'from' ? args.value : this.state.customQuery.from,
                groupBy: args.key === 'groupBy' ? args.value : this.state.customQuery.groupBy
            },
            testFlag: false
        });
    }

    resetQuery = (where, select, from, groupBy) => {
        this.setState({
            customQuery: {
                where: where,
                select: select,
                from: from,
                groupBy: groupBy
            },
            testFlag: false
        });
    }

    setTestFlagTrue = () => {
        const { queryType, customQuery } = this.state;
        let checkFlag = true;
        if (queryType === QueryType.CUSTOMQUERY) {
            let messages = validateQuery(customQuery, messages);
            if (messages.length > 0) {
                checkFlag = false
                this.setMessage(messages);
            }
        }
        if (checkFlag) {
            this.setState({
                testFlag: true
            })
        }
    }

    ownQueryUpdateHandler = (event) => {
        this.setState({
            [event.target.name]: event.target.value,
            testFlag: false
        });
    }


    render() {
        let { label, description, viewType, pollingTime, queryType, ownQuery, testFlag, customQuery, message } = this.state;
        let { modal, timeZone, applicationId, widgetCreationMetadata } = this.props;
        let metadata = {
            name: label, description, type: "COUNTER", config: {
                pollingTime, viewType, queryType, query: (queryType
                    === QueryType.QUERY) ? ownQuery : customQuery
            }
        };
        return (
            <Modal className="modal-dialog-centered" size="lg" isOpen={modal} backdrop="static">
                <ModalHeader toggle={this.closeModal} charCode="×">
                    <span>Configure widget</span>
                </ModalHeader>
                <ConfigurationForm
                    metadata={metadata}
                    message={message}
                    widgetCreationMetadata={widgetCreationMetadata}
                    timeZone={timeZone}
                    fieldUpdateHandler={this.fieldUpdateHandler}
                    radioButtonChangeHandler={this.radioButtonChangeHandler}
                    setMessage={this.setMessage}
                    queryTypeRadioButtonChangeHandler={this.queryTypeRadioButtonChangeHandler}
                    ownQueryUpdateHandler={this.ownQueryUpdateHandler}
                    setTestFlagTrue={this.setTestFlagTrue}
                    testFlag={testFlag}
                    applicationId={applicationId}
                    updateQuery={this.updateQuery}
                    resetQuery={this.resetQuery}
                />
                <ModalFooter className="align-content-center">
                    <Button color="primary" onClick={this.submitHandler}>Update</Button>
                </ModalFooter>
            </Modal>
        );
    }
}

EditCounterConfigurationModal.propTypes = {
    widgetCreationMetadata: PropTypes.object,
    applicationId: PropTypes.string,
    timeZone: PropTypes.string,
    modal: PropTypes.bool,
    widgetConfigDataForEdit: PropTypes.object,
    showWidgetSelectionPage: PropTypes.func,
    closeModal: PropTypes.func,
    updateWidget: PropTypes.func
};
