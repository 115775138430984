import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Label, Input, FormGroup } from 'reactstrap';
import CustomQueryBuilder from '../../common/whereQueryBuilder';
import SelectQuery from '../../common/selectQuery';
import GroupByQuery from '../../common/groupByQuery';
import OrderBy from '../../common/orderByQuery';
import {CommonOperationsList,IntegerOperationsList} from './constants'
import {performOperationsOnSelectValue,generateMeasurementList,
    generateFieldsAndTagsList,prePopulateStateData,getTagList} from '../../common/utils';
class QueryBuilder extends Component {

    constructor(props){
        super(props);
        this.state = {
            measurementList:[],
            fieldsAndTagList:[],
            fieldsList:[],
            showSelectAndWhere:false,
            limitValue: 100
        }
    }

    UNSAFE_componentWillMount = () =>{
        const {widgetCreationMetadata,config} = this.props;
        if(config.from === ""){
            let measurementList = generateMeasurementList(widgetCreationMetadata)
            this.setState({
                measurementList:measurementList
            })
        }
        else{
            let configData = JSON.parse(JSON.stringify(config));
            if(widgetCreationMetadata.measurements.find(x => x.measurement === configData.from)){
                let obj = prePopulateStateData(configData.from,widgetCreationMetadata);
                this.setState({
                    measurementList:obj.measurementList,
                    fieldsAndTagList:obj.fieldsAndTagList,
                    fieldsList:obj.fieldsList,
                    showSelectAndWhere:true,
                    limitValue:config.limit
                })
            }
            else{
                let measurementList = generateMeasurementList(widgetCreationMetadata)
                this.setState({
                    measurementList:measurementList
                })
            }
        }
    }

    UNSAFE_componentWillReceiveProps = (nextProps) =>{
        const {widgetCreationMetadata,config} = nextProps
        let configData = JSON.parse(JSON.stringify(config));
        if(configData.from !== ""){
            if(widgetCreationMetadata.measurements.find(x => x.measurement === configData.from)){
                let obj = generateFieldsAndTagsList(configData.from,widgetCreationMetadata);
                if(config.from !== this.props.config.from){
                    this.setState({
                        fieldsAndTagList:obj.fieldsAndTagList,
                        fieldsList:obj.fieldsList,
                        showSelectAndWhere:false,
                        limitValue:config.limit
                    },() =>{
                        const {config,resetQuery} = this.props;
                        resetQuery(null,[],[],100,config.from, { 
                            field:"time",
                            order:"DESC"
                        });
                    });
                }
                else{
                    this.setState({
                        fieldsAndTagList:obj.fieldsAndTagList,
                        fieldsList:obj.fieldsList,
                        showSelectAndWhere:true,
                        limitValue:config.limit
                    });
                }
            }
            else{
                this.setState({
                    showSelectAndWhere:false
                })
            }
        }
        else{
            this.setState({
                showSelectAndWhere:false
            })
        }
    }

    onQueryChange = (obj) => {
        const {updateQuery} = this.props;
        updateQuery({
            key: 'where',
            value: obj
        })
    }

    getTagList = () =>{
        const {widgetCreationMetadata,config} = this.props;
        return getTagList(config.from,widgetCreationMetadata,true) 
    }

    updateLimit = (event) => {
        const {updateQuery} = this.props;
        let value = event.target.value
        if(Number.isInteger(parseInt(value))){
            this.setState({
                limitValue: parseInt(value)
            },() => {
                updateQuery({
                    key: 'limit',
                    value: parseInt(value)
                })
            })
        }
    }

    updateSelectQuery = (val) =>{
        const {updateQuery} = this.props;
        const value = performOperationsOnSelectValue(val);
        updateQuery({
            key: 'select',
            value: value
        });
    }

    updateGroupByQuery = (args) =>{
        const {updateQuery} = this.props;
        updateQuery(args);
    }

    updateOrderBy = (obj) => {
        const {updateQuery} = this.props;
        updateQuery({
            key: 'orderBy',
            value: obj
        })
    }

    handleMeasurementChange = (event) => {
        const {updateQuery} = this.props;
        updateQuery({
            key: 'from',
            value: event.target.value
        })
    }

    render(){
        const {setMessage,config} = this.props;
        const { showSelectAndWhere,measurementList,fieldsAndTagList,fieldsList,limitValue } = this.state;
        let options = [<option key={0} value="">Select an option</option>];
        measurementList.forEach(function (sourceOption, i) {
            options.push(<option key={i+1} value={sourceOption}>{sourceOption}</option>);
        });
        return(
            <React.Fragment>
                <FormGroup>
                    <Label for="selectField">Measurement</Label>
                    <Input required type="select" name="selectField" className="custom-select" id="selectField"
                        defaultValue={config.from} onChange={this.handleMeasurementChange}>
                        {options}
                    </Input>
                </FormGroup>
                { showSelectAndWhere?
                    <React.Fragment>
                        <SelectQuery
                            fieldsList={fieldsList}
                            updateSelectQuery={this.updateSelectQuery}
                            config={config}
                            CommonOperationsList={CommonOperationsList}
                            IntegerOperationsList={IntegerOperationsList}
                            tags={this.getTagList()}
                        />
                        <FormGroup>
                            <Label> Filters </Label>
                            <CustomQueryBuilder
                                fields={fieldsAndTagList}
                                query={config.where}
                                onQueryChange={this.onQueryChange}
                                setMessage={setMessage}
                            ></CustomQueryBuilder>
                        </FormGroup>
                        <FormGroup>
                            <GroupByQuery
                                tags={this.getTagList()}
                                config={config}
                                updateGroupByQuery={this.updateGroupByQuery}
                            />
                        </FormGroup>
                        <FormGroup>
                            <OrderBy 
                                config={config}
                                updateOrderBy={this.updateOrderBy}
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label> Maximum rows </Label>
                            <input placeholder="value" type="number" min="1" step="1"
                                className="form-control border-top-left-radius-0 border-bottom-left-radius-0 border-top-right-radius-0"
                                name="value" value={limitValue} onChange={this.updateLimit}
                            />
                            <p className="f-10 mt-1">** In case of GroupBy, it displays maximum number of rows per group.</p>
                        </FormGroup>
                    </React.Fragment>:null
                }
            </React.Fragment>
        )
    }
}

QueryBuilder.propTypes = {
    widgetCreationMetadata: PropTypes.object,
    config: PropTypes.object,
    setMessage: PropTypes.func,
    updateQuery: PropTypes.func,
    resetQuery: PropTypes.func
}


export default QueryBuilder;