import { v1 as uuidv1 } from 'uuid'

const getGroupbyData = (id,field) =>{
    let obj ={
        id: id,
        field: field
    };
    return obj;
}

const addTimeToTagList = (tagsList) =>{
    tagsList.push({
        name:'time',
        label:'Time'
    })
    return tagsList;
}

export const convertOptions = (options,selectedOption) =>{
    let result = []
    for( let i=0; i < options.length; i++ ){
        let obj = {
            id: uuidv1(),
            label:options[i],
            value:false
        }
        if(selectedOption.findIndex( x => x.label === options[i]) !== -1){
            obj.value = true
        }
        result.push(obj)
    }
    return result;
}

export const populateOptions = (value,options,operations) =>{
    let option = JSON.parse(JSON.stringify(options));
    let optionField = option.find(x=> x.label === value);
    let operationsList = JSON.parse(JSON.stringify(operations));
    if(optionField.value){
        operationsList.push(optionField);
    }
    else{
        let index = operationsList.findIndex(x => x.label === value);
        operationsList.splice(index,1);
    }
    return operationsList;
}

export const addEmptyGroupBySelectSectionFunc = (groupBy) =>{
    let selectedGroupbyOptions = JSON.parse(JSON.stringify(groupBy));
    selectedGroupbyOptions.push(getGroupbyData(uuidv1(),''))
    return selectedGroupbyOptions;
}

export const updateGroupbyQueryFunc = (id,event,groupBy,value) =>{
    let selectedGroupbyOptions = JSON.parse(JSON.stringify(groupBy));
    let index = selectedGroupbyOptions.findIndex((o) => {return o.id === id});
    if(value){
        selectedGroupbyOptions[index][event.target.name] = value;
    }
    else{
        selectedGroupbyOptions[index][event.target.name] = event.target.value;
    }
    return selectedGroupbyOptions;
}

export const deleteGroupbyOptionFunc = (id,groupBy) =>{ 
    let selectedGroupbyOptions = JSON.parse(JSON.stringify(groupBy));
    let updatedSelectedGroupbyOptions = selectedGroupbyOptions.filter((o) => {return o.id !== id});
    return updatedSelectedGroupbyOptions;
}

export const generateFieldsAndTagsList = (value,widgetCreationMetadata) =>{
    let resultData = {}
    if(value !== ""){
        let obj = widgetCreationMetadata.measurements.find(x=> x.measurement === value);
        let fieldsList = JSON.parse(JSON.stringify(obj.fields));
        let fieldsAndTagList = JSON.parse(JSON.stringify(obj.fields));
        for( let i=0; i < obj.tags.length; i++ ){
            fieldsAndTagList.splice(0,0,obj.tags[i]);
        }
        resultData.fieldsAndTagList = fieldsAndTagList;
        resultData.fieldsList = fieldsList;
    }
    return resultData
}

export const generateMeasurementList = (widgetCreationMetadata) =>{
    let measurementList = []
    for( let i=0; i < widgetCreationMetadata.measurements.length; i++){
        measurementList.push(widgetCreationMetadata.measurements[i].measurement);
    }
    return measurementList;
}

export const prePopulateStateData = (value, widgetCreationMetadata) =>{
    let obj = generateFieldsAndTagsList(value,widgetCreationMetadata)
    obj.measurementList = generateMeasurementList(widgetCreationMetadata)
    return obj;
}

export const getTagList = (value,widgetCreationMetadata,addTimeFlag) =>{
    if(value !== ""){
        let obj = widgetCreationMetadata.measurements.find(x=> x.measurement === value);
        let tagsList = JSON.parse(JSON.stringify(obj.tags));
        if(addTimeFlag){
            addTimeToTagList(tagsList);
        }
        return tagsList;
    }
    else{
        return [];
    }
}

export const getSelectData = (id, selectField,selectAlias,operations) =>{
    let obj ={
        id: id,
        field: selectField,
        alias: selectAlias,
        operations: operations
    };
    return obj;
}

export const convertOperationsFormatFunc = (options) =>{
    let result = []
    for( let i=0; i < options.length; i++ ){
        let obj = {
            id: uuidv1(),
            label:options[i],
            value:true
        }
        result.push(obj)
    }
    return result;
}

export const performOperationsOnSelectValue = (val) =>{
    let value = JSON.parse(JSON.stringify(val))
    for(let i=0; i < value.length; i++){
        let operationsList = value[i].operations;
        let operations = []
        for(let j=0; j < operationsList.length; j++){
            if(operationsList[j].label){
                operations.push(operationsList[j].label);
            }
            else{
                operations.push(operationsList[j]);
            }
        }
        value[i].operations = operations;
    }
    return value;
}

export const checkForDiscrepanciesInField = (selectedSelectOptions,fieldsList) => {
    let flag = false;
    for(let i=0; i < selectedSelectOptions.length; i++){
        if(!fieldsList.find(x=> x.name === selectedSelectOptions[i].field)){
            flag = true;
            break;
        }
    }
    return flag;
}