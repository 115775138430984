import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalHeader, Button, ModalFooter } from 'reactstrap';
import {v1 as uuidv1} from 'uuid';
import {ViewType, defaultHeight, defaultWidth, defaultX, minWidth,maxWidth,minHeight,maxHeight} from './constants';
import ConfigurationForm from "./configurationBody";
import {validateForm,generateMetadata} from './utils';

export default class IFrameConfigurationModal extends Component{
    constructor(props){
        super(props);
        this.state = {
            label : "",
            description : "",
            viewType : ViewType.GRIDVIEW,
            iFrameUrl: ''
        }
    }

    closeModal = () => {
        this.setState({
            label : "",
            description : "",
            viewType : ViewType.GRIDVIEW,
            iFrameUrl: ''
        }, () => {
            this.props.closeModal();
        });
        
    }

    fieldUpdateHandler = (event) => {
        this.setState({[event.target.name] : event.target.value});
    }

    radioButtonChangeHandler = (name, value) => {
        this.setState({[name] : value});
    }

    RadioButtonChangeHandler = (name, value) => {
        this.setState({
            [name] : value,
            testFlag:false
        });
    }

    submitHandler = () => {
        const {getVerticalAlignmentY} = this.props;
        let {label, description, viewType, iFrameUrl} = this.state;
        let messages = validateForm(label, iFrameUrl);
        if(messages.length === 0) {
            let customConfig = {
                iFrameUrl,
                viewType
            };

            let widgetMetaData = generateMetadata(uuidv1(),label,description,defaultHeight,
                defaultWidth,defaultX,getVerticalAlignmentY(defaultWidth),minWidth,maxWidth,minHeight,maxHeight,"IFRAME",customConfig)
            this.props.addWidget(widgetMetaData).then(() => {
                this.closeModal();
            });
        } else {
            this.setMessage(messages);
        }
    }

    showWidgetSelectionPage = () =>{
        this.setState({
            label : "",
            description : "",
            viewType : ViewType.GRIDVIEW,
            iFrameUrl : '',
            message:'',
            testFlag:false
        },() => {
            this.props.showWidgetSelectionPage();
        })
    }

    setMessage = (messages) =>{
        this.setState({ message :  messages.join(', ') }, () => {
            setTimeout(() => {
                this.setState({ message : '' });
            }, 5000);
        })
    }

    setTestFlagTrue = () =>{
        this.setState({
            testFlag:true
        })
    }

    UpdateHandler = (event) =>{
        this.setState({
            [event.target.name] : event.target.value,
            testFlag:false
        });
    }

    render(){
        let {label, description, viewType, iFrameUrl, testFlag, message} = this.state;
        let {modal, applicationId, dashboardId, timeZone, widgetCreationMetadata} = this.props;
        let metadata = { name:label, description, type:"IFRAME", config: {
            iFrameUrl, viewType
        }};
        return(
            <Modal className="modal-dialog-centered" size="lg" isOpen={modal} backdrop="static" fade={false}>
                <ModalHeader toggle={this.closeModal} charCode= "×">
                    <span>Select Widget</span>
                    <span>Configure widget</span>
                </ModalHeader>
                <ConfigurationForm
                    metadata={metadata}
                    message={message}
                    applicationId={applicationId}
                    dashboardId={dashboardId}
                    widgetCreationMetadata={widgetCreationMetadata}
                    fieldUpdateHandler={this.fieldUpdateHandler}
                    timeZone={timeZone}
                    iFrameUrl={iFrameUrl}
                    radioButtonChangeHandler={this.radioButtonChangeHandler}
                    setMessage={this.setMessage}
                    RadioButtonChangeHandler={this.RadioButtonChangeHandler}
                    UpdateHandler={this.UpdateHandler}
                    setTestFlagTrue = {this.setTestFlagTrue}
                    testFlag= {testFlag}
                />
                <ModalFooter className="align-content-center">
                    <Button color="primary" onClick={this.submitHandler}>Add</Button>
                    <Button outline color="secondary" onClick={this.showWidgetSelectionPage}>Previous</Button>
                </ModalFooter>
            </Modal>
        );
    }
}

IFrameConfigurationModal.propTypes = {
    modal: PropTypes.bool,
    applicationId: PropTypes.string,
    dashboardId: PropTypes.string,
    timeZone: PropTypes.string,
    iFrameUrl: PropTypes.string,
    widgetCreationMetadata: PropTypes.object,
    showWidgetSelectionPage: PropTypes.func,
    getVerticalAlignmentY: PropTypes.func,
    closeModal: PropTypes.func,
    addWidget: PropTypes.func
};