import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Widget from './widget';
import {PROPERTY_BASE_NAME} from './constants';
import openSocket from 'socket.io-client';
import { cameraFeedBaseUrl } from '../../../store/constants/urls';

let url = ""
let highlightedCameraFeed = ""

class DashboardWidget extends Component {
    constructor(props){
        super(props);
        this.socket = openSocket(`${cameraFeedBaseUrl}clients/widget_event`,{'path': '/sockets'});
    }

    UNSAFE_componentWillMount = () =>{
        const {metadata, addFilterForData, applicationId, getEventData, emitEvent, getCameraFeedUrl, setStreamId} = this.props;

        const { serviceUrl, streamId } = metadata.config.defaultFeed ? metadata.config.defaultFeed : metadata.config.cameraFeeds[0];
        setStreamId(streamId);
        getCameraFeedUrl(serviceUrl);
        if(metadata.config.subType === "CAMERA_VIEW"){
            url = metadata.config.defaultFeed.serviceUrl
            addFilterForData({streamId})
        }
        else{
            emitEvent(this.socket, applicationId)
            getEventData(this.socket,"cameraSelectorEvents")
            .catch((error) => {
                this.setState({
                    message : error
                }, () => {
                    setTimeout(() => {
                        this.setState({ message : '' });
                    }, 5000);
                });
            });
        }
    }

    UNSAFE_componentWillReceiveProps = (nextProps) =>{
        const {widgetData} = nextProps;
        if(widgetData && widgetData.cameraSelectorEvents && widgetData.cameraSelectorEvents.camera_feed !== highlightedCameraFeed){
            highlightedCameraFeed = widgetData.cameraSelectorEvents.camera_feed
        }
    }

    clickHandler = (cameraFeed) => {
        const { serviceUrl, streamId } = cameraFeed.cameraFeed;

        highlightedCameraFeed = "";
        this.props.setStreamId(streamId);
        this.props.getCameraFeedUrl(serviceUrl);
        // this.props.addFilterForData(cameraFeed);
    }

    disConnectSocket = () => {
        this.socket.disconnect()
    }

    render() {
        const {metadata, widgetData, addFilterForData, clearFilters, editDeleteFlag, deleteWidget, getWidgetConfigData, cameraFeedUrl} = this.props;
        return(
            <React.Fragment>
                <Widget
                    widgetData={widgetData}
                    clickHandler={this.clickHandler} 
                    metadata={metadata} 
                    editDeleteFlag={editDeleteFlag}
                    getWidgetConfigData={getWidgetConfigData}
                    deleteWidget={deleteWidget}
                    addFilterForData={addFilterForData}
                    cameraUrl={cameraFeedUrl}
                    clearFilters={clearFilters}
                    highlightedCameraFeed={highlightedCameraFeed}
                />
            </React.Fragment>
        );
    }

    componentWillUnmount() {
        const {clearFilters} = this.props;
        clearFilters(PROPERTY_BASE_NAME);
        this.disConnectSocket();
    }
}

DashboardWidget.propTypes = {
    editDeleteFlag: PropTypes.bool,
    applicationId: PropTypes.string,
    streamId: PropTypes.string,
    metadata: PropTypes.object,
    widgetData: PropTypes.object,
    addFilterForData: PropTypes.func,
    getWidgetConfigData: PropTypes.func,
    deleteWidget: PropTypes.func,
    clearFilters: PropTypes.func,
    getEventData: PropTypes.func,
    emitEvent: PropTypes.func,
    getCameraFeedUrl: PropTypes.func,
    setStreamId: PropTypes.func,
    cameraFeedUrl: PropTypes.string,
}

export default DashboardWidget;