import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import ReactTable from "react-table";
import EmptyScreenImage from '../../../img/empty-screen-placeholder.svg';

function sort( a, b) {
    a = new Date(a.props.title)
    b = new Date(b.props.title)
    if(a > b) return 1;
    if(a < b) return -1;
    return 0;
}

class DashboardsLists extends Component {
    render() {
        const { currentApp , showCreateDashboardModal } = this.props;
        if(currentApp && currentApp.dashboards && currentApp.dashboards.length > 0) {
            let currentAppId = currentApp.id;
            let columns = [
                {
                    Header:"Dashboard name",
                    accessor: p => <p><Link to={'/applications/'+currentAppId+'/dashboards/'+p.id}>{p.name}</Link></p>,
                    id:'name',
                    headerClassName: 'hdrClsLeft'
                },
                {
                    Header:"Dashboard description",
                    accessor: p => <p>{p.description}</p>,
                    id:'description',
                    headerClassName: 'hdrCls'
                },
                {
                    Header:"Created on",
                    accessor: p => <p title={p.createdAt}>{p.createdAt}</p>,
                    id:'createdAt',
                    sortMethod: (a, b) => {
                        sort(a, b)
                    },
                    headerClassName: 'hdrCls'
                },
                {
                    Header:"Last modified on",
                    accessor: p => <p title={p.updatedAt}>{p.updatedAt}</p>,
                    id:'updatedAt',
                    sortMethod: (a, b) => {
                        sort(a, b)
                    },
                    headerClassName: 'hdrClsRight'
                }
            ]
            return (
                <div className="application-list my-3">
                    <ReactTable
                        data={currentApp.dashboards}
                        columns={columns}
                        defaultPageSize={5}
                        defaultSorted={[
                            {
                                id: "createdAt",
                                desc:true
                            }
                        ]}
                        className="-striped -highlight"
                    />
                </div>
            );
        } else {
            return (
                <div className="row py-3 align-items-center justify-content-center flex-grow-1 flex-shrink-0">
                    <div className="text-center">
                        <div className="d-flex flex-column">
                            <img src={EmptyScreenImage} alt="Add Dashboard" width=""/>
                            <div className="my-5">
                                There are no dashboards.
                                Click on ‘Add Dashboard‘ button to get started.
                            </div>
                            <p>
                                <button onClick={showCreateDashboardModal}
                                    title="Add Widget" className="btn-lg btn btn-primary shadow px-4">
                                    <i className="icon-app mr-2"/>
                                    <span className="f-16 font-weight-normal">Add Dashboard</span>
                                </button>
                            </p>
                        </div>
                    </div>
                </div>
            )
        }
    }
}

DashboardsLists.propTypes = {
    currentApp: PropTypes.object,
    showCreateDashboardModal: PropTypes.func
}

export default DashboardsLists;
