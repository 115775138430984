import React, {Component} from "react";
import { connect } from 'react-redux';
import { isEqual } from 'lodash';
import PropTypes from 'prop-types';
import moment from 'moment';
import {InputGroup,InputGroupAddon,Input,InputGroupText,Popover,PopoverBody} from 'reactstrap';
import {calculateText,convertDate,checkInterval,workerLogicForInterval,
    validateData,getDefinitionQuickSelectedOptions} from './utils';
import CustomAlert from '../../common/custom-alert';
import {ViewType} from './constants';
import { addTimeRangeStateToDashboard } from "../../../store/actions/dashboardActions";
import { parseTimeRangeState } from "../../../utils/commonUtils";

const DATE_TIME_FORMAT = 'YYYY-MM-DD HH:mm:ss';

class Widget extends Component{
    constructor(props){
        super(props);
        this.state = {
            showInterval:false,
            stopDate:'',
            stopTime:'',
            startDate:'',
            startTime:'',
            currentIntervalText:'',
            selectedQuickSelectionName:'',
            currentIntervalDuration:0,
            currentIntervalDurationType:'',
            toggleStartInterval:false,
            toggleStopInterval:false,
            popoverOpen: false,
            showFilterIndication: false,
            filterText: "",
            message:""
        }
    }

    componentDidUpdate(){
        const { dashboard } = this.props;
        const widget = dashboard && dashboard.widgets && dashboard.widgets.find(widget => widget.type === 'TIME_RANGE_SELECTOR');
        if(widget && widget.config.savedState && this.state.filterText !== widget.config.savedState.filterText) {
            this.setState(parseTimeRangeState(widget.config.savedState));
        }
    }

    getPreviousDateTime = () =>{
        this.setState({
            startDate: moment(moment(this.state.startDate + ' ' + this.state.startTime)
                .subtract(this.state.currentIntervalDuration, this.state.currentIntervalDurationType)._d)
                .format(DATE_TIME_FORMAT)
                .split(' ')[0],
            startTime: moment(moment(this.state.startDate + ' ' + this.state.startTime)
                .subtract(this.state.currentIntervalDuration, this.state.currentIntervalDurationType)._d)
                .format(DATE_TIME_FORMAT)
                .split(' ')[1],
            stopDate : moment(moment(this.state.stopDate + ' ' + this.state.stopTime)
                .subtract(this.state.currentIntervalDuration, this.state.currentIntervalDurationType)._d)
                .format(DATE_TIME_FORMAT)
                .split(' ')[0],
            stopTime : moment(moment(this.state.stopDate + ' ' + this.state.stopTime)
                .subtract(this.state.currentIntervalDuration, this.state.currentIntervalDurationType)._d)
                .format(DATE_TIME_FORMAT)
                .split(' ')[1],
            selectedQuickSelectionName:''
        })
    }

    getNextDateTime = () =>{
        this.setState({
            startDate: moment(moment(this.state.startDate + ' ' + this.state.startTime)
                .add(this.state.currentIntervalDuration, this.state.currentIntervalDurationType)._d)
                .format(DATE_TIME_FORMAT)
                .split(' ')[0],
            startTime: moment(moment(this.state.startDate + ' ' + this.state.startTime)
                .add(this.state.currentIntervalDuration, this.state.currentIntervalDurationType)._d)
                .format(DATE_TIME_FORMAT)
                .split(' ')[1],
            stopDate : moment(moment(this.state.stopDate + ' ' + this.state.stopTime)
                .add(this.state.currentIntervalDuration, this.state.currentIntervalDurationType)._d)
                .format(DATE_TIME_FORMAT)
                .split(' ')[0],
            stopTime : moment(moment(this.state.stopDate + ' ' + this.state.stopTime)
                .add(this.state.currentIntervalDuration, this.state.currentIntervalDurationType)._d)
                .format(DATE_TIME_FORMAT)
                .split(' ')[1],
            selectedQuickSelectionName:''
        })
    }

    UNSAFE_componentWillReceiveProps = (nextProps) => {
        if(!isEqual(nextProps.widgetState, this.props.widgetState)) {
            this.setState({
                ...nextProps.widgetState,
                popoverOpen: false,
            })
        }
        this.setState({
            popoverOpen: false,
        })
    }

    toggle = () =>{
        this.setState({
            popoverOpen: !this.state.popoverOpen
        });
    }

    resetFilter = () => {
        const {clearFilters} = this.props;
        this.setState({
            startDate: '',
            startTime: '',
            stopDate : '',
            stopTime : '',
            selectedQuickSelectionName:'',
            currentIntervalDuration:0,
            currentIntervalDurationType:'',
            currentIntervalText:'',
            showFilterIndication: false,
            filterText: ""
        }, () => {
            clearFilters();
        })
    }

    submitHandler = (e) =>{
        const {metadata,submitHandler} = this.props;
        const {startTime,startDate,stopDate,stopTime,selectedQuickSelectionName} = this.state;
        let filterObj = {}
        if( selectedQuickSelectionName !== ''){
            let obj = getDefinitionQuickSelectedOptions(metadata.config.selectedTimeRangeOptions,selectedQuickSelectionName)
            filterObj={
                startDateTime:obj.start,
                stopDateTime:obj.stop
            }
            this.setState({
                showFilterIndication: true,
                filterText: obj.name
            }, () => {
                submitHandler(filterObj, this.state);
                this.props.addStateToDashboard(this.state);
            })
        }
        else{
            let result = validateData(startTime,startDate,stopDate,stopTime)
            if(result.validateFlag){
                filterObj = {
                    startDateTime: moment(startDate + ' ' + startTime).format(DATE_TIME_FORMAT),
                    stopDateTime: moment(stopDate + ' ' + stopTime).format(DATE_TIME_FORMAT)
                }
                this.setState({
                    showFilterIndication: true,
                    filterText: startDate + ' ' + startTime + ' to ' + stopDate + ' ' + stopTime
                }, () => {
                    submitHandler(filterObj, this.state);
                    this.props.addStateToDashboard(this.state);
                })
            }
            else{
                this.setState({
                    message:result.message.join(", ")
                },()=>{
                    setTimeout(() => {
                        this.setState({message:''})
                    }, 5000)
                })
            }
        }
        e.preventDefault();
        e.stopPropagation();
    }

    onClick = (e) =>{
        e.preventDefault();
        e.stopPropagation();
        let input = e.target;
        input.blur();
        input.focus();
    }

    setDiffTime = () =>{
        const {startTime,startDate,stopDate,stopTime,toggleStartInterval,toggleStopInterval}= this.state; 
        if(toggleStartInterval && stopDate && stopTime){
            this.setState({
                showInterval:true,
                startDate: moment(moment(stopDate + ' ' + stopTime)
                    .subtract(this.state.currentIntervalDuration, this.state.currentIntervalDurationType)._d)
                    .format(DATE_TIME_FORMAT)
                    .split(' ')[0],
                startTime: moment(moment(stopDate + ' ' + stopTime)
                    .subtract(this.state.currentIntervalDuration, this.state.currentIntervalDurationType)._d)
                    .format(DATE_TIME_FORMAT)
                    .split(' ')[1]
            })
        }
        else if(toggleStopInterval && startTime && startDate){
            if(moment().diff(moment(startDate + ' ' + startTime).add(this.state.currentIntervalDuration, this.state.currentIntervalDurationType)) > 0){
                this.setState({
                    showInterval:true,
                    stopDate : moment(moment(startDate + ' ' + startTime)
                        .add(this.state.currentIntervalDuration, this.state.currentIntervalDurationType)._d)
                        .format(DATE_TIME_FORMAT)
                        .split(' ')[0],
                    stopTime : moment(moment(startDate + ' ' + startTime)
                        .add(this.state.currentIntervalDuration, this.state.currentIntervalDurationType)._d)
                        .format(DATE_TIME_FORMAT)
                        .split(' ')[1]
                })
            }
        }
        else if(startTime && startDate && stopDate && stopTime && !toggleStartInterval && !toggleStopInterval){
            let duration = moment.duration(moment(stopDate + ' ' + stopTime).diff(moment(startDate + ' ' + startTime)))
            this.setState({
                currentIntervalText:calculateText('',duration),
                showInterval: true,
                currentIntervalDuration:duration._milliseconds/1000,
                currentIntervalDurationType: 'seconds'
            })
        }
    }

    toggleStartIntervalFunc = () =>{
        if(!this.state.toggleStopInterval){
            this.setState({
                toggleStartInterval: !this.state.toggleStartInterval
            },() => {
                if(!this.state.toggleStartInterval){
                    this.setDiffTime()
                }
            })
        }
    }

    toggleStopIntervalFunc = () =>{
        if(!this.state.toggleStartInterval){
            this.setState({
                toggleStopInterval: !this.state.toggleStopInterval
            },() => {
                if(!this.state.toggleStopInterval){
                    this.setDiffTime()
                }
            })
        }
    }

    setSelectedQuickSelection = (event) =>{
        this.setState({
            startDate: moment(convertDate(event.target.getAttribute('starttext'))).format(DATE_TIME_FORMAT).split(' ')[0],
            startTime: moment(convertDate(event.target.getAttribute('starttext'))).format(DATE_TIME_FORMAT).split(' ')[1],
            stopDate : moment(convertDate(event.target.getAttribute('stop'))).format(DATE_TIME_FORMAT).split(' ')[0],
            stopTime : moment(convertDate(event.target.getAttribute('stop'))).format(DATE_TIME_FORMAT).split(' ')[1],
            selectedQuickSelectionName:event.target.getAttribute('name'),
            toggleStartInterval: false,
            toggleStopInterval: false
        },() =>{
            this.setDiffTime()
        })
    }

    fieldUpdateHandler = (event) => {
        this.setState({
            [event.target.name] : event.target.value,
            selectedQuickSelectionName:''
        }, () =>{
            this.setDiffTime()
        });
    }

    setStartInterval = (event) =>{
        if(checkInterval(event.target.value)){
            const {startTime,startDate,stopDate,stopTime} = this.state;
            let resultObj = workerLogicForInterval(event,true,startTime,startDate,stopDate,stopTime)
            if(resultObj.result){
                this.setState({
                    currentIntervalText:resultObj.currentIntervalTextData,
                    currentIntervalDuration:resultObj.value,
                    currentIntervalDurationType:resultObj.currentIntervalDurationTypeData,
                    startDate:resultObj.startStopDate?resultObj.startStopDate.split(' ')[0]:'',
                    startTime:resultObj.startStopDate?resultObj.startStopDate.split(' ')[1]:''
                })
            }
        }
        else{
            this.setState({
                currentIntervalText:'',
                currentIntervalDuration:0,
                currentIntervalDurationType:'seconds',
                selectedQuickSelectionName:''
            })
        }
    }

    setStopInterval = (event) =>{
        if(checkInterval(event.target.value)){
            const {startTime,startDate,stopDate,stopTime} = this.state;
            let resultObj = workerLogicForInterval(event,false,startTime,startDate,stopDate,stopTime)
            if(resultObj.result){
                this.setState({
                    currentIntervalText:resultObj.currentIntervalTextData,
                    currentIntervalDuration:resultObj.value,
                    currentIntervalDurationType:resultObj.currentIntervalDurationTypeData,
                    stopDate:resultObj.startStopDate?resultObj.startStopDate.split(' ')[0]:'',
                    stopTime:resultObj.startStopDate?resultObj.startStopDate.split(' ')[1]:''
                })
            }
        }
        else{
            this.setState({
                currentIntervalText:'',
                currentIntervalDuration:0,
                currentIntervalDurationType:'seconds',
                selectedQuickSelectionName:''
            })
        }
    }

    setStopDate = (e) =>{
        this.setState({
            stopDate : moment().format(DATE_TIME_FORMAT).split(' ')[0],
            stopTime : moment().format(DATE_TIME_FORMAT).split(' ')[1],
            selectedQuickSelectionName:''
        },() =>{
            this.setDiffTime()
        })
    }

    getEditDeleteSection = () => {
        const {deleteWidget, getWidgetConfigData, metadata} = this.props;
        let cssClass = metadata.config.viewType === ViewType.MINIFIEDVIEW ? "d-flex flex-column action-drawer" +
          " horizontal" : "d-flex flex-column action-drawer pt-1 pb-2";
        return (
            <div className={cssClass}>
                <button className="f-22" onClick={getWidgetConfigData.bind(this, metadata.id)}><i className="icon-edit"/></button>
                <button className="f-22" onClick={deleteWidget.bind(this, metadata.id)}><i className="icon-delete"/></button>
            </div>
        );
    }

    componentWillUnmount() {
        const {clearFilters} = this.props;
        clearFilters();
    }

    renderFullView = () =>{
        const {metadata,editDeleteFlag} = this.props;
        const {showInterval,showFilterIndication,filterText,startTime,startDate,
            stopDate,stopTime,currentIntervalText,selectedQuickSelectionName,
            toggleStartInterval,toggleStopInterval, message} = this.state;
        let rows = [];
        
        if(metadata && metadata.config && metadata.config.selectedTimeRangeOptions && metadata.config.selectedTimeRangeOptions.length>0){
            metadata.config.selectedTimeRangeOptions.map((obj,index) => {
                let classes = "btn btn-outline-primary btn-sm mb-2 mr-2";
                classes = selectedQuickSelectionName === obj.name?classes + " selected":classes;
                rows.push(
                    <a onClick={this.setSelectedQuickSelection} key={index} index={index} name={obj.name} starttext={obj.start} stop={obj.stop} className={classes} >{obj.name} </a>
                );
            });
        }
        return(
            <div className={metadata.config.viewType === ViewType.MINIFIEDVIEW?'card card-shadow h-100 w-100':'card card-shadow overflow-hidden h-100 w-100'}>
                <div className="card-header">
                    { metadata.config.viewType === ViewType.MINIFIEDVIEW?
                        <React.Fragment>
                            <i className="icon-time-range-selector mr-2"/>
                            {metadata.name? metadata.name:"Time Range Selector"}
                        </React.Fragment> :
                        <React.Fragment>
                            { editDeleteFlag ? this.getEditDeleteSection() : "" }
                            <div className="d-flex">
                                <i className="icon-time-range-selector mr-2"/>{metadata.name? metadata.name:"Time Range Selector"}
                            </div>
                            <div className="d-flex w-100 pl-2">
                                <div className={showFilterIndication?"indicator-success":"d-none"}></div>
                                <span className="filterText" title={showFilterIndication?filterText:"No Filter Applied."}>
                                    {showFilterIndication?filterText:"No Filter Applied."}
                                </span>
                            </div>
                        </React.Fragment>
                    }
                </div>
                { metadata && metadata.config && metadata.config.selectedTimeRangeOptions && metadata.config.selectedTimeRangeOptions.length>0?
                    <div className="card-header pb-0 pt-2">
                        {rows}
                    </div>:null
                }
                <div className="card-body overflow-auto">
                    <CustomAlert color='danger'>{message}</CustomAlert>
                    <form>
                        <label className="switch switch-small switch-inline" >
                            <input type="checkbox" name="switch5" onChange={this.toggleStartIntervalFunc} 
                                checked={toggleStartInterval}
                            />
                            <span className="switch-label mr-2">Start</span>
                            <span className="switch-input"/>
                            <span className="switch-label ml-2">Interval</span>
                        </label>
                        {(!toggleStartInterval)?
                        <div className="form-group">
                            <div className="d-flex justify-content-between">
                            <label name="exampleInputEmail1">Enter date &amp; time</label>
                            </div>
                            <div className="input-group">
                            <input type="date" className="form-control" name="startDate" onChange={this.fieldUpdateHandler} value={startDate}/>
                            <input type="time" placeholder="HH:mm:ss" className="form-control" name="startTime" onChange={this.fieldUpdateHandler} value={startTime}/>
                            </div>
                        </div>
                        :
                        <div className="form-group">
                            <div className="d-flex justify-content-between">
                            <label name="exampleInputEmail1">Enter interval (e.g: 1y,1mo,2h,1d,90m,90s)</label>
                            </div>
                            <InputGroup onClick={(e) => {this.onClick(e)}}>
                            <InputGroupAddon className="prepend-input-group" addonType="prepend">
                                Stop -
                            </InputGroupAddon>
                            <Input type="text" placeholder="1y/1mo/2h/1d/90m/90s" onChange={this.setStartInterval}/>
                            </InputGroup>
                        </div>
                        }
                        <label className="switch switch-small switch-inline" >
                        <input type="checkbox" name="switch5" onChange={this.toggleStopIntervalFunc} checked={toggleStopInterval}/>
                        <span className="switch-label mr-2">Stop</span>
                        <span className="switch-input"></span>
                        <span className="switch-label ml-2">Interval</span>
                        </label>
                        {!toggleStopInterval?
                        <div className="form-group">
                            <div className="d-flex justify-content-between">
                            <label name="exampleInputEmail1">Enter date &amp; time</label>
                            <span className="f-10 text-primary cursor-pointer" onClick={this.setStopDate}><i className="icon-hours-spin mr-2"></i>Now</span>
                            </div>
                            <div className="input-group">
                            <input type="date" className="form-control" name="stopDate" onChange={this.fieldUpdateHandler} value={stopDate}/>
                            <input type="time" placeholder="HH:mm:ss" name="stopTime" onChange={this.fieldUpdateHandler} className="form-control" value={stopTime}/>
                            </div>
                        </div>
                        :
                        <div className="form-group">
                            <div className="d-flex justify-content-between">
                            <label name="exampleInputEmail1">Enter interval (e.g: 1y,1mo,2h,1d,90m,90s)</label>
                            </div>
                            <InputGroup onClick={(e) => {this.onClick(e)}}>
                            <InputGroupAddon className="prepend-input-group" addonType="prepend"><InputGroupText>Start +</InputGroupText></InputGroupAddon>
                            <Input type="text" placeholder="1y/1mo/2h/1d/90m/90s" onChange={this.setStopInterval}/>
                            </InputGroup>
                        </div>
                        }
                    </form>
                    {!showInterval?
                        <p className="card-text text-center my-5">No interval is selected</p>:
                        <div className="current-interval">Current interval
                            <div className="d-flex justify-content-between align-items-center mt-3">
                                <div className="prev f-24 d-flex align-items-center" onClick={this.getPreviousDateTime}>
                                    <i className="icon-left-arrow mr-2"></i>
                                    <span className="f-14">Prev.</span>
                                </div>
                                <div className="content">{currentIntervalText}</div>
                                <div className="next f-24 d-flex align-items-center" onClick={this.getNextDateTime}>
                                    <span className="f-14">Next</span>
                                    <i className="icon-right-arrow ml-2"></i>
                                </div>
                            </div>
                        </div>
                    }
                </div>
                <div className="card-footer text-right">
                    <a  href="#" className="float-left h2" ><i onClick={this.resetFilter} className="icon-spiner mr-2" ></i></a>
                    <button type="button" className="btn btn-primary mr-1" onClick={this.submitHandler}>Submit</button>
                </div>
            </div>
        );
    }

    render(){
        const {metadata,editDeleteFlag} = this.props;
        const {showFilterIndication,filterText} = this.state;
        if(metadata && metadata.config && metadata.config.viewType === ViewType.MINIFIEDVIEW){
            let id = "Popover1" + (metadata.id?metadata.id:"Time_Range_Selector")
            return(
                <div className="card card-shadow overflow-hidden w-100 h-100">
                    <div className="card-header w-100 h-100 p-0">
                        { editDeleteFlag ? this.getEditDeleteSection() : null }
                        <div className="d-flex w-100 h-100">
                            <div className='btn btn-primary overflow-hidden h-100 w-100 pl-1 pr-2'>
                                <div className="w-100 h-100 cursor-pointer" onClick={this.toggle}>
                                    <div className="w-100 text-left">
                                        <div id={id}  className="w-100 h-100 p-2 d-flex Popover align-items-center cursor-pointer" >
                                            <i className="icon-time-range-selector mr-2"/>
                                            <span className="text-truncate">{metadata.name? metadata.name:"Time Range Selector"}</span>
                                        </div>
                                    </div>
                                    <div className="d-flex w-100 pl-2">
                                        <div className={showFilterIndication?"indicator-success":"d-none"}></div>
                                        <span className="filterText" style={{color:"#734927"}} title={showFilterIndication?filterText:"No Filter Applied."}>{
                                            showFilterIndication?filterText:"No Filter Applied."}
                                        </span>
                                    </div>
                                </div>
                                <Popover className="popoverClass" placement="bottom" isOpen={this.state.popoverOpen} target={id}>
                                    <PopoverBody className="popoverBody">
                                        {this.renderFullView()}
                                    </PopoverBody>
                                </Popover>
                            </div>
                        </div>
                    </div>
                </div>
            );
        } else{
            return(
                this.renderFullView()
            );

        }
    }
}

Widget.propTypes = {
    editDeleteFlag: PropTypes.bool,
    range: PropTypes.object,
    metadata: PropTypes.object,
    getWidgetData: PropTypes.func,
    addFilterForData: PropTypes.func,
    submitHandler: PropTypes.func,
    deleteWidget: PropTypes.func,
    getWidgetConfigData: PropTypes.func,
    clearFilters: PropTypes.func,
    widgetState: PropTypes.object,
    dashboard: PropTypes.object,
    addStateToDashboard: PropTypes.func,
}

const mapDispatchToProps = (dispatch) => {
    return {
        addStateToDashboard: (data) => dispatch(addTimeRangeStateToDashboard(data))
    }
} 

const mapStateToProps = (state) => ({
    dashboard: state.dashboard,
})

export default connect(mapStateToProps, mapDispatchToProps)(Widget);