import {
    SET_WIDGET_DATA,
    ADD_WIDGET_DATA_FILTER,
    DELETE_WIDGET_DATA,
    CLEAR_WIDGET_DATA_FILTER,
    CLEAR_WIDGET_ERROR_DATA,
    SET_WIDGET_ERROR_DATA,
    SET_EVENTS_DATA,
    SET_IFRAME_ID,
    SET_GRAFANA_DASHBOARD,
    SET_TEMP_TIME_RANGE_WIDGET_STATE,
    REMOVE_TEMP_TIME_RANGE_WIDGET_STATE,
} from '../constants/actionTypes'
import { setData, deleteData } from '../../utils/storeUtils';

const setFilterForWidgetData = (state, newFilterObj) => {
    
    let stateCopy = JSON.parse(JSON.stringify(state));
    let currentFilters = !stateCopy.filters ? {} : Object.assign({}, stateCopy.filters);
    return setData(state, "filters", Object.assign({}, currentFilters, newFilterObj));
};

const clearFilterForWidgetData = (state, fieldName) => {
    if(state.filters) {
        let stateCopy = JSON.parse(JSON.stringify(state));
        delete stateCopy.filters[fieldName];
        return setData(state, "filters", stateCopy.filters);
    }
    return state;
}

const setErrorData = (state, property, data) => {
    let stateCopy = JSON.parse(JSON.stringify(state));
    let currentErrors = !stateCopy.errors ? {} : Object.assign({}, stateCopy.errors);
    let newErrors = {};
    newErrors[property] = data;
    return setData(state, "errors", Object.assign({}, currentErrors, newErrors));
}

const clearErrorData = (state,property) => {
    if(state.errors){
        let stateCopy = JSON.parse(JSON.stringify(state));
        delete stateCopy.errors[property];
        return setData(state, "errors", stateCopy.errors);
    }
    return state;
}

const widgetDataReducer = (state = {}, action) => {
    switch (action.type) {
        case SET_WIDGET_DATA:
            return setData(state, action.property, action.data);
        case SET_WIDGET_ERROR_DATA:
            return setErrorData(state, action.property, action.data);
        case SET_EVENTS_DATA:
            return setData(state, action.property, action.data);
        case ADD_WIDGET_DATA_FILTER:
            return setFilterForWidgetData(state, action.data);
        case DELETE_WIDGET_DATA:
            return deleteData(state, action.property);
        case CLEAR_WIDGET_DATA_FILTER:
            return clearFilterForWidgetData(state, action.data);
        case CLEAR_WIDGET_ERROR_DATA:
            return clearErrorData(state, action.property);
        case SET_TEMP_TIME_RANGE_WIDGET_STATE:
            return {...state, initTimeRangeState: {...action.data}};
        case REMOVE_TEMP_TIME_RANGE_WIDGET_STATE:
            return {...state, initTimeRangeState: {}};
        case SET_IFRAME_ID:
            return {...state, iframeId : action.id}
        case SET_GRAFANA_DASHBOARD: 
            return {...state, grafanaDashboard: action.dashboard}
        default:
            return state;
    }
};

export default widgetDataReducer;
