import React, {Component} from 'react'
import PropTypes from 'prop-types'
import MultiSelectReact  from 'multi-select-react';
import {selectedOptionsStyles,optionsListStyles} from './constants';
import {convertOptions,populateOptions} from './utils';
export default class MultiSelect extends Component {
    constructor() {
        super();
        this.state = {
            multiSelect: []
        };
    }

    UNSAFE_componentWillMount = () => {
        const {options, operations} = this.props;
        let data = convertOptions(options,operations);
        this.setState({
            multiSelect:data
        })
    }

    UNSAFE_componentWillReceiveProps = (nextProps) =>{
        const {options, operations} = nextProps;
        let data = convertOptions(options,operations);
        this.setState({
            multiSelect:data
        })
    }
    render() {
            
        return (
            <div className="input-group pt-1" style={{"width":"99.5% !important"}}>
                <MultiSelectReact 
                    options={this.state.multiSelect}
                    optionClicked={this.optionClicked.bind(this)}
                    selectedBadgeClicked={this.selectedBadgeClicked.bind(this)}
                    selectedOptionsStyles={selectedOptionsStyles}
                    optionsListStyles={optionsListStyles} 
                    isTextWrap={true}
                />
            </div>
        );
    }
   
    optionClicked(optionsList) {
        this.setState({ multiSelect: optionsList },() =>{
            const {updateOperations, id, operations} = this.props;
            let operation = populateOptions(event.target.innerHTML,optionsList,operations)
            updateOperations(id,operation)
        });
    }
    selectedBadgeClicked(optionsList) {
        this.setState({ multiSelect: optionsList },() => {
            const {updateOperations, id, operations} = this.props;
            let operation = populateOptions(event.target.innerHTML,optionsList,operations)
            updateOperations(id,operation)
        });
    }
   
}

MultiSelect.propTypes= {
    id: PropTypes.string,
    options: PropTypes.array,
    operations: PropTypes.array,
    updateOperations: PropTypes.func,
};