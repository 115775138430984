"use strict";
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Form } from 'reactstrap';
import CreateEditAppModal from '../common/createEditAppModal';
import Alert from '../common/custom-alert';

const errorMessages = {
    MESSAGE_VALID_EXTENSION : "Upload a valid file (Allowed extensions .jpeg/.jpg/.png/.gif only)",
    MESSAGE_VALID_SIZE: "Image size exceeds! (max size limit 500kb)"
};
export default class EditApp extends Component {
    constructor(){
        super();
        this.state = {
            name : "",
            description: "",
            dataSource : "",
            icon:null,
            imagePreview: "",
            iconUrl:"",
            timeZone: "",
            id:"",
            message: ""
        }
    }

    UNSAFE_componentWillReceiveProps(newProps) {
        let {currentApp} = newProps;
        if(currentApp.id !== this.state.id){
            this.setState({
                name : currentApp.name,
                description: currentApp.description,
                dataSource: currentApp.dataSource,
                icon: currentApp.icon,
                iconUrl: currentApp.iconUrl,
                timeZone: currentApp.timeZone,
                id: newProps.currentApp.id
            });
        }
    }

    fieldUpdateHandler = (event) => {
        if(event.target.name === "icon")
        {
            const file = event.target.files[0];
                
            this.setState({
                icon : event.target.files[0],
                imagePreview:  URL.createObjectURL(event.target.files[0])
            });
        }
        else
             this.setState({[event.target.name] : event.target.value});
    }

    removeIcon = () => {
        this.setState({
            icon:null,
            imagePreview: "",
        })
    }

    submitHandler = (event) => {
        event.preventDefault();
        let {editApplication} = this.props;
        let {name, description, dataSource, icon, iconUrl, id, timeZone} = this.state;
        let data = {name, description, dataSource,icon, iconUrl, id, timeZone};
        const imageValid = this.fileValidation();
        if (imageValid) {
            editApplication(data).then(() => {
                this.closeModal();
            }).catch((error) => {
                this.setState({message : error},this.resetMessage);
            });
        }
    }

    fileValidation = () => {
        if(this.state.icon === null) {
            return true;
        }
        var fileInput = document.getElementById('application-icon');
        if(fileInput.files.length) {
            var fileSize = fileInput.files[0].size;
        }
        var filePath = fileInput.value;
        if(!filePath) {
            return true;
        }
        var allowedExtensions = /(\.jpg|\.jpeg|\.png|\.gif)$/i;
        if(!allowedExtensions.exec(filePath)){
            this.setState({ message: errorMessages.MESSAGE_VALID_EXTENSION }, this.resetMessage)
            fileInput.value = '';
            this.setState({
                icon : null
            });
            return false;
        }
        if(fileSize > 512000) {
            this.setState({ message: errorMessages.MESSAGE_VALID_SIZE }, this.resetMessage)
            fileInput.value = '';
            this.setState({
                icon : null
            });
            return false;
        }
       return true;
    }

    resetMessage = () => {
        setTimeout(() => {
            this.setState({message:''})
        }, 5000);
    }
    closeModal = () =>{
        this.setState({
            name : "",
            description: "",
            dataSource : "",
            icon:null,
            iconUrl:"",
            timeZone: "",
            id:"",
            message: "",
            imagePreview: "",
        },() => {
            this.props.closeEditModal();
        });
    }
    render() {
        let { editModal, currentApp }  = this.props;
        let { name, description, dataSource, icon, imagePreview, iconUrl, timeZone, message } = this.state;
        let {submitHandler, closeModal, fieldUpdateHandler, removeIcon} = this;
        return(
            <Modal isOpen={editModal} >
                <Form onSubmit={submitHandler} style = {{padding : "10px"}}>
                    <ModalHeader toggle={closeModal}>Edit App</ModalHeader>
                    <ModalBody>
                        <Alert color="danger">{message}</Alert>
                        <CreateEditAppModal
                            name={name}
                            description={description}
                            dataSource={dataSource}
                            icon={icon}
                            imagePreview={imagePreview}
                            iconUrl={iconUrl}
                            timeZone={timeZone}
                            applicationId={currentApp.id}
                            fieldUpdateHandler={fieldUpdateHandler}
                            removeIcon={removeIcon}
                        />
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" type="submit">Update</Button>
                        <Button outline color="secondary" onClick={closeModal}>Cancel</Button>
                    </ModalFooter>
                </Form>
            </Modal>
        );
    }
}

EditApp.propTypes = {
    editModal: PropTypes.bool,
    currentApp: PropTypes.object,
    editApplication: PropTypes.func,
    closeEditModal: PropTypes.func,
};
