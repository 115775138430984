import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Widget from './widget';
import {areDifferent} from '../../../utils/commonUtils';
import {PROPERTY_BASE_NAME} from './constants';

class DashboardWidget extends Component {
    constructor(props){
        super(props);
        this.state = {
            propertyName:""
        }
        this.interval = null;
    }

    componentDidMount = () => {
        // const {getWidgetData,metadata} = this.props;
        // let propertyName = PROPERTY_BASE_NAME + '_' + metadata.id;
        // this.setState({
        //     propertyName: propertyName
        // }, () => {
        //     getWidgetData(this.socket,propertyName)
        //     // this.setupPolling();
        // });
    }

    componentDidUpdate(prevProps) {
        // let {metadata, widgetData, deleteWidgetData} = prevProps;
        // if(areDifferent(metadata.config, this.props.metadata.config)){
        //     if(widgetData[PROPERTY_BASE_NAME + '_' + metadata.id] && widgetData[PROPERTY_BASE_NAME + '_' + metadata.id].data){
        //         deleteWidgetData(PROPERTY_BASE_NAME + '_' + metadata.id )
        //     }
            // this.clearPolling();
            // this.setupPolling();
        // }
    }

    // setupPolling = () => {
    //     let {applicationId, streamId, metadata, widgetData, emitWidgetData, timeZone} = this.props;
    //     let query = JSON.parse(JSON.stringify(metadata.config.query));
    //     console.log('query', query);
    //     let obj = {
    //         type:metadata.config.queryType,
    //         value:query
    //     };
    //     let pollingTime = metadata.config.pollingTime ? metadata.config.pollingTime * 1000 : 1000;
    //     let filterCopy = Object.assign({}, widgetData.filters);
    //     if(filterCopy.startDateTime)
    //         filterCopy.startDateTime = convertRelativeDateToAbsoluteDate(filterCopy.startDateTime,timeZone);
    //     if(filterCopy.stopDateTime)
    //         filterCopy.stopDateTime = convertRelativeDateToAbsoluteDate(filterCopy.stopDateTime,timeZone);
    //     this.clearPolling();
    //     emitWidgetData(this.socket,obj,streamId,timeZone)
    //     .then(() => {
    //         this.interval = setTimeout(() => {
    //             this.setupPolling();
    //         }, pollingTime);
    //     })
    // }

    render(){
        const {metadata, widgetData, iFrameUrl, clearWidgetErrorData, getWidgetConfigData, deleteWidget, deleteWidgetData, editDeleteFlag} = this.props;
        const {propertyName} = this.state;
        return(
            <React.Fragment>
                <Widget 
                    editDeleteFlag={editDeleteFlag}
                    getWidgetConfigData={getWidgetConfigData}
                    deleteWidget={deleteWidget}
                    metadata={metadata} 
                    widgetData={widgetData} 
                    propertyName={propertyName}
                    iFrameUrl={metadata.config.iFrameUrl}
                    deleteWidgetData={deleteWidgetData}
                    clearWidgetErrorData={clearWidgetErrorData}
                />
            </React.Fragment>
        );
    }

    componentWillUnmount = () => {
        // const {clearWidgetErrorData,deleteWidgetData,metadata} = this.props;
        // deleteWidgetData(PROPERTY_BASE_NAME + '_' + metadata.id )
        // .then(() => {
        //     clearWidgetErrorData(PROPERTY_BASE_NAME)
        //     .then(() => {
        //         this.disConnectSocket();
        //         this.clearPolling();
        //     })
        // })
    }
}

DashboardWidget.propTypes = {
    editDeleteFlag: PropTypes.bool,
    applicationId: PropTypes.string,
    timeZone: PropTypes.string,
    metadata: PropTypes.object,
    widgetData: PropTypes.object,
    streamId: PropTypes.string,
    iFrameUrl: PropTypes.string,
    getWidgetData: PropTypes.func,
    deleteWidget: PropTypes.func,
    getWidgetConfigData: PropTypes.func,
    deleteWidgetData: PropTypes.func,
    emitWidgetData: PropTypes.func,
    clearWidgetErrorData: PropTypes.func
}

export default DashboardWidget;