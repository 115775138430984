import thumbnail from '../timeRangeSelectorWidget/thumbnail';
import configuration from '../timeRangeSelectorWidget/addConfiguration';
import editConfiguration from '../timeRangeSelectorWidget/editConfiguration';
import widget from '../timeRangeSelectorWidget/dashboardWidgetContainer';

const timeRangeSelectorWidget = {
    type : "TIME_RANGE_SELECTOR",
    thumbnail : thumbnail,
    configuration: configuration,
    editConfiguration: editConfiguration,
    widget : widget,
    maxAllowed : 1
};

export default timeRangeSelectorWidget;