import React, { Component } from 'react';
import PropTypes from 'prop-types';
import CameraViewWidget from './cameraViewWidget';
import CameraSelectorWidget from './cameraSelectorWidget';

class Widget extends Component {
    constructor(props){
        super(props);
    }

    render() {
        const {metadata, highlightedCameraFeed, cameraUrl, widgetData, editDeleteFlag, deleteWidget, getWidgetConfigData, clickHandler} = this.props;
        return (
            metadata.config.subType === "CAMERA_VIEW" 
                ? <CameraViewWidget
                    metadata={metadata}
                    widgetData={widgetData}
                    editDeleteFlag={editDeleteFlag}
                    getWidgetConfigData={getWidgetConfigData}
                    deleteWidget={deleteWidget}
                    cameraUrl={cameraUrl}
                    />
                : <CameraSelectorWidget
                    metadata={metadata}
                    widgetData={widgetData}
                    clickHandler={clickHandler}
                    highlightedCameraFeed={highlightedCameraFeed}
                    />
        );
    }
}

Widget.propTypes = {
    editDeleteFlag: PropTypes.bool,
    cameraUrl: PropTypes.string,
    highlightedCameraFeed: PropTypes.string,
    metadata: PropTypes.object,
    widgetData: PropTypes.object,
    clickHandler: PropTypes.func,
    getWidgetConfigData: PropTypes.func,
    deleteWidget: PropTypes.func
}

export default Widget;