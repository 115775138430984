import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Form,ModalFooter,Button,ModalBody,FormGroup,Label,Input} from 'reactstrap';
import {validateForm} from './utils';
import Alert from '../common/custom-alert';
export default class AddLayouts extends Component {
    constructor() {
        super();
        this.state = {
            layoutName:"",
            messages: ""
        }
    }

    fieldUpdateHandler = (event) => {
        this.setState({[event.target.name] : event.target.value});
    }

    addLayoutHandler = () =>{
        const {addLayoutHandler,activeDashboardID} = this.props;
        const {layoutName} = this.state;
        let layoutList = []
        let layouts = JSON.parse(localStorage.getItem('layouts'));
        if(layouts && layouts.find(x => x.id === activeDashboardID)){
            layoutList = layouts.find(x => x.id === activeDashboardID).layouts
        }
        let messages = validateForm(layoutList,layoutName);
        if(messages.length > 0){
            this.setState({ message :  messages.join(', ') }, () => {
                setTimeout(() => {
                    this.setState({ message : '' });
                }, 5000);
            })
        }
        else{
            addLayoutHandler(layoutName);
        }
    }

    render(){
        const {layoutName,message} = this.state;
        const {closeModal} = this.props;
        return(
            <React.Fragment>
                <ModalBody className="py-0">
                    <Alert color="danger">{message}</Alert>
                    <Form>
                        <FormGroup>
                            <Label for="layout-label">Layout Label</Label>
                            <Input maxLength={30} required type="text" name="layoutName" id="layout-label"
                                placeholder="Enter the layout label here" value={layoutName}
                                onChange={this.fieldUpdateHandler}/>
                        </FormGroup>
                    </Form>
                </ModalBody>
                <ModalFooter className="align-content-center">
                    <Button color="primary" onClick={this.addLayoutHandler}>Save</Button>
                    <Button outline color="secondary" onClick={closeModal}>Close</Button>
                </ModalFooter>
            </React.Fragment>
        );
    }
}

AddLayouts.propTypes = {
    activeDashboardID: PropTypes.string,
    addLayoutHandler: PropTypes.func,
    closeModal: PropTypes.func
}
