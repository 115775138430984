import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Form } from 'reactstrap';
import history from '../../router/history';
import Alert from '../common/custom-alert';
import CreateEditDashboardModal from '../common/createEditDashboardModal'

const errorMessages = {
    MESSAGE_VALID_EXTENSION : "Upload a valid file (Allowed extensions .jpeg/.jpg/.png/.gif only)",
    MESSAGE_VALID_SIZE: "Image size exceeds! (max size limit 500kb)"
};

export default class CreateDashboard extends Component {
    constructor() {
        super();
        this.state = {
            name: "",
            description: "",
            message: "",
            icon:null,
            layoutid: "",
            natsSubject: "",
            imagePreview: '',
        }
    }

    resetMessage = () => {
        setTimeout(() => {
            this.setState({ message: '' })
        }, 5000)
    }

    removeIcon = () => {
        this.setState({
            icon:null,
            imagePreview: "",
        })
    }

    fieldUpdateHandler = (event) => {

        if(event.target.name === "icon")
        {
            const file = event.target.files[0];
                
            this.setState({
                icon : event.target.files[0],
                imagePreview:  URL.createObjectURL(event.target.files[0]),
            });
        }
        else
             this.setState({[event.target.name] : event.target.value});
    }

    submitHandler = (event) => {
        event.preventDefault();
        let { createDashboard, applicationId, layouts } = this.props;
        let { name, description, layoutid, icon, natsSubject } = this.state;
        if (!layoutid) {
            layoutid = layouts && layouts[0].layoutId
        }
        let data = {
            name,
            description,
            icon,
            layoutid,
            natsSubject
        };
        var imageValid = this.fileValidation();
        if (imageValid) {
        createDashboard(data, applicationId)
            .then((dashboard) => {
                history.push(`/applications/${applicationId}/dashboards/${dashboard.id}`);
            })
            .catch((error) => {
                this.setState({ message: error });
            })
        }
    }

    fileValidation = () => {
        if(this.state.icon === null) {
            return true;
        }
        var fileInput = document.getElementById('dashboard-icon');
        if(fileInput.files.length) {
            var fileSize = fileInput.files[0].size;
        }
        var filePath = fileInput.value;
        if(!filePath) {
            return true;
        }
        var allowedExtensions = /(\.jpg|\.jpeg|\.png|\.gif)$/i;
        if(!allowedExtensions.exec(filePath)){
            this.setState({ message: errorMessages.MESSAGE_VALID_EXTENSION }, this.resetMessage)
            fileInput.value = '';
            this.setState({
                icon : null
            });
            return false;
        }
        if(fileSize > 512000) {
            this.setState({ message: errorMessages.MESSAGE_VALID_SIZE }, this.resetMessage)
            fileInput.value = '';
            this.setState({
                icon : null
            });
            return false;
        }
       return true;
    }

    closeDashboardModal = () => {
        this.setState({
            name: "",
            description: "",
            icon:null,
            message : ""
        },() => {
            this.props.closeModal();
        });
    }

    handleLayoutChange = (event) => {
        this.setState({ layoutid: event.target.value });
        this.setState({ natsSubject: event.target.options[event.target.selectedIndex].dataset.natsSubject });
    }

    render() {
        let { modal, layouts } = this.props;
        let { name, imagePreview, description, icon, message, layoutid, natsSubject } = this.state;
        let disabled = false;
        return (
            <Modal isOpen={modal}>
                <Form onSubmit={this.submitHandler} style={{ padding: "10px" }}>
                    <ModalHeader toggle={this.closeDashboardModal}>Create New Dashboard</ModalHeader>
                    <ModalBody>
                        <Alert color="danger">{message}</Alert>
                        <CreateEditDashboardModal imagePreview={imagePreview} removeIcon={this.removeIcon} dashboardId='' name={name} description={description} natsSubject={natsSubject} layoutid={layoutid} icon={icon} fieldUpdateHandler={this.fieldUpdateHandler} layouts={layouts} handleLayoutChange={this.handleLayoutChange} disabled={disabled} />
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" type="submit">Create</Button>
                        <Button outline color="secondary" onClick={this.closeDashboardModal}>Cancel</Button>
                    </ModalFooter>
                </Form>
            </Modal>
        );
    }
}

CreateDashboard.propTypes = {
    applicationId: PropTypes.string,
    modal: PropTypes.bool,
    closeModal: PropTypes.func,
    createDashboard: PropTypes.func,
    layouts: PropTypes.array,
    handleLayoutChange: PropTypes.func,
}
