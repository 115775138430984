export const CounterType = Object.freeze({
  TIMERANGEAGGREGATE: "TIMERANGEAGGREGATE",
  ONSCREENCOUNT: "ONSCREENCOUNT"
});

export const ViewType = Object.freeze({
  GRIDVIEW: "GRIDVIEW",
  LISTVIEW: "LISTVIEW"
});

export const defaultHeight = 20,
defaultWidth = 20,
defaultX = 0,
minWidth= 1,
maxWidth=60,
minHeight=1,
maxHeight=100,
PROPERTY_BASE_NAME = "iFrameWidget";

export const CustomOperators = Object.freeze([
  { label: "DISTINCT", value: "DISTINCT" }
]);