import React from 'react';
import PropTypes from 'prop-types';
import {widgetLibrary} from '../../utils/widgetUtils';
import NoWidgetHtml from './noWidgetSelected';
import InvalidWidgetHtml from './invalidWidget';

const WidgetConfiguration = ({
    modal,
    widgetCreationMetadata,
    selectedWidget,
    showWidgetSelectionPage,
    addWidget,
    closeModal,
    addCustomSelectionForTimeRangeWidget,
    timeZone,
    getVerticalAlignmentY,
    applicationId,
    dashboardId}) => {
    let configHTML;
    if(selectedWidget === ""){
        configHTML = <NoWidgetHtml
            modal={modal}
            showWidgetSelectionPage={showWidgetSelectionPage}
            closeModal={closeModal} />;
    } else {
        let widget = widgetLibrary[selectedWidget];
        if(!widget) {
            configHTML =  <InvalidWidgetHtml
                showWidgetSelectionPage={showWidgetSelectionPage}
                modal={modal}
                closeModal={closeModal}/>;
        } else {
            configHTML = <widget.configuration
                modal={modal}
                widgetCreationMetadata={widgetCreationMetadata}
                closeModal={closeModal}
                showWidgetSelectionPage={showWidgetSelectionPage}
                addWidget={addWidget}
                timeZone={timeZone}
                getVerticalAlignmentY={getVerticalAlignmentY}
                addCustomSelectionForTimeRangeWidget={addCustomSelectionForTimeRangeWidget}
                applicationId={applicationId}
                dashboardId={dashboardId}
            />;
        }
    }
    return (
        <div>
            {configHTML}
        </div>
    );
};

WidgetConfiguration.propTypes = {
    modal: PropTypes.bool,
    selectedWidget: PropTypes.string,
    applicationId: PropTypes.string,
    dashboardId: PropTypes.string,
    timeZone: PropTypes.string,
    widgetCreationMetadata : PropTypes.object,
    showWidgetSelectionPage: PropTypes.func,
    getVerticalAlignmentY: PropTypes.func,
    addWidget: PropTypes.func,
    closeModal: PropTypes.func,
    addCustomSelectionForTimeRangeWidget:PropTypes.func
};

export default WidgetConfiguration;
