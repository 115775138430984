import { connect } from 'react-redux'
// import {getWidgetData, emitWidgetData, deleteWidget, deleteWidgetData} from '../../../store/actions/widgetActions'
import PreviewWidget from './previewWidget';
import { getCameraFeedUrl } from '../../../store/actions/cameraActions'
import { setStreamId } from '../../../store/actions/widgetActions'

const mapStateToProps = (state) => ({
    widgetData: state.widgetData,
    cameraFeedUrl: state.camera.cameraFeedUrl,
    streamId: state.dashboard.streamId
})

const mapDispatchToProps = {
    getCameraFeedUrl,
    setStreamId
}

const PreviewWidgetContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(PreviewWidget)

export default PreviewWidgetContainer;
