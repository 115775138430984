// App Actions
export const SET_APP_LIST = 'SET_APP_LIST';
export const SET_CURRENT_APP = 'SET_CURRENT_APP';
export const ADD_NEW_APP = 'ADD_NEW_APP';
export const EDIT_APP = 'EDIT_APP';
export const DELETE_APP = 'DELETE_APP';

// Dashboard Actions
export const ADD_NEW_DASHBOARD = 'ADD_NEW_DASHBOARD';
export const DELETE_DASHBOARD = 'DELETE_DASHBOARD';
export const UPDATE_DASHBOARD = 'UPDATE_DASHBOARD';
export const SET_DASHBOARD = 'SET_DASHBOARD';
export const CLEAR_DASHBOARD = 'CLEAR_DASHBOARD';
export const ADD_STATE_TO_DASHBOARD = 'ADD_STATE_TO_DASHBOARD';
export const SET_RUNTIME_PARAMETERS = 'SET_RUNTIME_PARAMETERS';
export const CHANGE_RUNTIME_PARAMETER = 'CHANGE_RUNTIME_PARAMETER';
export const CHANGE_RUNTIME_PARAMETER_IN_WIDGET = 'CHANGE_RUNTIME_PARAMETER_IN_WIDGET';
export const SET_PARAMETERS_LOADING = 'SET_PARAMETERS_LOADING';

// Widget Actions
export const ADD_WIDGET = 'ADD_WIDGET';
export const UPDATE_WIDGET = 'UPDATE_WIDGET';
export const UPDATE_ALL_WIDGETS = 'UPDATE_ALL_WIDGETS';
export const DELETE_WIDGET = 'DELETE_WIDGET';
export const SET_WIDGET_DATA = "SET_WIDGET_DATA";
export const SET_WIDGET_ERROR_DATA = "SET_WIDGET_ERROR_DATA";
export const ADD_WIDGET_DATA_FILTER = "ADD_WIDGET_DATA_FILTER";
export const CLEAR_WIDGET_DATA_FILTER = "CLEAR_WIDGET_DATA_FILTER";
export const SET_WIDGET_METADATA = "SET_WIDGET_METADATA";
export const DELETE_WIDGET_DATA = "DELETE_WIDGET_DATA";
export const TIME_RANGE_CUSTOM_FILTER_ERROR = "TIME_RANGE_CUSTOM_FILTER_ERROR";
export const SET_EVENTS_DATA = "SET_EVENTS_DATA";
export const CLEAR_WIDGET_ERROR_DATA = "CLEAR_WIDGET_ERROR_DATA";
export const SET_STREAM_ID = "SET_STREAM_ID";
export const SET_TEMP_TIME_RANGE_WIDGET_STATE = "SET_TEMP_TIME_RANGE_WIDGET_STATE";
export const REMOVE_TEMP_TIME_RANGE_WIDGET_STATE = "REMOVE_TEMP_TIME_RANGE_WIDGET_STATE";
export const SET_IFRAME_ID = 'SET_IFRAME_ID';
export const SET_GRAFANA_DASHBOARD = 'SET_GRAFANA_DASHBOARD';
// Camera Actions
export const SET_CAMERA_FEED_URL = "SET_CAMERA_FEED_URL";
export const REMOVE_CAMERA_FEED_URL = "REMOVE_CAMERA_FEED_URL";

//UI
export const CHANGE_THEME = "CHANGE_THEME";
