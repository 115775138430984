import React from 'react';
import PropTypes from 'prop-types';
import thumbnailImage from "../../../../img/widget-thumbnails/iframe.svg";

const IFrameThumbnail = ({clickHandler}) => {
    return (
        <div className="card cursor-pointer card-shadow">
            <div className="card-body text-center" onClick={clickHandler}>
                <h5 className="card-title">IFrame</h5>
                <div className="img-wrapper f-42"><img src={thumbnailImage} alt="IFrame"/></div>
                <p className="card-text">Display the embedded content by provided url</p>
            </div>
        </div>
    );
}

IFrameThumbnail.propTypes = {
    clickHandler: PropTypes.func
}

export default IFrameThumbnail;