import { combineReducers } from "redux";
import widgetDataReducer from './widgetDataReducer';
import applicationReducer from "./applicationReducer";
import metadataReducer from "./metadataReducer";
import dashboardReducer from "./dashboardReducer";
import cameraReducer from "./cameraReducer";
import uiReducer from './uiReducer';

export const reducers = combineReducers({
    application: applicationReducer,
    dashboard: dashboardReducer,
    metadata: metadataReducer,
    widgetData: widgetDataReducer,
    camera: cameraReducer,
    ui: uiReducer,
});
