import React, {Component} from "react";
import PropTypes from 'prop-types';
import { Responsive, WidthProvider } from "react-grid-layout";
import {widgetLibrary} from '../../utils/widgetUtils';
import {gridDefaultProps} from '../common/constants';
import {getFromLS,saveToLS,getWidgetObject} from './utils';
const ResponsiveReactGridLayout = WidthProvider(Responsive);
export default class DashboardTab extends Component{
    constructor(props){
        super(props);
        this.state = {
            layouts: JSON.parse(JSON.stringify(getFromLS("layouts")))
        };
    }

    static defaultProps = gridDefaultProps;
    setLayoutsInfo = (activeDashboardID,layoutName) =>{
        if(!layoutName || layoutName === "Default"){
            this.setState({
                layouts: {}
            })
        }
        else{
            let layout = JSON.parse(localStorage.getItem('layouts'));
            let layoutWidget = layout.find(x=> x.id === activeDashboardID).layouts.find(x => x.name === layoutName).widgetsConfiguration;
            saveToLS("layouts", {lg:layoutWidget,md:layoutWidget});
            this.setState({
                layouts: JSON.parse(JSON.stringify(getFromLS("layouts")))
            })
        }
    }

    UNSAFE_componentWillMount = () =>{
        const {activeDashboardID, layoutName}  = this.props;
        this.setLayoutsInfo(activeDashboardID, layoutName);
    }

    UNSAFE_componentWillReceiveProps = (newProps) => {
        const {activeDashboardID,layoutName}  = newProps;
        if(activeDashboardID !== this.props.activeDashboardID){
            this.setLayoutsInfo(activeDashboardID, layoutName)
        }
    }

    addWidgetsToCanvas = () => {
        let self = this;
        const {currentApp, activeDashboardID, layoutName}  = this.props;
        let widgetComponents = [];
        let dashboard = currentApp.dashboards.find(x => x.id === activeDashboardID)
        if(!layoutName || layoutName === "Default"){
            dashboard.widgets.forEach((widget) => {
                let widgetObj = getWidgetObject(widget);
                widgetComponents.push(self.createElement(widgetObj));
            });
        }
        else{
            let layout = JSON.parse(localStorage.getItem('layouts'));
            let layoutWidget = layout.find(x=> x.id === activeDashboardID).layouts.find(x => x.name === layoutName).widgetsConfiguration;
            dashboard.widgets.forEach((widget) => {
                let widgetObj = getWidgetObject(widget);
                let layoutIndividualWidget = layoutWidget.find( x => x.id === widgetObj.id);
                if(layoutIndividualWidget){
                    let layoutWidgetObj = Object.assign({}, widgetObj, layoutIndividualWidget);
                    widgetComponents.push(self.createElement(layoutWidgetObj));
                }
                else{
                    widgetComponents.push(self.createElement(widgetObj));
                }
            })
        }
        return widgetComponents;
    }

    createElement = (widget) => {
        let {currentApp} = this.props;
        let WidgetComponent = widgetLibrary[widget.type].widget;
        return (
            <div key={widget.id} data-grid={widget}>
                <WidgetComponent
                  metadata={widget}
                  applicationId={currentApp.id}
                  timeZone={currentApp.timeZone}
                />
            </div>
        );
    }

    onLayoutChange = (layout) => {
        saveToLS("layouts", layout);
    }

    render(){
        let props = JSON.parse(JSON.stringify(this.props));
        delete props.currentApp
        return(
            <div className="tab-pane fade show active">
                <ResponsiveReactGridLayout
                    onLayoutChange={this.onLayoutChange}
                    layouts={this.state.layouts}
                    {...props}
                >
                    {this.addWidgetsToCanvas()}
                </ResponsiveReactGridLayout>
            </div>
        );
    }
}

DashboardTab.propTypes = {
    layoutName: PropTypes.string,
    activeDashboardID: PropTypes.string,
    currentApp: PropTypes.object
}
