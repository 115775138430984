export const ViewType = Object.freeze({
    FULLVIEW: "FULLVIEW",
    MINIFIEDVIEW: "MINIFIEDVIEW"
});

export const defaultHeightForFullView = 25,
    defaultHeightForMinifiedView = 4,
    defaultWidthForFullView = 10,
    defaultWidthForMinifiedView = 6,
    minWidthForMinifiedView= 6,
    minWidthForFullView= 8,
    maxWidthForFullView=30,
    maxWidthForMinifiedView=30,
    minHeightForFullView=25, //4
    minHeightForMinifiedView=4,
    maxHeightForMinifiedView=4, //25
    maxHeightForFullView=25,
    defaultX = 0;

export const generateMetadata = (id,name,description,height,width,xCoordinate,
    yCoordinate,minWidth,maxWidth,minHeight,maxHeight,viewType,selectedTimeRangeOptions) =>{
    return {
        id: id,
        name : name,
        description: description,
        height: height,
        width : width,
        xCoordinate: xCoordinate,
        yCoordinate: yCoordinate,
        minWidth: minWidth,
        maxWidth: maxWidth,
        minHeight: minHeight,
        maxHeight: maxHeight,
        type: "TIME_RANGE_SELECTOR",
        config : {
            viewType,
            selectedTimeRangeOptions
        }
    }
}

export const getDefaultTimeRangeOptions =  

    [{
        name: 'Last 5 minutes',
        start: "$current_date_time - 5m",
        stop: "$current_date_time"
    },
    {
        name: 'Last Hour',
        start: "$current_date_time - 1h",
        stop: "$current_date_time"
       },
       {
        name: 'Last Day',
        start: "$current_date_time - 1d",
        stop: "$current_date_time"
       },
       {
        name: 'Last Week',
        start: "$current_date_time - 7d",
        stop: "$current_date_time"
       },
       {
        name: 'Last Fortnight',
        start: "$current_date_time - 14d",
        stop: "$current_date_time"
       },
       {
        name: 'Last Month',
        start: "$current_date_time - 30d",
        stop: "$current_date_time"
       }]


