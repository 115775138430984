import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {ViewType} from './constants';
import Alert from '../../common/custom-alert';
import {v1 as uuidv1} from 'uuid';
import LoadingImage from '../../../../img/loader.gif';
const CounterBody = ({viewType,countFields}) =>{
    let view = []
    let countField = JSON.parse(JSON.stringify(countFields));
    for( let i =0; i < countField.length; i++){
        let obj = JSON.parse(JSON.stringify(countField[i]));
        delete obj['resultFeeds'];
        for(let j=0; j < countField[i].resultFeeds.length; j++){
            let resultFeedsObj = JSON.parse(JSON.stringify(countField[i].resultFeeds[j]));
            for(let k=0; k < Object.keys(resultFeedsObj).length; k++){
                if(Object.keys(resultFeedsObj)[k] !== "time" && typeof(Object.values(resultFeedsObj)[k]) !== 'boolean' && typeof(Object.values(resultFeedsObj)[k]) !== 'string'){
                    view.push(
                        <div className={viewType === ViewType.GRIDVIEW ?"d-inline-block":"d-flex"} key={uuidv1()}>
                            <label>
                                {Object.keys(obj).length > 0? Object.keys(resultFeedsObj)[k] + "  " +JSON.stringify(obj).replace(',','&') :Object.keys(resultFeedsObj)[k]}
                            </label>
                            <h5 className="text-primary">{Object.values(resultFeedsObj)[k]}</h5>
                        </div>
                    )
                }
            }
        }
    }
   
    return(
        <div className={viewType === ViewType.GRIDVIEW?"counter grid-view d-block text-center text-nowrap":"counter list-view d-flex"}>
            {view}
        </div>
    );
}

CounterBody.propTypes ={
    viewType:PropTypes.string,
    countFields:PropTypes.array
}

class Widget extends Component {
    constructor(props){
        super(props);
        this.state = {
        }
    }

    getEditDeleteSection = () => {
        const {deleteWidget, getWidgetConfigData, metadata} = this.props;
        return (
            <div className="d-flex flex-column action-drawer pt-1 pb-2">
                <button className="f-22" onClick={getWidgetConfigData.bind(this, metadata.id)}><i className="icon-edit"/></button>
                <button className="f-22" onClick={deleteWidget.bind(this, metadata.id)}><i className="icon-delete"/></button>
            </div>
        );
    }

    getWidget = () => {
        const {metadata, widgetData, propertyName} = this.props;
        let message = widgetData.errors && widgetData.errors[propertyName] ? widgetData.errors[propertyName] : "";
        if (widgetData[propertyName].data.length > 0) {
            return (
                <div className="card-body  py-0 my-3 d-block align-items-center">
                    <Alert color="danger">{message}</Alert>                    
                    <CounterBody
                        viewType={metadata.config.viewType}
                        countFields={widgetData[propertyName].data}
                    />
                </div>
            )
        }
        return(
            <div className="card-body py-0 my-3 d-block align-items-center">
                <div className="d-flex h-100 justify-content-center">No data</div>
            </div>
        );
    }
    render(){
        const {metadata, widgetData, propertyName, editDeleteFlag} = this.props;
        return(
            <div className='card card-shadow overflow-hidden h-100 w-100' key={metadata.id} >
                <div className="card-header">
                    { editDeleteFlag ? this.getEditDeleteSection() : "" }
                    <div className="d-flex">
                        <i className="icon-counter mr-2"/>{metadata.name ? metadata.name : 'Counter'}
                    </div>
                </div>
                {   widgetData[propertyName] && widgetData[propertyName].data ?
                    this.getWidget()
                    :
                    <div className="d-flex h-100 justify-content-center">
                        <img className="d-flex align-self-center" src={LoadingImage} />
                    </div>
                }                
                <div className="card-footer">
                    <div>{metadata.description?metadata.description : ' '}</div>
                </div>
            </div>
        );
    }
}

Widget.propTypes = {
    editDeleteFlag: PropTypes.bool,
    propertyName: PropTypes.string,
    metadata: PropTypes.object,
    widgetData: PropTypes.object,
    deleteWidget: PropTypes.func,
    getWidgetConfigData: PropTypes.func,
    deleteWidgetData: PropTypes.func,
    clearWidgetErrorData: PropTypes.func
}

export default Widget;