import React, {Component} from 'react'
import PropTypes from 'prop-types'
import { FormGroup, Label, ButtonGroup, Button } from 'reactstrap';
import { CustomOperators } from './constants'
import { getSelectData } from './utils'
import { v1 as uuidv1 } from 'uuid'

class CounterSelectQuery extends Component {

  addEmptySelectSection = () => {
    const {config,updateSelectQuery} = this.props;
    let selectedSelectOptions = JSON.parse(JSON.stringify(config.select));
    selectedSelectOptions.push(getSelectData(uuidv1(),'',''))
    updateSelectQuery(selectedSelectOptions);
  }

  updateSelectQuery = (id, event) => {
    const {config,updateSelectQuery} = this.props;
    let selectedSelectOptions = JSON.parse(JSON.stringify(config.select));
    let index = selectedSelectOptions.findIndex((o) => {return o.id === id});
    selectedSelectOptions[index][event.target.name] = event.target.value;
    if(event.target.name === "field")
        selectedSelectOptions[index]["alias"] = event.target.value;
    
    updateSelectQuery(selectedSelectOptions);
  }

  updateOperations = (id, value) => {
    const {config,updateSelectQuery} = this.props;
    let selectedSelectOptions = JSON.parse(JSON.stringify(config.select));
    let index = selectedSelectOptions.findIndex((o) => {return o.id === id});
    let operatorIndex = selectedSelectOptions[index].operations.indexOf(value);
    if(operatorIndex > -1){
      selectedSelectOptions[index].operations.splice(operatorIndex, 1);
    } else {
      selectedSelectOptions[index].operations.push(value);
    }
    updateSelectQuery(selectedSelectOptions);
  }

  deleteSelectOption = (id) =>{
    const {config,updateSelectQuery} = this.props;
    let selectedSelectOptions = JSON.parse(JSON.stringify(config.select));
    let updatedSelectedSelectOptions = selectedSelectOptions.filter((o) => {return o.id !== id});
    updateSelectQuery(updatedSelectedSelectOptions);
  }

  renderRow = (index, selectedOption) => {

    let{fieldsList} = this.props;
    const{tags} = this.props;

    let fieldAndTagOptions = [...fieldsList,...tags];
    let selectOptions=[<option key={0} value="">Select a field</option>]
    fieldAndTagOptions.forEach(function (sourceOption, i) {
      selectOptions.push(<option key={i + 1} value={sourceOption.name}>{sourceOption.label}</option>);
    });

    let customOperatorList = [];
    CustomOperators.forEach((op, index) => {
      let outlineFlag = !selectedOption.operations.includes(op.value);
      let classes = "font-weight-normal";
      if(index === 0) classes+= " border-top-left-radius-0";
      if(index === CustomOperators.length -1 ) classes+= " border-top-right-radius-0";
      customOperatorList.push(
        <Button key={index} outline={outlineFlag} color="primary" className={classes} onClick={this.updateOperations.bind(this, selectedOption.id, op.value)}>{op.label}</Button>
      );
    })
    return (
      <FormGroup key={selectedOption.id}>
        <div>
          <Label className="font-weight-normal"> Field {index + 1}</Label>
          <a onClick={this.deleteSelectOption.bind(null, selectedOption.id)} className="float-right link d-flex align-items-center f-12">
            <i className="icon-delete ml-1"/>
          </a>
        </div>
        <div>
          <div className="input-group">
            <div className="input-group-prepend w-50">
              <select className="form-control group-input custom-select border-bottom-left-radius-0" name="field"
                      defaultValue={selectedOption.field} onChange={(e) => this.updateSelectQuery(selectedOption.id, e)}>
                {selectOptions}
              </select>
            </div>
            <div className="input-group-append w-50">
              <input placeholder="alias" maxLength={30} type="text" className="form-control group-input border-bottom-right-radius-0"
                     name="alias" value={selectedOption.alias} onChange={(e) => this.updateSelectQuery(selectedOption.id, e)}
              />
            </div>
          </div>
          <div className="input-group">
            <div style={{width: "99.5%"}}>
              <ButtonGroup className="group-input btn-multi-select">
                {customOperatorList}
              </ButtonGroup>
            </div>
          </div>
        </div>
      </FormGroup>
    )
  }

  render() {
    let{config} = this.props;
    let rows = [];
    let selectedSelectOptions = JSON.parse(JSON.stringify(config.select))
    selectedSelectOptions.forEach((obj,index) =>{
      rows.push(this.renderRow(index, obj));
    })
    return(
      <React.Fragment>
        <FormGroup className="mb-0">
          <Label> Selected Fields </Label>
        </FormGroup>
        {rows}
        <div className="py-1 mb-2">
          <a className="link f-14" onClick={this.addEmptySelectSection}>
            <i className="icon-add-quick-selection mr-2"/>
            Add Select Query
          </a>
        </div>
      </React.Fragment>
    )
  }

}

CounterSelectQuery.propTypes= {
  config: PropTypes.object,
  fieldsList: PropTypes.array,
  updateSelectQuery: PropTypes.func,
  tags: PropTypes.array
};

export default CounterSelectQuery;