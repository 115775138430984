import moment from 'moment';
import momentTz from 'moment-timezone';
import { accessUrl } from '../store/constants/urls'

const CURRENT_DATE_TIME = '$current_date_time';
const CURRENT_DATE = '$current_date';
const DATE_TIME_FORMAT = 'YYYY-MM-DD HH:mm:ss';
const DATE_FORMAT = 'YYYY-MM-DD';

const DARK_THEME_COLORS = {
    '--color-primary-first': '#000000',
    '--color-primary-second': 'rgba(255, 255, 255, 0.1)',
    '--color-primary-third': '#949fa1',
    '--color-primary-fourth': 'rgba(255, 255, 255, 0.6)',
    '--color-secondary-first': '#131313',
    '--color-secondary-second': '#cfc1bf',
    '--color-secondary-third': '#c6c6c6',
    '--color-secondary-fourth': 'rgba(255, 255, 255, 0.6)',
    '--color-accent-first': '#f67f21',
    '--color-accent-second': '#e1e1e1',
    '--color-accent-third': '#303e40',
}

const LIGHT_THEME_COLORS = {
    '--color-primary-first': '#fff', 
    '--color-primary-second': 'rgba(0, 0, 0, 0.1)', 
    '--color-primary-third': '#f1f1f1', 
    '--color-primary-fourth': 'rgba(0, 0, 0, 0.6)', 
    '--color-secondary-first': '#f8f8f8', 
    '--color-secondary-second': '#303E40', 
    '--color-secondary-third': '#4F5051 ', 
    '--color-secondary-fourth': 'rgba(0, 0, 0, 0.6)', 
    '--color-accent-first': '#f67f21', 
    '--color-accent-second': '#4E6366', 
    '--color-accent-third': '#657e82', 
}

export function areDifferent(obj1,obj2){
    return JSON.stringify(obj2) !== JSON.stringify(obj1)
}

export function areDifferentArrays(a, b, propertyName) {
    let idsA = a.map( function(x){ return x[propertyName]; } ).sort().join(',');
    let idsB = b.map( function(x){ return x[propertyName]; } ).sort().join(',');
    return (idsA !== idsB );
}

export const convertRelativeDateToAbsoluteDate = (value,timeZone) => {
    if(value === CURRENT_DATE_TIME) return momentTz.tz(moment().format(DATE_TIME_FORMAT),timeZone).format();
    if(value === CURRENT_DATE) return momentTz.tz(moment().format(DATE_FORMAT) + ' 00:00:00', timeZone).format();
    if(value.startsWith(`${CURRENT_DATE} - `)){
        if(value.endsWith('h') || value.endsWith('d') || value.endsWith('m') || value.endsWith('s')){
            if(!isNaN(value.substring((`${CURRENT_DATE} - `).length , value.length - 1))){
                if(value.endsWith('h')){
                    return momentTz.tz(moment(moment().format(DATE_FORMAT) + ' ' + "00:00:00")
                        .subtract(parseInt(value.substring((`${CURRENT_DATE} - `).length , value.length - 1)), 'hours')
                        .format(DATE_TIME_FORMAT),timeZone).format();
                }
                if(value.endsWith('d')){
                    return momentTz.tz(moment(moment().format(DATE_FORMAT) + ' ' + "00:00:00")
                        .subtract(parseInt(value.substring((`${CURRENT_DATE} - `).length , value.length - 1)), 'days')
                        .format(DATE_TIME_FORMAT),timeZone).format();
                }
                if(value.endsWith('m')){
                    return momentTz.tz(moment(moment().format(DATE_FORMAT) + ' ' + "00:00:00")
                        .subtract(parseInt(value.substring((`${CURRENT_DATE} - `).length , value.length - 1)), 'minutes')
                        .format(DATE_TIME_FORMAT),timeZone).format();
                }
                return momentTz.tz(moment(moment().format(DATE_FORMAT) + ' ' + "00:00:00")
                    .subtract(parseInt(value.substring((`${CURRENT_DATE} - `).length , value.length - 1)), 'seconds')
                    .format(DATE_TIME_FORMAT),timeZone).format();
            }
        }
    }
    if(value.startsWith(`${CURRENT_DATE_TIME} - `)){
        if(value.endsWith('h') || value.endsWith('d') || value.endsWith('m') || value.endsWith('s')){
            if(!isNaN(value.substring((`${CURRENT_DATE_TIME} - `).length , value.length - 1))){
                if(value.endsWith('h'))
                    return momentTz.tz(moment(moment().format(DATE_TIME_FORMAT))
                        .subtract(parseInt(value.substring((`${CURRENT_DATE_TIME} - `).length , value.length - 1)), 'hours')
                        .format(DATE_TIME_FORMAT),timeZone).format();
                if(value.endsWith('d'))
                    return momentTz.tz(moment(moment().format(DATE_TIME_FORMAT))
                        .subtract(parseInt(value.substring((`${CURRENT_DATE_TIME} - `).length , value.length - 1)), 'days')
                        .format(DATE_TIME_FORMAT),timeZone).format();
                if(value.endsWith('m'))
                    return momentTz.tz(moment(moment().format(DATE_TIME_FORMAT))
                        .subtract(parseInt(value.substring((`${CURRENT_DATE_TIME} - `).length , value.length - 1)), 'minutes')
                        .format(DATE_TIME_FORMAT),timeZone).format();
                return momentTz.tz(moment(moment().format(DATE_TIME_FORMAT))
                    .subtract(parseInt(value.substring((`${CURRENT_DATE_TIME} - `).length , value.length - 1)), 'seconds')
                    .format(DATE_TIME_FORMAT),timeZone).format();
            }
        }
    }

    if(value.startsWith(`${CURRENT_DATE} `)){
        if(value.indexOf(':')!== -1){
            let subStr = value.substring(value.length - 8 , value.length);
            if(value.length - (`${CURRENT_DATE} `).length === 8 && 
                ((subStr.split(':').length === 3 && subStr.split(':')[0].length === 2 && subStr.split(':')[1].length === 2 && subStr.split(':')[2].length === 2) 
                || (subStr.split(':').length === 2 && subStr.split(':')[0].length === 2 && subStr.split(':')[1].length === 2))){
                return momentTz.tz(moment().format(DATE_FORMAT) + ' ' + subStr,timeZone).format()
            }
        }
    }
    return momentTz.tz(value,timeZone).format();
} 

export const checkIfMetadataIsAfterWidgetUpdate = (metadataUpdatedAt,widgetUpdatedAt) => {
    if(moment(metadataUpdatedAt, 'DD-MM-YYYY HH:mm:ss').diff(moment(widgetUpdatedAt, 'DD-MM-YYYY HH:mm:ss')) > 0){
        return true
    }
    return false;
}

export const parseTimeRangeState = function(object){
    if(typeof object === 'object' && object !== null) {
        object['currentIntervalDuration'] = parseInt(object['currentIntervalDuration']);
        for(let key in object) {
            if(object[key] === 'false') {
                object[key] = false;
            }
            if(object[key] === 'true'){
                object[key] = true;
            }
        }
        return object;
    }
}

export const stringify = function(object) {
    for(const key in object) {
        if(typeof object[key] !== 'string') {
            object[key] = `${object[key]}`;
        }
    }
    return object;
}

export const toggleTheme = function(changeTheme) {
    const theme = getTheme();
    switch(theme) {
        case 'light': { applyTheme('dark'); changeTheme('dark')} break;
        case 'dark': { applyTheme('light'); changeTheme('light')} break;
        default: { applyTheme('light'); changeTheme('light')}
    }
}

export const applyTheme = function(theme) {
    const rootStyles = document.documentElement.style;
    const colors = theme === 'dark' ? DARK_THEME_COLORS : LIGHT_THEME_COLORS;
    for(const key in colors) {
        rootStyles.setProperty(key, colors[key]);
    }
    localStorage.setItem('theme', theme)
}

export const getTheme = function(){
    return localStorage.getItem('theme');
}


export const fetchAccessToken = () => {
    return new Promise((resolve, reject) => {
        fetch(accessUrl, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            }
        }).then(res => res.json()).then(response => {
            const result = JSON.parse(JSON.stringify(response))
            return resolve(result['data']);
        }).catch(error => {
            
            reject(error.message);
        });
    })
}

export const getServiceUrl = url => url.slice(0, url.indexOf('/api'))