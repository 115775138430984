import React from 'react';
import { connect } from 'react-redux';
import thumbnailImage from "../../../../img/widget-thumbnails/time-range-selector.svg";
import thumbnailImageDark from "../../../../img/widget-thumbnails/time-range-selector-dark.svg"
import PropTypes from 'prop-types';
import { getTheme } from '../../../utils/commonUtils'

const TimeRangeSelectorThumbnail = ({isDisabled, clickHandler}) => {
    let classes = isDisabled ? "card card-shadow card-disabled" : "card card-shadow cursor-pointer";
    return (
        <div className={classes}>
            <div className="card-body text-center" onClick={clickHandler}>
                <h5 className="card-title">Time Range Selector</h5>
                <div className="img-wrapper f-42"><img src={getTheme() === 'dark' ? thumbnailImage : thumbnailImageDark} alt="Time Range Selector"/></div>
                <p className="card-text">
                    {isDisabled ? "This widget has already been added." : "Select (or input) time intervals and see relevant information."}
                </p>
            </div>
        </div>
    );
}

TimeRangeSelectorThumbnail.propTypes = {
    isDisabled: PropTypes.bool,
    clickHandler: PropTypes.func
}

const mapStateToProps = (state) => ({theme: state.ui.theme})

export default connect(mapStateToProps)(TimeRangeSelectorThumbnail);