import React from "react";
import PropTypes from "prop-types";

const DefaultButton = props => <button {...props}>{props.children}</button>;

DefaultButton.propTypes = {
    props: PropTypes.any,
    children : PropTypes.any
}
export default class Pagination extends React.Component {
    constructor(props) {
        super();
        this.changePage = this.changePage.bind(this);
        this.state = {
            visiblePages: this.getVisiblePages(null, props.pages),
            dropdownOpen:false
        };
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (this.props.pages !== nextProps.pages) {
            this.setState({
                visiblePages: this.getVisiblePages(null, nextProps.pages)
            });
        }
        this.changePage(nextProps.page + 1);
    }

    filterPages = (visiblePages, totalPages) => {
        return visiblePages.filter(page => page <= totalPages);
    }

    getVisiblePages = (page, total) => {
        if (total < 7)
            return this.filterPages([1, 2, 3, 4, 5, 6], total);
        if (page % 5 >= 0 && page > 4 && page + 2 < total) {
            return [1, page - 1, page, page + 1, total];
        } else if (page % 5 >= 0 && page > 4 && page + 2 >= total) {
            return [1, total - 3, total - 2, total - 1, total];
        } else {
            return [1, 2, 3, 4, 5, total];
        }
    }

    changePage = (page) => {
        const activePage = this.props.page + 1;
        if (page === activePage) return;
        const visiblePages = this.getVisiblePages(page, this.props.pages);
        this.setState({
            visiblePages: this.filterPages(visiblePages, this.props.pages)
        });
        this.props.onPageChange(page - 1);
    }

    toggleDropDown = () =>{
        this.setState({
            dropdownOpen: !this.state.dropdownOpen
        });
    }

    setPageSize = (val) => {
        this.setState({
            dropdownOpen: !this.state.dropdownOpen
        },() =>{
            if(val === "5 items per page"){
                this.props.onPageSizeChange(5)
            }
            else if(val === "10 items per page"){
                this.props.onPageSizeChange(10)
            }
            else if(val === "25 items per page"){
                this.props.onPageSizeChange(25)
            }
            else if(val === "50 items per page"){
                this.props.onPageSizeChange(50)
            }
            else{
                this.props.onPageSizeChange(100)
            }
        })
    }

    render() {
        const { PageButtonComponent = DefaultButton , page , sortedData , pages , pageSize } = this.props;
        const {visiblePages,dropdownOpen} = this.state;
        const activePage = page + 1;
        let startIndex = sortedData.length > 0 ?(((activePage - 1) * pageSize) + 1):0;
        let endIndex = sortedData.length > 0 ?((activePage === pages)? sortedData.length: activePage*pageSize) : 0;
        let totalIndex = sortedData.length;
        let text = startIndex +' - '+ endIndex +' of '+ totalIndex;
        let cssClasses = dropdownOpen ? "dropdown-menu dropdown-secondary show":"dropdown-menu" +
          " dropdown-secondary";

        return (
            <div className="Table__pagination">
                <div className="Table__showRecords">
                    <div className="btn-group dropdown">
                        <button onClick={this.toggleDropDown} type="button" className="f-12 px-2 btn btn-outline-secondary dropdown-toggle" data-toggle="dropdown">
                            {text}
                        </button>
                        <div className={cssClasses} style={{position: "absolute"}}>
                            <button onClick={(e) => {this.setPageSize(e.target.innerHTML)}} className="dropdown-item">5 items per page</button>
                            <button onClick={(e) => {this.setPageSize(e.target.innerHTML)}} className="dropdown-item">10 items per page</button>
                            <button onClick={(e) => {this.setPageSize(e.target.innerHTML)}} className="dropdown-item">25 items per page</button>
                            <button onClick={(e) => {this.setPageSize(e.target.innerHTML)}} className="dropdown-item">50 items per page</button>
                            <button onClick={(e) => {this.setPageSize(e.target.innerHTML)}} className="dropdown-item">100 items per page</button>
                        </div>
                    </div>
                </div>
                <div className="Table__prevPageWrapper">
                    <PageButtonComponent className="Table__pageButton" disabled={activePage === 1}
                        onClick={ () => { 
                            if (activePage === 1) return; 
                            this.changePage(activePage - 1);
                        }} >
                        {this.props.previousText}
                    </PageButtonComponent>
                </div>
                <div className="Table__visiblePagesWrapper">
                    {
                        visiblePages.map((page, index, array) => {
                            return (
                                <PageButtonComponent key={page} onClick={this.changePage.bind(null, page)}
                                    className={ activePage === page ? "Table__pageButton Table__pageButton--active" : "Table__pageButton"} >
                                    {array[index - 1] + 2 < page ? `...${page}` : page}
                                </PageButtonComponent>
                            );
                        })
                    }
                </div>
                <div className="Table__nextPageWrapper">
                    <PageButtonComponent className="Table__pageButton" disabled={activePage === this.props.pages}
                        onClick={() => { 
                            if (activePage === this.props.pages) return;
                            this.changePage(activePage + 1);
                        }}>
                        {this.props.nextText}
                    </PageButtonComponent>
                </div>
            </div>
        );
    }
}

Pagination.propTypes = {
    pageSize:PropTypes.number,
    pages: PropTypes.number,
    page: PropTypes.number,
    previousText: PropTypes.string,
    nextText: PropTypes.string,
    sortedData:PropTypes.array,
    PageButtonComponent: PropTypes.any,
    onPageChange: PropTypes.func,
    onPageSizeChange: PropTypes.func
}
