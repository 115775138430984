export const defaultHeight = 15,
    defaultWidth = 10,
    minWidth= 1,
    maxWidth=30,
    minHeight=1,
    maxHeight=30,
    defaultX = 0,
    PROPERTY_BASE_NAME = "timeSeriesGraphWidget";

export const CommonOperationsList = ["COUNT"];

export const IntegerOperationsList = [
    "COUNT",
    "DISTINCT",
    "MODE",
    "FIRST",
    "LAST",
    "MEAN",
    "MEDIAN",
    "SPREAD",
    "STDDEV",
    "SUM",
    "MAX",
    "MIN",
    "ABS",
    "CEIL",
    "COS",
    "CUMULATIVE_SUM",
    "DERIVATIVE",
    "DIFFERENCE",
    "FLOOR",
    "ROUND",
    "SIN"
]
