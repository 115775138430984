import thumbnail from '../cameraWidget/thumbnail';
import configuration from '../cameraWidget/addConfiguration';
import editConfiguration from '../cameraWidget/editConfiguration';
import widget from '../cameraWidget/dashboardWidgetContainer';

const cameraWidget = {
    type : "CAMERA",
    thumbnail : thumbnail,
    configuration: configuration,
    editConfiguration: editConfiguration,
    widget : widget,
    maxAllowed : 1
};

export default cameraWidget;