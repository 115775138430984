import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Modal, Button, ModalHeader, ModalFooter } from 'reactstrap';
import ConfigurationForm from "./configurationBody";
import {
    ViewType, defaultHeightForFullView, defaultHeightForMinifiedView, defaultWidthForFullView,
    defaultWidthForMinifiedView, generateMetadata, minWidthForFullView, minWidthForMinifiedView,
    minHeightForFullView, minHeightForMinifiedView, maxWidthForFullView, maxWidthForMinifiedView, maxHeightForMinifiedView,
    maxHeightForFullView, getDefaultTimeRangeOptions
} from './constants';

function mergeOptions(currentOptions ,newOptions) {
    return newOptions.length === 1 ? [...currentOptions, ...newOptions] : currentOptions;
}

let ViewTypeOnEdit = ViewType.FULLVIEW
class TimeRangeSelectorConfigurationModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            label: "",
            description: "",
            selectedTimeRangeOptions: getDefaultTimeRangeOptions,
            openQuickCustomModal: false,
            viewType: ViewType.FULLVIEW,
            message: ""
        }
    }

    UNSAFE_componentWillMount() {
        const { widgetConfigDataForEdit } = this.props;
        this.setStateFunc(widgetConfigDataForEdit)
    }

    setStateFunc = (widgetConfigDataForEdit) => {
        ViewTypeOnEdit = widgetConfigDataForEdit.config.viewType;
        this.setState({
            label: widgetConfigDataForEdit.name,
            description: widgetConfigDataForEdit.description,
            viewType: widgetConfigDataForEdit.config.viewType,
            selectedTimeRangeOptions: widgetConfigDataForEdit.config.selectedTimeRangeOptions
        })
    }

    closeModal = () => {
        this.setState({
            label: "",
            description: "",
            selectedTimeRangeOptions: getDefaultTimeRangeOptions,
            viewType: ViewType.FULLVIEW,
            openQuickCustomModal: false
        }, () => {
            this.props.closeModal();
        })
    }

    setTimeRangeOptions = (e, obj) => {
        let selectedOptions = this.state.selectedTimeRangeOptions;
        let index = selectedOptions.findIndex(x => x.name === obj.name)
        if (index === -1) {
            selectedOptions.push(obj);
        }
        else {
            selectedOptions.splice(index, 1);
        }
        this.setState({
            selectedTimeRangeOptions: selectedOptions
        });
    }

    fieldUpdateHandler = (event) => {
        this.setState({
            [event.target.name]: event.target.value
        });
    }

    submitHandler = () => {
        let { label, description, viewType, selectedTimeRangeOptions } = this.state;
        const rangeOptions = mergeOptions(selectedTimeRangeOptions, this.props.widgetMetadata);
        if (label) {
            const { widgetConfigDataForEdit } = this.props;
            let widgetMetaData = {}
            if (ViewTypeOnEdit === viewType) {
                widgetMetaData = generateMetadata(widgetConfigDataForEdit.id, label, description,
                    widgetConfigDataForEdit.height, widgetConfigDataForEdit.width,
                    widgetConfigDataForEdit.xCoordinate, widgetConfigDataForEdit.yCoordinate, widgetConfigDataForEdit.minWidth,
                    widgetConfigDataForEdit.maxWidth, widgetConfigDataForEdit.minHeight, widgetConfigDataForEdit.maxHeight, viewType,
                    rangeOptions)
            }
            else {
                let defaultHeight = viewType === ViewType.FULLVIEW ? defaultHeightForFullView : defaultHeightForMinifiedView;
                let defaultWidth = viewType === ViewType.FULLVIEW ? defaultWidthForFullView : defaultWidthForMinifiedView;
                let minWidth = viewType === ViewType.FULLVIEW ? minWidthForFullView : minWidthForMinifiedView;
                let maxWidth = viewType === ViewType.FULLVIEW ? maxWidthForFullView : maxWidthForMinifiedView;
                let minHeight = viewType === ViewType.FULLVIEW ? minHeightForFullView : minHeightForMinifiedView;
                let maxHeight = viewType === ViewType.FULLVIEW ? maxHeightForFullView : maxHeightForMinifiedView;

                widgetMetaData = generateMetadata(widgetConfigDataForEdit.id, label, description, defaultHeight,
                    defaultWidth, widgetConfigDataForEdit.xCoordinate, widgetConfigDataForEdit.yCoordinate,
                    minWidth, maxWidth, minHeight, maxHeight, viewType, rangeOptions)
            }
            const { dashboard } = this.props;
            const widget = dashboard && dashboard.widgets.find(widget => widget.type === 'TIME_RANGE_SELECTOR');
            if(widget && widget.config.savedState && this.state.filterText !== widget.config.savedState.filterText) {
                widgetMetaData.config.savedState = widget.config.savedState;
            }
            this.props.updateWidget(widgetMetaData)
                .then(() => {
                    this.closeModal();
                })
        }
        else {
            this.setState({
                message: 'Widget Name cannot be empty'
            }, () => {
                setTimeout(() => {
                    this.setState({ message: '' })
                }, 5000)
            })
        }
    }

    radioButtonChangeHandler = (name, value) => {
        this.setState({ [name]: value });
    }

    openQuickCustomSelectionModal = () => {
        this.setState({
            openQuickCustomModal: true
        })
    }

    closeQuickSelectionModal = () => {
        this.setState({
            openQuickCustomModal: false
        })
    }

    render() {
        let { label, description, viewType, selectedTimeRangeOptions, openQuickCustomModal, message } = this.state;
        let { modal, applicationId, widgetCreationMetadata, addCustomSelectionForTimeRangeWidget } = this.props;
        const rangeOptions = mergeOptions(selectedTimeRangeOptions, this.props.widgetMetadata);
        let metadata = {
            name: label, description: description, config: {
                viewType,
                selectedTimeRangeOptions: rangeOptions,
            }
        };

        return (
            <Modal className="modal-dialog-centered" size="lg" isOpen={modal} backdrop="static" fade={false}>
                <ModalHeader toggle={this.closeModal} charCode="×">
                    <div>
                        <span>Select Widget</span>
                        <span>Configure widget</span>
                    </div>
                </ModalHeader>
                <ConfigurationForm
                    metadata={metadata}
                    message={message}
                    widgetCreationMetadata={widgetCreationMetadata}
                    applicationId={applicationId}
                    fieldUpdateHandler={this.fieldUpdateHandler}
                    addCustomSelectionForTimeRangeWidget={addCustomSelectionForTimeRangeWidget}
                    openQuickCustomModal={openQuickCustomModal}
                    openQuickCustomSelectionModal={this.openQuickCustomSelectionModal}
                    setTimeRangeOptions={this.setTimeRangeOptions}
                    closeQuickSelectionModal={this.closeQuickSelectionModal}
                    radioButtonChangeHandler={this.radioButtonChangeHandler}
                />
                <ModalFooter>
                    <Button color="primary" onClick={this.submitHandler}>Update</Button>
                </ModalFooter>
            </Modal>
        );
    }
}

TimeRangeSelectorConfigurationModal.propTypes = {
    modal: PropTypes.bool,
    applicationId: PropTypes.string,
    widgetCreationMetadata: PropTypes.object,
    widgetConfigDataForEdit: PropTypes.object,
    widgetData: PropTypes.object,
    closeModal: PropTypes.func,
    updateWidget: PropTypes.func,
    showWidgetSelectionPage: PropTypes.func,
    addCustomSelectionForTimeRangeWidget: PropTypes.func,
    widgetMetadata: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.array,
    ]),
    dashboard: PropTypes.object,
};

const mapStateToProps = (state) => ({
    widgetMetadata: state.metadata.meta.timeRangeOptions,
    dashboard: state.dashboard,
});

export default connect(mapStateToProps)(TimeRangeSelectorConfigurationModal);