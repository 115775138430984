import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import thumbnailImage from "../../../../img/widget-thumbnails/table.svg";
import thumbnailImageDark from "../../../../img/widget-thumbnails/table-dark.svg";
import { getTheme } from '../../../utils/commonUtils';

const TableThumbnail = ({clickHandler}) => {
    return (
        <div className="card cursor-pointer card-shadow">
            <div className="card-body text-center" onClick={clickHandler}>
              <h5 className="card-title">Table</h5>
              <div className="img-wrapper f-42"><img src={getTheme() === 'dark' ? thumbnailImage : thumbnailImageDark} alt="Table"/></div>
              <p className="card-text">Graphical view of counts, over a selected time-period.</p>
            </div>
        </div>
    );
}

TableThumbnail.propTypes = {
    clickHandler: PropTypes.func
}

const mapStateToProps = (state) => ({theme: state.ui.theme})

export default connect(mapStateToProps)(TableThumbnail);