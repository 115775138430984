import React, { Component } from 'react';
import PropTypes from 'prop-types';
import DeleteModalPanel from '../common/deleteModal';
import history from '../../router/history';
import EditApp from './editApp';
import {ButtonDropdown, DropdownToggle, DropdownMenu , DropdownItem} from 'reactstrap';
import LoadingImage from '../../../img/loader.gif';

class AppDetail extends Component {
    constructor(){
        super();
        this.state = {
            dropdownOpen : false,
            deleteModal: false,
            editModal:false
        }
    }

    toggleDropDown = () => {
        this.setState({
            dropdownOpen: !this.state.dropdownOpen
        });
    }

    closeDeleteModal = () => {
        this.setState({
            deleteModal: false,
        })
    }

    closeEditModal = () => {
        this.setState({
            editModal: false,
        })
    }

    onDeleteApplication = () => {
        this.props.deleteApplication(this.props.currentApp.id)
            .then(history.push('/'))
    }

    openEditAppModal = () => {
        this.setState({
            editModal : true
        },() =>{
            this.toggleDropDown();
        });
    }

    openDeleteAppModal = () => {
        this.setState({
            deleteModal : true
        },() =>{
            this.toggleDropDown();
        });
    }

    updateMetadata = () => {
        let {currentApp, setMessage, updateApplicationMetadata} = this.props;
        updateApplicationMetadata(currentApp.id).then(() => {
            
            setMessage('Metadata updated successfully.', 'success');
        }).catch((errorMsg) => {
            
            setMessage(errorMsg, 'danger');
        });
    }

    render() {
        let {currentApp, editApplication} = this.props;
        let { dropdownOpen, deleteModal, editModal } = this.state;
        if(currentApp) {
            let url = '/applications/'+ this.props.currentApp.id+'/preview'
            return (
                <div className="card border-0">
                    <div className="row metric-row">
                        <div className="col-md-8 text-left">
                            <div className="d-flex flex-column">
                                <div className="f-20">{currentApp.name}
                                    <ButtonDropdown isOpen={dropdownOpen} toggle={this.toggleDropDown} size="sm" >
                                        <DropdownToggle className="px-2 btn btn-link " nav>
                                            <span className="ml-2 btn btn-outline-primary f-5 px-1 py-2"><i className="icon-menu-dots"/></span>
                                        </DropdownToggle>
                                        <DropdownMenu>
                                            <DropdownItem onClick={this.openEditAppModal}>Configure application</DropdownItem>
                                            <DropdownItem onClick={this.updateMetadata}>Update metadata</DropdownItem>
                                            <DropdownItem tag="a" href={url} target="_blank">Preview application</DropdownItem>
                                            <DropdownItem>Publish application</DropdownItem>
                                            <DropdownItem onClick={this.openDeleteAppModal}>Delete application</DropdownItem>
                                        </DropdownMenu>
                                    </ButtonDropdown>
                                </div>
                                <div className="f-12 app-info">{"Created on "+ currentApp.createdAt}</div>
                                <p className="f-14 mt-3 mb-0">{currentApp.description}</p>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="d-flex flex-column">
                                <label className="f-12 mb-2">RVAI access URL</label>
                                <p className="f-14 mb-4">{currentApp.dataSource}</p>
                                <label className="f-12 mb-2">Timezone</label>
                                <p className="f-14 mb-0">{currentApp.timeZone}</p>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="d-flex flex-column">
                                <label className="f-12 mb-2">Last modified on</label>
                                <p className="f-14 mb-4">{currentApp.updatedAt}</p>
                            </div>
                        </div>
                    </div>
                    <DeleteModalPanel
                        modal={deleteModal}
                        closeDeleteModal={this.closeDeleteModal}
                        deleteContent={this.onDeleteApplication}
                        name={currentApp ? currentApp.name : 'Application'}
                        type='application'
                    />
                    <EditApp
                        editModal={editModal}
                        currentApp={currentApp}
                        closeEditModal={this.closeEditModal}
                        editApplication={editApplication}
                    />
                </div>
            );
        } else {
            return(
                <main className="preview-dashboard dashboard-container px-0">
                    <div className="d-flex h-100 justify-content-center">
                        <img className="d-flex align-self-center" src={LoadingImage} />
                    </div>
                </main>
            );
        }
    }
}

AppDetail.propTypes = {
    currentApp: PropTypes.object,
    selectedAppAction: PropTypes.func,
    deleteApplication: PropTypes.func,
    editApplication: PropTypes.func,
    setMessage: PropTypes.func,
    updateApplicationMetadata: PropTypes.func
};

export default AppDetail;
