import thumbnail from '../counterWidget/thumbnail';
import configuration from '../counterWidget/addConfiguration';
import editConfiguration from '../counterWidget/editConfiguration';
import widget from '../counterWidget/dashboardWidgetContainer';

const counterWidget = {
    type : "COUNTER",
    thumbnail : thumbnail,
    configuration: configuration,
    editConfiguration: editConfiguration,
    widget : widget
};

export default counterWidget;