import React, {Component} from "react";
import HomeContainer from "../containers/homeContainer.js";

class IndexComponent extends Component{
    constructor(props){
        super(props);
    }

    render(){
        return(
            <React.Fragment>
                <HomeContainer />
            </React.Fragment>
        );
    }
}

export default IndexComponent;