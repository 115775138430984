"use strict";
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Header from '../common/header';
import Applications from './applications';
import CreateApp from './createApp';
import NoAppComponent from './noApp';
import Alert from '../common/custom-alert';

export default class Home extends Component {

    constructor() {
        super();
        this.state = {
            createAppModalOpen : false,
            message : ''
        }
    }
    
    showCreateAppModal = () => {
        this.setState({
            createAppModalOpen : true
        });
    }

    closeCreateAppModal = () => {
        this.setState({
            createAppModalOpen : false
        })
    }

    componentDidMount() {
        this.props.getApplications().catch((error) => {
            this.setState({message : error});
        });
    }

    render() {
        const { application, createApplication, testConnection } = this.props;
        const { createAppModalOpen, message } = this.state;
        let {appList} = application;
        let html = appList && appList.length > 0 
            ? <Applications appList={appList} showCreateAppModal={this.showCreateAppModal} /> 
            : <NoAppComponent showCreateAppModal={this.showCreateAppModal}/>;
        return (
            <React.Fragment>
                <Header />
                <main className="dashboard-container pl-0">
                    <div className="container-fluid h-100 overflow-auto">
                        <div className="row py-3 align-items-center justify-content-center">
                            <Alert color="danger">{message}</Alert>
                        </div>
                        <div className="row h-100 py-3 align-items-center justify-content-center">
                            {html}
                        </div>
                    </div>
                    <CreateApp
                        modal={createAppModalOpen}
                        createApplication={createApplication}
                        closeCreateAppModal={this.closeCreateAppModal}
                        testConnection={testConnection}
                    />
                </main>
            </React.Fragment>
        );
    }
}

Home.propTypes = {
    application: PropTypes.object,
    createApplication: PropTypes.func,
    getApplications: PropTypes.func,
    testConnection : PropTypes.func
}