import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalHeader, Button, ModalFooter } from 'reactstrap';
import { ViewType, WIDGET_TYPE } from './constants';
import { changeRuntimeParameterInWidgetAction } from '../../../store/actions/dashboardActions'
import ConfigurationForm from "./configurationBody";
import { generateMetadata, validateForm } from './utils';
import { connect } from 'react-redux';

class EditRuntimeParametersConfigurationModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            label: "",
            description: "",
            viewType: ViewType.GRIDVIEW,
            message: '',
            testFlag: false,
            selectedParameter: ''
        }
    }

    UNSAFE_componentWillMount() {
        const { widgetConfigDataForEdit } = this.props;
        this.setStateFunc(widgetConfigDataForEdit)
    }

    setStateFunc = (widgetConfigDataForEdit) => {
        this.setState({
            label: widgetConfigDataForEdit.name,
            description: widgetConfigDataForEdit.description,
            viewType: widgetConfigDataForEdit.config.viewType,
            iFrameUrl: widgetConfigDataForEdit.config.iFrameUrl,
        })
    }

    closeModal = () => {
        this.setState({
            label: "",
            description: "",
            viewType: ViewType.GRIDVIEW,
            iFrameUrl: '',
            message: '',
            testFlag: false
        }, () => {
            this.props.closeModal();
        });

    }


    changeRuntimeParameter = (e) => {
        this.props.changeRuntimeParameter({
            value: e.target.value,
            id: this.props.widgetConfigDataForEdit.id
        })
    }

    fieldUpdateHandler = (event) => {
        this.setState({ [event.target.name]: event.target.value });
    }

    radioButtonChangeHandler = (name, value) => {
        this.setState({ [name]: value });
    }

    queryTypeRadioButtonChangeHandler = (name, value) => {
        this.setState({
            [name]: value,
            testFlag: false
        });
    }

    submitHandler = () => {
        const { label, description, viewType } = this.state;
        const { widgetConfigDataForEdit } = this.props;
        let messages = validateForm(label, widgetConfigDataForEdit.config.selectedParameter);
        if (messages.length === 0) {
            let customConfig = {
                viewType,
                runtimeParameters: widgetConfigDataForEdit.config.runtimeParameters,
                selectedParameter: widgetConfigDataForEdit.config.selectedParameter,
                parametersName: widgetConfigDataForEdit.config.parametersName

            };
            let widgetMetaData = generateMetadata(widgetConfigDataForEdit.id, label, description,
                widgetConfigDataForEdit.height, widgetConfigDataForEdit.width, widgetConfigDataForEdit.xCoordinate,
                widgetConfigDataForEdit.yCoordinate, widgetConfigDataForEdit.minWidth, widgetConfigDataForEdit.maxWidth, widgetConfigDataForEdit.minHeight, widgetConfigDataForEdit.maxHeight, WIDGET_TYPE, customConfig)
            this.props.updateWidget(widgetMetaData).then(() => {
                this.closeModal();
            })
        } else {
            this.setMessage(messages);
        }
    }

    showWidgetSelectionPage = () => {
        this.setState({
            label: "",
            description: "",
            viewType: ViewType.GRIDVIEW,
            iFrameUrl: '',
            message: '',
            testFlag: false
        }, () => {
            this.props.showWidgetSelectionPage();
        })
    }

    setMessage = (messages) => {
        this.setState({ message: messages.join(', ') }, () => {
            setTimeout(() => {
                this.setState({ message: '' });
            }, 5000);
        })
    }

    setTestFlagTrue = () => {
        this.setState({
            testFlag: true
        })
    }

    render() {
        let { label, description, viewType, testFlag, message } = this.state;
        let { modal, timeZone, applicationId, widgetCreationMetadata, widgetConfigDataForEdit } = this.props;
        let metadata = {
            name: label, description, type: WIDGET_TYPE, config: {
                viewType,
                selectedParameter: widgetConfigDataForEdit.config.selectedParameter,
                parametersName: widgetConfigDataForEdit.config.parametersName,
                runtimeParameters: widgetConfigDataForEdit.config.runtimeParameters
            }
        };
        return (
            <Modal className="modal-dialog-centered" size="lg" isOpen={modal} backdrop="static">
                <ModalHeader toggle={this.closeModal} charCode="×">
                    <span>Configure widget</span>
                </ModalHeader>
                <ConfigurationForm
                    metadata={metadata}
                    message={message}
                    widgetCreationMetadata={widgetCreationMetadata}
                    timeZone={timeZone}
                    fieldUpdateHandler={this.fieldUpdateHandler}
                    radioButtonChangeHandler={this.radioButtonChangeHandler}
                    setMessage={this.setMessage}
                    setTestFlagTrue={this.setTestFlagTrue}
                    testFlag={testFlag}
                    applicationId={applicationId}
                    changeRuntimeParameter={this.changeRuntimeParameter}
                />
                <ModalFooter className="align-content-center">
                    <Button color="primary" onClick={this.submitHandler}>Update</Button>
                </ModalFooter>
            </Modal>
        );
    }
}

EditRuntimeParametersConfigurationModal.propTypes = {
    widgetCreationMetadata: PropTypes.object,
    applicationId: PropTypes.string,
    timeZone: PropTypes.string,
    modal: PropTypes.bool,
    iFrameUrl: PropTypes.string,
    widgetConfigDataForEdit: PropTypes.object,
    showWidgetSelectionPage: PropTypes.func,
    closeModal: PropTypes.func,
    updateWidget: PropTypes.func,
    changeRuntimeParameter: PropTypes.func
};

const mapDispatch = dispatch => ({
    changeRuntimeParameter: payload => dispatch(changeRuntimeParameterInWidgetAction(payload))
})

const mapState = state => ({
    dashboard: state.dashboard 
})

export default connect(mapState, mapDispatch)(EditRuntimeParametersConfigurationModal)