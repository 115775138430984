import { CHANGE_THEME } from '../constants/actionTypes';

const initState = {
    theme: localStorage.getItem('theme')
}

const uiReducer = (state = initState, action) => {
    switch(action.type) {
        case CHANGE_THEME: return {
            ...state,
            theme: action.payload
        }
        default: return {
            ...state
        }
    }
};

export default uiReducer;