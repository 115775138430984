import React , {Component} from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import ReactTable from 'react-table';

const sort = function(a, b) {
    a = new Date(a.props.title)
    b = new Date(b.props.title)
    if(a > b) return 1;
    else if(a < b) return -1;
    return 0;
};

class Applications extends Component{
    render(){
        const {appList,showCreateAppModal} = this.props;
        let unpublishedDashboardCounter = appList.filter(x => x.status === "NEW" || x.status === "INACTIVE").length;
        let columns = [
            {
                Header:"Application name",
                accessor: p => <p><Link to={'/applications/'+p.id}>{p.name}</Link></p>,
                id:'name',
                headerClassName: 'hdrClsLeft'
            },
            {
                Header:"Application TimeZone",
                accessor: p => <p>{p.timeZone}</p>,
                id:'timeZone',
                headerClassName: 'hdrCls'
            },
            {
                Header:"Created on",
                accessor: p => <p title={p.createdAt}>{p.createdAt}</p>,
                id:'createdAt',
                sortMethod: (a, b) => {
                    sort(a, b);
                },
                headerClassName: 'hdrCls'
            },
            {
                Header:"Last modified on",
                accessor: p => <p title={p.updatedAt}>{p.updatedAt}</p>,
                id:'updatedAt',
                sortMethod: (a, b) => {
                    sort(a, b);
                },
                headerClassName: 'hdrClsRight'
            }
        ]
        return (
            <div className="container-fluid h-100">
                <div className="row h-100 py-3">
                    <div className="col">
                        <h2 className="mt-1 mb-3 font-weight-bold">All Applications</h2>
                        <div className="card border-0">
                            <div className="row metric-row">
                            <div className="col-md-6 text-center border-right">
                                <div className="d-inline-flex align-items-center">
                                <div className="text-right mr-3 f-36 text-primary">{appList.length}</div>
                                <div className="text-left">
                                    <h5 className="m-0">Applications<br/>Created</h5>
                                </div>
                                </div>
                            </div>
                            <div className="col-md-6 text-center border-right">
                                <div className="d-inline-flex align-items-center">
                                <div className="text-right mr-3 f-36 text-primary">{appList.length - unpublishedDashboardCounter}</div>
                                <div className="text-left">
                                    <h5 className="m-0">Published<br/>Applications</h5>
                                </div>
                                </div>
                            </div>
                            <div className="col-md-6 text-center">
                                <div className="d-inline-flex align-items-center">
                                <div className="text-right mr-3 f-36 text-primary">{unpublishedDashboardCounter}</div>
                                <div className="text-left">
                                    <h5 className="m-0">Unpublished<br/>Applications</h5>
                                </div>
                                </div>
                            </div>
                            <div className="ml-auto d-flex align-items-center">
                                <button onClick={showCreateAppModal} title="Add Application" className="btn btn-primary shadow px-3 py-2 f-18">
                                    <i className="icon-add-app mr-2"/>
                                    <span className="f-14 font-weight-normal">Add Application</span>
                                </button>
                            </div>
                            </div>
                        </div>
                        <div className="application-list my-3">
                            <ReactTable
                                data={appList}
                                columns={columns}
                                defaultPageSize={5}
                                defaultSorted={[
                                    {
                                        id: "createdAt",
                                        desc:true
                                    }
                                ]}
                                className="-striped -highlight"
                            />
                        </div>
                    </div>
                </div>
            </div>

        );
    }
}

export default Applications;

Applications.propTypes = {
    appList : PropTypes.array,
    showCreateAppModal : PropTypes.func
};
