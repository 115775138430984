"use strict";
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Header from '../common/header';
import DashboardsDetailList from './dashboardsLists';
import CreateDashboard from './createDashboard';
import AppDetail from './appDetail';
import ApplicationHeader from './applicationHeader';
import Alert from '../common/custom-alert';

export default class Application extends Component {

    constructor() {
        super();
        this.state = {
            modal: false,
            message: '',
            color: '',
        }
    }

    showCreateDashboardModal = () => {
        this.setState({
            modal: true
        });
    }

    closeModal = () => {
        this.setState({
            modal: false
        })
    }

    componentDidMount() {
        this.props.getApplicationDetail(this.props.applicationId).catch((error) => {
            this.setState({ message: error, color: 'danger' });
        });
    }

    resetMessage = () => {
        setTimeout(() => {
            this.setState({ message: '', color: '' })
        }, 5000);
    }

    setMessage = (message, color) => {
        this.setState({ message, color }, this.resetMessage);
    }
    render() {
        const { application, applicationId, deleteApplication, editApplication, createDashboard,
            updateApplicationMetadata } = this.props;
        const { currentApp } = application;
        const { layouts } = currentApp ? currentApp : {}
        const { modal, message, color } = this.state;
        return (
            <React.Fragment>
                <Header currentApp={currentApp} />
                <main className="dashboard-container pl-0 overflow-auto">
                    <div className="container-fluid h-100">
                        <div className="container-fluid h-100 d-flex flex-column">
                            <div className="row py-3 flex-shrink-0">
                                <div className="col">
                                    <h2 className="mt-1 mb-3 font-weight-light f-14">
                                        <Link to={'/'} className="link"><i className="icon-sort" />Back to all applications</Link>
                                    </h2>
                                    <Alert color={color}>{message}</Alert>
                                    <ApplicationHeader currentApp={currentApp} showCreateDashboardModal={this.showCreateDashboardModal} />
                                    <AppDetail
                                        currentApp={currentApp}
                                        deleteApplication={deleteApplication}
                                        editApplication={editApplication}
                                        setMessage={this.setMessage}
                                        updateApplicationMetadata={updateApplicationMetadata}
                                    />
                                    <DashboardsDetailList currentApp={currentApp} showCreateDashboardModal={this.showCreateDashboardModal} />
                                    <CreateDashboard modal={modal}
                                        closeModal={this.closeModal}
                                        createDashboard={createDashboard}
                                        applicationId={applicationId}
                                        layouts={layouts}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </React.Fragment>
        );
    }
}

Application.propTypes = {
    application: PropTypes.object,
    applicationId: PropTypes.string,
    getApplicationDetail: PropTypes.func,
    deleteApplication: PropTypes.func,
    createDashboard: PropTypes.func,
    editApplication: PropTypes.func,
    updateApplicationMetadata: PropTypes.func
}
