import React from 'react';
import PropTypes from 'prop-types';

const ApplicationHeader = ({currentApp,showCreateDashboardModal}) => {
    return(
        <div className="my-4">
            <div className="d-flex ml-auto align-items-center">
                <div className="ml-auto">
                    <button title="Add Dashboard" className="btn btn-primary shadow px-3 py-2 f-18" onClick={showCreateDashboardModal}>
                        <i className="icon-add-app mr-2"/>
                        <span className="f-14 font-weight-normal">Add Dashboard</span>
                    </button>
                </div>
            </div>
        </div>
    );
}

ApplicationHeader.propTypes = {
    currentApp: PropTypes.object,
    showCreateDashboardModal: PropTypes.func
};


export default ApplicationHeader;
