import React, {Component} from "react";
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import RoboVisionLogo from "../../../img/robovision-logo.svg";
import RoboVisionLogoDark from "../../../img/robovision-logo-dark.svg";
import HelpIcon from "../../../img/help-icon.svg";
import {Link} from 'react-router-dom';
import { toggleTheme, getTheme } from '../../utils/commonUtils';
import { changeThemeAction } from '../../store/actions/uiActions';
import { DASHBOARD_HELP_URL } from './constants';

const HeaderWithAppNameAndDashboardName = ({currentApp, dashboardName, changeTheme}) => {
    return (
        <header>
            <nav className="navbar navbar-expand-md navbar-light bg-light">
                <Link to="/" className="navbar-brand">
                    <img className='logo-img theme-change' 
                        src={getTheme() === 'dark' ? RoboVisionLogo : RoboVisionLogoDark} 
                        alt="Robovision" 
                        width=""/>
                </Link>
                <button type="button" className="navbar-toggler">
                    <span className="navbar-toggler-icon"/>
                </button>
                <div id="navbarText" className="collapse navbar-collapse">
                    <ol className="breadcrumb p-0 mb-0 ml-3 mr-auto">
                        <li className="breadcrumb-item">
                            <Link to={`/applications/${currentApp.id}`}>{currentApp.name}</Link>
                        </li>
                        <li className="breadcrumb-item active">
                            <a>{dashboardName}</a>
                        </li>
                    </ol>
                </div>
                <button className='theme-toggler' onClick={() => {toggleTheme(changeTheme)}}>
                    {getTheme() === 'dark' ? 'Light' : 'Dark'}
                </button>
                <a href={DASHBOARD_HELP_URL} target="_blank"  rel="noopener noreferrer">
                    <img src={HelpIcon} alt="Help" width=""/>
                </a>
            </nav>
        </header>
    );
};

HeaderWithAppNameAndDashboardName.propTypes = {
    currentApp: PropTypes.object,
    dashboardName: PropTypes.string,
    dropdownOpen: PropTypes.bool,
    toggle: PropTypes.func,
    changeTheme: PropTypes.func
};

HeaderWithAppNameAndDashboardName.defaultProps = {
    changeName: () => {}
}

const HeaderWithAppName = ({currentApp, changeTheme}) => {
    return(
        <header>
            <nav className="navbar navbar-expand-md navbar-light bg-light">
                <Link to="/" className="navbar-brand">
                    <img className='logo-img theme-change' 
                        src={getTheme() === 'dark' ? RoboVisionLogo : RoboVisionLogoDark}
                        alt="Robovision" 
                        width=""/>
                </Link>
                <button type="button" className="navbar-toggler">
                    <span className="navbar-toggler-icon"/>
                </button>
                <div id="navbarText" className="collapse navbar-collapse">
                    <ol className="breadcrumb p-0 mb-0 ml-3 mr-auto">
                        <li className="breadcrumb-item active">
                            <a>{currentApp.name}</a>
                        </li>
                    </ol>
                </div>
                <button className='theme-toggler' onClick={() => {toggleTheme(changeTheme)}}>
                    {getTheme() === 'dark' ? 'Light' : 'Dark'}
                </button>
                <a href={DASHBOARD_HELP_URL} target="_blank"  rel="noopener noreferrer">
                    <img src={HelpIcon} alt="Help" width=""/>
                </a>
            </nav>
        </header>
    );
};

HeaderWithAppName.propTypes = {
    currentApp: PropTypes.object,
    dropdownOpen: PropTypes.bool,
    toggle: PropTypes.func,
    changeTheme: PropTypes.func
};

HeaderWithAppName.defaultProps = {
    changeName: () => {}
}

const DefaultHeader = ({ changeTheme }) => {
    return (
        <header>
            <nav className="navbar navbar-expand-md navbar-light bg-light">
                <Link to="/" className="navbar-brand">
                    <img className='logo-img theme-change' 
                        src={getTheme() === 'dark' ? RoboVisionLogo : RoboVisionLogoDark} 
                        alt="Robovision" 
                        width=""/>
                </Link>
                <button type="button" className="navbar-toggler">
                    <span className="navbar-toggler-icon"/>
                </button>
                <div id="navbarText" className="collapse navbar-collapse">
                </div>
                <button className='theme-toggler' onClick={() => {toggleTheme(changeTheme)}}>
                    {getTheme() === 'dark' ? 'Light' : 'Dark'}
                </button>
                <a href={DASHBOARD_HELP_URL} target="_blank"  rel="noopener noreferrer">
                    <img src={HelpIcon} alt="Help" width=""/>
                </a>
            </nav>
        </header>
    );
};

DefaultHeader.propTypes = {
    dropdownOpen: PropTypes.bool,
    toggle: PropTypes.func,
    changeTheme: PropTypes.func
};

DefaultHeader.defaultProps = {
    changeName: () => {}
}

class Header extends Component{
    constructor(props){
        super(props);
        this.state = {
        };
    }
    render(){
        const { currentApp, dashboardName, changeTheme } = this.props;
        if(currentApp && currentApp.name && dashboardName) {
            return(
                <HeaderWithAppNameAndDashboardName
                    currentApp={currentApp}
                    dashboardName={dashboardName}
                    changeTheme={changeTheme}
                />
            );
        } else if (currentApp && currentApp.name && !dashboardName) {
            return(
                <HeaderWithAppName changeTheme={changeTheme} currentApp={currentApp}/>
            );
        } else {
            return(
                <DefaultHeader changeTheme={changeTheme}/>
            );
        }
    }
}

Header.propTypes = {
    currentApp: PropTypes.object,
    dashboardName: PropTypes.string,
    changeTheme: PropTypes.func
};

const mapDispatchToProps = (dispatch) => ({
    changeTheme: (theme) => dispatch(changeThemeAction(theme))
})

const mapStateToProps = (state) => ({
    theme: state.ui.theme
})

export default connect(mapStateToProps, mapDispatchToProps)(Header);
