import React from 'react';
import PropTypes from 'prop-types';
import thumbnailImage from "../../../../img/widget-thumbnails/iframe.svg";

const RuntimeParametersThumbnail = ({clickHandler}) => {
    return (
        <div className="card cursor-pointer card-shadow">
            <div className="card-body text-center" onClick={clickHandler}>
                <h5 className="card-title">Runtime parameters</h5>
                <div className="img-wrapper f-42"><img src={thumbnailImage} alt="IFrame"/></div>
                <p className="card-text">Display the runtime parameters</p>
            </div>
        </div>
    );
}

RuntimeParametersThumbnail.propTypes = {
    clickHandler: PropTypes.func
}

export default RuntimeParametersThumbnail;