exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(false);
// imports
exports.i(require("-!../../node_modules/css-loader/index.js!./site.css"), "");
exports.i(require("-!../../node_modules/css-loader/index.js!./grid-item.css"), "");
exports.i(require("-!../../node_modules/css-loader/index.js!./robovision.css"), "");

// module
exports.push([module.id, "/*@import \"grid-layout.css\";*/\n/* \nLight colors:\nbackground [\nprimary-first: #fff\nprimary-second: rgba(0, 0, 0, 0.1);\nsecondary-first: #F8F8F8\nsecondary-second: #303e40\nsecondary-third: #535353;\naccent-first: #657e82\naccent-second: #4E6366\n]\ntext [\nprimary-first: #303E40\nprimary-second: #fff\nprimary-third: #4f5051\nsecondary-first: #C3C8CA\nsecondary-second: rgba(0, 0, 0, 0.6);\n]\n\nDark colors:\nbackground [\n--color-primary-first: #000000;\n--color-primary-second: rgba(255, 255, 255, 0.1);\n--color-primary-third: #949fa1;\n--color-secondary-first: #131313;\n--color-secondary-second: #cfc1bf;\n--color-secondary-third: #c6c6c6;\n--color-secondary-fourth: rgba(255, 255, 255, 0.6);\n--color-accent-first: #f68f3d;\n--color-accent-second: #e1e1e1;\n--color-accent-third: #303e40;\n*/\n", ""]);

// exports
