export const validateQuery = (customQuery) =>{
    let messages = [];
    if(customQuery.from === ""){
        messages.push("Please select dimension");
    }
    if(customQuery.select.length <= 0 ){
        messages.push("Please construct select query");
        return messages;
    }
    if(customQuery.select.findIndex(x => x.field !== "") === -1){
        messages.push("Please select field in the select query");
        return messages;
    }
    let aliasList = [], count = 0;
    let select = JSON.parse(JSON.stringify(customQuery.select));
    for(let i=0; i < select.length; i++){
        if(select[i].alias !== "") {
            if(aliasList.indexOf(select[i].alias) === - 1){
                aliasList.push(select[i].alias);
            } else { count++; }
        }
    }
    if(count > 0){
        messages.push("Duplicate aliases are present in the query");
    }
    return messages;
}

export const validateForm = (label, iFrameUrl) => {
    let messages = [];
    if(!label) messages.push('Label is mandatory');
    if(!iFrameUrl) messages.push('iFrame Url is mandatory and greater than zero');
    return messages;
}

export const generateMetadata = (id,name,description,height,width,xCoordinate,yCoordinate,minWidth,maxWidth,minHeight,maxHeight,type,config) =>{
    return {
        id: id,
        name : name,
        description: description,
        height: height,
        width : width,
        xCoordinate: xCoordinate,
        yCoordinate: yCoordinate,
        minWidth: minWidth,
        maxWidth: maxWidth,
        minHeight: minHeight,
        maxHeight: maxHeight,
        type: type,
        config : config
    };
}

export const getSelectData = (id, selectField,selectAlias,isDistinct) =>{
    let obj ={
        id: id,
        field: selectField,
        alias: selectAlias,
        operations: ["COUNT"]
    };
    if(isDistinct){
        obj.operations.push("DISTINCT")
    }
    return obj;
}