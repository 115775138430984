import React from "react";
import { ModalBody, Form, FormGroup, Label, Input, InputGroup, InputGroupAddon, InputGroupText } from 'reactstrap';
import PropTypes from "prop-types";
import PreviewWidget from './previewWidgetContainer';
import Alert from '../../common/custom-alert';
import QueryBuilder from './queryBuilder';
import {QueryType} from '../../common/constants';
const ConfigForm = ({
    message,
    label,
    fieldUpdateHandler,
    description,
    metadata,
    widgetCreationMetadata,
    testFlag,
    setTestFlagTrue,
    ownQueryUpdateHandler,
    setMessage,
    queryTypeRadioButtonChangeHandler,
    applicationId,
    dashboardId,
    updateQuery,
    resetQuery,
    timeZone
}) => {
    let {config} = metadata;
    return(
        <ModalBody className="py-0">
            <div className="row">
                <div className="col-md-10 pl-5 py-2 d-flex flex-column h-100" style={{maxHeight:"70vh", minHeight:"70vh"}}>
                    <div className="preview-header border-bottom py-3 mr-5 mb-3">
                        <i className="icon-table mr-2"/>Table
                    </div>
                    <Alert color="danger">{message}</Alert>
                    <Form className="flex-shrink-1 overflow-auto pr-5">
                        <FormGroup>
                            <Label for="table-widget-label">Widget label</Label>
                            <Input maxLength={30} required type="text" name="label" id="table-widget-label"
                                placeholder="Enter the widget label here"
                                value={label} onChange={fieldUpdateHandler}
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label for="table-widget-description">Widget description</Label>
                            <Input maxLength={150} required type="text" name="description" id="table-widget-description"
                                placeholder="Enter the widget description here"
                                value={description} onChange={fieldUpdateHandler}
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label for="">Polling Time</Label>
                            <InputGroup>
                                <Input placeholder="Polling Time" name="pollingTime" type="number"
                                    min="1" step="1" defaultValue={config.pollingTime} onChange={fieldUpdateHandler}
                                />
                                <InputGroupAddon addonType="append">
                                    <InputGroupText className="f-14">
                                        seconds
                                    </InputGroupText>
                                </InputGroupAddon>
                            </InputGroup>
                        </FormGroup>
                        <div className="mb-3 mt-4 border-top"/>
                        <FormGroup>
                            <Label for="">Query Builder type</Label>
                            <div className="row">
                                <div className="col">
                                    <div className="custom-control custom-radio custom-control-inline"
                                        onClick={queryTypeRadioButtonChangeHandler.bind(this, "queryType", QueryType.CUSTOMQUERY)} >
                                        <Input type="radio" name="queryType" className="custom-control-input"
                                            checked={config.queryType === QueryType.CUSTOMQUERY} readOnly/>
                                        <Label className="custom-control-label">Use query builder</Label>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="custom-control custom-radio custom-control-inline"
                                        onClick={queryTypeRadioButtonChangeHandler.bind(this, "queryType", QueryType.QUERY)}>
                                        <Input type="radio" name="queryType" className="custom-control-input"
                                            checked={config.queryType === QueryType.QUERY} readOnly/>
                                        <Label className="custom-control-label">Write query</Label>
                                    </div>
                                </div>
                            </div>
                        </FormGroup>
                        <FormGroup>
                            { config.queryType === QueryType.QUERY?
                                <textarea className="form-control w-100" name="ownQuery" rows="5" value={config.query}
                                    placeholder="Enter Your Query here" onChange={ownQueryUpdateHandler}>
                                </textarea>
                                :
                                <React.Fragment>
                                    <QueryBuilder
                                        widgetCreationMetadata={widgetCreationMetadata}
                                        config={config.query}
                                        setMessage={setMessage}
                                        updateQuery={updateQuery}
                                        resetQuery={resetQuery}
                                    />
                                </React.Fragment>
                            }
                            <button type="button" className="btn btn-primary mt-1" onClick={setTestFlagTrue}>Test</button>
                        </FormGroup>
                    </Form>                    
                </div>
                <div className="col-md-14 preview-bg">
                    <div className="row justify-content-center h-100">
                        <div className="col col-md-14 d-flex align-items-center justify-content-center" style={{height: "80%"}}>
                            <PreviewWidget
                                metadata={metadata}
                                applicationId={applicationId}
                                dashboardId={dashboardId}
                                testFlag={testFlag}
                                timeZone={timeZone}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </ModalBody>
    );
};

ConfigForm.propTypes = {
    testFlag: PropTypes.bool,
    label: PropTypes.string,
    applicationId: PropTypes.string,
    dashboardId: PropTypes.string,
    message: PropTypes.string,
    description: PropTypes.string,
    timeZone: PropTypes.string,
    metadata: PropTypes.object,
    widgetCreationMetadata: PropTypes.object,
    fieldUpdateHandler: PropTypes.func,
    setTestFlagTrue: PropTypes.func,
    ownQueryUpdateHandler: PropTypes.func,
    setMessage: PropTypes.func,
    queryTypeRadioButtonChangeHandler: PropTypes.func,
    updateQuery: PropTypes.func,
    resetQuery: PropTypes.func
}

export default ConfigForm;