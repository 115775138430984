import React, {Component} from 'react';
import PropTypes from 'prop-types';

class LazyImage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loaded: false,
            error: false
        };
    }

    componentDidMount() {
        this.loadImage(this.props.src, this.props.errorHandler);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if(nextProps.src !== this.props.src) {
            this.setState({
                loaded: false
            }, () => {
                this.loadImage(nextProps.src, nextProps.errorHandler);
            })
        }
    }

    loadImage = (src, errorHandler) => {
        const img = new Image();
        img.onload = () => {
            this.setState({
                loaded: true,
                error: false
            });
        };
        img.onerror = () => {
            this.setState({
                loaded: false,
                error: true
            }, ()=> {
                if(errorHandler) errorHandler()
            });
        };
        img.src = src;
    }

    render() {
        let {id, className, alt, style, src, loadingImage, errorImage} = this.props;
        let {error, loaded} = this.state;
        if (error) {
            return <img className={className} style={style} src={errorImage} alt="Image not available" />
        } else if (!loaded) {
            return (
                <div className="d-flex h-100 justify-content-center">
                    <img className="d-flex align-self-center" src={loadingImage} alt={alt} />
                </div>
            );
        }
        return <img id={id} className={className} style={style} src={src} alt={alt} />
    }
}

LazyImage.propTypes = {
    id: PropTypes.string,
    className: PropTypes.string,
    style: PropTypes.string,
    loadingImage: PropTypes.string,
    errorImage: PropTypes.string,
    src: PropTypes.string,
    alt: PropTypes.string,
    errorHandler: PropTypes.func
}

export default LazyImage;