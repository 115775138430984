import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Widget from './widget';

class DashboardWidget extends Component {

    submitHandler = (filterObj, widgetState) =>{
        this.props.addFilterForData(filterObj, widgetState)
    }

    clearFilters = () => {
        const {clearFilters} = this.props;
        clearFilters("startDateTime")
            .then(clearFilters("stopDateTime"))
    }

    render() {
        const {metadata,deleteWidget,getWidgetConfigData,editDeleteFlag} = this.props;
        return(
            <React.Fragment>
                <Widget 
                    editDeleteFlag={editDeleteFlag}
                    getWidgetConfigData={getWidgetConfigData}
                    deleteWidget={deleteWidget}
                    metadata={metadata} 
                    submitHandler={this.submitHandler}
                    clearFilters={this.clearFilters}
                />
            </React.Fragment>
        );
    }
}
DashboardWidget.propTypes = {
    editDeleteFlag: PropTypes.bool,
    metadata: PropTypes.object,
    addFilterForData: PropTypes.func,
    deleteWidget: PropTypes.func,
    getWidgetConfigData: PropTypes.func,
    clearFilters: PropTypes.func
}

export default DashboardWidget;