import { SET_CAMERA_FEED_URL, REMOVE_CAMERA_FEED_URL } from '../constants/actionTypes';
import { fetchAccessToken } from '../../utils/commonUtils'

export const getCameraFeedUrl = serviceUrl => dispatch => {
    return new Promise((resolve, reject) => {
        fetchAccessToken()
            .then(token => {
                fetch(serviceUrl, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    }
                }).then(res => res.json()).then(response => {
                    if (response.status === "error") {
                        let errorMessage = response.errors.map(err => err.userMessage).join(',');
                        
                        reject(errorMessage);
                    } else {
                        if(response.error) {
                            dispatch({
                                type: REMOVE_CAMERA_FEED_URL,
                            })
                        } else {
                            dispatch({
                                type: SET_CAMERA_FEED_URL,
                                data: response,
                            // streamId
                            });
                        }   
                    }
                }).catch(error => {
                    
                    reject(error.message);
                })
            }).catch(error => {
                
                reject(error.message);
            })
    });
};
