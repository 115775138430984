import React, { Component } from 'react';
import PropTypes from 'prop-types';
import QueryBuilder from "react-querybuilder";
import { operators, IntOperators, BooleanOperators, StringOperators, IntOperatorsWithRange } from './constants';

const errorMessages = {
    VALUE_NOT_FLOAT : "Value is not a Float",
    VALUE_GREATER_THAN_MAX: "Value is greater than predefined maximum value required",
    VALUE_LESS_THAN_MIN: "Value is less than predefined minimum value required",
    MIN_EQUALS_MAX: "Minimum Value cannot be greater or equal to maximum value"
};

export default class CustomQueryBuilder extends Component {
    render() {
        const {fields,query} = this.props;
        let controlElements = {
            operatorSelector: this.customOperatorEditor(fields,operators,IntOperators,BooleanOperators,StringOperators,IntOperatorsWithRange),
            valueEditor: this.customValueEditor(fields)
        }
        return (
            <div key={JSON.stringify(fields)} className="flex-box">
                <div className="scroll">
                    <QueryBuilder fields={fields}
                        controlElements={controlElements}
                        operators={operators}
                        query={query}
                        controlClassnames={{fields: 'form-control'}}
                        onQueryChange={this.passQuery.bind(this)}
                    />
                </div>
            </div> 
        );
    }
    
    customOperatorEditor(fields,operators,IntOperators,BooleanOperators,StringOperators,IntOperatorsWithRange){
        return class CustomOperatorEditor extends Component{
            constructor(props) {
                super(props);
                this.state = {
                    fieldName : ""
                }
            }

            UNSAFE_componentWillMount = () => {
                this.setState({
                    fieldName: this.props.field 
                })
            }

            getFirstOperator = () => {
                let field = fields.find(x=>x.name === this.props.field)
                if(field && field.type){
                    if(field.type.toLowerCase() === "string"){
                        return StringOperators[0].name;
                    }
                    else if(field.type.toLowerCase() === "integer" || field.type.toLowerCase() === "float"){
                        if((field.min !== null && field.min !==undefined)&&
                            (field.max !== null && field.max !== undefined)){
                            return IntOperatorsWithRange[0].name;
                        }
                        else{
                            return IntOperators[0].name;
                        }
                    }
                    else if(field.type.toLowerCase() === "boolean"){
                        return BooleanOperators[0].name;
                    }
                    else{
                        return operators[0].name;
                    }
                }
                return null;
            }

            UNSAFE_componentWillReceiveProps = (nextProps) => {
                const {fieldName} = this.state;
                if(fieldName !== nextProps.field){
                    this.setState({
                        fieldName : nextProps.field
                    }, () => {
                        let operator = this.getFirstOperator();
                        this.props.handleOnChange(operator)
                    })
                }
            }
        
            getOptionsHTML = (data) =>{
                let resultdata = [];
                data.map((obj,index) =>{
                    resultdata.push(<option key={index} value={obj.name}>{obj.label}</option>)
                })
                return <React.Fragment>{resultdata}</React.Fragment>
            }
        
            getOperatorsOptions = (field) => {
                if(field.type) {
                    if(field.type.toLowerCase() === "string"){
                        return <React.Fragment>{this.getOptionsHTML(StringOperators)}</React.Fragment>
                    }
                    else if(field.type.toLowerCase() === "integer" || field.type.toLowerCase() === "float"){
                        if((field.min !== null && field.min !==undefined)&& (field.max !== null && field.max !== undefined)){
                            return <React.Fragment>{this.getOptionsHTML(IntOperatorsWithRange)}</React.Fragment>
                        }
                        else{
                            return <React.Fragment>{this.getOptionsHTML(IntOperators)}</React.Fragment>
                        }
                    }
                    else if(field.type.toLowerCase() === "boolean"){
                        return <React.Fragment>{this.getOptionsHTML(BooleanOperators)}</React.Fragment>
                    }
                    else{
                        return <React.Fragment>{this.getOptionsHTML(operators)}</React.Fragment>
                    }
                } else {
                    return <React.Fragment>{this.getOptionsHTML(operators)}</React.Fragment>
                }
            }
        
            render(){
                let field = fields.find(x=>x.name === this.props.field)
                if(field){
                    return <select className="rule-operators form-control" title="Operators" 
                        value={this.props.value} onChange={e => this.props.handleOnChange(e.target.value)}>
                        {this.getOperatorsOptions(field)}</select>
                }
                else{
                    return null;
                }
            }
        }
    }

    passMessage = (value) =>{
        const {setMessage} = this.props;
        setMessage([value]);
    }
    
    customValueEditor(fields) {
        let self = this;
        return class CustomValueEditor extends Component {
            constructor(props) {
                super(props);
                this.state={
                    range:{
                        min:0,
                        max:0
                    },
                    inNotInValue:"",
                    operator: "",
                    numericValue:"",
                    numericMinValue:"",
                    numericMaxValue:"",
                    fieldName : ""
                }
            }

            UNSAFE_componentWillMount = () => {
                let field = fields.find(x=>x.name === this.props.field)
                if(field && field.type && field.type.toLowerCase() === "boolean" && this.props.value === ""){
                    this.props.handleOnChange(false)
                }
                if(this.props.operator === "in" || this.props.operator === "notIn"){
                    this.setState({
                        operator : this.props.operator,
                        inNotInValue:this.props.value.join('|'),
                        fieldName: this.props.field 
                    })
                }
                else if(this.props.operator === "range"){
                    this.setState({
                        operator : this.props.operator,
                        numericMinValue:this.props.value.min,
                        numericMaxValue:this.props.value.max,
                        fieldName: this.props.field 
                    })
                }
                else if(["=", "!=", "<", ">", "<=", ">="].includes(this.props.operator)){
                    this.setState({
                        operator : this.props.operator,
                        numericValue:this.props.value,
                        fieldName: this.props.field 
                    })
                }
                else{
                    this.setState({
                        operator : this.props.operator,
                        fieldName: this.props.field 
                    })
                }
            }

            UNSAFE_componentWillReceiveProps = (nextProps) => {
                const {operator} = this.state;
                const {fieldName} = this.state;
                if(fieldName !== nextProps.field || operator !== nextProps.operator){
                    let field = fields.find(x=>x.name === nextProps.field)
                    if(field && field.type && field.type.toLowerCase() === "boolean"){
                        this.setState({
                            fieldName : nextProps.field,
                            operator : nextProps.operator
                        }, () => {
                            if(typeof(nextProps.value) !== "boolean"){
                                this.props.handleOnChange(false)
                            }
                        })
                    }
                    else if(field && nextProps.operator === "range"){
                        this.setState({
                            operator : nextProps.operator,
                            inNotInValue : "",
                            numericValue : "",
                            numericMinValue : field.min,
                            numericMaxValue : field.max,
                            fieldName : nextProps.field 
                        }, () => {
                            this.props.handleOnChange({
                                min:field.min,
                                max:field.max,
                            })
                        })
                    }
                    else{
                        this.setState({
                            operator : nextProps.operator,
                            inNotInValue:"",
                            numericValue:"",
                            numericMinValue:"",
                            numericMaxValue:"",
                            fieldName : nextProps.field
                        }, () => {
                            this.props.handleOnChange("")
                        })
                    }
                }
            }
        
            handleOnMinChange = (value,min,max,type) =>{
                let errorMessage;
                let val = type.toLowerCase() === 'float' ? parseFloat(value) : parseInt(value);
                if((type.toLowerCase() === 'float' && isNaN(val)) || (type.toLowerCase()!== 'float' && !Number.isInteger(val))){
                    errorMessage = errorMessages.VALUE_NOT_FLOAT;
                } else if(val > max){
                    errorMessage = errorMessages.VALUE_GREATER_THAN_MAX;
                } else if(val < min){
                    errorMessage = errorMessages.VALUE_LESS_THAN_MIN;
                } else if(val >= parseFloat(this.state.numericMaxValue)){
                    errorMessage = errorMessages.MIN_EQUALS_MAX;
                }
                if (errorMessage) {
                    this.setState({
                        numericMinValue: value
                    },() => {
                        self.passMessage(errorMessage)
                    })
                } else {
                    this.setState({
                        numericMinValue: value,
                        range:{
                            min:val,
                            max:this.state.numericMaxValue !== ""? parseFloat(this.state.numericMaxValue): max,
                        }
                    },() => {
                        this.props.handleOnChange(this.state.range)
                    })
                }
            }
        
            handleOnMaxChange = (value,min,max,type) =>{
                let errorMessage;
                let val = type.toLowerCase() === 'float' ? parseFloat(value) : parseInt(value);
                if((type.toLowerCase() === 'float' && isNaN(val)) || (type.toLowerCase()!== 'float' && !Number.isInteger(val))){
                    errorMessage = errorMessages.VALUE_NOT_FLOAT;
                } else if(val > max) {
                    errorMessage = errorMessages.VALUE_GREATER_THAN_MAX;
                } else if(val < min){
                    errorMessage = errorMessages.VALUE_LESS_THAN_MIN;
                } else if(val <= parseFloat(this.state.numericMinValue)){
                    errorMessage = errorMessages.MIN_EQUALS_MAX;
                }

                if(errorMessage) {
                    this.setState({
                        numericMaxValue: value
                    },() => {
                        self.passMessage(errorMessage);
                    })
                } else {
                    this.setState({
                        numericMaxValue: value,
                        range:{
                            min:this.state.numericMinValue !== ""? parseFloat(this.state.numericMinValue): min,
                            max:val
                        }
                    },() => {
                        this.props.handleOnChange(this.state.range)
                    })
                }            
            }

            handleOnInChange = (value) =>{
                let arrOfvalue = value.split('|');
                this.setState({
                    inNotInValue:value
                },() => {
                    this.props.handleOnChange(arrOfvalue)
                })
            }

            handleNumericChange = (value,type) =>{
                if(type.toLowerCase() === 'integer'){
                    if( !Number.isInteger(parseInt(value)) ){
                        this.setState({
                            numericValue: value
                        },() => {
                            self.passMessage("Value is not an Integer")
                            this.props.handleOnChange(value)
                        })
                    }
                    else{
                        this.setState({
                            numericValue: value
                        }, () => {
                            this.props.handleOnChange(parseInt(value))
                        })
                    }
                } else{
                    if( isNaN(parseFloat(value)) ){
                        this.setState({
                            numericValue: value
                        },() => {
                            self.passMessage(errorMessages.VALUE_NOT_FLOAT)
                            this.props.handleOnChange(value)
                        })
                    }
                    else{
                        this.setState({
                            numericValue: value
                        }, () => {
                            this.props.handleOnChange(parseFloat(value))
                        })
                    }
                }
            }

            valueOptions = (values) =>{
                let resultdata = [];
                values.map((obj,index) =>{
                    resultdata.push(<option key={index + 1} value={obj}>{obj}</option>)
                })
                return <React.Fragment>{resultdata}</React.Fragment>
            }
        
            handleStringField = () => {
                let field = fields.find(x=>x.name === this.props.field)
                const {inNotInValue} = this.state;
                if (this.props.operator === 'StartsWith' || this.props.operator === 'Contains' || this.props.operator === 'EndsWith') {
                    return (
                        <input type="text" className="form-control mt-1 w-auto"
                            value={this.props.value} placeholder="Enter Value"
                            onChange={e => this.props.handleOnChange(e.target.value)}
                        />
                    )
                }
                else if(this.props.operator === "=" || this.props.operator === "!="){
                    if(field.options){
                        return (
                            <select className="form-control mt-1 w-auto" value={this.props.value} title="ValueEditor" onChange={e => this.props.handleOnChange(e.target.value)}>
                                <option key={0} value="">Select an option</option>
                                {this.valueOptions(field.options)}
                            </select>
                        )
                    }
                    else{
                        return (
                            <input type="text" className="mt-1 w-auto form-control"
                                value={this.props.value} placeholder="Enter Value"
                                onChange={e => this.props.handleOnChange(e.target.value)}
                            />
                        )
                    }
                }
                else if(this.props.operator === "in" || this.props.operator === "notIn"){
                    return (
                        <input type="text" className="mt-1 w-auto form-control"
                            value={inNotInValue}
                            placeholder="e.g abc|def"
                            onChange={e => this.handleOnInChange(e.target.value)}/>
                    );
                }
                else{
                    return null
                }
            }

            handleBooleanField = () => {
                return (
                    <span>
                        <input type="checkbox" 
                            checked={this.props.value}
                            onChange={e => this.props.handleOnChange(e.target.checked)}/>
                    </span>
                );
            }

            handleRangeField = () => {
                let field = fields.find(x=>x.name === this.props.field)
                const {numericMinValue,numericMaxValue} = this.state;
                return (
                    <div className="d-flex pt-1">
                        <input type="number" className="form-control"
                            value={numericMinValue} placeholder="Enter Minimum Value"
                            onChange={e => this.handleOnMinChange(e.target.value,field.min,field.max,field.type)}/>
                        <span className='ml-1 mr-1'>-</span>
                        <input type="number" className="form-control"
                            value={numericMaxValue} placeholder="Enter Maximum Value"
                            onChange={e => this.handleOnMaxChange(e.target.value,field.min,field.max,field.type)}/>
                    </div>
                )
            }

            render() {
                let field = fields.find(x=>x.name === this.props.field)
                const {numericValue} = this.state;
                if (!field) return null;
             //   if(this.props.operator === 'null' || this.props.operator ==="notNull") return null;
                if(field.type.toLowerCase() === "string"){
                    return this.handleStringField(field);
                }
                if(field.type.toLowerCase() === "boolean") {
                    return this.handleBooleanField(field);
                }
                if(this.props.operator === 'range') {
                    return this.handleRangeField(field);
                }
                if(this.props.operator === "="|| this.props.operator === '!='||
                this.props.operator === '<'|| this.props.operator === '>'||
                this.props.operator === '<='||this.props.operator === '>=' || (field && field.type === 'Integer')) {
                    return (
                        <input type="number" className="mt-1 w-auto form-control"
                            value={numericValue} placeholder="Enter Value"
                            onChange={e => this.handleNumericChange(e.target.value,field.type)}/>
                    );
                }
                return null;
            }
        }
    }
    
    passQuery(query) {
        this.props.onQueryChange(query);
    }
  
}

CustomQueryBuilder.propTypes = {
    fields: PropTypes.array,
    query: PropTypes.object,
    field: PropTypes.string,
    operator: PropTypes.string,
    value: PropTypes.string,
    handleOnChange: PropTypes.func,
    onQueryChange: PropTypes.func,
    setMessage: PropTypes.func
}
