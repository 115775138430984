import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Label, Input, FormGroup } from 'reactstrap';
import SelectQuery from './selectQuery'
import CustomQueryBuilder from '../../common/whereQueryBuilder';
import GroupByQuery from '../../common/groupByQuery';
import {generateMeasurementList,generateFieldsAndTagsList,prePopulateStateData,getTagList} from '../../common/utils';
class QueryBuilder extends Component {

    constructor(props){
        super(props);
        this.state = {
            measurementList:[],
            fieldsAndTagList:[],
            fieldsList:[],
            showSelectAndWhere:false,
            toggleSelectQueryModal: false
        }
    }

    UNSAFE_componentWillMount = () =>{
        const {widgetCreationMetadata,config} = this.props;
        if(config.from === ""){
            let measurementList = generateMeasurementList(widgetCreationMetadata)
            this.setState({
                measurementList:measurementList
            })
        }
        else{
            let configData = JSON.parse(JSON.stringify(config));
            if(widgetCreationMetadata.measurements.find(x => x.measurement === configData.from)){
                let obj = prePopulateStateData(configData.from,widgetCreationMetadata);
                this.setState({
                    measurementList:obj.measurementList,
                    fieldsAndTagList:obj.fieldsAndTagList,
                    fieldsList:obj.fieldsList,
                    showSelectAndWhere:true,
                })
            }
            else{
                let measurementList = generateMeasurementList(widgetCreationMetadata)
                this.setState({
                    measurementList:measurementList
                })
            }
        }
    }

    UNSAFE_componentWillReceiveProps = (nextProps) =>{
        const {widgetCreationMetadata,config} = nextProps
        let configData = JSON.parse(JSON.stringify(config));
        if(configData.from !== ""){
            if(widgetCreationMetadata.measurements.find(x => x.measurement === configData.from)){
                let obj = generateFieldsAndTagsList(configData.from,widgetCreationMetadata);
                if(config.from !== this.props.config.from){
                    this.setState({
                        fieldsAndTagList:obj.fieldsAndTagList,
                        fieldsList:obj.fieldsList,
                        showSelectAndWhere:false
                    },() =>{
                        const {config,resetQuery} = this.props;
                        resetQuery(null,[],config.from,[]);
                    });
                }
                else{
                    this.setState({
                        fieldsAndTagList:obj.fieldsAndTagList,
                        fieldsList:obj.fieldsList,
                        showSelectAndWhere:true
                    });
                }
            }
            else{
                this.setState({
                    showSelectAndWhere:false
                })
            }
        }
        else{
            this.setState({
                showSelectAndWhere:false
            })
        }
    }

    onQueryChange = (obj) => {
        const {updateQuery} = this.props;
        updateQuery({
            key: 'where',
            value: obj
        })
    }

    updateSelectQuery = (val) =>{
        const {updateQuery} = this.props;
        updateQuery({
            key: 'select',
            value: val
        });
    }

    handleMeasurementChange = (event) => {
        const {updateQuery} = this.props;
        updateQuery({
            key: 'from',
            value: event.target.value
        })
    }

    getTagList = () =>{
        const {widgetCreationMetadata,config} = this.props;
        return getTagList(config.from,widgetCreationMetadata,false) 
    }

    updateGroupByQuery = (args) =>{
        const {updateQuery} = this.props;
        updateQuery(args);
    }

    render(){
        const {setMessage,config} = this.props;
        const { showSelectAndWhere,measurementList,fieldsAndTagList,fieldsList } = this.state;
        let measurementOptions = [<option key={0} value="">Select a measurement</option>];
        measurementList.forEach(function (sourceOption, i) {
            measurementOptions.push(<option key={i+1} value={sourceOption}>{sourceOption}</option>);
        });
        return(
            <React.Fragment>
                <FormGroup>
                    <Label for="selectField">Measurement</Label>
                    <Input required type="select" name="selectField" className="custom-select" id="selectField"
                        defaultValue={config.from} onChange={this.handleMeasurementChange}>
                        {measurementOptions}
                    </Input>
                </FormGroup>
                {showSelectAndWhere?
                    <React.Fragment>
                        <SelectQuery
                            fieldsList={fieldsList}
                            updateSelectQuery={this.updateSelectQuery}
                            config={config}
                            tags={this.getTagList()}
                        />
                        <FormGroup>
                            <Label> Filters </Label>
                            <CustomQueryBuilder
                                fields={fieldsAndTagList}
                                query={config.where}
                                onQueryChange={this.onQueryChange}
                                setMessage={setMessage}
                            />
                        </FormGroup>
                        <FormGroup>
                            <GroupByQuery
                                tags={this.getTagList()}
                                config={config}
                                updateGroupByQuery={this.updateGroupByQuery}
                            />
                        </FormGroup>
                    </React.Fragment>:
                    null
                }
            </React.Fragment>
        )
    }
}

QueryBuilder.propTypes = {
    widgetCreationMetadata: PropTypes.object,
    config: PropTypes.object,
    setMessage: PropTypes.func,
    updateQuery: PropTypes.func,
    resetQuery: PropTypes.func
}


export default QueryBuilder;