import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactTable from "react-table";
import Pagination from "./pagination";
import CsvDownloader from 'react-csv-downloader';
import {computeColumnsAndCSV,computeTableData,getListOfKeys,setTimeFlag} from './utils';
import Alert from '../../common/custom-alert';
import LoadingImage from '../../../../img/loader.gif';
class Widget extends Component {
    constructor(props){
        super(props);
        this.state = {
            toggleFilter : false,
            pageSize:5
        }
    }

    UNSAFE_componentWillReceiveProps(newProps) {
        if(this.props.metadata.h != newProps.metadata.h )
        {
            if(newProps.metadata.h>24)
                this.setState({pageSize: 10});
            else
                this.setState({pageSize: 5});
        }
    }

    toggleFilterable = () =>{
        this.setState({
            toggleFilter: !this.state.toggleFilter
        })
    }

    downloadCSV = () => {
        this.props.downloadClickHandler(this.props.metadata.id);
    }

    changePageSize = (val) =>{
        this.setState({
            defaultPageSize:val
        })
    }

    onClick(e){
        e.preventDefault();
        e.stopPropagation();
        let input = e.target;
        input.blur();
        input.focus();
    }
    
   
    getEditDeleteSection = () => {
        const {deleteWidget, getWidgetConfigData, metadata} = this.props;
        return (
            <div className="d-flex flex-column action-drawer pt-1 pb-2">
                <button className="f-22" onClick={getWidgetConfigData.bind(this, metadata.id)}><i className="icon-edit"/></button>
                <button className="f-22" onClick={deleteWidget.bind(this, metadata.id)}><i className="icon-delete"/></button>
            </div>
        );
    }

    render() {
        const {metadata, widgetData, propertyName, editDeleteFlag} = this.props;
        const {toggleFilter} = this.state;
        let tableData = []
        let csvColumns = []
        let columns = []
        
        if(widgetData && widgetData[propertyName] && widgetData[propertyName].data && widgetData[propertyName].data.length > 0){
            let timeFlag = setTimeFlag(metadata.config)
            let obj = computeColumnsAndCSV(getListOfKeys(widgetData[propertyName].data[0],timeFlag),timeFlag);
            csvColumns = obj.csvColumns;
            columns = obj.columns;
            tableData = computeTableData(widgetData[propertyName].data,timeFlag);
        }
        let msg = widgetData && widgetData.errors && widgetData.errors[propertyName]?widgetData.errors[propertyName]:""
        return(
            <div className='card card-shadow overflow-hidden h-100 w-100' key={metadata.id} data-grid={metadata} >
                <div className="card-header">
                    { editDeleteFlag ? this.getEditDeleteSection() : "" }
                    <div className="d-flex align-items-center">
                        <i className="icon-table-s align-self-center mr-2"/>
                        {metadata.name && metadata.name.length > 0 ? metadata.name : 'Table'}
                        <span className="ml-auto">
                            <button className="d-inline-flex ml-2 p-1 btn-sm btn btn-secondary" onClick={this.toggleFilterable}>
                                <i className="icon-search"/>
                            </button>
                            <button className="d-inline-flex ml-2 p-1 btn-sm btn btn-secondary" onClick={this.downloadCSV}>
                                <i className="icon-download"/>
                            </button>
                            <CsvDownloader filename="TableData" columns={csvColumns} datas={tableData}>
                                <a id={metadata.id+"-csv"} style={{display: "none"}}/>
                            </CsvDownloader>
                        </span>
                    </div>
                </div>
                <Alert color="danger">{msg}</Alert>
                {   widgetData[propertyName] && widgetData[propertyName].data ?
                    widgetData[propertyName].data.length > 0 ?
                        <div className="card-body overflow-auto p-0 m-0" onClick={(e) => {this.onClick(e)}}>
                            <ReactTable
                                key={this.state.pageSize}
                                PaginationComponent={Pagination}
                                data={tableData}
                                filterable={toggleFilter}
                                columns={columns}
                                defaultPageSize={this.state.pageSize}
                                showPaginationTop={true}
                                showPaginationBottom= {false}
                                className="-striped -highlight"
                            />
                        </div>
                        : 
                        <div className="card-body overflow-auto py-0 my-3 d-block align-items-center">
                            <div className="d-flex h-100 justify-content-center">No data</div>
                        </div>
                    :
                    <div className="d-flex h-100 justify-content-center">
                        <img className="d-flex align-self-center" src={LoadingImage} />
                    </div>
                }
                <div className="card-footer">
                    <div>{metadata.description?metadata.description : ' '}</div>
                </div>
            </div>
        );
    }
}

Widget.propTypes = {
    editDeleteFlag: PropTypes.bool,
    propertyName: PropTypes.string,
    metadata: PropTypes.object,
    widgetData: PropTypes.object,
    deleteWidget: PropTypes.func,
    getWidgetConfigData: PropTypes.func,
    downloadClickHandler: PropTypes.func,
    deleteWidgetData: PropTypes.func
}

export default Widget;