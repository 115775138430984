import React from "react";
import PropTypes from 'prop-types';
import {Modal, ModalFooter, ModalBody, ModalHeader, Button} from 'reactstrap';

const NoWidgetSelected = ({modal, closeModal, showWidgetSelectionPage}) => {
    return (
        <Modal isOpen={modal} backdrop="static" fade={false}>
            <ModalHeader toggle={closeModal} charCode= "x">Configure Widget</ModalHeader>
            <ModalBody>
                <div>No widget selected</div>
            </ModalBody>
            <ModalFooter>
                <Button color="primary" onClick={showWidgetSelectionPage}>Go Back</Button>
            </ModalFooter>
        </Modal>
    );
};

NoWidgetSelected.propTypes = {
    modal: PropTypes.bool,
    closeModal: PropTypes.func,
    showWidgetSelectionPage: PropTypes.func
}

export default NoWidgetSelected;
